import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import { useEffect, useMemo, useState } from 'react';
import Row from 'antd/lib/row';
import { IDateRange } from '../../../pages/interface';
import { eachMonthOfInterval, eachQuarterOfInterval, eachYearOfInterval, endOfMonth, format, startOfMonth } from 'date-fns';
import { upperFirst } from 'lodash';
import { useAuth } from '../../../domain/auth/useAuth';
import { UserType } from '../../../domain/user/interface';
import { useCompanies } from '../globalCompanySelector/CompanyContext';
import { ICompany } from '../../../domain/company/interface';
import { SEPARATOR } from '../../../domain/commonConst';

interface IDateSelectorOption {
    label: string;
    value: string;
}

export enum DatePeriod {
    ANNUALLY = 'annually',
    QUARTERLY = 'quarterly',
    MONTHLY = 'monthly',
}

export const DATE_FORMAT = {
    [DatePeriod.ANNUALLY]: 'yyyy',
    [DatePeriod.QUARTERLY]: 'QQQ yyyy',
    [DatePeriod.MONTHLY]: 'MMMM yyyy',
};

export const DEFAULT_START_OF_PERIOD = new Date('2021-01-01');
export const DEFAULT_PERIOD_OPTIONS: DatePeriod[] = [DatePeriod.ANNUALLY, DatePeriod.QUARTERLY, DatePeriod.MONTHLY];

export const DEFAULT_PERIOD = {
    start: +startOfMonth(new Date()),
    end: +endOfMonth(new Date()),
};

const getPeriodOptions = (optionType: string[]) => optionType.map(period => ({ label: upperFirst(period), value: period }));

const getQuarterOptions = (start: Date, end: Date) => {
    const dateQuarters = eachQuarterOfInterval({ start, end }).reverse();

    return dateQuarters.map(dateQuarter => {
        return {
            label: format(dateQuarter, DATE_FORMAT[DatePeriod.QUARTERLY]),
            value: `${+startOfQuarter(dateQuarter)}${SEPARATOR}${+endOfQuarter(dateQuarter)}`,
        };
    });
};

const getYearOptions = (start: Date, end: Date) => {
    const dateYears = eachYearOfInterval({ start, end }).reverse();
    return dateYears.map(dateYear => {
        return {
            label: format(dateYear, DATE_FORMAT[DatePeriod.ANNUALLY]),
            value: `${+startOfYear(dateYear)}${SEPARATOR}${+endOfYear(dateYear)}`,
        };
    });
};

const getMonthOptions = (start: Date, end: Date) => {
    const dateMonths = eachMonthOfInterval({ start, end }).reverse();

    return dateMonths.map(dateMonth => ({
        label: format(dateMonth, DATE_FORMAT[DatePeriod.MONTHLY]),
        value: `${+startOfMonth(dateMonth)}${SEPARATOR}${+endOfMonth(dateMonth)}`,
    }));
};

const getOptions: { [key: string]: Function } = {
    [DatePeriod.ANNUALLY]: getYearOptions,
    [DatePeriod.QUARTERLY]: getQuarterOptions,
    [DatePeriod.MONTHLY]: getMonthOptions,
};

export const getDateRangeOptions = (optionsType: string[], startOfPeriod: Date) => {
    const optionValueMap = new Map<string, IDateSelectorOption[]>();
    const periodOptions: { label: string; value: string }[] = getPeriodOptions(optionsType);

    const start = startOfPeriod;
    const end = new Date();

    optionsType.forEach(optionType => {
        optionValueMap.set(optionType, getOptions[optionType](start, end));
    });

    return { periodOptions, optionValueMap };
};

export const toSelectedOption = (selectedPeriod: IDateRange, optionValueMap: Map<string, IDateSelectorOption[]>) => {
    const result: any = {};
    const formattedSelectedPeriod = `${+selectedPeriod.start}${SEPARATOR}${+selectedPeriod.end}`;

    for (const [key, value] of optionValueMap.entries()) {
        value.forEach((el: IDateSelectorOption) => {
            if (el.value === formattedSelectedPeriod) {
                result.period = key;
                result.datePeriod = el.value;
            }
        });
    }

    return result;
};

interface IDateSelector {
    optionsType?: DatePeriod[];
    startOfPeriod?: Date;
    disabled?: boolean;
    onChange: Function;
    selectedPeriod: IDateRange;
}

const getCompanyCreationDate = (companies: ICompany[], userCompanyId: number) => {
    const userCompany = companies.find(company => company.company_id === userCompanyId);
    const dateCreation = userCompany ? startOfYear(new Date(userCompany?.company_date_created)) : DEFAULT_START_OF_PERIOD;
    return dateCreation;
};

export const DatePeriodSelector = ({
    selectedPeriod,
    onChange,
    optionsType = DEFAULT_PERIOD_OPTIONS,
    startOfPeriod = DEFAULT_START_OF_PERIOD,
    disabled = false,
}: IDateSelector) => {
    const auth = useAuth()!;
    const { companies } = useCompanies()!;
    const actualStartOfPeriod = useMemo(
        () =>
            [UserType.CUSTOMER, UserType.OPERATOR].includes(auth.user?.user_type!)
                ? getCompanyCreationDate(companies, auth.user?.company_id!)
                : startOfPeriod,
        [auth.user]
    );
    const { periodOptions, optionValueMap } = useMemo(
        () => getDateRangeOptions(optionsType, actualStartOfPeriod),
        [optionsType, actualStartOfPeriod]
    );
    const [period, setPeriod] = useState<string>('');
    const [periodOption, setPeriodOption] = useState<string>();

    useEffect(() => {
        const { period, datePeriod } = toSelectedOption(selectedPeriod, optionValueMap);
        setPeriod(period);
        setPeriodOption(datePeriod);
    }, [selectedPeriod]);

    const onSelectPeriodOption = (value: string) => {
        const [start, end] = value.split(SEPARATOR);
        onChange({ start: +start, end: +end });
    };

    const onSelectPeriod = (value: string) => {
        setPeriod(value);
        const periodOptions = optionValueMap.get(value)!;
        onSelectPeriodOption(periodOptions[0].value);
    };

    return (
        <Row align="middle" data-cy="date-period-selector">
            <Typography.Text strong>Select Time Period:</Typography.Text>
            <Select
                value={period}
                style={{ width: '170px', marginLeft: '8px' }}
                key="time-period"
                size="large"
                options={periodOptions}
                onChange={onSelectPeriod}
                disabled={disabled}
            />
            &nbsp; - &nbsp;
            <Select
                style={{ width: '150px' }}
                key="time-period-dates"
                size="large"
                options={optionValueMap.get(period)}
                onChange={onSelectPeriodOption}
                value={periodOption}
                placeholder="Select period"
                disabled={disabled}
            />
        </Row>
    );
};
