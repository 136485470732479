import { omit } from 'lodash';
import { API } from '../api';
import { IFeedback } from './interface';
import { readAsBlob } from '../common/readAsBlob';

export const createFeedback = async (feedback: IFeedback) => {
    const formData = await feedbackToFormData(feedback);

    const response = await API.fetch('/customer-feedback', {
        method: 'post',
        body: formData,
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot create feedback!`);
};

const feedbackToFormData = async (feedback: IFeedback) => {
    const formData = new FormData();

    if (feedback.files?.length) {
        for await (const file of feedback.files) {
            const blob = await readAsBlob(file as any);
            formData.append('files', blob, file.name);
        }
    }

    Object.entries(omit(feedback, ['files'])).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return formData;
};
