import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Alert from 'antd/lib/alert';
import Space from 'antd/lib/space';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import Row from 'antd/lib/row';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import { CsvParsingErrors } from '../../../../components/CsvParsingErrors/CsvParsingErrors';
import { ICleanResponseTrigger } from '../../../../domain/clean-response/interface';
import { exportCleanResponseTemplate, importCleanResponseTriggers } from '../../../../domain/clean-response';
import { parseCleanResponseTriggersCsvData } from './parseCleanResponseTriggersCsvData';

interface ICleanResponseFileUploadModalProps {
    onClose: Function;
    company: number | null;
}

const prepareErrors = (errors: any) => {
    const parsedError = JSON.parse(errors.message || '');
    return parsedError.map((error: any) => `In row ${error.rowIndex + 1}: ${error.error}. `);
};

export const CleanResponseFileUploadModal: React.FC<ICleanResponseFileUploadModalProps> = ({ onClose, company }) => {
    const [form] = Form.useForm();
    const [file, setFile] = useState<File | null>(null);
    const [parsedValue, setParsedValue] = useState<ICleanResponseTrigger[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    const onFormFinish = async () => {
        if (parsedValue.length === 0) return;
        setLoading(true);

        try {
            await importCleanResponseTriggers(parsedValue);
            onClose(parsedValue);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            const preparedErrors = prepareErrors(error);
            preparedErrors && setErrors(preparedErrors);
        }
    };

    const parseCsv = (content: string) => {
        const [errors, result] = parseCleanResponseTriggersCsvData(content, company);
        errors.length ? setErrors(errors) : setParsedValue(result);
    };

    const closeModal = () => {
        onClose();
    };

    const uploadDraggerProps: DraggerProps = {
        accept: '.csv',
        multiple: false,
        maxCount: 1,
        onRemove: () => {
            setFile(null);
            setErrors([]);
        },
        onChange: (info: any) => {
            if (info.file.status) return;
            setFile(info.file);
        },
        beforeUpload: (file: File) => {
            setErrors([]);

            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e: any) => {
                const content = e.target?.result;
                parseCsv(content);
            };

            return false;
        },
    };

    return (
        <Modal
            open
            destroyOnClose
            data-cy='file-upload-modal'
            title='Import Clean Response Triggers'
            onCancel={closeModal}
            footer={[
                <Button key='clean-response-modal-cancel' onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key='clean-response-modal-submit' type='primary' disabled={!file && !errors.length} loading={loading} onClick={form.submit}>
                    Replace Triggers
                </Button>,
            ]}
        >
            <Form form={form} name='import-clean-response-triggers-form' preserve={false} onFinish={onFormFinish}>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Alert showIcon message='All existing Clean Response triggers will be replaced.' type='warning' style={{ marginBottom: '8px' }} />
                    <Row justify='space-around'>
                        <Button type='link' onClick={exportCleanResponseTemplate} style={{ paddingLeft: 0 }}>
                            Download Template
                        </Button>
                    </Row>

                    <Dragger {...uploadDraggerProps}>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                        <p className='ant-upload-hint'>Allowed file extensions: .csv</p>
                        <p className='ant-upload-hint'>You can download your current triggers or template.</p>
                    </Dragger>

                    <CsvParsingErrors errors={errors} />
                </Space>
            </Form>
        </Modal>
    );
};
