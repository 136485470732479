import { useState } from 'react';
import Dropdown from 'antd/lib/dropdown';
import React from 'react';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Button from 'antd/lib/button';

export interface IExportButtonProps {
    actions: { key: string; title: string; fn: () => void }[];
    [key: string]: any;
}

export const ExportButton = ({ actions, ...props }: IExportButtonProps) => {
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    function actionWrapper(fn: Function) {
        return async () => {
            try {
                setExportLoading(true);
                await fn();
            } catch (err: any) {
                throw err;
            }
            setExportLoading(false);
        };
    }

    let menu: any = null;
    const defaultAction = actions[0];

    if (actions?.length > 1) {
        const menuActions = actions.slice(1);
        menu = menuActions.map(action => ({
            key: action.key,
            label: action.title,
            onClick: actionWrapper(action.fn),
        }));
    }

    return menu ? (
        <Dropdown.Button
            size="large"
            key="export-dropdown-button"
            data-cy="export-data"
            menu={{ items: menu }}
            onClick={actionWrapper(defaultAction.fn)}
            buttonsRender={([leftButton, rightButton]) => [
                leftButton,
                React.cloneElement(rightButton as React.ReactElement<any, string>, { loading: exportLoading }),
            ]}
            {...props}
        >
            {<DownloadOutlined />} {defaultAction.title}
        </Dropdown.Button>
    ) : (
        <Button
            data-cy="export-data"
            size="large"
            key={defaultAction.key}
            onClick={actionWrapper(defaultAction.fn)}
            icon={<DownloadOutlined />}
            loading={exportLoading}
        >
            {defaultAction.title}
        </Button>
    );
};
