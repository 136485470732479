import Checkbox from 'antd/lib/checkbox';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import './notificationsSettings.css';

const notificationConfigs = [
    {
        title: 'Event Created:',
        paths: [
            ['notification_settings', 'event_created', 'email'],
            ['notification_settings', 'event_created', 'sms'],
        ],
    },
    {
        title: 'Pre Event:',
        paths: [
            ['notification_settings', 'pre_event', 'email'],
            ['notification_settings', 'pre_event', 'sms'],
        ],
    },
    {
        title: 'Event Started:',
        paths: [
            ['notification_settings', 'event_started', 'email'],
            ['notification_settings', 'event_started', 'sms'],
        ],
    },
    {
        title: 'Event Completed:',
        paths: [
            ['notification_settings', 'event_completed', 'email'],
            ['notification_settings', 'event_completed', 'sms'],
        ],
    },
];

export const NotificationsSettings = () => (
    <>
        <Divider plain>Notification</Divider>
        <table className="notification-table">
            <thead>
                <tr>
                    <th></th>
                    <th>Email</th>
                    <th>Sms</th>
                </tr>
            </thead>
            <tbody>
                {notificationConfigs.map(notificationConfig => (
                    <tr key={notificationConfig.title}>
                        <td>
                            <Typography.Text strong>{notificationConfig.title}&nbsp;</Typography.Text>
                        </td>
                        {notificationConfig.paths.map(path => (
                            <td key={`${path}`}>
                                <Form.Item name={path} initialValue={true} valuePropName="checked" noStyle>
                                    <Checkbox />
                                </Form.Item>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </>
);
