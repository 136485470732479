import App from 'antd/lib/app';
import { useEffect, useMemo, useState } from 'react';
import { mapLocationPrices } from '../../domain/market-prices/mapLocationPrices';
import { useRealTimePriceListQuery } from '../../domain/market-prices/queries';
import { ISiteLocation } from '../../domain/site/interface';
import { useSiteLocationListQuery } from '../../domain/site/queries';

export const useFetchMapLocations = (companyId: number | null) => {
    const { notification } = App.useApp();
    const siteLocationsRes = useSiteLocationListQuery({ companyId });
    const pricesRes = useRealTimePriceListQuery({
        refetchOnMount: false,
    });

    const locations = useMemo(() => siteLocationsRes.data || [], [siteLocationsRes.data]);
    const prices = useMemo(() => pricesRes.data || [], [pricesRes.data]);

    const isError = siteLocationsRes.isError || pricesRes.isError;
    const error = siteLocationsRes.error || pricesRes.error;

    const [mappedLocations, setMappedLocations] = useState<ISiteLocation[]>([]);

    if (isError) {
        notification.error({ key: 'fetch-locations-error', message: error?.message || 'Cannot get locations' });
    }

    useEffect(() => {
        if (!locations.length || !prices.length) {
            setMappedLocations([]);
            return;
        }

        setMappedLocations(mapLocationPrices(locations, prices));
    }, [
        locations, siteLocationsRes.isFetching,
        prices, pricesRes.isFetching,
    ]);

    return {
        isLoading: siteLocationsRes.isFetching || pricesRes.isFetching,
        locations: mappedLocations,
    };
};
