import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import App from 'antd/lib/app';

import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import UserOutlined from '@ant-design/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import Typography from 'antd/lib/typography';

import { useAuth } from '../../domain/auth/useAuth';
import { MfaSetupModal } from '../../components/mfaSetupModal/MfaSetupModal';
import { RequiredConfirmationModal } from '../../components/modals/requiredConfirmationModal/requiredConfirmationModal';
import { IConfirmationCode, IMfaSetting, IUser, MfaRequiredError, MfaSetupError } from '../../domain/user/interface';
import logoImg from '../../logo.png';
import { useDocumentTitle } from '../../components/useDocumentTitle';

interface LoginData {
    email: string;
    password: string;
    remember: boolean;
}

export const Login = () => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    useDocumentTitle('Login');

    const [form] = Form.useForm();
    const [mfaSetting, setMfaSetting] = useState<IMfaSetting | Partial<IMfaSetting>>();
    const [user, setUser] = useState<IUser | null>(null);
    const [showMfaSetup, setShowMfaSetup] = useState<boolean>(false);

    let from = '/';
    const search = new URLSearchParams(location.search);
    const returnPath = search.get('return_path');
    if (returnPath) {
        from = decodeURIComponent(returnPath);
    }

    useEffect(() => {
        if (auth?.user?.user_id && auth.checkUserSession()) {
            navigation(from, { replace: true });
        }
    }, [auth]);

    const onFinish = async (values: LoginData, confirmationCode?: IConfirmationCode) => {
        setLoading(true);
        try {
            await auth?.signin({ data: values, confirmationCode })!;
        } catch (error: any) {
            if (error instanceof MfaRequiredError) {
                setMfaSetting(error);
            } else if (error instanceof MfaSetupError) {
                setUser(error.user);
                setShowMfaSetup(true);
            } else {
                notification.error({
                    key: 'signin-error',
                    message: 'SignIn Error',
                    description: error?.message || '',
                });
            }
        }
        setLoading(false);
    };

    const handleSubmitConfirmationModal = async (confirmationCode?: IConfirmationCode) => {
        try {
            closeConfirmationModal();

            /** resubmit login form but now with MFA code */
            const formValues = await form.validateFields();
            onFinish(formValues, confirmationCode);
        } catch (error: any) {
            notification.error({
                key: 'signin-error',
                message: 'SignIn Error',
                description: error?.message || '',
            });
        }
    };

    const closeConfirmationModal = () => setMfaSetting({});

    const onCloseMfaSetup = () => setShowMfaSetup(false);

    const handleUpdateUuid = (uuid: string) => setMfaSetting({ ...mfaSetting, uuid: uuid });

    return (
        <>
            <img className='logo' src={logoImg} alt='Enersponse logo' />
            <Form form={form} name='normal_login' className='login-form' initialValues={{ remember: true }}
                onFinish={onFinish}>
                <Form.Item
                    name='email'
                    rules={[
                        { required: true, message: 'Please enter your email!' },
                        { type: 'email', message: 'Please enter valid email!' },
                    ]}
                >
                    <Input data-cy='email-input' prefix={<UserOutlined className='site-form-item-icon' />}
                        placeholder='Email' autoFocus />
                </Form.Item>
                <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input.Password data-cy='password-input' prefix={<LockOutlined className='site-form-item-icon' />} placeholder='Password' />
                </Form.Item>
                <Form.Item>
                    <Form.Item name='remember' valuePropName='checked' noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <Link data-cy='navigate-to-reset' to='/reset-password'>Forgot password</Link>
                </Form.Item>
                <Form.Item noStyle>
                    <Button data-cy='login-submit' type='primary' htmlType='submit' loading={loading} block>
                        Log in
                    </Button>
                </Form.Item>

                <Typography.Paragraph style={{ fontSize: '10px', marginBottom: 0, marginTop: '5px' }}>
                    This site is protected by reCAPTCHA and the Google
                    <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
                    <a href='https://policies.google.com/terms'> Terms of Service</a> apply.
                </Typography.Paragraph>
            </Form>
            {!isEmpty(mfaSetting) && (
                <RequiredConfirmationModal
                    resendSettings={{
                        uuid: mfaSetting?.uuid || '',
                        updateUuid: handleUpdateUuid,
                        infoMessage: mfaSetting?.infoMessage,
                        email: form.getFieldValue('email'),
                    }}
                    required={mfaSetting?.require || []}
                    onSubmit={handleSubmitConfirmationModal}
                    onCloseModal={closeConfirmationModal}
                    error={mfaSetting?.message}
                />
            )}
            {showMfaSetup && <MfaSetupModal user={user!} onClose={onCloseMfaSetup} />}
        </>
    );
};
