import SoundOutlined from '@ant-design/icons/lib/icons/SoundOutlined';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Empty from 'antd/lib/empty';
import Typography from 'antd/lib/typography';
import Card from 'antd/lib/card/Card';
import { useNavigate } from 'react-router-dom';
import { currencyUSDFormatter } from '../../domain/common/currencyFormatters';
import { IPriceResponseTrigger } from '../../domain/price-response/interface';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { useCompanies } from '../selectors/globalCompanySelector/CompanyContext';
import { ReactComponent as TargetIcon } from '../icons/target.svg';

interface ITriggerPriceWidget {
    trigger: IPriceResponseTrigger | null;
}

export const TriggerPriceWidget = ({ trigger }: ITriggerPriceWidget) => {
    const { company } = useCompanies()!;
    const navigation = useNavigate();

    const navigateToPriceResponsePage = () => {
        const companyQuery = company ? `companyId=${company}` : '';
        navigation(`/trigger/price-response?sorter.field=date_modified&sorter.order=descend&${companyQuery}`);
    };

    return (
        <Card
            title={
                <Row justify='center' align='middle'>
                    <TargetIcon className='target-icon' />
                    <Typography.Title level={4} style={{ color: '#fff', margin: 0 }}>
                        Current Trigger Price
                    </Typography.Title>
                </Row>
            }
            headStyle={{
                background: '#2fadab',
            }}
            className='trigger-price-card'
        >
            <div className='content-background content-row'>
                {trigger ? (
                    <ContentBlock
                        value={currencyUSDFormatter.format(trigger.price)}
                        description='Per MWh'
                        valueClass='content-value trigger-price'
                        descriptionClass='content-description'
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Currently, you have no triggers' />
                )}

                <Row align='middle' justify='center'>
                    <Button type='primary' icon={<SoundOutlined />} onClick={navigateToPriceResponsePage} size='large'>
                        {trigger ? 'Smart Trigger Price' : 'Create Smart Trigger'}
                    </Button>
                </Row>
            </div>
        </Card>
    );
};
