import React from 'react';
import Typography from 'antd/lib/typography';
import Space from 'antd/lib/space';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import { HOUR_MINUTES } from '../../domain/commonConst';

interface IEventDurationInputProps {
    min?: number;
    max?: number;
    value: number;
    name: string;
    onChange: (value: number) => void;
}

export const EventDurationInput: React.FC<IEventDurationInputProps> = ({
    min = 0,
    max = 8,
    value,
    onChange,
    name
}) => {
    const durationValidator = (_: any, duration: number) => {
        if (duration <= 1 / HOUR_MINUTES) {
            return Promise.reject();
        }

        return Promise.resolve();
    };

    return (
        <Form.Item
            required
            htmlFor='duration'
            label={<Typography.Text strong>Duration (hours)</Typography.Text>}
        >
            <Space>
                <Form.Item
                    noStyle
                    name={name}
                    rules={[{ validator: durationValidator, message: `Duration must be greater or equal than 1 minute` }]}
                >
                    <InputNumber
                        id='duration'
                        style={{ width: '100%' }}
                        onChange={onChange as any}
                        value={value}
                        min={min} max={max} step={0.25}
                        size='large'
                    />
                </Form.Item>
            </Space>
        </Form.Item>
    );
};