import Modal from 'antd/lib/modal';
import { App, Table } from 'antd';
import { ICaisoRegistration, ICaisoRegistrationLocation } from 'src/domain/caiso/drrs/registrations/interface';
import { WithEmptyDataTable } from 'src/components/table/withEmptyDataTable';
import { useCaisoRegistrationLocationsListQuery } from 'src/domain/caiso/drrs/registrations/queries';
import { useEffect, useState } from 'react';
import { ICaisoPageState } from 'src/pages/caiso/interface';
import { DEFAULT_PAGINATION } from 'src/domain/commonConst';
import { IPaginationMeta } from 'src/domain/IPagination';
import { ColumnsType } from 'antd/lib/table';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { sortingComparator } from 'src/domain/caiso/drrs/utils';
import { PageHeader } from 'src/components/pageHeader/pageHeader';
import Button from 'antd/lib/button';
import { DownloadOutlined } from '@ant-design/icons';
import { exportRegistrationLocations } from 'src/domain/caiso/drrs/registrations';

interface IDrrsRegistrationModalProps {
    registration: ICaisoRegistration;
    onClose: Function;
}

export function DrrsRegistrationModal({ registration, onClose }: IDrrsRegistrationModalProps) {
    const { notification } = App.useApp();
    const [pageState, setPageState] = useState<Omit<ICaisoPageState, 'search'>>({
        pagination: DEFAULT_PAGINATION,
        sorter: { field: '', order: 'ascend' },
        filter: {},
    });
    const { data, isLoading, error } = useCaisoRegistrationLocationsListQuery(registration.id, pageState);
    const [exportLoading, setExportLoading] = useState(false);

    const locations = data?.data || [];
    const meta = (data?.meta ?? { total: 0 }) as IPaginationMeta;
    const detailTitle = `Registration Details: ${registration.name}`;

    useEffect(() => {
        if (!isLoading && error) {
            notification.error({
                key: 'caiso-registration-locations-list-error',
                message: (error as Error)?.message || 'Cannot load registration locations!',
            });
        }
    }, [isLoading, error]);

    const handleExportRegistrationLocations = async () => {
        try {
            setExportLoading(true);
            await exportRegistrationLocations(registration.id, registration.name, pageState);
        } catch (error) {
            console.error('Export failed:', error);
            alert('Export failed. Please try again.');
        } finally {
            setExportLoading(false);
        }
    };

    function closeModal() {
        onClose();
    }

    function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageState({ ...pageState, ...preparedParams });
    }

    const columns: ColumnsType<ICaisoRegistrationLocation> = [
        {
            title: 'Location Name',
            dataIndex: 'name',
            sorter: (a, b) => sortingComparator(a.name, b.name),
        },
        {
            title: 'SAN',
            dataIndex: 'san',
            sorter: (a, b) => sortingComparator(a.san, b.san),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            sorter: (a, b) => sortingComparator(a.address, b.address),
            render: (text, record) => {
                const { address, address_city, address_state, address_zip } = record;
                return `${address}, ${address_city}, ${address_state} ${address_zip}`;
            },
        },
    ];

    return (
        <Modal open destroyOnClose width={'75%'} onCancel={closeModal} footer={null}>
            <PageHeader
                pageTitle= {detailTitle}
                actions={[
                    <Button
                    data-cy="download-data"
                    onClick={handleExportRegistrationLocations}
                    loading={exportLoading}
                    style={{ marginRight: 20 }}
                    size="middle"
                    >
                        {!exportLoading && <DownloadOutlined />} Download CSV
                    </Button>,
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={locations}
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>
        </Modal>
    );
}
