import { API } from '../api';
import { LOCAL_STORAGE_PREFIX } from '../commonConst';
import { fetchUser } from '../user';
import { IUser, MfaRequiredError, MfaSetupError } from '../user/interface';

interface IToken {
    accessToken: string;
    expiresAt: number;
}

export const Session = {
    getUser(): IUser | null {
        try {
            const item = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.user`);
            if (!item) return null;
            return JSON.parse(item);
        } catch (err) {
            // invalid user data - logout
            this.clear();
            return null;
        }
    },

    setUser(user: IUser) {
        localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.user`, JSON.stringify(user));
    },

    getToken(): IToken | null {
        try {
            const item = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.token`);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            this.clear();
            return null;
        }
    },

    setToken(token: IToken) {
        localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.token`, JSON.stringify(token));
    },

    clear() {
        localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}.token`);
        localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}.user`);
    },
};

interface SigninData {
    email: string;
    password: string;
    remember: boolean;
}

export interface ISignin {
    data: SigninData;
    confirmationCode?: any;
    reCaptchaToken?: string;
}

export const Auth = {
    isAuthenticated: false,
    async signin({ data, confirmationCode, reCaptchaToken }: ISignin): Promise<IUser> {
        const body = { email: data.email.trim(), password: data.password };
        const isConfirmationCode = confirmationCode?.code;
        const response = await API.fetch(`/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(reCaptchaToken && { 'g-response-key': reCaptchaToken }),
                ...(isConfirmationCode && { 'ener-mfa': isConfirmationCode }),
            },
            credentials: 'include',
            body: JSON.stringify(body),
        });

        const respBody: any = await response.json();

        if (response.status === 200) {
            if (respBody.type === 'api') {
                throw new Error(`This user is not allowed to use portal!`);
            }

            Auth.isAuthenticated = true;
            const tokens = { ...respBody.tokens };
            const user = { ...respBody, tokens: undefined };
            Session.setToken(tokens);
            Session.setUser(user);
            return user;
        }

        if (response.status === 400) {
            if ((respBody?.require || []).includes('mfa')) {
                throw new MfaRequiredError(respBody);
            }
            if ((respBody?.require || []).includes('mfa-setup')) {
                throw new MfaSetupError(respBody);
            }
        }

        throw new Error(respBody?.error?.message || `Signin error!`);
    },

    async refetch() {
        /** refetch user */
        const user = Session.getUser();
        if (!user) return null;

        const freshUser = await fetchUser(user.user_id);
        Session.setUser(freshUser);
        return freshUser;
    },

    signout() {
        Auth.isAuthenticated = false;
        Session.clear();
        window.location.href = '/login';
    },

    terminateSession() {
        Auth.isAuthenticated = false;
        Session.clear();
        const url = new URL(window.location.href);
        const isHomePage = url.pathname === '/';
        const returnPath = encodeURIComponent(`${url.pathname}${url.search}`);
        window.location.href = isHomePage ? '/login' : `/login?return_path=${returnPath}`;
    },
};

export const resetPassword = async (data: { email: string }) => {
    const response = await API.fetch(`/auth/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot send an email`);
};

export const resetPasswordComplete = async (data: { password: string; token: string | undefined }) => {
    const response = await API.fetch('/auth/reset-password/confirm', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot reset password`);
};

export const completeSignup = async (data: { password: string; token: string | undefined }) => {
    const response = await API.fetch('/auth/complete-signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot complete signup`);
};
