import Typography from 'antd/lib/typography';
import React, { FC } from 'react';

interface ISiteUtilityProps {
    siteUtility?: string;
}

export const SiteUtility: FC<ISiteUtilityProps> = ({ siteUtility }) => {
    const [title, subtitle] = siteUtility ? siteUtility.split('|') : [siteUtility];

    return (
        <>
            <Typography.Text>{title}</Typography.Text>
            {subtitle && (
                <>
                    <br />
                    <Typography.Text type='secondary'>{subtitle}</Typography.Text>
                </>
            )}
        </>
    );
}
