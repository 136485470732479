export enum EVENT_FREQUENCY {
    HOUR = 'hour',
    DAY = 'day',
}

export interface ISettings {
    priceResponse: {
        eventPer: EVENT_FREQUENCY;
    };
    cleanResponse: {
        eventPer: EVENT_FREQUENCY;
    }
}
