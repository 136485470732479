import { useQuery } from '@tanstack/react-query';

import { fetchCaisoSyncs } from '.';
import { QueryKey } from 'src/query-client';
import { IFetchPageQuery } from 'src/domain/IFetchQueryOptions';
import { CaisoSyncOrigin } from './interface';

export const useCaisoSyncListQuery = (origin: CaisoSyncOrigin, query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.DRRS_SYNCS, query],
        queryFn: () => fetchCaisoSyncs(origin, query),
        ...options,
    });
};
