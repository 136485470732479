import React, { useState } from 'react';
import Row from 'antd/lib/row';
import Button from 'antd/lib/button';
import { JobName } from '../../../domain/async-job/interface';
import { AsyncJobsModal } from '../AsyncJobsModal/AsyncJobsModal';

interface ISyncSalesforceButtonProps {
    jobName: JobName;
}

export const SyncSalesforceButton: React.FC<ISyncSalesforceButtonProps> = ({ jobName }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Row align='middle'>
            <Button size='large' onClick={handleModalOpen}>Salesforce Sync</Button>

            <AsyncJobsModal
                jobName={jobName}
                isModalOpen={isModalOpen}
                onClose={handleModalClose}
            />
        </Row>
    );
};
