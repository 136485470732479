import Row from 'antd/lib/row';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { WARNING_COLOR } from '../../theme';
import { ISite } from '../../domain/site/interface';

interface ISiteDeleteModal {
    site: ISite;
    isLoading: boolean;
    onClose: Function;
    onSubmit: Function;
}

export const DeleteSiteModal = ({ site, isLoading, onClose, onSubmit }: ISiteDeleteModal) => {
    const handleConfirm = async () => {
        onSubmit(site);
    };

    const handleClose = () => onClose();

    return (
        <Modal
            open
            title="Confirm Delete"
            destroyOnClose
            closeIcon
            onCancel={handleClose}
            styles={{
                footer: {
                    borderTop: 'none',
                    padding: 24,
                    paddingTop: 0,
                },
            }}
            footer={[
                <Button key="modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="delete-confirm" data-cy="delete-confirm" type="primary" onClick={handleConfirm} loading={isLoading}>
                    Ok
                </Button>,
            ]}
        >
            <Row align="middle">
                <ExclamationCircleOutlined style={{ fontSize: 22, color: WARNING_COLOR, marginRight: 8 }} />
                <Typography.Text>Deleting site {site.site_name || ''}. Are you sure?</Typography.Text>
            </Row>
        </Modal>
    );
};
