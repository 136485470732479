import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { ISite } from '../../domain/site/interface';
import HomeOutlined from '@ant-design/icons/HomeOutlined';

export interface ISiteTagProps {
    value?: ISite;
}

export const SiteTag = ({ value }: ISiteTagProps) => {
    if (!value?.site_name) return <></>;

    const { site_name, company } = value;
    const companyName = company?.company_name || '';
    const title = companyName ? `${companyName} / ${site_name}` : site_name;
    return (
        <Tooltip title={title} key={`site-${value.site_id}`}>
            <Typography className="ellipsis">
                <Tag style={{ display: 'initial' }} icon={<HomeOutlined />}>
                    {title}
                </Tag>
            </Typography>
        </Tooltip>
    );
};
