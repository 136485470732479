import { notification } from 'antd';
import { ExportButton } from '../../../components/ExportButton/ExportButton';
import { exportCSVImpact, exportPDFImpact } from '../../../domain/impact';
import { format } from 'date-fns';
import sanitaze from 'sanitize-filename';
import { IDateRange } from '../../interface';

interface IImpactExportButton {
    filter: {
        companyId: number | null;
        date: IDateRange;
    };
    companyName: string;
    [key: string]: any;
}

export const ImpactExportButton = ({ filter, companyName, ...props }: IImpactExportButton) => {
    const timePeriod = `${format(new Date(filter.date.start), 'MM-dd-yyyy')}_${format(new Date(filter.date.end), 'MM-dd-yyyy')}`;
    const fileName = `impact-${sanitaze(companyName)}-${timePeriod}`;

    const exportImpactPDFAction = async () => {
        try {
            await exportPDFImpact({ ...filter, fileName: `${fileName}.pdf` });
        } catch (error: any) {
            notification.error({ key: 'impact-export-error', message: error.message || 'Cannot export pdf impact file!' });
        }
    };

    const exportImpactCSVAction = async () => {
        try {
            await exportCSVImpact({ ...filter, fileName: `${fileName}.csv` });
        } catch (error: any) {
            notification.error({ key: 'impact-export-csv-error', message: error.message || 'Cannot export csv impact file!' });
        }
    };

    const actions = [
        {
            key: 'e-b-1',
            title: 'Export to PDF',
            fn: exportImpactPDFAction,
        },
        {
            key: 'e-b-2',
            title: 'Download Impact Statement metrics CSV',
            fn: exportImpactCSVAction,
        },
    ];

    return <ExportButton actions={actions} {...props} />;
};
