import React from 'react';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import { BASE_COLOR } from '../../../../theme';

export const CarbonIntensityMapLegends = () => {
    return (
        <div className='map-legend'>
            <Typography.Text>Carbon Intensity (lbs CO2 per MW):&nbsp;&nbsp;</Typography.Text>
            <Tag>no data</Tag>
            <Tag color='#FFFFB0'>
                <span style={{ color: BASE_COLOR }}>&nbsp;&gt; 0 &nbsp;</span>
            </Tag>
            <Tag color='rgba(224, 176, 64, 0.4)'>&gt; 200</Tag>
            <Tag color='rgba(160, 96, 48, 0.4)'>&gt; 400</Tag>
            <Tag color='rgba(96, 32, 32,  0.4)'>&gt; 600</Tag>
            <Tag color='rgba(0, 0, 16, 0.4)'>&gt; 800</Tag>
        </div>
    )
}
