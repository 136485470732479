import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { HistoryOutlined } from '@ant-design/icons';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import without from 'lodash/without';
import { AbilityContext } from '../../components/ability/can';
import { TriggerSettingsModal } from '../../components/modals/triggerSettingsModal/TriggerSettingsModal';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { SearchInput } from '../../components/searchInput/searchInput';
import { useCompanies } from '../../components/selectors/globalCompanySelector/CompanyContext';
import { ActionList } from '../../components/table/actionList/actionList';

import { getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { TriggerTimeTag } from '../../components/TriggerTimeTag';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { FORM_MODE, useFormMode } from '../../components/useFormMode';
import { WithControlledTooltip } from '../../components/withControlledTooltip';
import { currencyUSDFormatter } from '../../domain/common/currencyFormatters';
import { formatToMinutesAndHours } from '../../domain/common/dateFormatters';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { SIGNAL_LEVEL_OPTIONS, SignalType } from '../../domain/event/interface';
import { IPaginationMeta } from '../../domain/IPagination';
import { exportPriceResponseTriggers } from '../../domain/price-response';
import {
    IPriceResponseTrigger,
    PRICE_RESPONSE_ACTION,
    SPECIFIC_PRICE_RESPONSE_ACTION,
    PriceType,
    IAffectedPriceResponseTrigger,
} from '../../domain/price-response/interface';
import {
    useDeletePriceResponseTriggerMutation,
    usePriceResponseTriggerListQuery,
} from '../../domain/price-response/queries';
import { validatePagination } from '../../domain/validatePagination';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { PriceResponseFileUploadModal } from './components/PriceResponseFileUploadModal/PriceResponseFileUploadModal';
import { PriceResponseTriggerModal } from './components/PriceResponseTriggerModal';
import { marketLoadZoneToLabel } from '../../components/site/SiteMarketAndLoadZone';
import { useAuth } from '../../domain/auth/useAuth';
import { AuditTrailModal } from '../../components/modals/auditTrailModal/auditTrailModal';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { useTableSelection } from '../../components/table/useTableSelection';
import { UpdateBatchPriceResponseTriggerModal } from './components/UpdateBatchPriceResponseTriggerModal';
import { DeleteBatchPriceResponseTriggerModal } from './components/DeleteBatchPriceResponseTriggerModal';

const prepareSelectedItems = (priceResponseTriggers: IPriceResponseTrigger[]): IAffectedPriceResponseTrigger[] =>
    priceResponseTriggers.map(trigger => ({
        id: trigger.id,
        company: trigger.company.company_name,
        market: trigger.market,
        load_zone: trigger.load_zone,
        price_type: trigger.price_type,
    }));

export const PriceResponse = () => {
    const { notification, modal } = App.useApp();
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const auth = useAuth()!;

    useDocumentTitle('Price Response');

    const [trigger, setTrigger] = useState<IPriceResponseTrigger | Partial<IPriceResponseTrigger>>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [pageAction, setPageAction] = useState<PRICE_RESPONSE_ACTION | ''>();
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const { selectedKeys, selectedItems, handleSelectedItems } = useTableSelection('id');

    const { company, companies } = useCompanies()!;
    const { onSetFormMode, isEditMode } = useFormMode();

    const { mutateAsync: deleteTrigger } = useDeletePriceResponseTriggerMutation();

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.search && { search: pageState?.search }),
        ...(pageState?.companyId && { companyId: pageState?.companyId }),
        ...(pageState?.filter && { filter: pageState.filter }),
        include: 'company',
    };

    const {
        data,
        isLoading,
        refetch: refetchTriggers,
    } = usePriceResponseTriggerListQuery(listQuery, {
        keepPreviousData: true,
    });
    const triggers = data?.data || [];
    const meta = data?.meta || ({ total: 0 } as IPaginationMeta);

    function showNewPriceResponseModal() {
        setPageAction(PAGE_ACTION.NEW_EDIT);
        onSetFormMode(FORM_MODE.NEW);
        setTrigger({
            company_id: undefined,
            price: undefined,
            price_type: PriceType.REAL_TIME,
            duration: 30,
            signal: SignalType.HIGH,
            start_time: null,
            end_time: null,
            market: '',
            load_zone: '',
        });
    }

    async function onPriceResponseModalClose(trigger?: IPriceResponseTrigger) {
        setPageAction('');
        setTrigger(undefined);

        if (trigger) {
            await refetchTriggers(listQuery);
        }
    }

    async function onUpdateBatchPriceResponseModalClose(isRefreshNeeded: boolean) {
        setPageAction('');

        if (isRefreshNeeded) {
            await refetchTriggers(listQuery);
        }
    }

    async function onDeleteBatchPriceResponseModalClose(selectedKeys: number[] = []) {
        setPageAction('');

        if (selectedKeys.length) {
            const validPagination = validatePagination(pageState.pagination, meta.total - selectedKeys.length);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchTriggers({ ...pageState, pagination: validPagination });

            handleSelectedItems([], triggers);
        }
    }

    const showAuditTrailModal = (record: IPriceResponseTrigger) => () => {
        setPageAction(PAGE_ACTION.AUDIT_TRAIL);
        setTrigger(record);
    };

    function showEditPriceResponseTriggerModal(record: any) {
        return () => {
            setPageAction(PAGE_ACTION.NEW_EDIT);
            onSetFormMode(FORM_MODE.EDIT);
            setTrigger(record);
        };
    }

    function deletePriceResponseTriggerWithConfirmation(record: IPriceResponseTrigger) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting Price Response trigger for ${record.company.company_name}. Are you sure?`,
                onOk: async () => {
                    await deletePriceResponseTriggerAction(record);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };
    }

    async function deletePriceResponseTriggerAction(trigger: IPriceResponseTrigger) {
        try {
            await deleteTrigger(trigger);

            if (selectedKeys && selectedKeys.includes(trigger.id)) {
                const updatedKeys = without(selectedKeys, trigger.id);
                handleSelectedItems(updatedKeys, selectedItems);
            }

            const validPagination = validatePagination(pageState.pagination, meta.total - 1);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchTriggers({ ...pageState, pagination: validPagination });

            notification.info({ key: 'trigger-delete-info', message: 'Price Response trigger deleted' });
        } catch (error: any) {
            notification.error({
                key: 'trigger-delete-error',
                message: error.message || 'Cannot delete Price Response trigger!',
            });
        }
    }

    async function exportPriceResponseTriggersAction() {
        try {
            setExportLoading(true);
            await exportPriceResponseTriggers({ companyId: company, search: pageState?.search });
        } catch (err: any) {
            notification.error({
                key: 'price-response-export-error',
                message: err.message || 'Cannot export triggers!',
            });
        }
        setExportLoading(false);
    }

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    const actions = [
        ...(ability.can('update', 'PriceResponse')
            ? [
                {
                    title: 'Edit',
                    onClick: (record: IPriceResponseTrigger) => showEditPriceResponseTriggerModal(record),
                },
            ]
            : []),
        ...(auth.user?.isAdminRoleType()
            ? [
                {
                    title: 'Audit Trail',
                    onClick: (record: IPriceResponseTrigger) => showAuditTrailModal(record),
                    icon: <HistoryOutlined />,
                },
            ]
            : []),
        ...(ability.can('delete', 'PriceResponse')
            ? [
                {
                    title: 'Delete',
                    onClick: (record: IPriceResponseTrigger) => deletePriceResponseTriggerWithConfirmation(record),
                },
            ]
            : []),
    ];

    const onSelectRow = (selectedRows: any) => {
        handleSelectedItems(selectedRows, triggers);
    };

    function showUploadModal() {
        setPageAction(PAGE_ACTION.UPLOAD_FILE);
    }

    async function onCloseUploadModal(triggers: IPriceResponseTrigger[]) {
        setPageAction('');

        if (triggers) {
            await refetchTriggers(toUsefulParams(pageState));
        }
    }

    const columns: ColumnsType<IPriceResponseTrigger> = [
        {
            title: 'Company',
            dataIndex: ['company_id'],
            render: (text, record) => <>{record.company.company_name}</>,
        },
        {
            title: 'Price $/MWh',
            dataIndex: 'price',
            sorter: (a, b) => a.price - b.price,
            ...getColumnSortOrder('price', pageState.sorter),
            render: text => currencyUSDFormatter.format(text),
        },
        {
            title: 'Market',
            dataIndex: 'market',
            render: (text, record) => formatPriceResponseMarket(record),
            sorter: (a, b) => a.market.localeCompare(b.market),
            ...getColumnSortOrder('market', pageState.sorter),
        },
        {
            title: 'Zone',
            dataIndex: 'load_zone',
            sorter: (a, b) => a.load_zone.localeCompare(b.load_zone),
            ...getColumnSortOrder('load_zone', pageState.sorter),
            render: (text, record) => marketLoadZoneToLabel(record.market, text),
        },
        {
            title: 'Duration/Stop at Lower',
            dataIndex: 'duration',
            sorter: (a, b) => a.duration - b.duration,
            ...getColumnSortOrder('duration', pageState.sorter),
            render: (text, record) => {
                const durationText = formatToMinutesAndHours(text);
                const triggerStatus = record.cancel_when_price_lower ? '- Stop at Lower Active' : '';
                return `${durationText} ${triggerStatus}`;
            },
        },
        {
            title: 'Signal Level',
            dataIndex: 'signal',
            render: text => SIGNAL_LEVEL_OPTIONS.find(signal => +text === signal.value)?.label,
        },
        {
            title: 'Trigger time',
            render: (text, record) => <TriggerTimeTag value={record} />,
        },
        {
            key: 'action',
            width: 120,
            sorter: false,
            render: (text, record) => <ActionList actions={actions} item={record} />,
        },
    ];

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    const handleShowSettingsModal = () => setShowSettingsModal(true);
    const handleCloseSettingsModal = () => setShowSettingsModal(false);

    return (
        <>
            <PageHeader
                pageTitle={`Price Response (${meta.total || 0})`}
                extra={[]}
                actions={[
                    selectedKeys.length > 0 && (
                        <React.Fragment key="selection-trigger-actions">
                            <Button
                                key="update-triggers-batch"
                                size="large"
                                onClick={() => setPageAction(SPECIFIC_PRICE_RESPONSE_ACTION.BATCH_UPDATE)}
                            >
                                Update ({selectedKeys.length})
                            </Button>

                            <Button
                                key="delete-triggers-batch"
                                size="large"
                                onClick={() => setPageAction(SPECIFIC_PRICE_RESPONSE_ACTION.BATCH_DELETE)}
                            >
                                Delete ({selectedKeys.length})
                            </Button>

                            <Button
                                size="large"
                                key="clear-selection"
                                onClick={() => handleSelectedItems([], triggers)}
                            >
                                Clear Selected ({selectedKeys.length})
                            </Button>
                        </React.Fragment>
                    ),
                    <SearchInput
                        key="search-input-price-response"
                        onSearch={handleSearch}
                        defaultValue={pageState.search}
                    />,
                    ability.can('create', 'PriceResponse') && (
                        <WithControlledTooltip
                            title="Please select company"
                            extraVisibleCondition={!company}
                            key="import-triggers"
                        >
                            <Button
                                style={!company ? { pointerEvents: 'none' } : {}}
                                onClick={showUploadModal}
                                icon={<UploadOutlined />}
                                disabled={!company}
                                size="large"
                                data-cy="import-action"
                            >
                                Import Triggers
                            </Button>
                        </WithControlledTooltip>
                    ),
                    <Button
                        size="large"
                        key="upload-csv"
                        onClick={exportPriceResponseTriggersAction}
                        loading={exportLoading}
                        icon={<DownloadOutlined />}
                        data-cy="export-action"
                    >
                        Export Triggers
                    </Button>,
                    ability.can('read', 'Settings') && (
                        <Button
                            size="large"
                            key="trigger-settings"
                            icon={<SettingOutlined />}
                            onClick={handleShowSettingsModal}
                            data-cy="trigger-settings-action"
                        >
                            Trigger Settings
                        </Button>
                    ),
                    <Button
                        size="large"
                        key="new-price-response-trigger"
                        type="primary"
                        onClick={showNewPriceResponseModal}
                        icon={<PlusCircleOutlined />}
                        data-cy="new-trigger"
                    >
                        New Trigger
                    </Button>,
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={triggers}
                    rowSelection={
                        ability.can('update', 'PriceResponse')
                            ? {
                                selectedRowKeys: selectedKeys,
                                onChange: onSelectRow,
                                preserveSelectedRowKeys: true,
                            }
                            : undefined
                    }
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {trigger && pageAction === PAGE_ACTION.NEW_EDIT && (
                <PriceResponseTriggerModal
                    companies={companies}
                    onClose={onPriceResponseModalClose}
                    trigger={trigger}
                    isEditMode={isEditMode}
                />
            )}

            {pageAction === SPECIFIC_PRICE_RESPONSE_ACTION.BATCH_UPDATE && (
                <UpdateBatchPriceResponseTriggerModal
                    triggerIdList={selectedKeys}
                    onClose={onUpdateBatchPriceResponseModalClose}
                    selectedItemList={prepareSelectedItems(selectedItems)}
                />
            )}

            {pageAction === SPECIFIC_PRICE_RESPONSE_ACTION.BATCH_DELETE && (
                <DeleteBatchPriceResponseTriggerModal
                    triggerIdList={selectedKeys}
                    selectedItemList={prepareSelectedItems(selectedItems)}
                    onClose={onDeleteBatchPriceResponseModalClose}
                />
            )}

            {trigger && pageAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal
                    entityId={trigger.id!}
                    entityType={AuditEntityType.PRICE_RESPONSE_TRIGGER}
                    onClose={onPriceResponseModalClose}
                />
            )}

            {pageAction === PAGE_ACTION.UPLOAD_FILE && (
                <PriceResponseFileUploadModal onClose={onCloseUploadModal} company={company} />
            )}

            {showSettingsModal && (
                <TriggerSettingsModal triggerName="priceResponse" onClose={handleCloseSettingsModal} />
            )}
        </>
    );
};

export function formatPriceResponseMarket(record: { price_type: PriceType; market: string }) {
    const priceType = record.price_type === PriceType.DAY_AHEAD ? 'Day Ahead' : 'Real Time';
    return `${priceType}, ${record.market.toUpperCase()}`;
}
