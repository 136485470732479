import { saveAs } from 'file-saver';
import { API } from '../api';
import { IMPACT_TYPE, IRawImpact } from './interface';
import { IDateRange } from '../../pages/interface';
import { formatToISOString } from '../common/dateFormatters';
import { exportCSVData } from '../common/exportCSVData';

export const fetchImpact = async (
    { companyId = null, date }: { companyId: any; date: IDateRange },
    signal?: AbortSignal | null
): Promise<IRawImpact[]> => {
    const path = `/impact?${new URLSearchParams({
        ...(companyId && { companyId }),
        start: formatToISOString(date.start),
        end: formatToISOString(date.end),
        impactType: IMPACT_TYPE.ANY_PROGRAM,
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

export const exportPDFImpact = async ({ companyId = null, date, fileName }: { companyId: any; date: IDateRange; fileName: string }) => {
    const path = `/impact?${new URLSearchParams({
        ...(companyId && { companyId }),
        start: formatToISOString(date.start),
        end: formatToISOString(date.end),
        format: 'pdf',
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'application/pdf',
        },
    });

    const respBody: any = await response.blob();
    if (response.ok) {
        const blob = new Blob([respBody], { type: 'application/pdf' });
        saveAs(blob, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export pdf file!`);
    }
};

export const exportCSVImpact = async ({ companyId = null, date, fileName }: { companyId: any; date: IDateRange; fileName: string }) => {
    const path = `/impact?${new URLSearchParams({
        ...(companyId && { companyId }),
        start: formatToISOString(date.start),
        end: formatToISOString(date.end),
        format: 'csv',
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: any = await response.blob();
    if (response.ok) {
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export csv file!`);
    }
};
