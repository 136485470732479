import React from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import { SIGNAL_LEVEL_OPTIONS } from '../../../domain/event/interface';

interface ISignalLevelSelectorProps extends SelectProps {}

export const SignalLevelSelector: React.FC<ISignalLevelSelectorProps> = (props) => {
    return (
        <Select options={SIGNAL_LEVEL_OPTIONS} size='large' {...props} />
    )
}
