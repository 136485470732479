import { IEvent } from './interface';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import { isBetween } from '../common/isBetween';
import { isCompleted } from './index';

export function getEventState(event: IEvent) {
    const now = new Date();
    const preEventStart = sub(new Date(event.event_datetime_start), { minutes: event.event_pre_duration });

    const eventStart = new Date(event.event_datetime_start);

    const eventEnd = add(new Date(event.event_datetime_start), { minutes: event.event_duration });

    if (event.event_cancelled) {
        return 'cancelled';
    }

    if (isBetween(now, preEventStart, eventStart, '[)')) {
        return 'pre-event';
    }

    if (isBetween(now, eventStart, eventEnd, '[)')) {
        return 'active';
    }

    if (isCompleted(event)) {
        return 'completed';
    }

    return 'scheduled';
}
