import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageLocation } from '../../pages/usePageState';

export const useViewSwitcher = (defaultView: string) => {
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const viewMode = pageState?.viewMode ? pageState?.viewMode : defaultView;
    const [view, setView] = useState(viewMode);

    const handleView = (view: string) => {
        setView(view);
        setPageQuery({
            companyId: pageState.companyId,
            viewMode: view,
        });
    };

    return { view, handleView };
};
