export enum AsyncJobStatus {
    PENDING = 'pending',
    IN_PROGRESS = 'in-progress',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export enum JobName {
    SYNC_SALESFORCE_METERS_WITH_ENROLLMENTS = 'sync-salesforce-meters-with-enrollments',
    SYNC_SALESFORCE_PRODUCTS = 'sync-salesforce-products',
    SYNC_SALESFORCE_ACCOUNTS = 'sync-salesforce-accounts',
}

// keep an order, we use it for sorting on UI
export const AsyncJobContextKeyList = [
    'salesforceMetersAmount',
    'metersWithSyncedFlagAmount',
    'dbMetersAmount',
    'metersToCreate',
    'metersToUpdate',
    'metersToDelete',
    'salesforceMeterEnrollmentsAmount',
    'dbMeterEnrollmentsAmount',
    'meterEnrollmentsToCreate',
    'meterEnrollmentsToUpdate',
    'meterEnrollmentsToDelete',
    'salesforceAccountsAmount',
    'dbAccountsAmount',
    'accountsToCreate',
    'accountsToUpdate',
    'accountsToDelete',
    'salesforceProductsAmount',
    'dbProductsAmount',
    'productsToCreate',
    'productsToUpdate',
    'productsToDelete',
    'changesSavedToDB',
    'error',
];

export interface IAsyncJob {
    request_id: string;
    requested_by: string;
    job_name: JobName;
    status: AsyncJobStatus;
    created_at: string;
    updated_at: string;
    context?: any;
    started_at?: string;
    finished_at?: string;
}

export interface ICreateAsyncJob {
    job_name: JobName;
}
