import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import Typography from 'antd/lib/typography';
import Empty from 'antd/lib/empty';

import { IOverviewItem, statusColorMap, VenStatus } from '../../../../domain/ven/interface';
import { IStackedChart } from './chartInterface';
import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { toAllowedStringLength } from '../../../../domain/common/formattersToAllowedValueLength';
import { calculateChartHeight } from '../../../../domain/common/chart';

echarts.use([TooltipComponent, GridComponent, CanvasRenderer, TitleComponent, BarChart]);

const commonSettingsForSeriesOfData = {
    type: 'bar',
    stack: 'total',
    barMinHeight: 10,

    emphasis: {
        focus: 'series',
    },
    label: {
        show: true,
    },
};

const prepareOverviewData = (data: IOverviewItem[]) => {
    const sortedCompanies: string[] = [];
    const disabled: any[] = [];
    const error: any[] = [];
    const offline: any[] = [];
    const online: any[] = [];

    const sortedData = orderBy(data, ['companyName'], ['desc']);
    sortedData.forEach(item => {
        const companyNumbers = item.numbers;

        sortedCompanies.push(item.companyName || 'Without Provider');
        disabled.push(companyNumbers.disabled || undefined);
        error.push(companyNumbers.error || undefined);
        offline.push(companyNumbers.offline || undefined);
        online.push(companyNumbers.online || undefined);
    });

    return {
        companies: sortedCompanies,
        preparedOverviewData: [
            {
                ...commonSettingsForSeriesOfData,
                name: VenStatus.ONLINE,
                data: online,
                itemStyle: { color: statusColorMap[VenStatus.ONLINE] },
            },
            {
                ...commonSettingsForSeriesOfData,
                name: VenStatus.OFFLINE,
                data: offline,
                itemStyle: { color: statusColorMap[VenStatus.OFFLINE] },
            },
            {
                ...commonSettingsForSeriesOfData,
                name: VenStatus.ERROR,
                data: error,
                itemStyle: { color: statusColorMap[VenStatus.ERROR] },
            },
            {
                ...commonSettingsForSeriesOfData,
                name: VenStatus.DISABLED,
                data: disabled,
                itemStyle: { color: statusColorMap[VenStatus.DISABLED] },
            },
        ],
    };
};

const getChartHeight = (overviewData: IOverviewItem[]) => {
    const numberOfRows = overviewData.length;
    const height = calculateChartHeight(numberOfRows);

    return height;
};

export const OverviewStackedChart = ({ overviewData, title }: IStackedChart) => {
    const { companies, preparedOverviewData } = useMemo(() => prepareOverviewData(overviewData), [overviewData]);
    const height = getChartHeight(overviewData);
    const option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        xAxis: {
            axisLabel: {
                show: false,
                overflow: 'truncate',
            },
        },
        yAxis: {
            type: 'category',
            data: companies,
            axisLabel: {
                width: 150,
                overflow: 'truncate',
                hideOverlap: true,
                formatter: (label: string) => toAllowedStringLength(label),
            },
        },
        series: preparedOverviewData,
        grid: {
            left: '20%',
        },
    };

    return (
        <>
            <Typography.Title level={5} style={{ paddingTop: '8px', textAlign: 'center' }}>
                {title}
            </Typography.Title>
            {overviewData.length ? (
                <ReactEChartsCore
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{ height: `${height}px`, minHeight: '200px' }}
                />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </>
    );
};
