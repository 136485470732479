import { useEffect, useState } from 'react';
import Typography from 'antd/lib/typography';
import Card from 'antd/lib/card';
import Tag from 'antd/lib/tag';

import format from 'date-fns/format';
import uniqBy from 'lodash/uniqBy';

import { fetchEvents } from '../../domain/event';
import { IEvent } from '../../domain/event/interface';
import useAbortableFetch from '../../domain/useAbortableFetch';
import App from 'antd/lib/app';

interface IEventsDateWidget {
    company?: number | null;
    title: string;
    date: {
        start: Date;
        end: Date;
    };
    onActiveEventsHandle: Function;
}

export const EventsDateWidget = ({ company, title, date, onActiveEventsHandle }: IEventsDateWidget) => {
    const { notification } = App.useApp();
    const [events, setEvents] = useState<IEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [abortableFetchEvents] = useAbortableFetch(fetchEvents);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await abortableFetchEvents({
                    companyId: company,
                    start: date.start,
                    end: date.end,
                    include: 'site,program',
                    event_cancelled: false,
                });
                const uniqEvents = uniqBy(data, (event: IEvent) =>
                    [event.event_datetime_start, (event?.event_context?.programs || []).map((p: string) => p)].join()
                );
                uniqEvents.length && onActiveEventsHandle(true);
                setEvents(uniqEvents);
            } catch (error: any) {
                if (error.name === 'AbortError') return;
                notification.error({ key: 'events-info', message: error.message || 'Cannot get events' });
            }
            setLoading(false);
        })();
    }, [company, date]);

    const renderEventInfo = (event: IEvent) => {
        const eventStart = format(new Date(event.event_datetime_start), 'hh:mm a MMM dd, yyyy');
        const eventPrograms = event?.event_context?.programs || [];

        return (
            <p key={event.event_id}>
                {eventStart + (eventPrograms.length ? ' ' : '')}
                {Boolean(eventPrograms.length) && eventPrograms.map((program: string) => <Tag color={'gold'}>{program}</Tag>)}
            </p>
        );
    };

    return (
        <Card
            title={title}
            extra={format(date.start, 'LLL dd')}
            loading={loading}
            bodyStyle={{ maxHeight: '200px', minHeight: '120px', overflow: 'auto' }}
        >
            {events.length ? events.map(event => renderEventInfo(event)) : <Typography.Text>No events currently scheduled.</Typography.Text>}
        </Card>
    );
};
