import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import { minutesToHours } from '../../domain/common/timeHelpers';
import { toUniversalTime } from '../../domain/event';
import { IEvent, ISlice, OptType, SignalType } from '../../domain/event/interface';
import { IResource } from '../../domain/event/resource';

export enum VIEW_MODE {
    HISTORY = 'history',
    CALENDAR = 'calendar',
    TRACKING = 'tracking',
}

export enum FORM_ACTION {
    CREATE = 'create',
    UPDATE = 'update',
    UPDATE_BATCH = 'update_batch',
    UPDATE_BATCH_IN_COMPANY = 'update_batch_in_company',
    DELETE = 'delete',
    DELETE_BATCH = 'delete_batch',
    DELETE_BATCH_IN_COMPANY = 'delete_batch_in_company',
}

export interface IEventFormProps {
    event: Partial<IEvent>;
    resources: IResource[];
    onClose: Function;
    isEditMode: boolean;
}

export interface IAffectedSites {
    company: string;
    company_id?: number;
    site: string;
    id: string;
}

export interface IEventPeriodDate {
    label: string;
    value: {
        start: number;
        end: number;
    };
}

export class ViewEvent {
    event_id?: number;
    site_id?: number;
    event_cancelled?: boolean;
    event_datetime_start_local?: Date;
    event_duration_hours: number;
    event_emergency_generator_allowed: boolean;
    event_voluntary: boolean;
    event_external_event_id?: number;
    event_market_context?: string;
    event_modification_number?: number;
    event_opt_type?: OptType;
    event_post_duration_hours: number;
    event_pre_duration_hours: number;
    event_secondary_duration_hours: number;
    event_signal?: SignalType;
    event_test: boolean;
    event_datetime_created?: Date;
    event_batch?: string;
    event_slice?: ISlice;

    constructor(event: Partial<IEvent>) {
        this.event_id = event.event_id;
        this.event_signal = event.event_signal;
        this.event_test = !!event.event_test;
        this.event_emergency_generator_allowed = !!event.event_emergency_generator_allowed;
        this.event_voluntary = !!event.event_voluntary;
        this.event_datetime_start_local = undefined;
        this.event_market_context = event.event_market_context;
        this.event_batch = event.event_batch;

        this.event_duration_hours = minutesToHours(event.event_duration);
        this.event_pre_duration_hours = minutesToHours(event.event_pre_duration);
        this.event_secondary_duration_hours = minutesToHours(event.event_secondary_duration);
        this.event_post_duration_hours = minutesToHours(event.event_post_duration) ?? 0;
        this.event_slice = event.event_slice;
    }
}

export function toBatchRequest(viewEvent: ViewEvent, resources?: IResource[]) {
    return {
        ...(resources ? { sites: resources.map(r => +r.id) } : { sites: [] }),
        event: {
            event_datetime_start_local: viewEvent.event_datetime_start_local && toUniversalTime(viewEvent.event_datetime_start_local),
            event_duration: viewEvent.event_duration_hours * 60,
            event_emergency_generator_allowed: viewEvent.event_emergency_generator_allowed,
            event_voluntary: viewEvent.event_voluntary,
            event_pre_duration: viewEvent.event_pre_duration_hours * 60,

            event_post_duration: viewEvent.event_post_duration_hours * 60,
            event_secondary_duration: viewEvent.event_secondary_duration_hours * 60,
            event_test: viewEvent.event_test,

            event_signal: viewEvent.event_signal,
            event_market_context: viewEvent.event_market_context,
            ...(viewEvent.event_slice && { event_slice: viewEvent.event_slice }),
        },
    };
}

export const eventViewOptions = (isAdminRoleType: boolean) => [
    {
        title: 'Calendar View',
        value: VIEW_MODE.CALENDAR,
        tooltip: 'Calendar View',
        icon: <CalendarOutlined />,
        isVisible: true,
    },
    {
        title: 'Historical View',
        value: VIEW_MODE.HISTORY,
        tooltip: 'Historical View',
        icon: <TableOutlined />,
        isVisible: true,
    },
    {
        title: 'Tracking View',
        value: VIEW_MODE.TRACKING,
        tooltip: 'Tracking View',
        icon: <AuditOutlined />,
        isVisible: isAdminRoleType,
    },
];
