import Card from 'antd/lib/card';
import Empty from 'antd/lib/empty';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
    DataZoomComponent, GridComponent, LegendScrollComponent, MarkAreaComponent, TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useMemo } from 'react';
import { IEnergyData } from '../../../../domain/energy/interface';
import { SECONDARY_COLOR } from '../../../../theme';
import { IDateRange } from '../../../interface';
import { ENERGY_DATA_TIME_INTERVAL, prepareEnergyDataForBarChart } from './prepareEnergyDataForBarChart';

echarts.use([BarChart, LineChart, TooltipComponent, GridComponent, CanvasRenderer, MarkAreaComponent, DataZoomComponent,
    LegendScrollComponent]);

interface ISiteEnergyWidget {
    energyData: IEnergyData[];
    dateRange: IDateRange;
}

export const SiteEnergyWidget = ({ dateRange, energyData }: ISiteEnergyWidget) => {
    const isDataExist = useMemo(() => {
            // Filter out data that is not in the date range
            const filteredEnergyData = energyData.filter(el => el.values.filter(([timestamp, usage]) => timestamp >= dateRange.start.valueOf() && timestamp <= dateRange.end.valueOf() && usage > 0).length);

            return filteredEnergyData.length
        },
        [dateRange.end, dateRange.start, energyData]
    );
    const preparedEnergyData = useMemo(
        () => prepareEnergyDataForBarChart(dateRange, energyData, ENERGY_DATA_TIME_INTERVAL.HOUR),
        [energyData, dateRange],
    );

    const option = {
        legend: {},
        tooltip: {
            formatter: (params: any) => {
                const [date, usage] = params.value;
                const formattedDate = format(new Date(date), 'yyyy-MM-dd  HH:mm:ss');
                return `${formattedDate}</br> ${params.marker}<strong>${usage} kWh</strong></br>`;
            },
        },
        grid: {
            bottom: '7%',
            right: '10%',
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                formatter: {
                    year: '{yyyy}',
                    month: '{MMM}',
                    day: '{dd}-{MMM}',
                    hour: '{HH}:{mm}',
                    minute: '{HH}:{mm}',
                },
            },
            max: dateRange.end.valueOf(),
            min: dateRange.start.valueOf(),
        },
        yAxis: {
            axisLabel: {
                show: true,
            },
            name: 'kWh',
            nameLocation: 'middle',
            nameGap: 40,
        },
        series: [
            {
                type: 'bar',
                showSymbol: false,
                emphasis: {
                    focus: 'series',
                },
                name: 'Energy usage',
                data: preparedEnergyData,
                itemStyle: {
                    color: '#2771ad',
                },
            },
        ],
        dataZoom: [
            {
                filterMode: 'weakFilter',
                type: 'inside',
                minSpan: 2,
            },
        ],
    };

    return (
        <Card className='site-energy-usage-widget' style={{ marginBottom: '16px' }}>
            <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR }}>
                Energy Consumption Over Time&nbsp;(kWh)
            </Typography.Title>
            <div className='site-energy-chart-title'>
                {isDataExist ? (
                    <ReactEChartsCore echarts={echarts} option={option} notMerge lazyUpdate />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </Card>
    );
};
