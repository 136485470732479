import { Market } from '../../../../domain/market-prices/interface';

export const REGIONS: { [key: string]: string } = {
    'US-CAL-BANC': Market.CAISO,
    'US-CAL-CISO': Market.CAISO,
    'US-CAL-IID': Market.CAISO,
    'US-CAL-LDWP': Market.CAISO,
    'US-CAL-TIDC': Market.CAISO,
    'US-CAR-CPLE': 'Southeast',
    'US-CAR-CPLW': 'Southeast',
    'US-CAR-DUK': 'Southeast',
    'US-CAR-SC': 'Southeast',
    'US-CAR-SCEG': 'Southeast',
    'US-CAR-YAD': 'Southeast',
    //Southwestern Power Administration ?? in SPP
    'US-CENT-SPA': Market.SPP,
    //Southwest Power Pool  is part of SPP and MISO ['SWPP', 'SPP & MISO']
    'US-CENT-SWPP': Market.SPP,
    'US-FLA-FMPP': 'Southeast',
    'US-FLA-FPC': 'Southeast',
    //Northwestern Energy (Nwmt)
    'US-NW-NWMT': 'Northwest',
    //Gainesville Regional Utilities
    'US-FLA-GVL': 'Southeast',
    //City Of Homestead ???
    'US-FLA-HST': 'HST',
    //JEA
    'US-FLA-JEA': 'JEA',
    // https://www.publicpower.org/periodical/article/wapa-desert-southwest-region-agrees-participate-caisos-real-time-energy-market
    'US-SW-WALC': Market.CAISO,
    'US-FLA-SEC': 'Southeast',
    'US-FLA-TAL': 'Southeast',
    'US-FLA-TEC': 'Southeast',
    'US-HI-HA': 'Hawaii',
    'US-HI-LA': '',
    'US-HI-MA': '',
    'US-HI-MO': '',
    'US-HI-NI': '',
    'US-HI-OA': 'Hawaii',
    'US-MIDA-PJM': Market.PJM,
    //or is part of SPP
    'US-MIDW-AECI': 'Southeast',
    'US-MIDW-MISO': Market.MISO,
    'US-MIDW-LGEE': Market.PJM,
    'US-NE-ISNE': Market.ISONE,
    'US-NW-AVA': 'Northwest',
    'US-NW-BPAT': Market.BPA,
    'US-NW-CHPD': 'Northwest',
    'US-NW-DOPD': 'Northwest',
    //Public Utility District No. 2 Of Grant County, Washington
    'US-NW-GCPD': 'Northwest',
    'US-NW-GRID': 'Northwest',
    'US-SW-AZPS': 'Northwest',
    'US-NW-IPCO': 'Northwest',
    'US-NW-NEVP': Market.SPP,
    'US-FLA-FPL': 'Southeast',
    'US-NW-PACE': 'Northwest',
    'US-NW-PACW': 'Northwest',
    'US-NW-PGE': 'PGE',
    'US-NW-PSCO': 'Southwest',
    'US-NW-PSEI': 'Northwest',
    'US-NW-TPWR': 'Northwest',
    'US-NW-WACM': Market.SPP,
    'US-NW-WAUW': '',
    'US-NY-NYIS': Market.NYISO,
    'US-SE-SOCO': 'Southeast',
    // ???
    'US-SE-SEPA': Market.PJM,
    'US-SW-EPE': 'Southwest',
    'US-SW-SRP': 'Northwest',
    'US-SW-PNM': Market.SPP,
    'US-SW-TEPC': 'Northwest',
    'US-TEN-TVA': Market.PJM,
    'US-TEX-ERCO': Market.ERCOT,
    'US-HI-KH': '',
    'US-NW-SCL': 'Northwest',
};
