import { CopyOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import App from 'antd/lib/app';
import { SECONDARY_COLOR } from '../theme';
import { useState } from 'react';

interface ICopyToClipboardProps {
    text: string;
    alwaysVisible?: boolean;
}

export const CopyToClipboard = ({ text, alwaysVisible }: ICopyToClipboardProps) => {
    const [show, setShow] = useState(false);
    const { notification } = App.useApp();

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(
            () => {
                notification.info({ key: 'copy-to-clipboard-info', message: 'Copied to clipboard' });
            },
            () => {
                notification.error({ key: 'copy-to-clipboard-error', message: 'Cannot copy to clipboard!' });
            }
        );
    }

    function handleMouseEnter() {
        setShow(true);
    }

    function handleMouseLeave() {
        setShow(false);
    }

    if (!text) return null;

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {text}
            {(show || alwaysVisible) && (
                <Tooltip title="Copy to clipboard">
                    &nbsp;&nbsp;
                    <CopyOutlined
                        onClick={ev => {
                            ev.stopPropagation();
                            copyToClipboard(text);
                        }}
                        style={{ color: SECONDARY_COLOR, cursor: 'pointer' }}
                    />
                </Tooltip>
            )}
        </div>
    );
};
