import { ReactElement } from 'react';

import { DrrsLocationsView, DrrsRegistrationsView } from './view';
import { useCaisoDrrsSwitcher } from 'src/components/caisoDrrsSwitcher/useCaisoDrrsSwitcher';
import { CAISO_DRRS_VIEW } from 'src/pages/interface';

export function DrrsView() {
    const { view, handleView } = useCaisoDrrsSwitcher(CAISO_DRRS_VIEW.LOCATIONS)!;

    const viewComponent: { [key: string]: ReactElement } = {
        [CAISO_DRRS_VIEW.LOCATIONS]: <DrrsLocationsView view={view} handleView={handleView} />,
        [CAISO_DRRS_VIEW.REGISTRATIONS]: <DrrsRegistrationsView view={view} handleView={handleView} />,
    };

    return <>{viewComponent[view]}</>;
}
