import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchCarbonIntensity } from './index';
import { ICarbonIntensity } from './interface';

export const useCarbonIntensityListQuery = (options = {}) => {
    return useQuery<ICarbonIntensity[], Error>({
        queryKey: [QueryKey.CARBON_INTENSITY],
        queryFn: () => fetchCarbonIntensity(),
        ...options
    });
};
