import Popover from 'antd/lib/popover';
import Tag from 'antd/lib/tag';
import { VenStatus } from '../../../../domain/ven/interface';
import './EventVenContext.css';

export interface IEventVenContextProps {
    value?: {
        ven_id: string;
        status: VenStatus;
        date_online: string; // DateString
    };
}

export const EventVenContext = ({ value }: IEventVenContextProps) => {
    if (!value) return <></>;

    const { ven_id, status, date_online } = value;

    const content = (
        <div className="event-ven-context-popover">
            <div>
                <span>
                    Customer VEN <small>(at the event creation moment)</small>
                </span>
            </div>
            <div>
                <span className="label">VEN Id:</span> <span>{ven_id}</span>
            </div>
            <div>
                <span className="label">Status:</span> <span>{status ? status.toUpperCase() : ''}</span>
            </div>
            <div>
                <span className="label">Online At:</span> <span>{date_online || 'never'}</span>
            </div>
        </div>
    );

    return (
        <Popover content={content}>
            <Tag color="geekblue">VEN</Tag>
        </Popover>
    );
};
