import Button from 'antd/lib/button';
import { useState } from 'react';
import { CreateProgramTypeFormModal } from './CreateProgramTypeFormModal';

export const CreateProgramTypeAction = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button size='small' type="default" onClick={handleOpenModal} style={{ marginLeft: 'auto' }}>Create New</Button>

            {isModalOpen &&
                <CreateProgramTypeFormModal onClose={handleCloseModal} onFormFinish={handleCloseModal} />
            }
        </>
    );
};
