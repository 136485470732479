import { Row } from 'antd';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MemoizedGreenButtonConnect } from '../../../components/greenButtonConnect/greenButtonConnect';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { viewNavigationOptions } from './viewNavigationOptions';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { AbilityContext } from '../../../components/ability/can';
import { usePageLocation } from '../../usePageState';
import { useDocumentTitle } from '../../../components/useDocumentTitle';

export const EnergyUsageDataConnect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const ability = useContext(AbilityContext);

    useDocumentTitle('Energy Usage Data');

    const onViewChange = (view: string) => {
        const companyQuery = pageState.companyId ? `?companyId=${pageState.companyId}` : '';
        navigate(`${view}${companyQuery}`);
    };

    return (
        <>
            <PageHeader
                pageTitle="Energy Usage Data"
                extra={[
                    <ViewSwitcher
                        viewOptions={viewNavigationOptions(ability!)}
                        key="energy-usage-data-view-switcher"
                        view={location.pathname}
                        handleViewMode={onViewChange}
                    />,
                ]}
            />
            <Row className="content-background" style={{ padding: '24px', borderRadius: '20px' }}>
                <MemoizedGreenButtonConnect />
            </Row>
        </>
    );
};
