import Tooltip from 'antd/lib/tooltip';

interface ICompanySiteCountTagProps {
    value?: {
        opened: number;
        closed: number;
    };
}

export const CompanySiteCountTag = ({ value }: ICompanySiteCountTagProps) => {
    const opened = value?.opened || 0;
    const closed = value?.closed || 0;

    const content = (
        <div>
            <div>Opened: {opened}</div>
            <div>Closed: {closed}</div>
        </div>
    );
    return <Tooltip title={content}>{opened + closed}</Tooltip>;
};
