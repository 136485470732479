import ConfigProvider from 'antd/lib/config-provider';
import Empty from 'antd/lib/empty';

const customizeRenderEmpty = (condition: boolean, emptyDataDescription?: string) =>
    condition ? <></> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDataDescription} />;

export const WithEmptyDataTable = ({
    condition,
    children,
    emptyDataDescription,
}: {
    condition: boolean;
    children: any;
    emptyDataDescription?: string;
}) => {
    return <ConfigProvider renderEmpty={() => customizeRenderEmpty(condition, emptyDataDescription)} children={children} />;
};
