import { ProgramUtilityAutomation } from './interface';
import orderBy from 'lodash/orderBy';

// "SCE, CBP" (email:APX) and "SCE, BIP" (email:SCE) options are temporary disabled as parsers for those are not ready
export const ProgramUtilityAutomationOptions = orderBy([
    // {
    //     value: ProgramUtilityAutomation.APX,
    //     label: '[email] - SCE, CBP',
    // },
    {
        value: ProgramUtilityAutomation.ENBALA,
        label: '[email] - PNM, Peak Saver',
    },
    {
        value: ProgramUtilityAutomation.EVERGY,
        label: '[email] - Evergy, BDR',
    },
    {
        value: ProgramUtilityAutomation.CPSENERGY,
        label: '[email] - CPS Demand Response',
    },
    {
        value: ProgramUtilityAutomation.CPOWERENERGY,
        label: '[email] - CPower, APS',
    },
    {
        value: ProgramUtilityAutomation.EVERBRIDGE,
        label: '[email] - SRP, Demand Response',
    },
    {
        value: ProgramUtilityAutomation.NRG_ACTIVATIONS,
        label: '[email] - NRG, ERS-30',
    },
    {
        value: ProgramUtilityAutomation.TFCCIONCOR,
        label: '[email] - Oncor CLM',
    },
    // {
    //     value: ProgramUtilityAutomation.SCE,
    //     label: '[email] - SCE, BIP',
    // },
    {
        value: ProgramUtilityAutomation.VOLTUS,
        label: '[email] - Voltus, PG&E DRAM',
    },
    {
        value: ProgramUtilityAutomation.PUGET_SOUND_ENEL,
        label: '[email] - Puget Sound, Enel',
    },
    {
        value: ProgramUtilityAutomation.ROCKY_MOUNTAIN_ENEL,
        label: '[email] - Rocky Mountain Power, Enel'
    }
], 'label');
