export const toUsefulParams = (state: any) => ({
    ...(state.pagination && { pagination: { pageSize: state.pagination.pageSize, current: state.pagination.current } }),
    ...(state.sorter && { sorter: { field: state.sorter.field, order: state.sorter.order } }),
    ...(state.filter && { filter: state.filter }),
    ...(state.companyId && { company_id: state.companyId }),
    ...(state?.search && { search: state.search }),
    ...(state?.siteId && { siteId: state?.siteId }),
    ...(state?.programId && { programId: state?.programId }),
    ...(state?.status && { status: state?.status }),
    ...(state?.start && { start: state?.start }),
    ...(state?.end && { end: state?.end }),
    ...(state?.type && { type: state?.type }),
    ...(state?.fileColumnsPreset && { fileColumnsPreset: state?.fileColumnsPreset }),
});
