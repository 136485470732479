import App from 'antd/lib/app';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { QueryKey } from '../../query-client';
import {
    fetchResources,
    createResource,
    updateResource,
    deleteResource,
    toggleResourceEnabledState,
    getSeasonById,
    uploadPeakHoursSeasonData,
    reprocessResource,
} from './index';
import { ICreateNyisoResource, IUpdateNyisoResource, INyisoResource, INyisoSeason } from './interface';
import { IPagination } from '../IPagination';
import { UploadFile } from 'antd/lib';

export const useNyisoResourcesListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<INyisoResource>, Error>({
        queryKey: [QueryKey.NYISO_RESOURCES, query],
        queryFn: ({ signal }) => fetchResources(query, signal),
        ...options,
    });
};

export const useNyisoResourceCreateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, ICreateNyisoResource>(createResource, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
            notification.success({ key: 'nyiso-resource-create-success', message: 'Resource created successfully' });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-resource-create-error',
                message: error.message || 'Cannot create resource',
            });
        },
    });
};

export const useNyisoResourceUpdateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUpdateNyisoResource>(updateResource, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
            notification.success({ key: 'nyiso-resource-update-success', message: 'Resource updated successfully' });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-resource-update-error',
                message: error.message || 'Cannot create resource',
            });
        },
    });
};

export const useNyisoResourceDeleteMutation = () => {
    const { notification } = App.useApp();

    return useMutation<void, Error, number>(deleteResource, {
        onSuccess: async () => {
            notification.success({ key: 'nyiso-resource-delete-success', message: 'Resource deleted successfully' });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-resource-delete-error',
                message: error.message || 'Cannot delete resource',
            });
        },
    });
};

export const useNyisoResourceToggleMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, { enable: boolean; ids: number[] }>(toggleResourceEnabledState, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
        },
        onError: async error => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
            notification.error({
                key: 'nyiso-resource-toggle-error',
                message: error.message || 'Cannot toggle resource enabled state',
            });
        },
    });
};

export const useNyisoMultipleResourcesToggleMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, { enable: boolean }>(toggleResourceEnabledState, {
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);

            notification.success({
                key: 'nyiso-resources-toggle-success',
                message: `All resources ${variables.enable ? 'enabled' : 'disabled'} successfully`,
            });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-resources-toggle-error',
                message: error.message || 'Cannot toggle resource enabled state',
            });
        },
    });
};

export const useNyisoSeasonQuery = (id: string, options = {}) => {
    return useQuery<INyisoSeason | null, Error>({
        queryKey: [QueryKey.NYISO_SEASON, id],
        queryFn: ({ signal }) => getSeasonById(id, signal),
        ...options,
    });
};

export const useNyisoPeakHoursSeasonDataUpload = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<INyisoSeason, Error, { id: string; file: UploadFile }>(uploadPeakHoursSeasonData, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.NYISO_SEASON]);
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
            notification.success({
                key: 'nyiso-season-upload-success',
                message: 'Peak hours data uploaded!',
            });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-season-upload-error',
                message: error.message || 'Cannot upload peak hours data!',
            });
        },
    });
};

export const useNyisoResourceReprocesMytation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, { resourceId: number; seasonId: string }>(reprocessResource, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.NYISO_RESOURCES]);
            notification.success({
                key: 'nyiso-resource-reprocess-success',
                message: 'Resource set to reprocess!',
            });
        },
        onError: async error => {
            notification.error({
                key: 'nyiso-resource-reprocess-error',
                message: error.message || 'Cannot set resource to reprocess!',
            });
        },
    });
};
