export enum SiteImportTableIndexes {
    COMPANY_NAME = 0,
    CONTROL_PROVIDER_NAME = 1,
    PROGRAMS = 2,
    SITE_NAME = 3,
    SITE_STORE_NUMBER = 4,
    SITE_ADDRESS = 5,
    SITE_CITY = 6,
    SITE_STATE = 7,
    SITE_ZIP = 8,
    SITE_MARKET = 9,
    SITE_LOAD_ZONE = 10,
    SITE_ESTIMATED_KW = 11,
    SITE_SERVICE_ACCOUNT_NUMBER = 12,
    SITE_UTILITY = 13,
    SITE_SLAP = 14,
    SITE_NETWORK = 15,
    EVENT_OFFSET = 16,
    EVENT_MAX_DURATION = 17,
    CREATE_VEN = 18,
    PARTNER = 19,
}
