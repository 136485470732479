import uniq from 'lodash/uniq';
import { LOCAL_STORAGE_PREFIX } from '../../../domain/commonConst';

const ALLOWED_LENGTH = 3;
const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}.recent.companies`;

export const recentCompanies = {
    getRecentCompanies(): number[] {
        try {
            const item = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (!item) return [];
            return JSON.parse(item);
        } catch (error: any) {
            this.clear();
            return [];
        }
    },

    setRecentCompany(company: number) {
        let keys = this.getRecentCompanies();
        keys.unshift(company);
        keys = uniq(keys);
        if (keys.length > ALLOWED_LENGTH) {
            keys.pop();
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(keys));
    },

    clear() {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    },
};
