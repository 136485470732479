import { IAccount } from '../account/interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';

export enum COMPANY_TYPE {
    CUSTOMER_COMPANY,
    CONTROL_PROVIDER,
}

export interface ICreateCompany {
    // company_id?: number;

    company_name: string;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: {
        emerson_key: string;
        emerson_key_error?: string;
    } | null;
    account_ids?: string[];
}

export interface IUpdateCompany {
    company_id: number;

    company_name: string;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: {
        emerson_key: string;
        emerson_key_error?: string;
    } | null;
    account_ids?: string[];
}

export interface ICompany {
    company_id: number;
    company_name: string;
    company_date_created?: any | null;
    type: COMPANY_TYPE;
    utility_names: string[];
    company_vertical?: string;
    api_credentials?: {
        emerson_key: string;
        emerson_key_error?: string;
    } | null;
    sites_count?: {
        opened: number;
        closed: number;
    };
    accounts?: IAccount[];
}

export const COMPANY_VERTICAL = [
    'Agriculture',
    'Big Box Retail',
    'Cold Storage',
    'Crypto/Datacenter',
    'Education',
    'Hotel',
    'Internal',
    'Manufacturing',
    'Medical',
    'Multi Family',
    'Office',
    'Pumping',
    'Residential',
    'Restaurant',
    'Small Box Retail',
    'Supermarket',
    'Worship',
    'Gas Station',
];

export interface IFetchCompanyPageQuery extends IFetchPageQuery {}
