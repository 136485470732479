export interface ProgramUtilityEntity {
    utility_id: string;
    name: string;
    energy_market: string;
}

export const ProgramUtilityList: ProgramUtilityEntity[] = [
    {
        utility_id: 'a-and-n-electric-coop',
        name: 'A & N Electric Coop',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'acacia-energy',
        name: 'ACACIA ENERGY',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'accent-energy-texas-dba-igs-energy',
        name: 'ACCENT ENERGY TEXAS DBA IGS ENERGY',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'aep-central',
        name: 'AEP Central',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'aep-north',
        name: 'AEP North',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'allete-inc.',
        name: 'ALLETE, Inc.',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'allegheny-power-system',
        name: 'ALLEGHENY POWER SYSTEM',
        energy_market: 'PJM',
    },
    {
        utility_id: 'alliance-power-company',
        name: 'ALLIANCE POWER COMPANY',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'alliant-energy',
        name: 'ALLIANT ENERGY',
        energy_market: 'MISO',
    },
    {
        utility_id: 'almika-solar',
        name: 'ALMIKA SOLAR',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'amber-energy-services',
        name: 'AMBER ENERGY SERVICES',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'ambit-energy',
        name: 'AMBIT ENERGY',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'ameren-illinois',
        name: 'Ameren Illinois',
        energy_market: 'MISO',
    },
    {
        utility_id: 'ameren-mo',
        name: 'Ameren MO',
        energy_market: 'MISO',
    },
    {
        utility_id: 'appalachian-power-co',
        name: 'Appalachian Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'arizona-public-service-co',
        name: 'Arizona Public Service Co',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'atlantic-city-electric-co',
        name: 'Atlantic City Electric Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'austin-energy',
        name: 'Austin Energy',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'baltimore-gas-and-electric-co',
        name: 'Baltimore Gas & Electric Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'cape-light-compact',
        name: 'CAPA LIGHT COMPACT',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'centerpoint',
        name: 'Centerpoint',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'central-hudson-gas-and-elec-corp',
        name: 'Central Hudson Gas & Elec Corp',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'central-other',
        name: 'CENTRAL OTHER',
        energy_market: 'CENTRAL Other',
    },
    {
        utility_id: 'city-of-columbus-(oh)',
        name: 'City of Columbus - (OH)',
        energy_market: 'PJM',
    },
    {
        utility_id: 'city-of-mishawaka',
        name: 'City of Mishawaka',
        energy_market: 'PJM',
    },
    {
        utility_id: 'cleveland-electric-illum-co',
        name: 'Cleveland Electric Illum Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'commonwealth-edison-co',
        name: 'Commonwealth Edison Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'con-edison-solutions',
        name: 'CON EDISON SOLUTIONS',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'consumers-energy',
        name: 'Consumers Energy',
        energy_market: 'MISO',
    },
    {
        utility_id: 'cps-energy',
        name: 'CPS Energy',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'dayton-power-and-light-co',
        name: 'Dayton Power & Light Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'delmarva-power',
        name: 'Delmarva Power',
        energy_market: 'PJM',
    },
    {
        utility_id: 'dominion-energy-south-carolina-inc',
        name: 'Dominion Energy South Carolina, Inc',
        energy_market: 'PJM',
    },
    {
        utility_id: 'duke-energy-indiana-llc',
        name: 'Duke Energy Indiana, LLC',
        energy_market: 'PJM',
    },
    {
        utility_id: 'duke-energy-kentucky',
        name: 'Duke Energy Kentucky',
        energy_market: 'PJM',
    },
    {
        utility_id: 'duke-energy-ohio-inc',
        name: 'Duke Energy Ohio Inc',
        energy_market: 'PJM',
    },
    {
        utility_id: 'duke-energy-progress-(nc)',
        name: 'Duke Energy Progress - (NC)',
        energy_market: 'EAST Other',
    },
    {
        utility_id: 'duquesne-light-co',
        name: 'Duquesne Light Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'east-other',
        name: 'EAST OTHER',
        energy_market: 'EAST Other',
    },
    {
        utility_id: 'efficiency-maine',
        name: 'Efficiency Maine',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'el-paso-electric-co',
        name: 'El Paso Electric Co',
        energy_market: 'Central Other',
    },
    {
        utility_id: 'el-paso-electric-tx',
        name: 'El Paso Electric TX',
        energy_market: 'Central Other',
    },
    {
        utility_id: 'entergy-arkansas-llc',
        name: 'Entergy Arkansas LLC',
        energy_market: 'MISO',
    },
    {
        utility_id: 'entergy-new-orleans-llc',
        name: 'Entergy New Orleans, LLC',
        energy_market: 'MISO',
    },
    {
        utility_id: 'entergy-texas-inc',
        name: 'Entergy Texas Inc.\r',
        energy_market: 'MISO',
    },
    {
        utility_id: 'evergy-kansas-south-inc',
        name: 'Evergy Kansas South, Inc',
        energy_market: 'MISO',
    },
    {
        utility_id: 'eversource',
        name: 'Eversource',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'eversource-ma',
        name: 'Eversource MA',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'eversource-nh',
        name: 'Eversource NH',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'florida-power-and-light-co',
        name: 'Florida Power & Light Co',
        energy_market: 'East Other',
    },
    {
        utility_id: 'hagerstown-light-department',
        name: 'Hagerstown Light Department',
        energy_market: 'PJM',
    },
    {
        utility_id: 'hawaiian-electric-co-inc',
        name: 'Hawaiian Electric Co Inc',
        energy_market: 'West Other',
    },
    {
        utility_id: 'idaho-power-co',
        name: 'Idaho Power Co',
        energy_market: 'West Other',
    },
    {
        utility_id: 'indiana-michigan-power-co',
        name: 'Indiana Michigan Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'jersey-central-power-and-lt-co',
        name: 'Jersey Central Power & Lt Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'kansas-city-power-and-light-co',
        name: 'Kansas City Power & Light Co',
        energy_market: 'Central Other',
    },
    {
        utility_id: 'kenergy-corp',
        name: 'Kenergy Corp',
        energy_market: 'PJM',
    },
    {
        utility_id: 'los-angeles-department-of-water-and-power',
        name: 'Los Angeles Department of Water & Power',
        energy_market: 'CAISO',
    },
    {
        utility_id: 'long-island-power-authority',
        name: 'Long Island Power Authority',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'louisville-gas-and-electric-co',
        name: 'Louisville Gas & Electric Co',
        energy_market: 'East Other',
    },
    {
        utility_id: 'metropolitan-edison-co',
        name: 'Metropolitan Edison Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'midamerican-energy-co',
        name: 'MidAmerican Energy Co',
        energy_market: 'MISO',
    },
    {
        utility_id: 'monongahela-power-co',
        name: 'Monongahela Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'montana-dakota-utilities-co',
        name: 'Montana-Dakota Utilities Co',
        energy_market: 'MISO',
    },
    {
        utility_id: 'national-grid-ma',
        name: 'National Grid MA',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'national-grid-ny',
        name: 'National Grid NY',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'national-grid-ri',
        name: 'National Grid RI',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'nevada-power-co',
        name: 'Nevada Power Co',
        energy_market: 'SPP',
    },
    {
        utility_id: 'northern-states-power-co-minnesota',
        name: 'Northern States Power Co - Minnesota',
        energy_market: 'MISO',
    },
    {
        utility_id: 'new-york-state-elec-and-gas-corp',
        name: 'New York State Elec & Gas Corp',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'ohio-edison-co',
        name: 'Ohio Edison Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'ohio-power-co',
        name: 'Ohio Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'oklahoma-gas-and-electric-co',
        name: 'Oklahoma Gas & Electric Co',
        energy_market: 'West Other',
    },
    {
        utility_id: 'oncor',
        name: 'Oncor',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'orange-and-rockland-utils-inc',
        name: 'Orange & Rockland Utils Inc',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'pge',
        name: 'Pacific Gas & Electric Co.',
        energy_market: 'CAISO',
    },
    {
        utility_id: 'pacific-power-rocky-mountain-power',
        name: 'Pacific Power-Rocky Mountain Power',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'peco-energy-co',
        name: 'PECO Energy Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'pennsylvania-electric-co',
        name: 'Pennsylvania Electric Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'pennsylvania-power-and-light',
        name: 'Pennsylvania Power and Light',
        energy_market: 'PJM',
    },
    {
        utility_id: 'public-service-co-of-nm',
        name: 'Public Service Co of NM',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'portland-general-electric-co',
        name: 'Portland General Electric Co',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'pjm-zone',
        name: 'PJM Zone',
        energy_market: 'PJM',
    },
    {
        utility_id: 'potomac-electric-power-co',
        name: 'Potomac Electric Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'pennsylvania-power-co',
        name: 'Pennsylvania Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'public-service-co-of-colorado',
        name: 'Public Service Co of Colorado',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'pseg-long-island',
        name: 'PSEG Long Island',
        energy_market: 'PJM',
    },
    {
        utility_id: 'public-service-nm',
        name: 'Public Service NM',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'public-service-co-of-oklahoma',
        name: 'Public Service Co of Oklahoma',
        energy_market: 'SPP',
    },
    {
        utility_id: 'public-service-elec-and-gas-co',
        name: 'Public Service Elec & Gas Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'puget-sound-energy-inc',
        name: 'Puget Sound Energy Inc',
        energy_market: 'West Other',
    },
    {
        utility_id: 'rochester-gas-and-electric-corp',
        name: 'Rochester Gas & Electric Corp',
        energy_market: 'NYISO',
    },
    {
        utility_id: 'rhode-island-energy',
        name: 'Rhode Island Energy',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'santee-cooper',
        name: 'Santee Cooper',
        energy_market: 'EAST Other',
    },
    {
        utility_id: 'sce',
        name: 'Southern California Edison Co',
        energy_market: 'CAISO',
    },
    {
        utility_id: 'southwestern-electric-power',
        name: 'Southwestern Electric Power',
        energy_market: 'SPP',
    },
    {
        utility_id: 'southwestern-power-ar',
        name: 'Southwestern Power AR',
        energy_market: 'SPP',
    },
    {
        utility_id: 'sdge',
        name: 'San Diego Gas & Electric Co',
        energy_market: 'CAISO',
    },
    {
        utility_id: 'sacramento-municipal-utility-district',
        name: 'Sacramento Municipal Utility District',
        energy_market: 'CAISO',
    },
    {
        utility_id: 'rockland-electric-co',
        name: 'Rockland Electric Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'southern-maryland-elec-coop-inc',
        name: 'Southern Maryland Elec Coop Inc',
        energy_market: 'PJM',
    },
    {
        utility_id: 'southwestern-electric-power-co',
        name: 'Southwestern Electric Power Co',
        energy_market: 'Central Other',
    },
    {
        utility_id: 'salt-river-project',
        name: 'Salt River Project',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'tampa-electric-co',
        name: 'Tampa Electric Co',
        energy_market: 'EAST Other',
    },
    {
        utility_id: 'tucson-electric-power-co',
        name: 'Tucson Electric Power Co',
        energy_market: 'WEST Other',
    },
    {
        utility_id: 'the-potomac-edison-company',
        name: 'The Potomac Edison Company',
        energy_market: 'PJM',
    },
    {
        utility_id: 'the-toledo-edison-co',
        name: 'The Toledo Edison Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'tennessee-valley-authority',
        name: 'Tennessee Valley Authority',
        energy_market: 'EAST Other',
    },
    {
        utility_id: 'texas-new-mexico-power',
        name: 'Texas-New Mexico Power',
        energy_market: 'ERCOT',
    },
    {
        utility_id: 'united-illuminating-co',
        name: 'United Illuminating Co',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'unitil-energy-systems',
        name: 'Unitil Energy Systems',
        energy_market: 'ISONE',
    },
    {
        utility_id: 'virginia-electric-and-power-co',
        name: 'Virginia Electric & Power Co',
        energy_market: 'PJM',
    },
    {
        utility_id: 'wabash-valley-power-alliance',
        name: 'Wabash Valley Power Alliance',
        energy_market: 'MISO'
    },
    {
        utility_id: 'west-penn-power-company',
        name: 'West Penn Power Company',
        energy_market: 'PJM',
    },
    {
        utility_id: 'xcel-energy-tx',
        name: 'Xcel Energy TX',
        energy_market: 'SPP',
    },
];
