import { IEnergyData, ISiteEnergyUsageData } from './interface';

/**
 *  function returns an array of readings for each site-san
 * (we take all sources of readings, they filtered on connect by priority (each day have 1 source by priority))
 * (later in code, charts summarize all values under the same timestamps)
 * */
export function getEnergyReadings(sitesEnergyUsageDataResults: ISiteEnergyUsageData[] | undefined): IEnergyData[] {
    const results: IEnergyData[] = [];

    if (!sitesEnergyUsageDataResults || sitesEnergyUsageDataResults?.length === 0) {
        return results;
    }

    sitesEnergyUsageDataResults.forEach(({ site, sanUsages }) => {
        sanUsages.forEach(({ readings: allSourcesReadings }) => {
            allSourcesReadings?.forEach(readings => {
                if (readings) {
                    readings.values = readings?.values.map(([seconds, usage]) => {
                        return [seconds * 1000, usage];
                    });
                }

                results.push({
                    site,
                    values: readings?.values || [],
                    fields: readings?.fields || ['timestamp', 'usage'],
                });
            });
        });
    });

    return results;
}
