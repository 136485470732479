import App from 'antd/lib/app';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import { createAsyncJob, fetchAsyncJobs } from './index';
import { IAsyncJob, ICreateAsyncJob } from './interface';

export const useAsyncJobListQuery = (query = {}, options = {}) => {
    return useQuery<IPagination<IAsyncJob>, Error>([QueryKey.ASYNC_JOB], ({ signal }) => fetchAsyncJobs(query, signal), {
        ...options
    });
};

export const useAsyncJobCreateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, ICreateAsyncJob>(createAsyncJob, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.ASYNC_JOB]);
            notification.success({ key: 'async-job-create-success', message: 'Async job created successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'async-job-create-error', message: error.message || 'Cannot create async job' });
        },
    });
};
