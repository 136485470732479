import { useContext } from 'react';
import App from 'antd/lib/app';
import { AbilityContext } from '../../../../components/ability/can';
import { ExportButton } from '../../../../components/ExportButton/ExportButton';
import { useExportCompanyListQuery, useExportConnectCustomersQuery, useExportEnergyCustomersQuery } from '../../../../domain/company/queries';

export interface ICompaniesExportButtonProps {
    filter: {
        search?: string;
        company_vertical?: string;
        type?: number;
    };
}

export const CompaniesExportButton = ({ filter }: ICompaniesExportButtonProps) => {
    const { notification } = App.useApp();
    const ability = useContext(AbilityContext);

    const exportCompanyListQuery = useExportCompanyListQuery(filter);
    if (exportCompanyListQuery.isError) {
        notification.error({
            key: 'fetch-companies-error',
            message: exportCompanyListQuery.error.message || 'Cannot export companies data!',
        });
    }

    const exportEnergyCustomersQuery = useExportEnergyCustomersQuery();
    if (exportEnergyCustomersQuery.isError) {
        notification.error({
            key: 'energy-customers-export-error',
            message: exportEnergyCustomersQuery.error.message || 'Cannot export customers!',
        });
    }

    const exportConnectCustomersQuery = useExportConnectCustomersQuery();
    if (exportEnergyCustomersQuery.isError) {
        notification.error({
            key: 'connect-customers-export-error',
            message: exportEnergyCustomersQuery.error.message || 'Cannot export Connect customers!',
        });
    }

    const exportCompaniesAction = async () => {
        await exportCompanyListQuery.refetch();
    };

    const exportEnergyCustomersAction = async () => {
        await exportEnergyCustomersQuery.refetch();
    };

    const exportConnectCustomersAction = async () => {
        await exportConnectCustomersQuery.refetch();
    };

    const actions: any[] = [{ key: 'e-b-1', title: 'Download CSV', fn: exportCompaniesAction }];
    if (ability.can('read', 'EnergyCustomer')) {
        actions.push({
            key: 'e-b-2',
            title: 'Download Interval Data Customer List',
            fn: exportEnergyCustomersAction,
        });
    }
    if (ability.can('read', 'ConnectCustomer')) {
        actions.push({
            key: 'e-b-3',
            title: 'Download Connect Customer List',
            fn: exportConnectCustomersAction,
        });
    }

    return <ExportButton actions={actions} />;
};
