import omit from 'lodash/omit';
import { API } from '../api';
import { IPagination } from '../IPagination';
import { ICleanResponseTrigger } from './interface';
import { format } from 'date-fns';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

interface IFetchPageQuery {
    pagination?: {
        pageSize?: number;
        current?: number;
    };
    sorter?: {
        field?: string;
        order?: string;
    };
    include?: string;
    companyId?: any;
    search?: string;
}

export const fetchCleanResponseTriggers = async (
    pageQuery: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICleanResponseTrigger>> => {
    let { pagination, sorter, search, include, companyId = null } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/clean-response-triggers?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(companyId && { companyId }),
        ...(search && { search }),
        ...(include && { include }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot list Clean Response Triggers data`);
};

export const createCleanResponseTrigger = async (cleanResponseTrigger: ICleanResponseTrigger) => {
    const response = await API.fetch('/clean-response-triggers', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(cleanResponseTrigger, ['id'])),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save Clean Response Trigger!`);
};

export const updateCleanResponseTrigger = async (cleanResponseTrigger: ICleanResponseTrigger) => {
    const response = await API.fetch(`/clean-response-triggers/${cleanResponseTrigger.id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(cleanResponseTrigger, ['company', 'id'])),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save Clean Response trigger!`);
};

export const deleteCleanResponseTrigger = async (trigger: ICleanResponseTrigger): Promise<void> => {
    const response = await API.fetch(`/clean-response-triggers/${trigger.id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete Clean Response trigger!`);
};

export const saveCleanResponseTrigger = async (data: ICleanResponseTrigger) =>
    data.id ? updateCleanResponseTrigger(data) : createCleanResponseTrigger(data);

export const importCleanResponseTriggers = async (triggers: ICleanResponseTrigger[]) => {
    const response = await API.fetch('/clean-response-triggers/import', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ triggers }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();

    throw new Error(respBody?.error?.message || 'Cannot import Clean Response Triggers!');
};

export const exportCleanResponseTriggers = async ({ companyId, search }: any): Promise<void> => {
    const query = new URLSearchParams({
        format: 'csv',
        include: 'company',
        ...(companyId && { companyId }),
        ...(search && { search }),
    });

    const response = await API.fetch(`/clean-response-triggers?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();
        const filename = `clean_response_triggers_${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const exportCleanResponseTemplate = () => {
    const csvData =
        'Market,Carbon Intensity(CO2/MWh),Event duration(minutes in range [1-480]),Event signal(number:0-normal;1-moderate;2-high;3-special),Cancel when carbon intensity lower(true/false),Start Time(number in format hh:mm),End Time(number in format hh:mm)  \n';
    exportCSVData(csvData, 'clean_response_triggers-template.csv');
};
