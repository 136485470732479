import { NYISOLabelMapping } from '../../domain/market';
import { ISite } from '../../domain/site/interface';

export function marketLoadZoneToLabel(market: string, zone: string): string {
    if (market.toUpperCase() === 'NYISO' && NYISOLabelMapping[zone.toUpperCase()]) {
        return NYISOLabelMapping[zone.toUpperCase()];
    } else {
        return zone.toUpperCase();
    }
}

export const SiteMarketAndLoadZone = ({ site }: { site: ISite }) => {
    let str = ``;

    if (site.lmp_market) {
        str += `${site.lmp_market.toUpperCase()}`;
    }

    if (site.site_load_zone) {
        str += `, ${marketLoadZoneToLabel(site.lmp_market, site.site_load_zone)}`;
    }

    return <>{str}</>;
};
