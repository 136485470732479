import { useMemo } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import Typography from 'antd/lib/typography';
import Empty from 'antd/lib/empty';

import { ITotalOverviewItem, statusColorMap } from '../../../../domain/ven/interface';
import { IPieChart } from './chartInterface';

echarts.use([TooltipComponent, GridComponent, CanvasRenderer, TitleComponent, PieChart]);

const prepareOverviewData = (overviewData: ITotalOverviewItem) =>
    Object.entries(overviewData).map(([key, value]) => ({
        name: key,
        value,
        itemStyle: { color: statusColorMap[key] },
        label: {
            show: !!value,
            formatter: '{b}: {c}',
        },
    }));

export const OverviewPieChart = ({ overviewData, title }: IPieChart) => {
    const preparedOverviewData = useMemo(() => prepareOverviewData(overviewData), [overviewData]);
    const isNoData = Object.values(overviewData).every(value => !value);
    const option = {
        legend: {},
        tooltip: {
            trigger: 'item',
        },
        series: [
            {
                type: 'pie',
                radius: '50%',
                showEmptyCircle: true,
                data: preparedOverviewData,
            },
        ],
        grid: {
            top: '0',
        },
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        },
    };

    return (
        <>
            <Typography.Title level={5} style={{ paddingTop: '8px', textAlign: 'center' }}>
                {title}
            </Typography.Title>
            {isNoData ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <ReactEChartsCore echarts={echarts} option={option} notMerge={true} lazyUpdate={true} />
            )}
        </>
    );
};
