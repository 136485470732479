import App from 'antd/lib/app';
import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Flex from 'antd/lib/flex';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Tag from 'antd/lib/tag';
import { useMemo } from 'react';
import { IWeatherForecastDaily } from '../../../../domain/weather/openWeather';
import { useForecastWeatherQuery } from '../../../../domain/weather/queries';
import { SUCCESS_COLOR } from '../../../../theme';
import greyCloud from '../../images/greyCloud.jpeg';
import './weatherForecast.css';
import './weatherIcons.css';

interface IWeatherForecast {
    location?: {
        lat: number;
        lng: number;
    };
    isActiveEvent: Map<string, boolean>;
}

const colStyles = {
    flexBasis: '20%',
    width: '20%',
};

export function WeatherForecast({ location, isActiveEvent }: IWeatherForecast) {
    const { notification } = App.useApp();

    const { data, isLoading, isError, error } = useForecastWeatherQuery(location!, {
        enabled: !!location?.lat && !!location?.lng,
        keepPreviousData: true,
    });

    if (isError) {
        notification.error({ key: 'weather-forecast', message: error.message || 'Cannot get weather-forecast' });
    }

    const weatherForecast = useMemo(() => {
        if (!data) return [];

        return prepareDailyForecast(data, isActiveEvent);
    }, [data, isActiveEvent]);

    if (isLoading) {
        return (
            <Skeleton loading active paragraph={{ rows: 5 }} />
        );
    }

    if (weatherForecast.length === 0) {
        return (
            <Flex align='center' justify='center' style={{ padding: '24px' }}>
                <Empty
                    description='No weather for this place'
                    image={greyCloud}
                    imageStyle={{ height: '60px' }}
                />
            </Flex>
        );
    }

    return (
        <>
            <Row gutter={8}>
                {weatherForecast.map(day => (
                    <Col style={{ ...colStyles }} key={day.dayName}>
                        <div className='weather-forecast-day-card'>
                            <h2>{day.dayName}</h2>
                            <h1>{day.temperature}°</h1>
                            <div className='content'>
                                <span className='icon-wrap icon'>
                                    <i className={`wi ${day.icon}`}></i>
                                </span>
                                <div className='description'>
                                    <p>{day.description}</p>
                                </div>
                                <div className={`dr-chance dr-chance-${day.drChance}`}>&nbsp;</div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

            <WeatherForecastLegend />
        </>
    );
}

function WeatherForecastLegend() {
    return (
        <div className='weather-forecast-legend'>
            <span>Forecasted chance of DR Event&nbsp;&nbsp;</span>
            <Tag color='#888787'>None</Tag>
            <Tag color={SUCCESS_COLOR}>Low</Tag>
            <Tag color='#fac42a'>Medium</Tag>
            <Tag color='#fa675c'>High</Tag>
        </div>
    );
}

function prepareDailyForecast(dailyForecast: IWeatherForecastDaily[], isActiveEvent: IWeatherForecast['isActiveEvent']) {
    const MAX_FORECAST_DAYS = 5;

    const daysToDisplay = Math.min(dailyForecast.length, MAX_FORECAST_DAYS);
    const fiveDayForecast = dailyForecast.slice(0, daysToDisplay);

    if (fiveDayForecast.length) {
        isActiveEvent.get('today') && (fiveDayForecast[0].drChance = 3);
        isActiveEvent.get('tomorrow') && (fiveDayForecast[1].drChance = 3);
    }

    return fiveDayForecast;
}
