import React, { ReactNode } from 'react';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

interface ILoadingIconProps {
    icon: ReactNode;
    loading?: boolean;
}

export const LoadingIcon: React.FC<ILoadingIconProps> = ({
    icon,
    loading = false,
}) => {
    return (
        <>
            {loading ? <LoadingOutlined spin style={{ fontSize: 18 }} /> : icon}
        </>
    );
};
