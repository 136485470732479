export enum DataInboxFileStatus {
    PENDING = 'pending', // file placed to parse
    DONE = 'done', // file was successfully parsed and data was extracted
    FAILED = 'failed', // file was not parsed, due to error in code or in file format, or unknown file format
    SKIPPED = 'skipped', // file content type was recognized, but we skipped to parse it as we do not interest in this kind of data
}

export interface DataInboxFileInfoDto {
    key: string;
    type: string;
    source: string;
    status: DataInboxFileStatus;
    message?: string;
    contentType?: string;
    sizeInBytes?: number;
    receivedAt: string;
    createdAt: string;
    updatedAt: string;
}
