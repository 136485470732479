import { API } from '../api';
import { readAsBlob } from '../common/readAsBlob';

export interface IPresignUploadDetails {
    url: string;
    hash: string;
}

export class Uploader {
    async upload(companyId: number, f: File): Promise<{ hash: string }> {
        const uploadParams = await this.preSignUploadUrl(companyId, f.name);

        await this.uploadToS3(uploadParams, f);

        return { hash: uploadParams.hash };
    }

    private async preSignUploadUrl(companyId: number, filename: string): Promise<IPresignUploadDetails> {
        const response = await API.fetch(`/settlement-files/presign`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filename,
                companyId,
            }),
        });

        const respBody: any = await response.json();

        if (response.ok) {
            return respBody;
        }

        throw new Error(respBody?.error?.message || `Cannot presign upload URL!`);
    }

    private async uploadToS3(params: IPresignUploadDetails, file: File) {
        const blob = await readAsBlob(file);

        await fetch(params.url, {
            method: 'PUT',
            body: blob,
        });
    }
}
