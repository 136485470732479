import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { endOfYear, startOfYear } from 'date-fns';
import App from 'antd/lib/app';
import Card from 'antd/lib/card';
import Row from 'antd/lib/row';
import Empty from 'antd/lib/empty';
import Skeleton from 'antd/lib/skeleton';
import Divider from 'antd/lib/divider';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import CloudOutlined from '@ant-design/icons/CloudOutlined';
import './impactInformation.css';
import { calculateTotalMetricsByMonths, formatTotalMetrics } from '../../../../domain/impact/metricsCalculations';
import { useAuth } from '../../../../domain/auth/useAuth';
import { useImpactListQuery } from '../../../../domain/impact/queries';
import { numberFormatter } from '../../../../domain/common/numberFormatter';
import { formatLbs, formatWatt } from '../../../impact/impact';
import stopWatch from '../../../../components/icons/stop-watch.svg';
import capacitySvg from '../../../../components/icons/capacity.svg';
import { InformationCardTitle } from '../../../../components/informationCard/informationCardTitle';
import { InformationCard } from '../../../../components/informationCard/informationCard';
import { MetricData } from '../../../../components/metricData/metricData';
import Space from 'antd/lib/space';
import Alert from 'antd/lib/alert';

const defaultDate = {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
};

export const ImpactInformation = ({ company }: { company: number | null }) => {
    const { notification } = App.useApp();
    const companyQuery = company ? `?companyId=${company}` : '';

    const auth = useAuth();

    const isQueryData = (auth?.user?.isAdminRoleType() || !!company);
    const { data: rawImpactData, isLoading, isError, error } = useImpactListQuery({ companyId: company, date: defaultDate }, {
        enabled: isQueryData
    });

    if (isError) {
        notification.error({
            key: 'impact-information-error',
            message: error?.message || 'Cannot load impact information!',
        });
    }

    const impactData = useMemo(() => {
        if (!rawImpactData || rawImpactData.length === 0) return undefined;

        return { ...rawImpactData[0], metrics: calculateTotalMetricsByMonths(rawImpactData[0].metrics) }
    }, [rawImpactData]);

    const renderContent = () => {
        if (!impactData) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
        }

        const totalMetrics = formatTotalMetrics(impactData.metrics);

        return (
            <Fragment>
                <InformationCard
                    classNames={['impact-information-card']}
                    title={<InformationCardTitle title='Locations' icon={<EnvironmentOutlined />} />}
                >
                    <MetricData value={numberFormatter.format(totalMetrics.locations)} />
                </InformationCard>

                <Divider type='vertical' className='impact-information-card-divider' />
                <InformationCard
                    classNames={['impact-information-card']}
                    title={<InformationCardTitle title='Event Hours'
                        icon={<img src={stopWatch} alt='events Hours img' />} />}
                >
                    <MetricData value={numberFormatter.format(totalMetrics.eventsHours)} />
                </InformationCard>
                <Divider type='vertical' className='impact-information-card-divider' />
                <InformationCard
                    classNames={['impact-information-card']}
                    title={<InformationCardTitle title='Available Capacity'
                        icon={<img src={capacitySvg} alt='Available Capacity img' />} />}
                >
                    <MetricData
                        uom={formatWatt(totalMetrics.capacity * 1000).uom}
                        value={numberFormatter.format(formatWatt(totalMetrics.capacity * 1000).value)}
                    />
                </InformationCard>
                <Divider type='vertical' className='impact-information-card-divider' />
                <InformationCard
                    classNames={['impact-information-card']}
                    title={<InformationCardTitle title='CO2 Impact' icon={<CloudOutlined />} />}
                >
                    <MetricData uom={formatLbs(totalMetrics.co2Impact).uom}
                        value={numberFormatter.format(formatLbs(totalMetrics.co2Impact).value)} />
                </InformationCard>
                <Divider type='vertical' className='impact-information-card-divider' />
            </Fragment>
        );
    };

    if (auth?.user?.isControlProvider() && !company) {
        return (
            <Card title='Impact' style={{ marginTop: '16px' }} extra={<Link to={`/impact${companyQuery}`}>View More</Link>}>
                <Row justify='center'>
                    <Space direction='vertical'>
                        <Alert showIcon message='Please select a company' type='warning' />
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Space>
                </Row>
            </Card>
        );
    }

    return (
        <Card title='Impact' style={{ marginTop: '16px' }} extra={<Link to={`/impact${companyQuery}`}>View More</Link>}>
            {isLoading ? (
                <Skeleton loading paragraph={{ rows: 3 }} active />
            ) : (
                <Row gutter={[16, 16]} style={{ justifyContent: 'space-evenly' }} align='middle'>
                    {renderContent()}
                </Row>
            )}
        </Card>
    );
};
