import { ISan, ISanFormItem } from '../../domain/site/interface';
import { formatSan } from '../../domain/site/formatSan';

export class SiteSanFormData {
    static fromEntity(san: ISan): ISanFormItem {
        return {
            service_account_number: formatSan(san.service_account_number),
            is_active: san.is_active,
        };
    }
}
