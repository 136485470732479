import React from 'react';
import sortBy from 'lodash/sortBy';
import Select, { SelectProps } from 'antd/lib/select';
import { CleanResponseMarket } from '../../../domain/clean-response/interface';

interface ICleanResponseMarketSelectorProps extends SelectProps {}

// Sort markets alphabetically
const cleanResponseMarketOptions = sortBy(Object.keys(CleanResponseMarket))
    .map((market) => ({ label: market, value: market.toLowerCase() }));

export const CleanResponseMarketSelector: React.FC<ICleanResponseMarketSelectorProps> = (props) => {
    return (
        <Select
            showSearch
            size='large'
            options={cleanResponseMarketOptions}
            placeholder='Select Market'
            {...props}
        />
    );
};