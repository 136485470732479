export interface IResource {
    id: string;
    company: string;
    programs: string[];
    _program?: string;
    site: string;
    zip: string;
    utility: string | null;
    selected?: boolean;
    event_offset: number;
    event_max_duration: number;
    site_state: string;
    sans: string[];
    site_address: string | null;

    lmp_market: string | null;
    site_load_zone: string | null;

    _market_load_zone?: string | null;
}

export class Resource {
    static SEPARATOR = ':::::';

    static transformList(resourceList: IResource[]): IResource[] {
        return resourceList.map(item => {
            const { company, programs, lmp_market, site_load_zone } = item;
            const sortedPrograms = [...programs].sort((r1, r2) => {
                if (r1.toUpperCase() < r2.toUpperCase()) return -1;
                if (r1.toUpperCase() > r2.toUpperCase()) return 1;
                return 0;
            });

            item.selected = false;

            item._market_load_zone = `${company}${Resource.SEPARATOR}${lmp_market}${Resource.SEPARATOR}${site_load_zone}`;

            item._program = `${company}${Resource.SEPARATOR}${lmp_market}${Resource.SEPARATOR}${site_load_zone}${Resource.SEPARATOR}${programs.join(',')}`;
            item.programs = sortedPrograms;

            return item;
        });
    }

    static parseValue(value: string): Partial<IResource> | null {
        if (!value) return null;
        const parts = value.split(Resource.SEPARATOR);
        const partsLength = parts.length;

        switch (partsLength) {
            case 1:
                return {
                    company: parts[0],
                };
            case 2:
                return {
                    company: parts[0],
                    lmp_market: parts[1] === 'null' ? null : parts[1],
                };
            case 3:
                return {
                    company: parts[0],
                    lmp_market: parts[1] === 'null' ? null : parts[1],
                    site_load_zone: parts[2] === 'null' ? null : parts[2],
                };
            case 4:
                return {
                    company: parts[0],
                    lmp_market: parts[1] === 'null' ? null : parts[1],
                    site_load_zone: parts[2] === 'null' ? null : parts[2],
                    programs: parts[3].split(','),
                };

            default:
                return null;
        }
    }
}
