import config from '../../config';
import { ISiteAddress } from '../site/interface';

export async function parseGooglePlace(place: google.maps.places.PlaceResult) {
    if (!place?.address_components) return null;

    const location: ISiteAddress = {
        site_address: null,
        site_city: null,
        site_country: null,
        site_lat: null,
        site_long: null,
        site_state: null,
        site_zip: null,
        site_timezone: null,
        google_place_id: null,
        site_county: null,
    };

    const components_by_type: any = {};

    for (let component of place.address_components) {
        components_by_type[component.types[0]] = component;
    }

    if ('street_number' in components_by_type) {
        let routeLongName = 'route' in components_by_type ? components_by_type['route']?.long_name : '';
        location.site_address = components_by_type['street_number']?.long_name + ' ' + routeLongName;
    } else {
        const parseFormattedAddress = (place.formatted_address || '').split(',')[0];
        location.site_address = parseFormattedAddress || null;
    }

    // Handle places API for new york locality with boroughs
    if ('locality' in components_by_type) {
        location.site_city = components_by_type['locality']?.long_name;
    } else if ('city' in components_by_type) {
        location.site_city = components_by_type['city']?.long_name;
    } else if ('neighborhood' in components_by_type) {
        location.site_city = components_by_type['neighborhood']?.long_name;
    } else if ('sublocality_level_1' in components_by_type) {
        location.site_city = components_by_type['sublocality_level_1']?.long_name;
    } else {
        location.site_city = components_by_type['administrative_area_level_3']?.long_name;
    }

    location.site_state = components_by_type['administrative_area_level_1']?.long_name;
    location.site_country = components_by_type['country']?.long_name;
    location.site_zip = components_by_type['postal_code']?.long_name ? components_by_type['postal_code']?.long_name : null;
    location.site_county = components_by_type['administrative_area_level_2']?.long_name || null;

    location.google_place_id = place.place_id;
    location.site_lat = place?.geometry?.location?.lat() || null;
    location.site_long = place?.geometry?.location?.lng() || null;

    if (location.site_lat !== null && location.site_long !== null) {
        location.site_timezone = await getTimezoneByLatLng(location.site_lat, location.site_long);
    }

    return location;
}

async function getTimezoneByLatLng(lat: number, lng: number): Promise<string | null> {
    try {
        interface ITimezoneResponse {
            dstOffset: number;
            rawOffset: number;
            status: 'OK' | 'INVALID_REQUEST' | 'OVER_DAILY_LIMIT' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'UNKNOWN_ERROR' | 'ZERO_RESULTS';
            timeZoneId: string;
            timeZoneName: string;
        }

        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${currentTimestamp.toString()}&key=${
            config.GOOGLE_MAP_API
        }`;

        const response = await fetch(url, { mode: 'cors' });

        const respBody: ITimezoneResponse = await response.json();

        if (response.ok && respBody.status === 'OK') {
            return respBody.timeZoneId;
        }

        return null;
    } catch (err) {
        console.error('getTimezoneByLatLng', err);
        return null;
    }
}
