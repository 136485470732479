import format from 'date-fns/format';
import omit from 'lodash/omit';
import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ICreateProgram, IProgram, IUniqueProgramOperator, IUniqueProgramType, IUpdateProgram } from './interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

export const fetchPrograms = async (
    { sorter, pagination, search, include, filter }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IProgram>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;
    if (!sorter?.field || !sorter?.order) {
        sorter = {};
    }

    const path = `/programs?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...filter,
        ...(search && { search }),
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of programs!`);
};

export const fetchProgramOperators = async (signal?: AbortSignal | null): Promise<IUniqueProgramOperator[]> => {
    const response = await API.fetch(`/programs/operators`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of programs operators!`);
};

export const fetchProgramsByCompanyId = async ({company_id}: IFetchPageQuery, signal?: AbortSignal | null): Promise<IProgram[]> => {
    
    const path = `/programs/byId?${new URLSearchParams({
        ...(company_id && { companyId: company_id.toString() }),
    })}`;
    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of programs by company!`);
};

export const fetchProgramTypes = async (signal?: AbortSignal | null): Promise<IUniqueProgramType[]> => {
    const response = await API.fetch(`/programs/types`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of programs types!`);
};

export const deleteProgram = async (program: IProgram) => {
    const response = await API.fetch(`/programs/${program.program_id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete program!`);
};

export const createProgram = async (program: ICreateProgram) => {
    const response = await API.fetch(`/programs`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(program),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save program!`);
};

export const updateProgram = async (program: IUpdateProgram) => {
    const response = await API.fetch(`/programs/${program.program_id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(program, 'program_id')),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot update program!`);
};

export const exportPrograms = async ({ search, filter = {} }: IFetchPageQuery) => {
    const response = await API.fetch(
        `/programs?${new URLSearchParams({
            format: 'csv',
            ...(search && { search }),
            ...filter,
            include: 'enrolled-sites-count,products',
        })}`,
        {
            headers: {
                'Content-Type': 'text/csv',
            },
        }
    );

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `programs--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};
