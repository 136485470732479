import { IEvent } from './interface';
import { ISiteEnergyUsageData } from '../energy/interface';
import { flatten } from 'lodash';

export interface IEventData {
    site_id: number,
    data: IEvent[]
}

export const getEventsData = (sitesEnergyUsageDataResults: ISiteEnergyUsageData[] | undefined) => {
    if (!sitesEnergyUsageDataResults || sitesEnergyUsageDataResults?.length === 0) {
        return [];
    }

    const events: IEventData[] = [];
    sitesEnergyUsageDataResults.forEach(data => {
        if (data?.events && data?.events.length) {

            events.push({
                site_id: data.site.site_id,
                data: data?.events,
            });
        }
    });
    return flatten(events);
};