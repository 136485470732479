import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchGreenButton } from './index';
import { IGreenButton } from './interface';

export const useGreenbuttonListQuery = (query: {}, options = {}) => {
    return useQuery<IGreenButton[], Error>({
        queryKey: [QueryKey.GREENBUTTONS, query],
        queryFn: ({ signal }) => fetchGreenButton(signal),
        ...options,
    });
};
