import { supportedEnergyMarkets } from '../../domain/market-prices/interface';
import { ICreateProgram, IProgram, IUpdateProgram, ProgramUtilityAutomation } from '../../domain/program/interface';

export interface IProgramFormData {
    program_id?: number;
    name: string;
    utility_id?: string;
    iso?: string;
    type_id: string;
    access_partner_id: string;
    description?: string;
    utility_automation?: ProgramUtilityAutomation | null;
    utility_iso?: string;
    product_ids: string[];
}

export class ProgramFormData {
    static fromEntity(program: IProgram | Partial<IProgram>): IProgramFormData {
        return {
            program_id: program.program_id,
            name: program.name!,
            type_id: program.type_id!,
            access_partner_id: program.access_partner_id!,
            description: program.description,
            utility_automation: program?.utility_automation || null,
            utility_iso: program.utility_id || program.iso,
            utility_id: program.utility_id,
            iso: program.iso,
            product_ids: program.products?.map((product) => product.salesforce_id) || [],
        };
    }

    static toEntity(formData: IProgramFormData): ICreateProgram | IUpdateProgram {
        const utilityISOfieldName = supportedEnergyMarkets.includes(formData.utility_iso!) ? 'iso' : 'utility_id';

        return {
            program_id: formData?.program_id,
            description: formData.description,
            name: formData.name,
            type_id: formData.type_id || null,
            access_partner_id: formData.access_partner_id || null,
            utility_automation: formData?.utility_automation || null,
            product_ids: formData.product_ids || [],
            [utilityISOfieldName]: formData.utility_iso,
        };
    }
}
