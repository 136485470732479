import { useState } from 'react';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import './AlternativeNamesInput.css';

interface IAlternativeNamesInputProps {
    utilities: string[];
    setUtilityNames: Function;
}

const DEFAULT_STATE = {
    isInputVisible: false,
    value: '',
};

export const AlternativeNamesInput = ({ utilities, setUtilityNames }: IAlternativeNamesInputProps) => {
    const [inputState, setInputState] = useState(DEFAULT_STATE);

    const showInput = () => setInputState({ ...inputState, isInputVisible: true });

    const handleChange = (e: any) => {
        setInputState({
            ...inputState,
            value: e.target.value,
        });
    };

    const handleClose = (utilityTagToRemove: string) => () => {
        const tags = utilities.filter(utility => utility !== utilityTagToRemove);
        setUtilityNames(tags);
    };

    const handleInputConfirm = () => {
        const result = utilities;
        const value = inputState.value;
        if (value && !utilities.includes(value)) {
            result.push(value);
        }
        setUtilityNames(result);
        setInputState(DEFAULT_STATE);
    };

    return (
        <Form.Item
            className='alternative-names-input'
            label={<Typography.Text strong>Company Alias at Utility</Typography.Text>}
            tooltip='The name/alias used at a utility, received in Greenbutton connections.'
        >
            {utilities.map(utility => (
                <Tag key={utility} closable onClose={handleClose(utility)} style={{ maxWidth: '100%', marginBottom: '8px' }}>
                    <Typography.Text ellipsis style={{ maxWidth: '96%' }}>
                        {utility}
                    </Typography.Text>
                </Tag>
            ))}

            {inputState.isInputVisible
                ? (
                    <Input
                        size='small'
                        className='tag-input'
                        autoFocus
                        value={inputState.value}
                        onChange={handleChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )
                : (
                    <Tag className='add-utility-name' onClick={showInput}>
                        <PlusOutlined /> New Alias
                    </Tag>
                )}
        </Form.Item>
    );
};
