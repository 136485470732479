import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Tooltip from 'antd/lib/tooltip';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isBefore from 'date-fns/isBefore';
import subMinutes from 'date-fns/subMinutes';
import React, { useMemo } from 'react';
import { AsyncJobStatus, IAsyncJob, JobName } from '../../../domain/async-job/interface';
import { useAsyncJobCreateMutation, useAsyncJobListQuery } from '../../../domain/async-job/queries';
import { MINUTE_MS, SORT_ORDER } from '../../../domain/commonConst';
import { AsyncJobsModalContent } from './AsyncJobsModalContent';

interface IAsyncJobsModalProps {
    isModalOpen: boolean;
    jobName: JobName;
    onClose: () => void;
}

const LIMIT = 10;

const MODAL_NAME = {
    [JobName.SYNC_SALESFORCE_PRODUCTS]: 'Salesforce Products',
    [JobName.SYNC_SALESFORCE_METERS_WITH_ENROLLMENTS]: 'Salesforce Meters with Enrollments',
    [JobName.SYNC_SALESFORCE_ACCOUNTS]: 'Salesforce Accounts',
};

export const AsyncJobsModal: React.FC<IAsyncJobsModalProps> = ({ isModalOpen, jobName, onClose }) => {
    const { mutate: createAsyncJob, isLoading: isCreateLoading } = useAsyncJobCreateMutation();
    const { data, isLoading } = useAsyncJobListQuery(
        {
            current: 1,
            pageSize: LIMIT,
            sortField: 'created_at',
            sortOrder: SORT_ORDER.DESCEND,
            filter: {
                jobName,
            },
        },
        {
            enabled: isModalOpen,
            refetchInterval: MINUTE_MS,
        }
    );
    const asyncJobs = useMemo(() => data?.data || [], [data]);

    const hasActiveJob = useMemo(
        () => {
            const now = new Date();

            return filterOutStuckedJobs(asyncJobs).some(
                job => {
                    const isInProgress = job.status === AsyncJobStatus.IN_PROGRESS;
                    const isPending = job.status === AsyncJobStatus.PENDING && differenceInMinutes(now, new Date(job.created_at)) < 10;

                    return isInProgress || isPending;
                }
            );
        },
        [asyncJobs]
    );

    const handleCreateAsyncJob = () => {
        createAsyncJob({
            job_name: jobName,
        });
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={onClose}
            destroyOnClose
            title={`${MODAL_NAME[jobName]} sync statuses`}
            width={1100}
            footer={[
                <Tooltip
                    title={
                        hasActiveJob
                            ? 'There is active or pending job, please, wait until this process finishes'
                            : 'Syncing data from Salesforce may take up to 10 minutes'
                    }
                >
                    <Button
                        type="primary"
                        key="sync-button"
                        disabled={hasActiveJob}
                        onClick={handleCreateAsyncJob}
                        loading={isCreateLoading}
                    >
                        Sync Now
                    </Button>
                </Tooltip>,
            ]}
        >
            <AsyncJobsModalContent asyncJobs={asyncJobs} limit={LIMIT} isLoading={isLoading} />
        </Modal>
    );
};

function filterOutStuckedJobs(jobs: IAsyncJob[]) {
    return jobs.filter(job => {
        if (job.status !== AsyncJobStatus.IN_PROGRESS) return true;

        const tenMinutesAgo = subMinutes(new Date(), 10);

        return !isBefore(new Date(job.created_at), tenMinutesAgo);
    });
}
