import { IUser, UserType } from '../user/interface';

export class Actor {
    user_id: number;
    company_id: number;
    company?: any;
    user_fname: string;
    user_lname: string;
    user_email: string;
    user_phone: string;
    user_type: UserType;
    user_feature_flag: string[];
    user_super_admin: boolean;
    mfa_enabled: boolean;
    site_label: string[];

    constructor(user: IUser) {
        this.user_id = user.user_id;
        this.company_id = user.company_id;
        this.company = user.company;
        this.user_fname = user.user_fname;
        this.user_lname = user.user_lname;
        this.user_email = user.user_email;
        this.user_phone = user.user_phone;
        this.user_type = user.user_type;
        this.user_feature_flag = user.user_feature_flag;
        this.user_super_admin = user.user_super_admin;
        this.mfa_enabled = user.mfa_enabled;
        this.site_label = user.site_label;
    }

    isSuperAdmin(): boolean {
        return this.user_super_admin && this.user_type === UserType.ADMIN;
    }

    isAdmin(): boolean {
        return this.user_type === UserType.ADMIN;
    }

    isAdminRoleType(): boolean {
        return [UserType.ADMIN, UserType.ACCOUNT_MANAGER].includes(this.user_type);
    }

    isCustomer(): boolean {
        return this.user_type === UserType.CUSTOMER;
    }

    isOperator(): boolean {
        return this.user_type === UserType.OPERATOR;
    }

    isControlProvider(): boolean {
        return this.user_type === UserType.CONTROL_PROVIDER;
    }
}
