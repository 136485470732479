import { ISiteLocation } from '../site/interface';
import { IRealTimeNodesPrice, IRealTimeZonesPrice, PriceDa, PriceRt } from './interface';

export function mapLocationPrices(locations: ISiteLocation[], prices: (IRealTimeNodesPrice | IRealTimeZonesPrice)[]): ISiteLocation[] {
    const pricesMap = new Map<string, [PriceRt, PriceDa]>();
    prices.forEach(item => {
        if (!('zones' in item) && !('nodes' in item)) return;

        const entries = 'nodes' in item ? Object.entries(item.nodes) : Object.entries(item.zones);
        for (let [key, nodeOrZonePrices] of entries) {
            pricesMap.set(key, nodeOrZonePrices);
        }
    });

    locations = locations.map(loc => {
        const name = loc.lmp_node_name || loc.site_load_zone;
        if (!name) return loc;

        const nodePrices = pricesMap.get(name);
        if (!nodePrices) return loc;

        loc.lmp_price_rt = nodePrices[0];
        loc.lmp_price_da = nodePrices[1];
        return loc;
    });

    return locations;
}
