import EditOutlined from '@ant-design/icons/EditOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Form, { FormInstance } from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import { cloneDeep } from 'lodash';
import inRange from 'lodash/inRange';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import config from '../../config';
import { useAuth } from '../../domain/auth/useAuth';
import { COMPANY_TYPE, ICompany } from '../../domain/company/interface';
import { parseGooglePlace } from '../../domain/location';
import { checkForStoreNumberDuplicates, checkIfCanUseSan, checkForAddressDuplicate } from '../../domain/site';
import {
    ICreateSite,
    IMeter,
    ISanFormItem,
    ISite,
    ISiteStoreNumberCheckRequest,
    IUpdateSite,
} from '../../domain/site/interface';
import { useSiteCreateMutation, useSiteUpdateMutation } from '../../domain/site/queries';
import { suggestSANFormat } from '../../domain/site/suggestSANFormat';
import { handleLettersNumberInput } from '../../pages/sites/siteInterface';
import { AbilityContext } from '../ability/can';
import {
    filterCompaniesByType,
    transformCompaniesToSelectOption,
} from '../selectors/globalCompanySelector/companyHelpers';
import { filterSelectorOption } from '../selectors/selectorHelpers';
import { SmartMarketZoneSelector } from '../selectors/SmartMarketZoneSelector/SmartMarketZoneSelector';
import { UtilitySelector } from '../selectors/UtilitySelector/UtilitySelector';
import { ManageSanModal } from './ManageSanModal';
import { PartnerIdStatusAlert } from './PartnerIdStatusAlert';
import { SanDetailsList } from './SanDetailsList';
import { ISiteFormData, SiteFormData } from './SiteFormData';
import { SiteLabelsSection } from './SiteLabelsSection';
import { SiteSanFormData } from './SiteSanFormData';
import { MeterList } from './MeterList';
import { ManageMeterModal } from './ManageMeterModal';

interface ISiteFormModal {
    companies: ICompany[];
    onClose: (site?: Partial<ISite>, closeModal?: boolean) => void;
    site: ISite | Partial<ISite>;
}

export function SiteFormModal({ site, companies, onClose }: ISiteFormModal) {
    const { notification } = App.useApp();
    const [form] = Form.useForm<ISiteFormData>();

    const auth = useAuth()!;
    const isCustomerRoleType = auth.user?.isCustomer();
    const ability = useContext(AbilityContext);
    const customerCompanies = filterCompaniesByType(companies, COMPANY_TYPE.CUSTOMER_COMPANY);
    const controlProviderCompanies = filterCompaniesByType(companies, COMPANY_TYPE.CONTROL_PROVIDER);
    const [place, setPlace] = useState(getPlaceFromSite(site));

    const [state, setState] = useState({
        isManageSansModalOpen: false,
        siteSans: site.sans?.map(SiteSanFormData.fromEntity) || [],
        labels: site.site_label || [],
    });

    const companyId = Form.useWatch('company_id', form);
    const utility = Form.useWatch('site_utility', form);
    const marketZone = Form.useWatch('site_market_and_zone', form);
    const siteId = Form.useWatch('site_id', form);
    const address = Form.useWatch('site_address', form);
    const market = marketZone?.[0] || site?.lmp_market || null;

    const isNewSite = !siteId;

    const siteCreateMutation = useSiteCreateMutation();
    const siteUpdateMutation = useSiteUpdateMutation();

    const isLoading = siteCreateMutation.isLoading || siteUpdateMutation.isLoading;

    const [isManageMetersModalOpen, setIsManageMetersModalOpen] = useState<boolean>(false);

    const [selectedMeters, setSelectedMeters] = useState<IMeter[]>(site.meters || []);

    useEffect(() => {
        if (site.site_id) {
            form?.validateFields(['site_store_number']);
        }
    }, []);

    const closeManageMetersModal = () => {
        setIsManageMetersModalOpen(false);
    };

    const openManageMetersModal = () => {
        setIsManageMetersModalOpen(true);
    };

    const handleManageMetersFinish = (updatedMeters: IMeter[]) => {
        const updatedMetersIds = updatedMeters.map(meter => meter.salesforce_id);

        setSelectedMeters(updatedMeters);
        form.setFieldValue('meter_ids', updatedMetersIds);
        setIsManageMetersModalOpen(false);
    };

    const handleUnlinkMeters = (salesforceId: string) => {
        const updatedMeters = selectedMeters.filter(meter => meter.salesforce_id !== salesforceId);
        const updatedMetersIds = updatedMeters.map(meter => meter.salesforce_id) || [];

        setSelectedMeters(updatedMeters);
        form.setFieldValue('meter_ids', updatedMetersIds);
    };

    async function onSiteModalFormFinish(siteFormData: ISiteFormData) {
        try {
            let preparedSite = SiteFormData.toEntity(siteFormData, auth.user!);
            preparedSite.site_label = state.labels;
            preparedSite.sans = state.siteSans;

            if (!isNewSite) {
                await siteUpdateMutation.mutateAsync(preparedSite as IUpdateSite);
            } else {
                await siteCreateMutation.mutateAsync(preparedSite as ICreateSite);
            }

            notification.success({ key: 'site-save-info', message: 'Site saved' });
            onClose(site);
        } catch (error: any) {
            notification.error({ key: 'site-save-info', message: error?.message || 'Cannot save site!' });
        }
    }

    const handleManageSansModalOpen = (value: boolean) => {
        setState({
            ...state,
            isManageSansModalOpen: value,
        });
    };

    const handleLabelsChange = (value: string[]) => {
        setState({
            ...state,
            labels: value,
        });
    };

    function handleMarketZoneChange(value: string | string[] | undefined) {
        form.setFieldValue('site_market_and_zone', value);
        form.validateFields(['site_market_and_zone']);
    }

    function handleUtilityChange(value: string | string[]) {
        form.setFieldValue('site_utility', value);
    }

    function closeSiteDialog() {
        onClose();
    }

    function handleSansUpdated(updatedSans: ISanFormItem[]) {
        setState(prevState => ({
            ...prevState,
            siteSans: updatedSans,
            isManageSansModalOpen: false,
        }));
    }

    function handleSansModalClose() {
        handleManageSansModalOpen(false);
    }

    const antInputRef: any = useRef(null);
    const { ref: antRef }: any = usePlacesWidget({
        apiKey: config.GOOGLE_MAP_API,
        language: 'en',
        options: {
            //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest.types
            types: ['establishment', 'geocode'],
        },
        onPlaceSelected: async place => {
            // TODO: add debounce!
            SiteAddressForm.onSelect(place);
        },
    });

    const SiteAddressForm = {
        resetHiddenFields() {
            form.setFieldsValue({ site_city: undefined });
            form.setFieldsValue({ site_state: undefined });
            form.setFieldsValue({ site_country: undefined });
            form.setFieldsValue({ site_lat: undefined });
            form.setFieldsValue({ site_long: undefined });
            form.setFieldsValue({ site_zip: undefined });
            form.setFieldsValue({ site_timezone: null });
            form.setFieldsValue({ google_place_id: null });
            form.setFieldsValue({ site_county: null });
        },

        async onSelect(place: google.maps.places.PlaceResult) {
            const parsed = await parseGooglePlace(place);

            setPlace({
                site_lat: parsed?.site_lat!,
                site_long: parsed?.site_long!,
                site_zip: parsed?.site_zip!,
                site_county: parsed?.site_county,
            });

            if (!parsed?.site_address) {
                SiteAddressForm.resetHiddenFields();
                return;
            }

            form.setFieldsValue({ site_address: parsed?.site_address });
            form.setFieldsValue({ site_city: parsed.site_city! });
            form.setFieldsValue({ site_state: parsed.site_state! });
            form.setFieldsValue({ site_country: parsed?.site_country! });
            form.setFieldsValue({ site_lat: parsed?.site_lat! });
            form.setFieldsValue({ site_long: parsed?.site_long! });
            form.setFieldsValue({ site_zip: parsed?.site_zip! });
            form.setFieldsValue({ site_timezone: parsed?.site_timezone });
            form.setFieldsValue({ google_place_id: parsed?.google_place_id });
            form.setFieldsValue({ site_county: parsed?.site_county });

            if (!parsed?.site_zip) {
                form.setFields([
                    {
                        name: 'site_address',
                        errors: ['Address should contain zip code'],
                    },
                ]);
            }

            if (!parsed?.site_timezone) {
                form.setFields([
                    {
                        name: 'site_timezone',
                        errors: ['Cannot get a timezone for this address'],
                    },
                ]);
            }

            form.validateFields(['site_address']);
        },

        onChange() {
            this.resetHiddenFields();
        },

        addressValidator({ getFieldValue }: { getFieldValue: Function }) {
            return {
                async validator(_: any, value: any) {
                    if (value === '') return Promise.resolve();

                    const allReqSiteAddressFieldsComplete =
                        getFieldValue('site_address') &&
                        getFieldValue('site_city') &&
                        getFieldValue('site_state') &&
                        getFieldValue('site_country') &&
                        getFieldValue('site_lat') &&
                        getFieldValue('site_long') &&
                        getFieldValue('site_zip') &&
                        getFieldValue('site_timezone');

                    if (!allReqSiteAddressFieldsComplete) {
                        return Promise.reject(new Error('Address is not correct!'));
                    }

                    if (!companyId) return Promise.resolve();

                    let duplicatedSites = await checkForAddressDuplicate({
                        companyId: companyId,
                        site_address: getFieldValue('site_address'),
                        site_city: getFieldValue('site_city'),
                        site_state: getFieldValue('site_state'),
                        site_zip: getFieldValue('site_zip'),
                        site_country: getFieldValue('site_country'),
                        google_place_id: getFieldValue('google_place_id'),
                    });

                    if (!isNewSite) {
                        duplicatedSites = duplicatedSites.filter(site => site.site_id !== siteId);
                    }

                    if (duplicatedSites.length) {
                        return Promise.reject(
                            new Error(`Site '${duplicatedSites[0].site_name}' with this address already exists`)
                        );
                    }

                    return Promise.resolve();
                },
            };
        },
    };

    const onCompanySelect = () => {
        form.validateFields(['site_store_number']);
        if (address) {
            form.validateFields(['site_address']);
        }
    };

    return (
        <Modal
            open
            title={site?.site_id ? 'Edit site' : 'New site'}
            destroyOnClose
            onCancel={closeSiteDialog}
            footer={[
                <Button key="site-modal-cancel" onClick={closeSiteDialog}>
                    Cancel
                </Button>,
                <Button
                    key="site-modal-submit"
                    data-cy="save-site"
                    type="primary"
                    loading={isLoading}
                    onClick={form.submit}
                >
                    Save
                </Button>,
            ]}
            data-cy="site-modal"
        >
            <Form
                form={form}
                name="site-form"
                preserve={false}
                layout="vertical"
                onFinish={onSiteModalFormFinish}
                initialValues={SiteFormData.fromEntity(site)}
            >
                <Form.Item name="site_id" hidden>
                    <Input data-cy="site-id" />
                </Form.Item>

                {!isCustomerRoleType && (
                    <Form.Item
                        name="company_id"
                        label={<Typography.Text strong>Company</Typography.Text>}
                        hasFeedback
                        rules={[{ required: true, message: 'Please select company!' }]}
                    >
                        <Select
                            showSearch
                            onChange={onCompanySelect}
                            data-cy="company-selector"
                            size="large"
                            placeholder="Please select company"
                            options={transformCompaniesToSelectOption(customerCompanies) as any}
                            filterOption={filterSelectorOption}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="site_name"
                    label={<Typography.Text strong>Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter name!' },
                        { max: 200, message: 'Number of characters should be less than 200' },
                    ]}
                >
                    <Input data-cy="site-name" placeholder="Site name" size="large" />
                </Form.Item>

                <Form.Item
                    shouldUpdate
                    name="site_address"
                    label={<Typography.Text strong>Address</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter address!' },
                        { max: 200, message: 'Number of characters should be less than 200' },
                        SiteAddressForm.addressValidator,
                    ]}
                >
                    <Input
                        placeholder="Address"
                        size="large"
                        data-cy="site-address"
                        onChange={() => SiteAddressForm.onChange()}
                        ref={c => {
                            antInputRef.current = c;
                            if (c) antRef.current = c.input;
                        }}
                    />
                </Form.Item>
                <Form.Item name="site_city" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_state" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_zip" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_country" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_county" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_lat" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_long" hidden>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="site_timezone" hidden data-cy="site-timezone">
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item name="google_place_id" hidden>
                    <Input placeholder="" />
                </Form.Item>

                <Form.Item
                    validateDebounce={500}
                    rules={[
                        { max: 12, message: 'Number of characters should be less than 12' },
                        {
                            message: 'Site with this store number already exists',
                            validator: (_, value) => checkSiteStoreNumber(_, value, site, form),
                            warningOnly: true,
                        },
                    ]}
                    name="site_store_number"
                    label={<Typography.Text strong>Store Number</Typography.Text>}
                >
                    <Input placeholder="Store Number" size="large" style={{ width: '100%' }} />
                </Form.Item>

                {auth.user?.isAdminRoleType() && (
                    <>
                        <Divider plain>Salesforce</Divider>

                        <Form.Item
                            name="meter_ids"
                            label={
                                <>
                                    <Typography.Text strong>Linked Salesforce Meters</Typography.Text>
                                    <Button size="small" type="link" onClick={openManageMetersModal}>
                                        Manage <EditOutlined />
                                    </Button>
                                </>
                            }
                        >
                            <MeterList meters={selectedMeters} onUnlinkMeter={handleUnlinkMeters} />
                        </Form.Item>
                    </>
                )}

                <Divider plain>Utility</Divider>

                <Row gutter={8}>
                    <Col span={24}>
                        <Form.Item
                            label={
                                <>
                                    <Typography.Text strong>Enrollment ID Details</Typography.Text>

                                    <Button type="link" onClick={() => handleManageSansModalOpen(true)}>
                                        Edit <EditOutlined />
                                    </Button>
                                </>
                            }
                        >
                            <SanDetailsList
                                utility={utility}
                                market={market}
                                siteId={site.site_id || null}
                                sans={state.siteSans}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item noStyle dependencies={['site_zip']}>
                            {({ getFieldValue }) => {
                                const selectedZip = getFieldValue('site_zip');
                                const utility = getFieldValue('site_utility');

                                return (
                                    <Form.Item
                                        name="site_utility"
                                        rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                                        label={<Typography.Text strong>Utility</Typography.Text>}
                                    >
                                        <UtilitySelector
                                            value={utility}
                                            onChange={handleUtilityChange}
                                            zip={selectedZip}
                                        />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            hasFeedback
                            name="site_market_and_zone"
                            label={<Typography.Text strong>Load Zone</Typography.Text>}
                        >
                            <SmartMarketZoneSelector
                                setSelectedValues={handleMarketZoneChange}
                                selectedValues={marketZone}
                                zipcode={place.site_zip}
                                lat={place.site_lat}
                                long={place.site_long}
                                county={place.site_county}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="site_network"
                            rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                            label={<Typography.Text strong>Network</Typography.Text>}
                        >
                            <Input placeholder="Network" size="large" />
                        </Form.Item>
                    </Col>

                    {auth.user?.isAdmin() && (
                        <Col span={12}>
                            <Form.Item
                                name="site_resource_id"
                                rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                                label={<Typography.Text strong>Resource ID</Typography.Text>}
                            >
                                <Input placeholder="Resource ID" size="large" />
                            </Form.Item>
                        </Col>
                    )}
                </Row>

                <Divider plain>Controls</Divider>

                <Form.Item label={<Typography.Text strong>Partner ID</Typography.Text>}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item noStyle name="partner_id">
                                <Input size="large" placeholder="Partner ID" />
                            </Form.Item>
                        </Col>
                        <PartnerIdStatusAlert partner_id_status={site.partner_id_status!} />
                    </Row>
                </Form.Item>

                {ability.can('update', 'ControlProvider') && (
                    <Form.Item
                        name="control_provider_id"
                        label={<Typography.Text strong>Control Provider</Typography.Text>}
                    >
                        <Select
                            allowClear
                            placeholder="Please select Control Provider"
                            size="large"
                            options={transformCompaniesToSelectOption(controlProviderCompanies) as any}
                        />
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && (
                    <>
                        <Form.Item
                            name="site_estimated_kw"
                            label={<Typography.Text strong>Site estimated KW</Typography.Text>}
                            rules={[
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            form.setFieldsValue({ site_estimated_kw: 0 });
                                            return Promise.resolve();
                                        }
                                        if (inRange(value, 0, 100001)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Value should be in range[0-100000]'));
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                type="number"
                                min={0}
                                max={100000}
                                size="large"
                                onKeyPress={handleLettersNumberInput}
                            />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    label={<Typography.Text strong>Event maximum duration</Typography.Text>}
                                    tooltip={
                                        <>
                                            <p style={{ marginBottom: 0 }}>
                                                Allow a maximum duration to be applied to the event time.
                                            </p>
                                            <p>Allowed interval is 0-4 hours</p>
                                        </>
                                    }
                                >
                                    <Space>
                                        <Form.Item name="event_max_duration" noStyle>
                                            <InputNumber min={0} max={4} step={0.25} size="large" />
                                        </Form.Item>
                                        <Typography.Text>hours</Typography.Text>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={<Typography.Text strong>Event Offset</Typography.Text>}
                                    tooltip={
                                        <>
                                            <p style={{ marginBottom: 0 }}>
                                                Allow an offset to be applied to the event time to start earlier than
                                                scheduled.
                                            </p>
                                            <p style={{ marginTop: 0 }}>Allowed interval is 0-30 min</p>
                                        </>
                                    }
                                >
                                    <Space>
                                        <Form.Item
                                            noStyle
                                            name="event_offset"
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (!value) {
                                                            form.setFieldsValue({ event_offset: 0 });
                                                            return Promise.resolve();
                                                        }

                                                        if (inRange(value, 0, 31)) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            new Error('Value should be in range[0-30]')
                                                        );
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                type="number"
                                                size="large"
                                                onKeyPress={handleLettersNumberInput}
                                            />
                                        </Form.Item>
                                        <Typography.Text>min</Typography.Text>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider plain>Features</Divider>

                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="site_highlight"
                                    valuePropName="checked"
                                    label={<Typography.Text strong>Highlight site</Typography.Text>}
                                >
                                    <Switch checked={site.site_highlight} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="email_only_integration"
                                    valuePropName="checked"
                                    label={
                                        <Typography.Text strong>
                                            Email Only. No API or OpenADR Integration
                                        </Typography.Text>
                                    }
                                >
                                    <Switch checked={site.email_only_integration} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {auth.user?.isAdminRoleType() && (
                            <Row gutter={8}>
                                <Col span={24}>
                                    <SiteLabelsSection
                                        allowCreate
                                        siteLabels={state.labels}
                                        setSiteLabels={handleLabelsChange}
                                        companyId={companyId}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </Form>

            {state.isManageSansModalOpen && (
                <ManageSanModal
                    siteId={site.site_id || null}
                    market={market}
                    utility={utility}
                    sans={cloneDeep(state.siteSans)}
                    onCancel={handleSansModalClose}
                    onOk={handleSansUpdated}
                />
            )}

            {isManageMetersModalOpen && (
                <ManageMeterModal
                    site={site.site_id ? (site as ISite) : null}
                    meters={selectedMeters}
                    onClose={closeManageMetersModal}
                    onOk={handleManageMetersFinish}
                />
            )}
        </Modal>
    );
}

function getPlaceFromSite(site: ISite | Partial<ISite>) {
    return {
        site_lat: site?.site_lat,
        site_long: site?.site_long,
        site_zip: site?.site_zip,
        site_county: site?.site_county,
    };
}

export function validateSanFormat(_: any, san: string, utility: string | null, market: string | null) {
    if (!san) return Promise.resolve();

    const suggestion = suggestSANFormat(san, utility, market);

    if (!suggestion || suggestion.isValid) return Promise.resolve();

    return Promise.reject(new Error(suggestion.suggestedFormat));
}

export async function validateIfSanAvailable(_: any, san: string, siteId: number | null) {
    if (!san) return Promise.resolve();

    const canUseSan = await checkIfCanUseSan(san, siteId);

    if (canUseSan) return Promise.resolve();

    return Promise.reject();
}

export async function checkSiteStoreNumber(
    _: any,
    storeNumber: string,
    site: ISite | Partial<ISite>,
    form: FormInstance<ISiteFormData>
) {
    if (!storeNumber) {
        return Promise.resolve();
    }

    const companyId = form.getFieldValue('company_id');

    if (!companyId) {
        return Promise.resolve();
    }

    const data: ISiteStoreNumberCheckRequest[] = [
        {
            companyId: companyId,
            storeNumbers: [storeNumber],
        },
    ];
    const duplicateData = await checkForStoreNumberDuplicates(data);
    const duplicatedStoreNumbers = duplicateData.length ? duplicateData[0].duplicatedStoreNumbers : [];

    if (!duplicatedStoreNumbers.length) {
        return Promise.resolve();
    }

    const originalStoreNumber = site?.site_store_number;
    const originalCompanyId = site?.company_id;

    const isSelfDuplicate =
        originalStoreNumber && originalStoreNumber === duplicatedStoreNumbers[0] && duplicatedStoreNumbers.length === 1;
    const isCompanyChanged = originalCompanyId && originalCompanyId !== companyId;

    if (isSelfDuplicate && !isCompanyChanged) {
        return Promise.resolve();
    }

    return Promise.reject();
}
