import { Brand } from '../Brand';

export enum Market {
    ERCOT = 'ercot',
    CAISO = 'caiso',
    NYISO = 'nyiso',
    PJM = 'pjm',
    ISONE = 'isone',
    SPP = 'spp',
    MISO = 'miso',
    BPA = 'bpa',
}

export const marketMaxPrices = {
    'PJM': 10000,
    'CAISO': 1000,
    'ERCOT': 5000,
    'NYISO': 10000,
    'ISONE': 10000,
  };

export const supportedMarkets = [ Market.ISONE, Market.NYISO, Market.SPP, Market.PJM, Market.CAISO, Market.MISO ] as string[];
export const supportedEnergyMarkets = [ ...supportedMarkets, Market.ERCOT ] as string[];

export type PriceRt = Brand<number, 'PriceRt'>;
export type PriceDa = Brand<number, 'PriceDa'>;

export interface IRealTimeNodesPrice {
    market: Market;
    nodes: Record<string, [PriceRt, PriceDa]>;
}

export interface IRealTimeZonesPrice {
    market: Market;
    zones: Record<string, [PriceRt, PriceDa]>;
}

export interface IFetchMarketZonePriceQuery {
    market?: string,
    zone?: string,
    start: Date,
    end:Date
}

export interface IMarketZonePrice {
    market: Market;
    load_zone: string;
    prices: [ number, number ][];
    date: string;
}