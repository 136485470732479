import { API } from '../api';
import { IRemoteAccessState } from './interface';

export const fetchRemoteAccessState = async (mac: string): Promise<IRemoteAccessState> => {
    const path = `/remote/${mac}`;
    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get remote state!`);
};

export const requestRemoteAccess = async (mac: string): Promise<IRemoteAccessState> => {
    const response = await API.fetch(`/remote/${mac}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot request remote state!`);
};

export const terminateRemoteAccess = async (mac: string): Promise<IRemoteAccessState> => {
    const response = await API.fetch(`/remote/${mac}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot terminate remote state!`);
};
