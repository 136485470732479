import { AppAbilityType } from '../../../components/ability/ability';

const VIEW_NAVIGATION = {
    SUMMARY: '/energy-usage-data/summary',
    CONNECT: '/energy-usage-data/connect',
    ANALYTICS: '/energy-usage-data/analytics',
};

export const viewNavigationOptions = (userAbility: AppAbilityType) => [
    {
        title: 'Analytics',
        value: VIEW_NAVIGATION.ANALYTICS,
        tooltip: 'Analytics',
        isVisible: userAbility.can('view-route', '/energy-usage-data/analytics'),
    },
    {
        title: 'Connect',
        value: VIEW_NAVIGATION.CONNECT,
        tooltip: 'Connect',
        isVisible: true,
    },
    {
        title: 'Summary',
        value: VIEW_NAVIGATION.SUMMARY,
        tooltip: 'Summary',
        isVisible: userAbility.can('view-route', '/energy-usage-data/summary'),
    },
];
