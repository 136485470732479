import { ICompany } from '../company/interface';
import { SignalType } from '../event/interface';

export interface ICleanResponseTrigger {
    id: number;
    company_id: number;
    company: ICompany;
    carbon_intensity: number;
    duration: number;
    signal: SignalType;
    market: string;
    cancel_when_intensity_lower: boolean;
    date_created: string;
    start_time: null | string;
    end_time: null | string;
}

export enum CleanResponseMarket {
    CAISO = 'caiso',
    SPP = 'spp',
    MISO = 'miso',
    PJM = 'pjm',
    NYISO = 'nyiso',
    ISONE = 'isone',
    BPA = 'bpa',
}
