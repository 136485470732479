const FIXED_NUMBER_OF_DIGITS = 3;
const ALLOWED_STRING_LEN = 33;

export const toAllowedStringLength = (label: string, allowedLen: number = ALLOWED_STRING_LEN) => {
    const isAllowedLen = label.length <= allowedLen;
    return isAllowedLen ? label : label.substring(0, allowedLen - 3) + '...';
};

export const formatEnergyValueToFixed = (value: number, digits: number = FIXED_NUMBER_OF_DIGITS) =>
    Number.isInteger(value) ? value : Number(value.toFixed(digits));
