import { IFetchPageQuery } from '../IFetchQueryOptions';

interface IUnknownVenErrorContext {
    body?: string;
    reqUrl: string;
    username?: string;
    password?: string;
    ven_profile: string;
}

export interface IUnknownVenError {
    id: string;
    ip: string;
    error_message: string;
    context: IUnknownVenErrorContext;
    date_modified: Date | null;
    date_created: Date;
}

export enum UNKNOWN_VEN_ERROR_ACTION {
    VIEW_CONTEXT = 'VIEW_CONTEXT'
}

export interface IFetchUnknownVenErrorsQuery extends IFetchPageQuery {}