import Tooltip from 'antd/es/tooltip';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { formatInTimeZone } from 'date-fns-tz';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
    DataZoomComponent, GridComponent, LegendScrollComponent, MarkAreaComponent, TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { IPriceResponseEventMetrics } from '../../domain/price-response/interface';
import { calculateTotalEventsMetrics } from '../../domain/price-response/priceResponseMetricCalculation';
import { PRIMARY_COLOR } from '../../theme';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';

echarts.use([BarChart, TooltipComponent, GridComponent, CanvasRenderer, MarkAreaComponent, DataZoomComponent, LegendScrollComponent]);

interface IEventStatisticWidget {
    events: IPriceResponseEventMetrics[];
}

const prepareEventsData = (events: IPriceResponseEventMetrics[]) => {
    let eventsValue: number[] = [];
    let months: string[] = [];
    let sortedEvents = events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    sortedEvents.forEach((data, i) => {
        const month = formatInTimeZone(new Date(data.date), 'UTC', 'LLL');
        eventsValue.push(data.count);
        months.push(month);
    });
    return { eventsValue, months };
};

export const EventStatisticWidget = ({ events }: IEventStatisticWidget) => {
    const eventsTotalMetrics = useMemo(() => calculateTotalEventsMetrics(events), [events]);
    const preparedEventData = useMemo(() => prepareEventsData(events), [events]);

    const option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
            formatter: (params: any) => {
                const numOfEvents = params[0]?.value;
                return !isNil(numOfEvents) ? `${numOfEvents} events` : '';
            },
        },

        xAxis: {
            type: 'category',
            axisTick: { show: false },
            data: preparedEventData.months,
        },
        yAxis: {
            type: 'value',
            nameLocation: 'middle',
            splitLine: {
                show: true,
            },
        },
        series: [
            {
                type: 'bar',
                barMaxWidth: '30px',
                data: preparedEventData.eventsValue,
                itemStyle: {
                    color: PRIMARY_COLOR,
                },
            },
        ],
    };

    return (
        <Row
            justify="space-between"
            gutter={[0, 0]}
            className="content-background"
            style={{ marginTop: '16px', marginBottom: '16px', padding: '32px 8px', borderRadius: 8 }}
        >
            <Col xs={24} md={11}>
                <div className="content-row">
                    <Row align="middle" justify="center">
                        <Tooltip title="General statistics about Price Response events in the selected period">
                            <Space align="center">
                                <ClockIcon style={{ width: '50px', height: '50px', fill: PRIMARY_COLOR }} />
                                <Typography.Title level={4} style={{ color: PRIMARY_COLOR, fontSize: '25px', margin: 0 }}>
                                    Events
                                </Typography.Title>
                            </Space>
                        </Tooltip>
                    </Row>
                    <ContentBlock
                        value={eventsTotalMetrics.days}
                        description={
                            <>
                                Total number <br /> of Event days
                            </>
                        }
                        descriptionClass="main-content-description"
                        valueClass="main-content-value"
                    />
                </div>
            </Col>
            <Col xs={0} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col xs={24} md={0}>
                <Divider style={{ height: '100%' }} />
            </Col>
            <Col xs={24} md={11}>
                <div className="content-row">
                    <ContentBlock
                        value={eventsTotalMetrics.count}
                        description={
                            <>
                                Total number <br />
                                of Site Events
                            </>
                        }
                        descriptionClass="content-description"
                        valueClass="content-value"
                    />
                </div>
            </Col>
            <Col span={24}>
                <ReactEChartsCore echarts={echarts} option={option} notMerge={true} lazyUpdate={true} />
            </Col>
        </Row>
    );
};
