import CloudOutlined from '@ant-design/icons/CloudOutlined';
import capitalize from 'lodash/capitalize';
import { AppAbilityType } from '../../../components/ability/ability';
import { CubeSVG, GridSVG } from '../../../components/icons/icons';
import { DEFAULT_PAGINATION } from '../../../domain/commonConst';
import { VenStatus } from '../../../domain/ven/interface';
import { IPageState, VIEW_MODE } from '../../interface';

export const defaultState = {
    loading: false,
    pagination: DEFAULT_PAGINATION,
    sorter: {
        field: 'ven_date_created',
        order: 'descend',
    },
    filter: {},
    status: '',
    type: '',
};

export const viewOptions = (userAbility: AppAbilityType) => [
    {
        title: 'Overview',
        value: VIEW_MODE.OVERVIEW,
        tooltip: 'Overview',
        icon: <GridSVG />,
        isVisible: true,
    },
    {
        title: 'Customer VEN',
        value: VIEW_MODE.CUSTOMER_VEN,
        tooltip: 'Customer VEN',
        icon: <CubeSVG />,
        isVisible: userAbility.can('read', 'CustomerVen'),
    },
    {
        title: 'Cloud VEN',
        value: VIEW_MODE.CLOUD_VEN,
        tooltip: 'Cloud Ven',
        icon: <CloudOutlined />,
        isVisible: userAbility.can('read', 'CloudVen'),
    },
];

export const venStatusFilter = Object.values(VenStatus).map(status => ({
    label: capitalize(status),
    value: status,
}));

export interface IVenPageState extends IPageState {
    status?: string | null;
    type?: string | null;
}
