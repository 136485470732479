export enum RemoteAccessStateCode {
    IDLE = 0,
    REQUESTED = 1, // admin requests remote access to a device
    ACTIVE = 2, // device started tunnel so admin can SSH into device
}

export interface IRemoteAccessState {
    state: RemoteAccessStateCode;
    state_text: string;
    datetime_requested?: string;
    url?: string;
}
