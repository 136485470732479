import App from 'antd/lib/app';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import { IAccessPartner, ICreateAccessPartner, IFetchAccessPartnersQuery } from './interface';
import { createAccessPartner, fetchAccessPartners } from './index';

export const useAccessPartnerListQuery = (query: IFetchAccessPartnersQuery) => {
    return useQuery<IPagination<IAccessPartner>, Error>([QueryKey.ACCESS_PARTNERS, query], ({ signal }) => fetchAccessPartners(query, signal));
};

export const useAccessPartnerCreate = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<IAccessPartner, Error, ICreateAccessPartner>(createAccessPartner, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.ACCESS_PARTNERS]);
            notification.success({ key: 'program-operator-create-success', message: 'Program Operator created successfully' });
        },
        onError: (error: any) => {
            notification.error({ key: 'program-operator-create-error', message: error.message || 'Cannot create program operator' });
        },
    });
};
