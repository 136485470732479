import { notification } from 'antd';
import { ExportButton } from '../ExportButton/ExportButton';
import { exportMeterEnrollments, exportMeters, exportSites } from '../../domain/site';
import { useAuth } from '../../domain/auth/useAuth';
import { useState } from 'react';

interface IImpactExportButton {
    filter: {
        [key: string]: any;
    };
    [key: string]: any;
}

export const SiteEnrollmentExportButton = ({ filter, ...props }: IImpactExportButton) => {
    const auth = useAuth()!;
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const exportSitesCSVAction = async (fileColumnsPreset: 'default' | 'detailed') => {
        try {
            setExportLoading(true);
            await exportSites({
                ...filter,
                fileColumnsPreset,
                include: auth.user?.isAdminRoleType()
                    ? 'company,control_provider,program,san,meter,meter_enrollment'
                    : 'company,control_provider,program,san',
            });
        } catch (error: any) {
            notification.error({
                key: 'sites-export-csv-error',
                message: error.message || 'Cannot export sites file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const exportMeterEnrollmentCSVAction = async () => {
        try {
            setExportLoading(true);
            await exportMeterEnrollments(filter);
        } catch (error: any) {
            notification.error({
                key: 'meter-enrollment-export-csv-error',
                message: error.message || 'Cannot export meter enrollments file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const exportMeterCSVAction = async () => {
        try {
            setExportLoading(true);
            await exportMeters(filter);
        } catch (error: any) {
            notification.error({
                key: 'meter-export-csv-error',
                message: error.message || 'Cannot export meters file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const actions = [
        {
            key: 'e-b-1',
            title: 'Download CSV',
            fn: () => exportSitesCSVAction('default'),
        },
        {
            key: 'e-b-2',
            title: 'Download detailed Sites CSV',
            fn: () => exportSitesCSVAction('detailed'),
        },
        ...(auth.user?.isAdminRoleType()
            ? [
                  {
                      key: 'e-b-3',
                      title: 'Download Meter CSV',
                      fn: exportMeterCSVAction,
                  },
                  {
                      key: 'e-b-4',
                      title: 'Download Meter Enrollment CSV',
                      fn: exportMeterEnrollmentCSVAction,
                  },
              ]
            : []),
    ];

    return <ExportButton key="site-export" actions={actions} disabled={exportLoading} {...props} />;
};
