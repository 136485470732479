import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IIntervalDataReporting } from './interface';
import { fetchIntervalDataReporting } from './index';

export const useIntervalDataReportingQuery = (companyId?: number) => {
    return useQuery<IIntervalDataReporting, Error>({
        queryKey: [QueryKey.INTERVAL_DATA_REPORTING, companyId],
        queryFn: () => fetchIntervalDataReporting(companyId),
    });
};
