import React from 'react';
import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import { IUnknownVenError } from '../../../domain/unknown-ven-error/interface';

interface ICustomerVenErrorContextPreviewModalProps {
    onClose: Function;
    unknownVenError: IUnknownVenError;
}

export const UnknownVenErrorContextModal: React.FC<ICustomerVenErrorContextPreviewModalProps> = ({
    onClose,
    unknownVenError,
}) => {
    const handleClose = () => onClose();

    return (
        <Modal
            open
            destroyOnClose
            onCancel={handleClose}
            title='Error details'
            footer={null}
            bodyStyle={{ maxHeight: '600px', overflow: 'auto' }}
        >
            <Typography.Title level={5}>Request url:</Typography.Title>
            <Typography.Text>{unknownVenError.context.reqUrl}</Typography.Text>

            <Typography.Title level={5}>Username:</Typography.Title>
            <Typography.Text>{unknownVenError.context.username || '-'}</Typography.Text>

            <Typography.Title level={5}>Password:</Typography.Title>
            <Typography.Text>{unknownVenError.context.password || '-'}</Typography.Text>

            <Typography.Title level={5}>VEN Profile:</Typography.Title>
            <Typography.Text>{unknownVenError.context.ven_profile}</Typography.Text>

            <Typography.Title level={5}>Received XML:</Typography.Title>
            <Typography.Paragraph code>{unknownVenError.context?.body || '-'}</Typography.Paragraph>
        </Modal>
    );
};