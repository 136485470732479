import Card from 'antd/lib/card';
import Layout from 'antd/lib/layout';
import { Outlet } from 'react-router-dom';

import './AuthLayout.css';

export function AuthLayout() {
    return (
        <Layout className="auth-layout">
            <Card className="auth-layout-content">
                <Layout.Content>
                    <Outlet />
                </Layout.Content>
            </Card>
        </Layout>
    );
}
