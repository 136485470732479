import fetchIntercept from 'fetch-intercept';
import config from '../config';
import { Session, Auth } from './auth/auth';

fetchIntercept.register({
    request: function (url, options = { headers: {} }) {
        if (url.includes(config.API_URL)) {
            // if (!options.headers?.['Content-Type']) {
            //     options.headers['Content-Type'] = `application/json`;
            // }

            // add auth token only if request goes to our backend
            const token = Session.getToken();
            if (token) {
                const accessToken = token.accessToken;
                if (!options.headers) {
                    options.headers = {};
                }
                options.headers['Authorization'] = `Bearer ${accessToken}`;
            }
        }

        return [url, options];
    },

    requestError: function (error) {
        // Called when an error occurred during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the response object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        console.info('response error', error);
        return Promise.reject(error);
    },
});

export const API = {
    fetch: async (path: string, options: RequestInit = { headers: {} }) => {
        return fetch(`${config.API_URL}${path}`, options).then(response => {
            if (response.status === 401) {
                console.info('got 401 error - logout');
                Auth.terminateSession();
            }
            return response;
        });
    },
};
