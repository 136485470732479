import { clone } from 'lodash';
import { DEFAULT_PAGINATION } from './commonConst';

// To prohibit pagination to exceed the total number of pages or below 1
export function validatePagination(pagination: { pageSize: number; current: number }, total: number) {
    const updatedPagination = clone(pagination) || DEFAULT_PAGINATION;
    const updatedPagesCount = Math.ceil(total / updatedPagination.pageSize);

    if (updatedPagination.current < 1) {
        updatedPagination.current = 1;

        return updatedPagination;
    }

    if (updatedPagination.current > updatedPagesCount) {
        updatedPagination.current = updatedPagesCount;
    }

    return updatedPagination;
}
