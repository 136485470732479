import { useEffect, useState } from 'react';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';
import Tag from 'antd/lib/tag';
import { toAllowedStringLength } from '../../domain/common/formattersToAllowedValueLength';
import { useSiteQuery } from '../../domain/site/queries';
import { ISite } from '../../domain/site/interface';

export const SelectedSiteFilterButton = ({ value, onClear }: { value: number; onClear: () => void }) => {
    const [site, setSite] = useState<ISite | null>(null);

    const { data: fetchedSite } = useSiteQuery(value);

    useEffect(() => {
        if (fetchedSite) {
            setSite(fetchedSite);
        }

    }, [fetchedSite]);

    if (!site) return <></>;

    return (
        <Tag
            closable
            icon={<FilterOutlined />}
            color="#108ee9"
            onClose={onClear}
            title="Events displayed for this site. Click close button to clear this filter."
        >
            {toAllowedStringLength(site.site_name, 25)}
        </Tag>
    );
};
