import { ISiteLocation } from '../../../../domain/site/interface';
import { PRIMARY_COLOR } from '../../../../theme';
import { calculateMarkerStyle } from './formatMapStyle';

export const getSiteMarker = (site: ISiteLocation) => {
    const position = { lat: site.site_lat!, lng: site.site_long! };
    const fillColor = site.lmp_price_rt ? calculateMarkerStyle(site.lmp_price_rt) : PRIMARY_COLOR;

    return {
        key: site.site_id,
        position,
        title: site.site_name,
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: fillColor,
            fillOpacity: 1,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1,
        },
    };
};
