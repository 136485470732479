import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import Collapse from 'antd/lib/collapse';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';
import { IAffectedPriceResponseTrigger } from '../../../domain/price-response/interface';
import { formatPriceResponseMarket } from '../PriceResponse';
import { marketLoadZoneToLabel } from '../../../components/site/SiteMarketAndLoadZone';

export const PriceResponseTriggerCollapsedList = ({ resources }: { resources: IAffectedPriceResponseTrigger[] }) => (
    <Collapse accordion expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel
            header={
                <div>
                    Selected {resources.length} Price Response Trigger{resources.length > 1 && 's'}
                </div>
            }
            key="1"
        >
            <List
                style={{
                    maxHeight: '400px',
                    overflow: 'auto',
                }}
                size="small"
                bordered={false}
                dataSource={resources}
                renderItem={item => {
                    const market = formatPriceResponseMarket(item);

                    const infoString = `${item.company} / ${market} / ${marketLoadZoneToLabel(item.market,item.load_zone)}`;
                    return (
                        <List.Item key={`${item.id}`}>
                            <Typography.Text ellipsis={{ tooltip: infoString }}>{infoString}</Typography.Text>
                        </List.Item>
                    );
                }}
            />
        </Collapse.Panel>
    </Collapse>
);
