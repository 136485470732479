import { API } from '../api';
import { IGreenButton } from './interface';

export const fetchGreenButton = async (signal?: AbortSignal | null): Promise<IGreenButton[]> => {
    const response = await API.fetch('/utilities/connect', {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        let respBody: IGreenButton[] = await response.json();
        return respBody;
    }

    let respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot load green button data.`);
};
