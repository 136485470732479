import { useEffect, useRef } from 'react';

export function useInterval(callback: Function, delayMs: number) {
    const savedCallback = useRef<Function | null>(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delayMs !== null) {
            let id = setInterval(tick, delayMs);
            return () => clearInterval(id);
        }
    }, [delayMs]);
}
