import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Form, { RuleRender } from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import React, { useEffect, useMemo, useState } from 'react';
import { EventDurationInput } from '../../../components/EventDurationInput/EventDurationInput';
import { CompanySelector } from '../../../components/selectors/CompanySelector/CompanySelector';
import { MarketZoneSelector } from '../../../components/selectors/MarketZoneSelector/MarketZoneSelector';
import { PriceResponseTargetSitesSelector } from '../../../components/selectors/PriceResponseTargetSitesSelector/PriceResponseTargetSitesSelector';
import { SignalLevelSelector } from '../../../components/selectors/SignalLevelSelector/SignalLevelSelector';
import { TimeRangeSelector } from '../../../components/selectors/TimeRangeSelector/TimeRangeSelector';
import { useAuth } from '../../../domain/auth/useAuth';
import { minutesToHours } from '../../../domain/common/timeHelpers';
import { ICompany } from '../../../domain/company/interface';
import { IPriceResponseTrigger } from '../../../domain/price-response/interface';
import { useSavePriceResponseTriggerMutation } from '../../../domain/price-response/queries';
import { UserType } from '../../../domain/user/interface';
import { CalendarEventPreview } from '../../events/components/CalendarEventPreview';
import { handleLettersNumberInput } from '../../sites/siteInterface';
import { marketMaxPrices } from '../../../domain/market-prices/interface';

interface IPriceResponseModal {
    companies: ICompany[];
    onClose: any;
    trigger: IPriceResponseTrigger | Partial<IPriceResponseTrigger>;
    isEditMode: boolean;
}

export const PriceResponseTriggerModal = ({ onClose, trigger, isEditMode }: IPriceResponseModal) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();

    const defaultMarketZoneValue = isEditMode
        ? (trigger.market && trigger.load_zone && [trigger.market.toUpperCase(), trigger.load_zone]) || []
        : [];
    const [marketAndZone, setMarketAndZone] = useState<string[]>(defaultMarketZoneValue);

    const auth = useAuth()!;
    const isCustomerRoleType = useMemo(() => auth.user?.user_type === UserType.CUSTOMER, [auth]);
    const isAdminRoleType = auth.user?.isAdminRoleType();

    const startTime = Form.useWatch('start_time', form);
    const endTime = Form.useWatch('end_time', form);
    const duration = Form.useWatch('duration', form);
    const priceType = Form.useWatch('price_type', form);

    const [priceResponseMarketErrors, setPriceResponseMarketErrors] = useState<string[]>([]);

    const { mutateAsync: savePriceResponseTrigger, isLoading } = useSavePriceResponseTriggerMutation();

    const handleTimeRangeChange = (fieldName: string, value: number | null) => {
        form.setFieldsValue({ [fieldName]: value });
    };

    const handleMarketAndZone = (marketAndZone: string[]) => {
        form.setFieldsValue({ market_and_zone: marketAndZone });
        setMarketAndZone(marketAndZone);
        form.validateFields(['price']);
    };

    const handleDurationChange = (value: number) => {
        form.setFieldsValue({ duration: value });
    };

    const priceResponseMarketAndZoneValidator = (rule: any, value: string) => {
        if (!value) {
            setPriceResponseMarketErrors([rule.message]);
            return Promise.reject();
        }

        setPriceResponseMarketErrors([]);
        return Promise.resolve();
    };

    const validatePrice: RuleRender = () => ({
        validator(rule, value) {
            const market = marketAndZone[0];
            const max = marketMaxPrices[market as keyof typeof marketMaxPrices] || Infinity;
            if (value > max) {
                return Promise.reject(`The price is too high for this market. ${market} maximum trigger is $${max}`);
            }
            return Promise.resolve();
        },
    });

    const onFormFinish = async (trigger: IPriceResponseTrigger) => {
        let [market, load_zone] = marketAndZone;

        // @ts-ignore
        delete trigger.market_and_zone;

        const preparedTrigger: IPriceResponseTrigger = {
            ...trigger,
            company_id: isAdminRoleType ? trigger.company_id : auth.user!.company_id,
            duration: trigger.duration * 60,
            signal: trigger.signal,
            market: market.toLowerCase(),
            load_zone: load_zone,
            start_time: formatHoursFromNumber(trigger.start_time),
            end_time: formatHoursFromNumber(trigger.end_time),
        };

        try {
            await savePriceResponseTrigger(preparedTrigger);

            notification.info({ key: 'trigger-save-info', message: 'Price Response trigger saved' });
            onClose(trigger);
        } catch (error: any) {
            notification.error({
                key: 'trigger-save-error',
                message: error.message || 'Cannot save Price Response trigger!',
            });
        }
    };

    useEffect(() => {
        if (isEditMode) {
            handleMarketAndZone(defaultMarketZoneValue);
        }
    }, [isEditMode]);

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Edit Price Response Trigger' : 'New Price Response Trigger'}
            width={600}
            onCancel={() => onClose()}
            footer={[
                <Button key="price-response-modal-cancel" onClick={() => onClose()}>
                    Cancel
                </Button>,
                <Button key="price-response-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            data-cy="create-edit-modal"
        >
            <Form
                form={form}
                name="price-response-form"
                preserve={false}
                layout="vertical"
                onFinish={onFormFinish}
                initialValues={transformTriggerToInitialValues(trigger)}
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>

                {isAdminRoleType && (
                    <Form.Item
                        hasFeedback
                        name="company_id"
                        label={<Typography.Text strong>Company</Typography.Text>}
                        rules={[{ required: true, message: 'Please select company!' }]}
                    >
                        <CompanySelector />
                    </Form.Item>
                )}

                {isCustomerRoleType ? (
                    <Form.Item
                        required
                        label={<Typography.Text strong>Select target sites</Typography.Text>}
                        name="market_and_zone"
                        rules={[
                            {
                                required: true,
                                message: 'Please select target sites!',
                                validator: priceResponseMarketAndZoneValidator,
                            },
                        ]}
                    >
                        <PriceResponseTargetSitesSelector
                            marketAndZone={marketAndZone}
                            onChange={handleMarketAndZone}
                            hasError={priceResponseMarketErrors.length > 0}
                            companyId={auth.user?.company_id!}
                        />
                    </Form.Item>
                ) : (
                    <Form.Item
                        required
                        hasFeedback
                        name="market_and_zone"
                        rules={[{ required: true, message: 'Please select load zone!' }]}
                        label={<Typography.Text strong>Load Zone</Typography.Text>}
                    >
                        <MarketZoneSelector
                            value={marketAndZone}
                            onChange={handleMarketAndZone}
                            allowClear={false}
                            supportPriceResponse
                        />
                    </Form.Item>
                )}

                <Row gutter={[10, 16]}>
                    <Col xs={12}>
                        <Form.Item
                            label={<Typography.Text strong>Price $/MWh</Typography.Text>}
                            name="price"
                            required
                            tooltip={{
                                title: `Market $/MWh Maximum\nPJM=$10000\nCAISO=$1000\nERCOT=$5000\nNYISO=$10000\nISONE=$10000`,
                                overlayStyle: { whiteSpace: 'pre-line' },
                            }}
                            rules={[{ required: true, message: 'Please enter price!' }, validatePrice]}
                        >
                            <InputNumber
                                size="large"
                                placeholder="Price"
                                type="number"
                                min={0}
                                onKeyPress={handleLettersNumberInput}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={10}>
                        <Form.Item
                            name="price_type"
                            required
                            rules={[{ required: true, message: 'Please select price type!' }]}
                        >
                            <Radio.Group style={{ margin: '25px 0 0 0' }}>
                                <Space direction="vertical">
                                    <Radio value="real-time">Real Time</Radio>
                                    <Radio value="day-ahead">Day Ahead</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24}>
                        <Form.Item
                            label={<Typography.Text strong>Trigger Time</Typography.Text>}
                            tooltip="Start and end hours of the day to monitor this specific price trigger, and only create an event during this time"
                            htmlFor="startTime"
                        >
                            <TimeRangeSelector
                                startTime={startTime}
                                endTime={endTime}
                                onChange={handleTimeRangeChange}
                                startTimeFieldName="start_time"
                                endTimeFieldName="end_time"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Card
                    title="Event"
                    extra={
                        <Tooltip title="When this trigger is activated we will create an event with this configuration">
                            <QuestionCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                    }
                    bordered={false}
                    style={{ backgroundColor: 'rgba(49, 172, 171, 0.1)' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={8}>
                            <EventDurationInput name="duration" value={duration} onChange={handleDurationChange} />
                        </Col>
                        <Col xs={8}>
                            <Form.Item
                                label={<Typography.Text strong>Signal Level</Typography.Text>}
                                name="signal"
                                required
                                style={{ width: '100%' }}
                            >
                                <SignalLevelSelector />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                name="cancel_when_price_lower"
                                label={
                                    <Typography.Text strong>Stop event if price drops below trigger</Typography.Text>
                                }
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div style={{ paddingTop: 16 }}>
                    <CalendarEventPreview
                        isPriceResponse={true}
                        preEventDurationHours={priceType === 'day-ahead' ? 1 : 0}
                        eventDurationHours={duration}
                        postEventDurationHours={0}
                        secondaryPreEventDurationHours={0}
                    />
                </div>
            </Form>
        </Modal>
    );
};

function transformTriggerToInitialValues(trigger: Partial<IPriceResponseTrigger>) {
    return {
        ...trigger,
        market: null,
        market_and_zone: null,
        duration: minutesToHours(trigger.duration!),
        start_time: normalizeTime(trigger.start_time),
        end_time: normalizeTime(trigger.end_time),
    };
}

function formatHoursFromNumber(hours?: string | number | null): string | null {
    if (!hours && hours !== 0) return null;

    return hours.toString().padStart(2, '0') + ':00';
}

//library at backEnd return 23:59 instead 00:00
function normalizeTime(time?: string | null) {
    if (time === '23:59') return 24;

    return time ? parseFloat(time) : time;
}
