import { isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const TIMEZONE = 'America/New_York';

export interface INyisoCurrentSeason {
    period: 'summer' | 'winter';
    year: number;
    viewValue: string;
    id: string;
    capabilityStart: string;
    capabilityEnd: string;
}

export const getCurrentSeasonInfo = (): INyisoCurrentSeason => {
    const currentDate = utcToZonedTime(new Date().toISOString(), TIMEZONE);
    const currentYear = currentDate.getFullYear();
    const summerSeasonStart = summerSeasonDates.start(currentYear);
    const summerSeasonEnd = summerSeasonDates.end(currentYear);

    const isSummerSeason = isAfter(currentDate, summerSeasonStart) && isBefore(currentDate, summerSeasonEnd);

    if (isSummerSeason) {
        return {
            period: 'summer',
            year: currentYear,
            viewValue: 'Summer Enrollment',
            id: `summer-${currentYear}`,
            capabilityStart: summerCapabilityPeriodDates.start(currentYear),
            capabilityEnd: summerCapabilityPeriodDates.end(currentYear),
        };
    }

    // Winter NYISO enrollment season goes through the year change.
    // So we keep showing past year season during second part of the winter (after actual year changed).
    const isSecondPartOfWinterSeason = isBefore(currentDate, summerSeasonStart);
    const seasonYear = isSecondPartOfWinterSeason ? currentYear - 1 : currentYear;

    return {
        period: 'winter',
        year: seasonYear,
        viewValue: 'Winter Enrollment',
        id: `winter-${seasonYear}`,
        capabilityStart: winterCapabilityPeriodDates.start(seasonYear),
        capabilityEnd: winterCapabilityPeriodDates.end(seasonYear),
    };
};

// Summer season starts May 01 and ends Nov 01
// If current year is not between those dates - we can assume it is winter season
export const summerSeasonDates = {
    start: (year: number) => utcToZonedTime(new Date(`${year}-05-01T00:00:00.000Z`), TIMEZONE),
    end: (year: number) => utcToZonedTime(new Date(`${year}-11-01T00:00:00.000Z`), TIMEZONE),
};

export const summerCapabilityPeriodDates = {
    start: (year: number) => `May 01, ${year - 1}`,
    end: (year: number) => `Oct 31, ${year - 1}`,
};

export const winterCapabilityPeriodDates = {
    start: (year: number) => `Nov 01, ${year - 1}`,
    end: (year: number) => `April 30, ${year}`,
};
