import { API } from '../../../api';
import { IFetchPageQuery } from '../../../IFetchQueryOptions';
import { IPagination } from '../../../IPagination';
import { CaisoSyncOrigin, ICaisoSync } from './interface';

export const fetchCaisoSyncs = async (
    origin: CaisoSyncOrigin,
    pageQuery: Pick<IFetchPageQuery, 'pagination'>,
    signal?: AbortSignal | null
): Promise<IPagination<ICaisoSync>> => {
    let { pagination } = pageQuery;

    // TODO: remove this mock delay
    await new Promise(resolve => setTimeout(resolve, 1_000));

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    const path = `/scheduling-coordinator/caiso/drrs/syncs?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        origin,
    })}`;

    // TODO: Remove this mock data
    return {
        meta: {
            total: 10,
            offset: 0,
            limit: 10,
        },
        data: [
            {
                id: '1',
                status: 'Pending',
                date: '2021-01-01',
                is_lambda_sync: false,
                requester: {
                    id: '1',
                    name: 'Requester 1',
                    email: 'requester1@mailinator.com',
                },
                error_message: '',
            },
            {
                id: '2',
                status: 'Success',
                date: '2022-01-01',
                is_lambda_sync: true,
            },
            {
                id: '3',
                status: 'Failed',
                date: '2023-01-01',
                is_lambda_sync: false,
                error_message: 'Error message',
            },
            {
                id: '4',
                status: 'Pending',
                date: '2024-01-01',
                is_lambda_sync: false,
                requester: {
                    id: '2',
                    name: 'Requester 2',
                    email: 'requester2@mailinator.com',
                },
                error_message: '',
            },
            {
                id: '5',
                status: 'Success',
                date: '2025-01-01',
                is_lambda_sync: true,
            },
            {
                id: '6',
                status: 'Failed',
                date: '2026-01-01',
                is_lambda_sync: false,
                error_message: 'Error message',
            },
            {
                id: '7',
                status: 'Pending',
                date: '2027-01-01',
                is_lambda_sync: false,
                requester: {
                    id: '3',
                    name: 'Requester 3',
                    email: 'requester3@mailinator.com',
                },
                error_message: '',
            },
            {
                id: '8',
                status: 'Success',
                date: '2028-01-01',
                is_lambda_sync: true,
            },
            {
                id: '9',
                status: 'Failed',
                date: '2029-01-01',
                is_lambda_sync: false,
                error_message: 'Error message',
            },
            {
                id: '10',
                status: 'Pending',
                date: '2030-01-01',
                is_lambda_sync: false,
                requester: {
                    id: '4',
                    name: 'Requester 4',
                    email: 'requester4@mailinator.com',
                },
                error_message: '',
            },
        ],
    };

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso registrations data!`);
};
