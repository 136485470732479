import { useState } from 'react';

import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import isThisMonth from 'date-fns/isThisMonth';
import add from 'date-fns/add';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import inRange from 'lodash/inRange';

import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import Divider from 'antd/lib/divider';

import { saveCaisoResource } from '../../../domain/caiso-resource';
import { ICaisoResource } from '../../../domain/caiso-resource/interface';
import { generateDatePeriod, ICaisoResourceData } from '../interface';
import App from 'antd/lib/app';
import Select from 'antd/lib/select';

interface IResourceFormModal {
    onCancel: Function;
    resource: ICaisoResourceData | Partial<ICaisoResourceData>;
    isEditMode: boolean;
}

const prepareMonthsData = (resourceMonthData: ICaisoResourceData['months']) =>
    Object.entries(resourceMonthData)
        .map(([month, value]) => ({
            month: month,
            mw: value.mw,
            heStart: value.heStart,
            heEnd: value.heEnd,
            lmp: value.lmp,
        }))
        .filter(monthData => !isUndefined(monthData.mw) && !isUndefined(monthData.lmp));

export const ResourceFormModal = ({ onCancel, resource, isEditMode }: IResourceFormModal) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => onCancel();

    const handleLettersInput = (event: any) => {
        var charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
        var charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^\d+$/)) event.preventDefault();
    };

    const datePeriod = generateDatePeriod();

    const onResourceFormModalFinish = async (preparedCaisoResource: any) => {

        const monthsData = prepareMonthsData(preparedCaisoResource.months);
        const caisoResource = {
            ...pick(preparedCaisoResource, ['name', 'pmin', 'pmax', 'id']),
            months: monthsData,
        } as ICaisoResource;
        try {
            setLoading(true);
            await saveCaisoResource(caisoResource);
            notification.info({ key: 'caiso-resource-save-info', message: 'Caiso resource saved' });
            onCancel(preparedCaisoResource);
        } catch (error: any) {
            notification.error({ key: 'caiso-resource-save-error', message: error.message || 'Cannot save caiso resource!' });
        }
        setLoading(false);
    };

    const isRequiredMWMonthField = (month: Date) => {
        const monthDate = new Date(month);
        const nextMonth = add(new Date(), { months: 1 });

        const isCurrentMonth = isThisMonth(monthDate);
        const isNextMonth = isSameMonth(nextMonth, monthDate);

        return isCurrentMonth || isNextMonth;
    };

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Edit resource' : 'New resource'}
            onCancel={handleCancel}
            footer={[
                <Button key="resource-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="resource-modal-submit" type="primary" loading={loading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
        >
            <Form form={form} name="resource-form" preserve={false} layout="vertical" onFinish={onResourceFormModalFinish} initialValues={resource}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="name"
                    label={<Typography.Text strong>Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter name!' },
                        { max: 255, message: 'Number of characters should be less than 255' },
                    ]}
                >
                    <Input placeholder="Name" size="large" />
                </Form.Item>
                <Row gutter={8}>
                    <Col span={7}>
                        <Form.Item
                            hasFeedback
                            required
                            label={<Typography.Text strong>PMIN</Typography.Text>}
                            dependencies={['pmax']}
                            name="pmin"
                            rules={[
                                { required: true, message: 'Please enter value!' },
                                {
                                    validator(_, value) {
                                        const pmax = form.getFieldValue('pmax');

                                        if (value && !pmax) {
                                            return Promise.reject(new Error('pmax should be defined'));
                                        }
                                        if (value > pmax) {
                                            return Promise.reject(new Error('pmax should be greater'));
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} max={10000} size="large" precision={2} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            hasFeedback
                            required
                            dependencies={['pmin']}
                            name="pmax"
                            label={<Typography.Text strong>PMAX</Typography.Text>}
                            rules={[
                                { required: true, message: 'Please enter value!' },
                                {
                                    validator(_, value) {
                                        const pmin = form.getFieldValue('pmin');

                                        if (value && (pmin === undefined || pmin === null)) {
                                            return Promise.reject(new Error('pmin should be defined'));
                                        }
                                        if (value && value === pmin) {
                                            return Promise.reject(new Error('pmin shouldn`t be equal pmax'));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} max={10000} size="large" precision={2} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider plain>SETTINGS</Divider>
                <Typography.Paragraph>Month MW should be in the range of Pmin to Pmax.</Typography.Paragraph>
                <Row gutter={8}>
                    {datePeriod.map(month => (
                        <Col key={month.toString()} span={12}>
                            <Typography.Text strong>{format(new Date(month), 'MMM, yyyy')}</Typography.Text>
                            <Form.Item
                                required={isRequiredMWMonthField(month)}
                                label={<Typography.Text strong>MW</Typography.Text>}
                                name={['months', `${format(month, 'yyyy/MM')}`, 'mw']}
                                rules={[
                                    ...(isRequiredMWMonthField(month) ? [{ required: true, message: 'Please enter value!' }] : []),
                                    {
                                        validator(_, value) {
                                            const pmin = form.getFieldValue('pmin');
                                            const pmax = form.getFieldValue('pmax');
                                            if (!isUndefined(value) && (value < pmin || value > pmax)) {
                                                return Promise.reject(new Error(`value not in range ${pmin}-${pmax}`));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <InputNumber min={0} precision={2} />
                            </Form.Item>
                            <Typography.Text strong>Price</Typography.Text>
                            <Form.Item
                                name={['months', `${format(month, 'yyyy/MM')}`, 'lmp']}
                                required={isRequiredMWMonthField(month)}
                                rules={[
                                    ...(isRequiredMWMonthField(month) ? [{ required: true, message: 'Please enter LMP value!' }] : []),
                                    {
                                        validator: (_, value) => {
                                            const lmpValue = value;
                                            if (!isUndefined(lmpValue) && (lmpValue < 0 || lmpValue > 1001)) {
                                                return Promise.reject(new Error('value not in range 0 - 1000'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const mwValue = getFieldValue(['months', `${format(month, 'yyyy/MM')}`, 'mw']);
                                            if (mwValue && !value) {
                                                return Promise.reject(new Error('LMP cannot be empty if MW has data for this month'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber precision={2} />
                            </Form.Item>
                            <Typography.Text strong>Hours Ending</Typography.Text>
                            <Form.Item>
                                <Form.Item
                                    name={['months', `${format(month, 'yyyy/MM')}`, 'heStart']}
                                    style={{ display: 'inline-block', margin: 'auto 0', marginBottom: '10px' }}
                                    dependencies={['months', `${format(month, 'yyyy/MM')}`, 'heEnd']}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const heEnd = getFieldValue(['months', `${format(month, 'yyyy/MM')}`, 'heEnd']);
                                                if ((!value && heEnd) || (value && !heEnd)) {
                                                    return Promise.reject(new Error('Both hours ending are required'));
                                                }
                                                if (value && heEnd && value > heEnd) {
                                                    return Promise.reject(new Error('H.E. Start should be before H.E. End'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Select placeholder="Start" style={{ width: '75px' }} onChange={() => {
                                        form.validateFields([['months', `${format(month, 'yyyy/MM')}`, 'heEnd']]);
                                    }}>
                                        {[...Array(24).keys()].map(hour => (
                                            <Select.Option key={hour + 1} value={hour + 1}>{hour + 1}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Typography.Text style={{ margin: 'auto 10px', display: 'inline-block', verticalAlign: 'middle' }}>-</Typography.Text>
                                <Form.Item
                                    name={['months', `${format(month, 'yyyy/MM')}`, 'heEnd']}
                                    style={{ display: 'inline-block', margin: 'auto 0', marginBottom: '10px' }}
                                    dependencies={['months', `${format(month, 'yyyy/MM')}`, 'heStart']}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const heStart = getFieldValue(['months', `${format(month, 'yyyy/MM')}`, 'heStart']);
                                                if ((!value && heStart) || (value && !heStart)) {
                                                    return Promise.reject(new Error('Both hours ending are required'));
                                                }
                                                if (value && heStart && value < heStart) {
                                                    return Promise.reject(new Error('H.E. End should be after H.E. Start'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Select placeholder="End" style={{ width: '75px' }} onChange={() => {
                                        form.validateFields([['months', `${format(month, 'yyyy/MM')}`, 'heStart']]);
                                    }}
                                    >
                                        {[...Array(24).keys()].map(hour => (
                                            <Select.Option key={hour + 1} value={hour + 1}>{hour + 1}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                            <Divider />
                        </Col>

                    ))}
                </Row>
            </Form>
        </Modal>
    );
};