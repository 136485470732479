import Tooltip from 'antd/lib/tooltip';
import { useState } from 'react';

interface IControlledTooltip {
    children: React.ReactNode;
    extraVisibleCondition: boolean;
    title: string;
}

export const WithControlledTooltip = ({ children, extraVisibleCondition, title }: IControlledTooltip) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleShowTooltip = () => setShowTooltip(true);
    const handleHideTooltip = () => setShowTooltip(false);

    return (
        <Tooltip title={title} open={showTooltip && extraVisibleCondition}>
            <div onMouseOver={handleShowTooltip} onMouseLeave={handleHideTooltip}>
                {children}
            </div>
        </Tooltip>
    );
};
