import message from 'antd/lib/message';

export async function copyVenToClipboard(data: any) {
    const preparedResourceId = data.ven_resource_id ? data.ven_resource_id.join(', ') : '';
    const dataToCopy = `
Username: ${data.ven_username || ''},
Password: ${data.ven_password || ''},
VEN ID: ${data.ven_id || ''},
VTN ID: ${data.ven_vtn_id || ''},
VTN URL: ${data.ven_uri || ''},
Market Context: ${data.ven_market_context || ''},
Resource ID: ${preparedResourceId}`;

    try {
        await navigator.clipboard.writeText(dataToCopy);
        message.success('VEN properties copied!');
    } catch (error: any) {
        message.error(error || 'Cannot copy VEN properties!');
    }
}
