import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

import Col from 'antd/lib/col';
import Statistic from 'antd/lib/statistic';
import Typography from 'antd/lib/typography';

interface IEquivalencyCard {
    value: number;
    description: string;
    link: string;
    icon: any;
}

export const EquivalencyCard = ({ value, description, icon, link }: IEquivalencyCard) => (
    <Col xs={24} sm={11}>
        <Statistic
            prefix={<img src={icon} alt="metric`s icon" style={{ marginRight: '12px' }} />}
            value={value}
            valueStyle={{ fontSize: '38px', fontWeight: 500 }}
        />
        <Typography.Text type="secondary" style={{ fontWeight: 500 }}>
            {description}&nbsp;
        </Typography.Text>
        <a href={link} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>
            <QuestionCircleOutlined className="info" />
        </a>
    </Col>
);
