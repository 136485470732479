import { PRIMARY_COLOR } from '../../theme';
import { ICompany } from '../company/interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { ISite } from '../site/interface';

export const profileOptions = [
    { label: '2.0a', value: '2.0a' },
    { label: '2.0b', value: '2.0b' },
];

export interface IFetchVenPageQuery extends IFetchPageQuery {
    status?: string;
    type?: string;
}

export interface ITotalOverviewItem {
    online: number;
    offline: number;
    error: number;
    disabled: number;
}

export interface IOverviewItem {
    companyId: number;
    companyName: string;
    numbers: ITotalOverviewItem;
}

export interface IVenOverview {
    customerVen: {
        total: ITotalOverviewItem;
        byCompany: IOverviewItem[];
        byControlProvider: IOverviewItem[];
    };
    cloudVen?: {
        total: ITotalOverviewItem;
    };
}

export interface IVen {
    id: string;
    status: string;
    ven_username: string;
    ven_password: string;
    ven_id: string;
    ven_uri: string;
    ven_vtn_id: string;
    ven_market_context: string;
    ven_resource_id: string;
    ven_group_id: string;
    ven_party_id: string;
    programs?: {
        program_id: number;
        program_utility: string;
        program_aggregator: string;
        program_market_context: string;
        ven_id: string;
        program_flag_requires_telemetry: number;
        program_date_created: Date;
        enrolled_sites_count: number;
        name: string;
    }[];

    sites?: ISite[];

    ven_cert: string;
    ven_key: string;
    ven_profile: string;
    ven_registration_id: string;
    ven_default_event_signal: number;
    ven_error: string;
    ven_detailed_logging: boolean;
    ven_enabled: boolean;
    ven_date_online: string;
    ven_date_modified: string;
    ven_date_created: string;
}

export interface ICloudVen extends IVen { }

export interface ICustomerVen extends IVen {
    company_id: number;
    company?: ICompany;
    site_id: number;
    site?: ISite;
    mac_address?: string;
}

export enum VenStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    DISABLED = 'disabled',
    ERROR = 'error',
}

export const statusColorMap: { [key: string]: string } = {
    [VenStatus.DISABLED]: '#888787',
    [VenStatus.ERROR]: '#fac42a',
    [VenStatus.OFFLINE]: '#fa675c',
    [VenStatus.ONLINE]: PRIMARY_COLOR,
};

export interface ICloudVenCertificateDetails {
    fingerprints: {
        sha1: string;
        sha256: string;
    };
}
