import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Table, { ColumnsType } from 'antd/lib/table';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import AlignLeftOutlined from '@ant-design/icons/lib/icons/AlignLeftOutlined';
import React, { useContext, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';
import { AuditTrailModal } from '../../../components/modals/auditTrailModal/auditTrailModal';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { SiteTag } from '../../../components/site/SiteTag';
import { ActionList } from '../../../components/table/actionList/actionList';
import { getColumnSortOrder } from '../../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { FORM_MODE, useFormMode } from '../../../components/useFormMode';
import { VenIcon } from '../../../components/VenIcon/VenIcon';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { AuditEntityType } from '../../../domain/audit-trail/interface';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../../domain/commonConst';
import { IPaginationMeta } from '../../../domain/IPagination';
import { buildProgramTableFilter } from '../../../domain/program/buildProgramTableFilter';
import { useProgramListQuery } from '../../../domain/program/queries';
import { validatePagination } from '../../../domain/validatePagination';

import { deleteCloudVen, exportCloudVens } from '../../../domain/ven';
import { ICloudVen } from '../../../domain/ven/interface';
import { useCloudVenListQuery } from '../../../domain/ven/queries';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { CloudVenFormModal } from '../components/CloudVenFormModal';
import { venStatusFilter, viewOptions } from './venViewInterface';
import { CloudVenLogsModal } from 'src/components/modals/CloudVenLogsModal/CloudVenLogsModal';
import { useAuth } from 'src/domain/auth/useAuth';

export const CloudVenView = () => {
    const { notification, modal } = App.useApp();
    const ability = useContext(AbilityContext);
    const auth = useAuth()!;
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const [cloudVen, setCloudVen] = useState<ICloudVen | Partial<ICloudVen>>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [venAction, setVenAction] = useState<PAGE_ACTION | ''>();

    const { onSetFormMode, formMode } = useFormMode();
    const { view, handleViewMode }: IView = useOutletContext();

    const programListQuery = useProgramListQuery({});
    const programs = programListQuery.data?.data || [];

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.sorter && { sorter: pageState.sorter }),
        ...(pageState?.search && { search: pageState.search }),
        ...(pageState?.status && { status: pageState.status }),
        ...(pageState?.filter && { filter: pageState.filter }),
    }

    const { data, isLoading, refetch: refetchCloudVens } = useCloudVenListQuery(listQuery, {
        keepPreviousData: true,
    });
    const cloudVens = data?.data || [];
    const meta = data?.meta || { total: 0 } as IPaginationMeta;

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery(
                { ...pageState, pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current } });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    const handleStatusChange = (value: string) => {
        setPageQuery({ ...pageState, status: value });
    };

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    // EXPORT
    async function exportCloudVensAction() {
        try {
            setExportLoading(true);
            await exportCloudVens(toUsefulParams(pageState));
        } catch (err: any) {
            notification.error({ key: 'cloud-ven-export-error', message: err.message || 'Cannot export cloud VENs!' });
        }
        setExportLoading(false);
    }

    // DELETE VEN
    function deleteCloudVenWithConfirm(selectedVen: ICloudVen) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting cloud VEN ${selectedVen.ven_username || ''}. Are you sure?`,
                onOk: async () => {
                    await deleteCloudVenAction(selectedVen);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };
    }

    async function deleteCloudVenAction(selectedVen: ICloudVen) {
        try {
            await deleteCloudVen(selectedVen);

            const updatedTotal = meta.total - 1;
            const validPagination = validatePagination(pageState.pagination, updatedTotal);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchCloudVens({ ...pageState, pagination: validPagination });

            notification.info({ key: 'cloud-ven-delete-info', message: 'Cloud VEN deleted' });
        } catch (err: any) {
            notification.error({ key: 'cloud-ven-delete-error', message: err.message || 'Cannot delete VEN!' });
        }
    }

    //NEW VEN
    const showNewVenCloudModal = () => {
        onSetFormMode(FORM_MODE.NEW);
        setCloudVen({
            ven_username: undefined,
            ven_password: undefined,
            ven_id: undefined,
            ven_uri: undefined,
            ven_vtn_id: undefined,
            ven_cert: undefined,
            ven_default_event_signal: undefined,
            ven_group_id: undefined,
            ven_key: undefined,
            ven_market_context: undefined,
            ven_party_id: undefined,
            ven_profile: '2.0a',
            ven_registration_id: undefined,
            ven_resource_id: undefined,
        });
    };

    //EDIT VEN
    function showEditVenModal(record: ICloudVen) {
        return () => {
            onSetFormMode(FORM_MODE.EDIT);
            setCloudVen(record);
        };
    }

    async function onVenModalClose(selectedVen: ICloudVen) {
        setCloudVen(undefined);
        onSetFormMode('');

        if (selectedVen) {
            await refetchCloudVens(listQuery);
        }
    }

    function showAuditTrailModal(record: ICloudVen) {
        return () => {
            setVenAction(PAGE_ACTION.AUDIT_TRAIL);
            setCloudVen(record);
        };
    }

    function showLogsModal(record: ICloudVen) {
        return () => {
            setVenAction(PAGE_ACTION.SHOW_LOGS);
            setCloudVen(record);
        };
    }

    const actions = [
        ...(ability.can('update', 'CloudVen')
            ? [{
                title: 'Edit',
                onClick: (record: ICloudVen) => showEditVenModal(record),
            }]
            : []),
        ...(ability.can('delete', 'CloudVen')
            ? [{
                title: 'Delete',
                onClick: (record: ICloudVen) => deleteCloudVenWithConfirm(record),
            }]
            : []),
        ...(auth.user?.isAdminRoleType()
            ? [{
                title: 'Show Logs',
                icon: <AlignLeftOutlined />,
                onClick: (record: ICloudVen) => showLogsModal(record),
            }]
            : []),
        ...(auth.user?.isAdminRoleType()
            ? [{
                title: 'Audit Trail',
                icon: <HistoryOutlined />,
                onClick: (record: ICloudVen) => showAuditTrailModal(record),
            }]
            : [])
    ];

    const columns: ColumnsType<ICloudVen> = [
        {
            width: 70,
            render: (type, record) => {
                return <VenIcon ven={record} />;
            },
        },
        {
            title: 'Username',
            dataIndex: 'ven_username',
            sorter: (a, b) => a.ven_username.localeCompare(b.ven_username),
            ...getColumnSortOrder('ven_username', pageState.sorter),
        },
        {
            title: 'Profile',
            dataIndex: 'ven_profile',
            width: 100,
            sorter: (a, b) => a.ven_profile.localeCompare(b.ven_profile),
            ...getColumnSortOrder('ven_profile', pageState.sorter),
        },
        {
            title: 'VEN ID',
            dataIndex: 'ven_id',
            sorter: (a, b) => a.ven_id.localeCompare(b.ven_id),
            ...getColumnSortOrder('ven_id', pageState.sorter),
        },
        {
            title: 'VTN ID',
            dataIndex: 'ven_vtn_id',
            sorter: (a, b) => a.ven_vtn_id.localeCompare(b.ven_vtn_id),
            ...getColumnSortOrder('ven_vtn_id', pageState.sorter),
        },
        {
            title: 'Market context',
            dataIndex: 'ven_market_context',
        },
        {
            title: (
                <>
                    Dispatch Events By{' '}
                    <Tooltip title='VEN can dispatch events by site or program(s). You can choose one.'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>
            ),
            children: [
                {
                    title: 'Enersponse Site',
                    dataIndex: ['site_id'],
                    render: (text, record) => {
                        return record?.sites?.map((s, index) => <SiteTag value={s} key={`site-${index}`} />);
                    },
                },
                { ...buildProgramTableFilter(pageState, programs, 'Enersponse Program') },
            ],
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text: string, record) => <ActionList actions={actions} item={record} />,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle='VEN'
                extra={[<ViewSwitcher viewOptions={viewOptions(ability)} key='ven-view-switcher' view={view} handleViewMode={handleViewMode} />]}
                actions={[
                    <Space key='cloud-ven-filter'>
                        <Typography.Text>VEN status:</Typography.Text>
                        <Select
                            size='large'
                            value={pageState.status ?? ''}
                            onChange={handleStatusChange}
                            style={{ width: '110px' }}
                            options={
                                [
                                    {
                                        label: 'All',
                                        value: '',
                                    },
                                    ...venStatusFilter,
                                ]
                            }
                        />
                    </Space>,
                    <SearchInput key='cloud-ven-search' onSearch={handleSearch} defaultValue={pageState?.search} />,
                    <Button size='large' key='download-csv' onClick={exportCloudVensAction} icon={<DownloadOutlined />} loading={exportLoading}>
                        Download CSV
                    </Button>,
                    ability.can('create', 'CloudVen') && (
                        <Button size='large' key='new-cloud-ven' type='primary' onClick={showNewVenCloudModal} icon={<PlusCircleOutlined />}>
                            New VEN
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size='small'
                    rowKey='id'
                    sticky
                    columns={columns}
                    dataSource={cloudVens}
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default'
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {cloudVen && (formMode === FORM_MODE.EDIT || formMode === FORM_MODE.NEW) && (
                <CloudVenFormModal ven={cloudVen} onClose={onVenModalClose} />
            )}

            {cloudVen && venAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal onClose={() => setVenAction('')} entityId={cloudVen.id!} entityType={AuditEntityType.CLOUD_VEN} />
            )}

            {cloudVen && venAction === PAGE_ACTION.SHOW_LOGS && (
                <CloudVenLogsModal onClose={() => setVenAction('')} ven={cloudVen} />
            )}
        </>
    );
};
