import { IIntervalDataReporting } from './interface';
import { API } from '../api';

export const fetchIntervalDataReporting = async (companyId?: number, signal?: AbortSignal | null): Promise<IIntervalDataReporting> => {
    const path = `/interval-data-reporting?${new URLSearchParams({
        ...(companyId && { companyId: companyId.toString() }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load interval data!`);
};
