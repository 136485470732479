import React, { useContext, useState } from 'react';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Transfer from 'antd/lib/transfer';
import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import DollarOutlined from '@ant-design/icons/lib/icons/DollarOutlined';
import { UtilityOrIsoTag } from '../UtilityOrIsoTag';
import { IProgram } from '../../domain/program/interface';
import { ISite } from '../../domain/site/interface';
import { useSiteEnrollProgramsMutation } from '../../domain/site/queries';
import { AbilityContext } from '../ability/can';
import { PRIMARY_COLOR } from '../../theme';
import { checkIfLoadZoneSupportsPriceResponse } from '../../domain/site/useMarketZoneOptions';
import { supportedMarkets } from '../../domain/market-prices/interface';
import { EnergySVG, LeafSVG } from '../icons/icons';
import { getActiveSiteSan } from '../../domain/site/getActiveSiteSan';
import { IEnrollSiteToPrograms } from '../../domain/site/interface';
import './enroll-program-modal.css';

interface IEnrollProgramModalProps {
    site: ISite;
    onCancel: Function;
    programs: IProgram[];
}

export function EnrollProgramModal({ site, programs, onCancel }: IEnrollProgramModalProps) {
    const { notification } = App.useApp();
    const { mutateAsync: enrollSiteToPrograms, isLoading } = useSiteEnrollProgramsMutation();
    const ability = useContext(AbilityContext);

    const availablePrograms = prepareProgramList(site, programs);
    const alreadyEnrolledPrograms = prepareAlreadyEnrolledProgramList(site);
    const [selectedProgramIds, setSelectedProgramIds] = useState<React.Key[]>(alreadyEnrolledPrograms);

    const onChange = (updateProgramIds: React.Key[]) => {
        setSelectedProgramIds(updateProgramIds);
    };

    function closeDialog() {
        onCancel();
    }

    function prepareAlreadyEnrolledProgramList(site: ISite): string[] {
        const targetList: string[] = [];

        if (site.site_price_response_enrolled) {
            targetList.push('price-response');
        }
        if (site.site_clean_response_enrolled) {
            targetList.push('clean-response');
        }

        if (site.site_leap_api_dispatch_enrolled) {
            targetList.push('leap-api-dispatch');
        }

        if (ability.can('enroll', 'Program')) {
            targetList.push(...site.programs.map(p => p.program_id.toString()));
        }

        return targetList;
    }

    function prepareProgramList(
        site: ISite,
        programs: IProgram[]
    ): (Partial<IProgram> & { key: string; disabled?: boolean })[] {
        const availablePrograms: (Partial<IProgram> & { key: string; disabled?: boolean })[] = [];

        if (ability.can('enroll', 'PriceResponse')) {
            availablePrograms.push({
                key: 'price-response',
                name: 'Price Response',
                disabled: !checkIfLoadZoneSupportsPriceResponse(site.lmp_market, site.site_load_zone),
            });
        }

        if (ability.can('enroll', 'CleanResponse')) {
            availablePrograms.push({
                key: 'clean-response',
                name: 'Clean Response',
                disabled: !supportedMarkets.includes(site.lmp_market),
            });
        }

        if (ability.can('enroll', 'LeapApi')) {
            const activeSan = getActiveSiteSan(site);
            const leapMeterId = activeSan?.san_info?.sources?.find(
                sanInfoSource => !!sanInfoSource?.leapMeterId
            )?.leapMeterId;

            availablePrograms.push({ key: 'leap-api-dispatch', name: 'Leap API', disabled: !leapMeterId });
        }

        if (ability.can('enroll', 'Program')) {
            availablePrograms.push(...programs.map(p => ({ ...p, key: p.program_id.toString() })));
        }

        return availablePrograms;
    }

    /** We could have few states where we need to send different values
     *  Before   Current     Result
     *  -        -           Undefined
     *  -        +           true
     *  +        -           false
     *  +        +           undefined   */
    function getEnrollmentStatusByCurrentAndPreviousState(prev: boolean, current: boolean) {
        return prev !== current ? current : undefined;
    }

    async function savePrograms() {
        try {
            const price_response = getEnrollmentStatusByCurrentAndPreviousState(
                alreadyEnrolledPrograms.includes('price-response'),
                selectedProgramIds.includes('price-response')
            );
            const clean_response = getEnrollmentStatusByCurrentAndPreviousState(
                alreadyEnrolledPrograms.includes('clean-response'),
                selectedProgramIds.includes('clean-response')
            );
            const leap_api_dispatch = getEnrollmentStatusByCurrentAndPreviousState(
                alreadyEnrolledPrograms.includes('leap-api-dispatch'),
                selectedProgramIds.includes('leap-api-dispatch')
            );

            let programs;

            if (ability.can('enroll', 'Program')) {
                programs = selectedProgramIds
                    .filter(p => !['price-response', 'clean-response', 'leap-api-dispatch'].includes(p as string))
                    .map(selectedProgramId => Number(selectedProgramId));
            }

            const enrollments: IEnrollSiteToPrograms = {
                programs,
                price_response,
                clean_response,
                leap_api_dispatch,
            };

            await enrollSiteToPrograms({
                site,
                enrollments,
            });

            notification.info({ key: 'enroll-program-info', message: 'Saved' });
            onCancel(site);
        } catch (error: any) {
            notification.error({ key: 'enroll-program-error', message: error.message || 'Cannot save data!' });
        }
    }

    function transferItemRender({
        key,
        disabled,
        name,
        iso,
        utility_name,
    }: Partial<IProgram> & { key: string; disabled?: boolean }) {
        const icon = getIconByKey({ key, disabled });
        const DELIMITER = ' • ';

        const utilityOrIso = iso || utility_name;
        const tooltipText = disabled ? getDisabledProgramTextByKey(key) : null;

        return (
            <Tooltip title={tooltipText}>
                <Typography.Text disabled={disabled} title={`${name}${DELIMITER}${utilityOrIso}`}>
                    {icon} {name}
                    {utilityOrIso && (
                        <>
                            {DELIMITER}
                            <Typography.Text type="secondary">
                                <UtilityOrIsoTag utilityOrIso={iso || utility_name} />
                            </Typography.Text>
                        </>
                    )}
                </Typography.Text>
            </Tooltip>
        );
    }

    return (
        <Modal
            open
            width={1000}
            title={`Enroll ${site?.company?.company_name}/${site.site_name} to programs`}
            destroyOnClose
            onCancel={closeDialog}
            className="enroll-program-modal"
            footer={[
                <Button key="enroll-program-modal-cancel" onClick={closeDialog}>
                    Cancel
                </Button>,
                <Button key="enroll-program-modal-submit" type="primary" loading={isLoading} onClick={savePrograms}>
                    Save
                </Button>,
            ]}
        >
            <Transfer
                showSearch
                dataSource={availablePrograms}
                titles={['Available Programs', 'Enrolled']}
                targetKeys={selectedProgramIds}
                onChange={onChange}
                render={transferItemRender}
                filterOption={(inputValue, { name }) =>
                    name!.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) > -1
                }
            />
        </Modal>
    );
}

// we need to show icon for price-response, clean-response and leap-api-dispatch
export function getIconByKey({ key, disabled }: { key: string; disabled?: boolean }) {
    const style = { color: !disabled ? PRIMARY_COLOR : '#ccc' };

    switch (key) {
        case 'price-response':
            return <DollarOutlined style={style} />;
        case 'clean-response':
            return <LeafSVG style={style} />;
        case 'leap-api-dispatch':
            return <EnergySVG style={style} />;
        default:
            return '';
    }
}

function getDisabledProgramTextByKey(key: string) {
    switch (key) {
        case 'price-response':
            return 'Price Response is unsupported in your region, please contact the account manager.';
        case 'clean-response':
            return 'Clean Response is unsupported in your region, please contact the account manager.';
        case 'leap-api-dispatch':
            return 'Leap API is unsupported in your region, please connect with account manager.';
        default:
            return '';
    }
}
