import { useRef, useEffect, useCallback } from 'react';

const noop = () => {};

const mockAbortController = {
    abort: noop,
    signal: {
        aborted: false,
        onabort: noop,
        addEventListener: noop,
        removeEventListener: noop,
        dispatchEvent: noop,
    },
};

const createAbortController = () => {
    let abortController;
    if (window.AbortController) {
        abortController = new window.AbortController();
    } else {
        abortController = mockAbortController;
    }
    return abortController;
};

const createIterableResult = ({ callback, abort }: any = {}) => {
    return {
        callback,
        abort,
        [Symbol.iterator]: function* () {
            yield this['callback'];
            yield this['abort'];
        },
    };
};

const useAbortableFetch = (fetcher: Function) => {
    const abortControllerRef = useRef(createAbortController());

    let callback = (...args: any) => {
        abortControllerRef.current = createAbortController();
        const { signal } = abortControllerRef.current;
        return fetcher(...args, signal);
    };

    const memoCallback: Function = useCallback(callback, [fetcher]);
    const memoAbort: Function = useCallback(() => abortControllerRef.current.abort(), [fetcher]);

    useEffect(() => {
        return () => {
            memoAbort();
        };
    }, [memoAbort]);

    let resultRef = useRef(createIterableResult());
    resultRef.current.callback = memoCallback;
    resultRef.current.abort = memoAbort;
    return resultRef.current;
};

export default useAbortableFetch;
