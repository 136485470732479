import { getColumnFilteredValue } from '../../components/table/columnFormatHelpers';
import { IProgram } from './interface';
import { EventProgramsContext } from '../../pages/events/components/EventProgramsContext';
import { toAllowedStringLength } from '../common/formattersToAllowedValueLength';
import Tooltip from 'antd/lib/tooltip';
import { IEvent } from '../event/interface';

export const getProgramFilterValue = (state: any) => {
    let { filteredValue } = getColumnFilteredValue('eventProgramName', state?.filter || {});
    return filteredValue;
};

const buildProgramFilter = (programs: IProgram[]) => programs.map(p => ({
    text: (
        <Tooltip title={p.name} placement="right">
            {toAllowedStringLength(p.name)}
        </Tooltip>
    ),
    fullText: p.name,
    value: p.name,
}));

export function buildEventProgramTableFilter(state: any, programs: IProgram[]) {
    return {
        title: (
            <Tooltip title="Filter by program is based on the program name at time of dispatch.">
                Program
            </Tooltip>
        ),
        dataIndex: 'eventProgramName',
        ellipsis: { showTitle: false },
        filters: buildProgramFilter(programs),
        filteredValue: getProgramFilterValue(state),
        filterSearch: (input: string, record: any) => record?.fullText?.toLowerCase().includes(input.toLowerCase()),
        render: (text: string, record: IEvent) => <EventProgramsContext value={record} />,
    };
}
