import { ISiteStoreNumberCheckResponse, ISiteStoreNumberCheckRequest } from '../../../domain/site/interface';
import { checkForStoreNumberDuplicates } from '../../../domain/site';
import { ICompany } from '../../../domain/company/interface';
import { SiteImportTableIndexes as Idx } from './siteImportTableIndexes';

export async function getDuplicatedSitesByStoreNumber(records: any[][], companies: ICompany[]): Promise<ISiteStoreNumberCheckResponse[]> {
    if (!records.length) {
        return [];
    }

    const companyToStoreNumberMap: { [key: number]: string[] } = {};

    records.forEach(record => {
        const recordCompanyName = record[Idx.COMPANY_NAME];
        const companyId = companies.find(company => company.company_name === recordCompanyName)?.company_id;
        const recordStoreNumber = record[Idx.SITE_STORE_NUMBER];

        if (!companyId || !recordStoreNumber) {
            return;
        }

        companyToStoreNumberMap[companyId] ?
            companyToStoreNumberMap[companyId].push(recordStoreNumber) :
            companyToStoreNumberMap[companyId] = [recordStoreNumber]
    });

    const checkForDuplicatesRequest: ISiteStoreNumberCheckRequest[] = [];

    for (let key in companyToStoreNumberMap) {
        checkForDuplicatesRequest.push({
            companyId: +key,
            storeNumbers: companyToStoreNumberMap[key]
        })
    }

    if (!checkForDuplicatesRequest.length) {
        return [];
    }

    return await checkForStoreNumberDuplicates(checkForDuplicatesRequest);
}