import { IEvent } from '../../../../domain/event/interface';
import { IUser } from '../../../../domain/user/interface';

function eventSiteLabelsMatchesUser(event: IEvent, user: IUser) {
    if (!user.site_label || user.site_label.length === 0) {
        return event;
    }

    return user.site_label.some(userLabel => {
        const exist = Array.isArray(event.site?.site_label) && event.site?.site_label.length !== 0;

        return exist && event.site?.site_label.includes(userLabel);
    });
}

export function checkIfUserSiteLabelMatchesEvent(event: IEvent, user: IUser): Error | null {
    if (!eventSiteLabelsMatchesUser(event, user)) {
        return new Error('Site labels mismatch');
    }

    return null;
}
