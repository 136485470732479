import Tooltip from 'antd/lib/tooltip';
import { toAllowedStringLength } from '../common/formattersToAllowedValueLength';
import { IProgram } from './interface';

export const buildProgramFilter = (programs: IProgram[]) => programs.map(p => ({
    text: (
        <Tooltip title={p.name} placement="right">
            {toAllowedStringLength(p.name)}
        </Tooltip>
    ),
    fullText: p.name,
    value: p.program_id,
}));
