import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Form, { RuleRender } from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import Alert from 'antd/lib/alert';
import { minBy, uniq } from 'lodash';
import { EventDurationInput } from '../../../components/EventDurationInput/EventDurationInput';
import { SignalLevelSelector } from '../../../components/selectors/SignalLevelSelector/SignalLevelSelector';
import { TimeRangeSelector } from '../../../components/selectors/TimeRangeSelector/TimeRangeSelector';
import { minutesToHours } from '../../../domain/common/timeHelpers';
import {
    IAffectedPriceResponseTrigger,
    IBatchUpdatePriceResponseTrigger,
} from '../../../domain/price-response/interface';
import { useUpdateBatchPriceResponseTriggersMutation } from '../../../domain/price-response/queries';
import { handleLettersNumberInput } from '../../sites/siteInterface';
import { SignalType } from '../../../domain/event/interface';
import { PriceResponseTriggerCollapsedList } from './PriceResponseTriggerCollapsedList';
import { marketMaxPrices } from '../../../domain/market-prices/interface';

interface IMarketAndMaxPrice {
    market: string;
    maxPrice: number;
}

interface IUpdateBatchPriceResponseModal {
    triggerIdList: number[];
    selectedItemList: IAffectedPriceResponseTrigger[];
    onClose: Function;
}

function getInitialValues(): IBatchUpdatePriceResponseTrigger {
    return {
        duration: minutesToHours(30),
        signal: SignalType.HIGH,
    };
}

function formatHoursFromNumber(hours?: string | number | null): string | null {
    if (!hours && hours !== 0) return null;

    return hours.toString().padStart(2, '0') + ':00';
}

export const UpdateBatchPriceResponseTriggerModal = ({
    onClose,
    triggerIdList,
    selectedItemList,
}: IUpdateBatchPriceResponseModal) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();

    const startTime = Form.useWatch('start_time', form);
    const endTime = Form.useWatch('end_time', form);
    const duration = Form.useWatch('duration', form);

    const { mutateAsync: saveUpdateBatchPriceResponseTriggers, isLoading } =
        useUpdateBatchPriceResponseTriggersMutation();

    const handleTimeRangeChange = (fieldName: string, value: number | null) => {
        form.setFieldsValue({ [fieldName]: value });
    };

    const handleDurationChange = (value: number) => {
        form.setFieldsValue({ duration: value });
    };

    const validatePrice: RuleRender = () => ({
        validator(rule, value) {
            const selectedMarkets = uniq(selectedItemList.map(i => i.market.toUpperCase()));
            const marketsWithMaxPrice = selectedMarkets.map(
                (market): IMarketAndMaxPrice => ({
                    market: market.toUpperCase(),
                    maxPrice: marketMaxPrices[market.toUpperCase() as keyof typeof marketMaxPrices],
                })
            );

            const maxAllowedPrice = minBy(marketsWithMaxPrice, i => i.maxPrice);

            if (value > maxAllowedPrice!.maxPrice) {
                return Promise.reject(
                    `The price is too high for one of the selected markets. ${maxAllowedPrice?.market} maximum trigger is $${maxAllowedPrice?.maxPrice}`
                );
            }
            return Promise.resolve();
        },
    });

    const onFormFinish = async (updatedFields: IBatchUpdatePriceResponseTrigger) => {
        const preparedTriggerFields: IBatchUpdatePriceResponseTrigger = {
            ...updatedFields,
            duration: updatedFields.duration! * 60,
            start_time: formatHoursFromNumber(updatedFields.start_time),
            end_time: formatHoursFromNumber(updatedFields.end_time),
        };

        try {
            await saveUpdateBatchPriceResponseTriggers({
                idList: triggerIdList,
                updatedFields: preparedTriggerFields,
            });

            notification.info({ key: 'batch-trigger-update-info', message: 'Price Response triggers updated' });
            onClose(true);
        } catch (error: any) {
            notification.error({
                key: 'batch-trigger-update-error',
                message: error.message || 'Cannot update Price Response trigger list!',
            });
        }
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Edit Price Response Triggers Batch"
            width={600}
            onCancel={() => onClose()}
            footer={[
                <Button key="batch-price-response-modal-cancel" onClick={() => onClose()}>
                    Cancel
                </Button>,
                <Button
                    key="batch-price-response-modal-submit"
                    type="primary"
                    loading={isLoading}
                    onClick={form.submit}
                >
                    Save
                </Button>,
            ]}
            data-cy="batch-edit-modal"
        >
            <Alert
                message="Next changes will be applied to all selected Price Response Triggers."
                type="warning"
                style={{ marginBottom: '8px' }}
                showIcon
            />
            <PriceResponseTriggerCollapsedList resources={selectedItemList} />
            <Form
                form={form}
                name="batch-price-response-form"
                preserve={false}
                layout="vertical"
                onFinish={onFormFinish}
                initialValues={getInitialValues()}
                style={{ marginTop: '24px' }}
            >
                <Form.Item
                    label={<Typography.Text strong>Price $/MWh</Typography.Text>}
                    name="price"
                    required
                    tooltip={{
                        title: `Market $/MWh Maximum\nPJM=$10000\nCAISO=$1000\nERCOT=$5000\nNYISO=$10000\nISONE=$10000`,
                        overlayStyle: { whiteSpace: 'pre-line' },
                    }}
                    rules={[{ required: true, message: 'Please enter price!' }, validatePrice]}
                >
                    <InputNumber
                        size="large"
                        placeholder="Price"
                        type="number"
                        min={0}
                        onKeyPress={handleLettersNumberInput}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Row>
                    <Col xs={24}>
                        <Form.Item
                            label={<Typography.Text strong>Trigger Time</Typography.Text>}
                            tooltip="Start and end hours of the day to monitor this specific price trigger, and only create an event during this time"
                            htmlFor="startTime"
                        >
                            <TimeRangeSelector
                                startTime={startTime}
                                endTime={endTime}
                                onChange={handleTimeRangeChange}
                                startTimeFieldName="start_time"
                                endTimeFieldName="end_time"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Card
                    title="Event"
                    extra={
                        <Tooltip title="When this trigger is activated we will create an event with this configuration">
                            <QuestionCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                    }
                    bordered={false}
                    style={{ backgroundColor: 'rgba(49, 172, 171, 0.1)' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={8}>
                            <EventDurationInput name="duration" value={duration} onChange={handleDurationChange} />
                        </Col>
                        <Col xs={8}>
                            <Form.Item
                                label={<Typography.Text strong>Signal Level</Typography.Text>}
                                name="signal"
                                required
                                style={{ width: '100%' }}
                            >
                                <SignalLevelSelector />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Modal>
    );
};
