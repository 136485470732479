import { HistoryOutlined, LinkOutlined } from '@ant-design/icons';
import { Card, Empty } from 'antd/lib';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Descriptions from 'antd/lib/descriptions';
import Typography from 'antd/lib/typography';
import React, { useState } from 'react';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { PAGE_ACTION } from '../../domain/commonConst';
import { IMeter, IMeterEnrollment } from '../../domain/site/interface';
import { AuditTrailModal } from '../modals/auditTrailModal/auditTrailModal';
import Divider from 'antd/lib/divider';
import Flex from 'antd/lib/flex';
import { SiteFullAddress } from './SiteAddress';

const MeterInfo = ({ meterList, showAuditTrailModal }: { meterList?: IMeter[]; showAuditTrailModal: Function }) => {
    if (!meterList?.length) return <span>&nbsp;-&nbsp;</span>;

    return (
        <Collapse style={{ width: '100%' }}>
            {meterList.map((meterInfo, index) => (
                <Collapse.Panel
                    key={`${meterInfo.salesforce_id}-${meterInfo.name}-${index}`}
                    header={
                        <Flex justify="space-between" align="center">
                            <Typography.Text
                                ellipsis={{ tooltip: { title: meterInfo.name } }}
                                style={{ maxWidth: '500px' }}
                            >
                                {meterInfo.name}
                            </Typography.Text>
                            <Flex>
                                <Button
                                    size="small"
                                    type="link"
                                    target={'_blank'}
                                    icon={<LinkOutlined />}
                                    href={meterInfo.salesforce_url}
                                />
                                <Button
                                    size="small"
                                    onClick={showAuditTrailModal(meterInfo.salesforce_id, AuditEntityType.METER_ENTITY)}
                                    type="text"
                                    icon={<HistoryOutlined />}
                                />
                            </Flex>
                        </Flex>
                    }
                >
                    <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2 }}>
                        <Descriptions.Item label="Meter Salesforce ID">
                            {meterInfo.salesforce_id || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Service Account Number">
                            {meterInfo.service_account_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Store Number">{meterInfo.store_number || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Account Name">{meterInfo?.account?.name || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Site Max Duration">
                            {meterInfo.event_max_duration || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Site Enrolled (kW)">
                            {meterInfo.contract_max_per_site || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Zone SubLAP">{meterInfo.zone_sub_lap || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Address" span={5}>
                            <SiteFullAddress
                                address={meterInfo.address}
                                city={meterInfo.city}
                                state={meterInfo.state}
                                zip={meterInfo.zip}
                                country={meterInfo.country}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce created by">
                            {meterInfo.salesforce_created_by || '-'} /{' '}
                            {defaultDateFormat(meterInfo.salesforce_created_at, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce updated by">
                            {meterInfo.salesforce_updated_by || '-'} /{' '}
                            {defaultDateFormat(meterInfo.salesforce_updated_at, '-')}
                        </Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

const MeterEnrollmentInfo = ({
    enrollmentList,
    showAuditTrailModal,
}: {
    enrollmentList?: IMeterEnrollment[];
    showAuditTrailModal: Function;
}) => {
    if (!enrollmentList?.length) return <span>&nbsp;-&nbsp;</span>;

    return (
        <Collapse style={{ width: '100%' }}>
            {enrollmentList.map((enrollmentInfo, index) => (
                <Collapse.Panel
                    key={`${enrollmentInfo.salesforce_id}-${enrollmentInfo.name}-${index}`}
                    header={
                        <Flex justify="space-between" align="center">
                            <Typography.Text
                                ellipsis={{ tooltip: { title: enrollmentInfo.name } }}
                                style={{ maxWidth: '500px' }}
                            >
                                {enrollmentInfo.name}
                            </Typography.Text>
                            <Button
                                size="small"
                                onClick={showAuditTrailModal(
                                    enrollmentInfo.salesforce_id,
                                    AuditEntityType.METER_ENROLLMENT_ENTITY
                                )}
                                type="text"
                                icon={<HistoryOutlined />}
                            />
                        </Flex>
                    }
                >
                    <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2 }}>
                        <Descriptions.Item label="Enrollment Salesforce ID">
                            {enrollmentInfo.salesforce_id || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Enrollment ID">
                            {enrollmentInfo.enrollment_id || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product name">{enrollmentInfo.product_name || '-'}</Descriptions.Item>
                        <Descriptions.Item label="Enrollment status">
                            {enrollmentInfo.enrollment_status || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Enrollment start date'}>
                            {defaultDateFormat(enrollmentInfo.enrollment_start_date, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Enrollment end date'}>
                            {defaultDateFormat(enrollmentInfo.enrollment_end_date, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Salesforce submitted at'}>
                            {defaultDateFormat(enrollmentInfo.salesforce_submitted_at, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce created by">
                            {enrollmentInfo.salesforce_created_by || '-'} /{' '}
                            {defaultDateFormat(enrollmentInfo.salesforce_created_at, '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Salesforce updated by">
                            {enrollmentInfo.salesforce_updated_by || '-'} /{' '}
                            {defaultDateFormat(enrollmentInfo.salesforce_updated_at, '-')}
                        </Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

export const ExtraSiteInformation = ({
    meters,
    meterEnrollments,
}: {
    meters?: IMeter[];
    meterEnrollments?: IMeterEnrollment[];
}) => {
    const [pageAction, setPageAction] = useState<PAGE_ACTION | ''>();
    const [selectedEntityType, setSelectedEntityType] = useState<
        AuditEntityType.METER_ENTITY | AuditEntityType.METER_ENROLLMENT_ENTITY | null
    >();
    const [selectedEntitySalesforceId, setSelectedEntitySalesforceId] = useState<string | null>();

    const showAuditTrailModal =
        (salesforceId: string, entityType: AuditEntityType.METER_ENTITY | AuditEntityType.METER_ENROLLMENT_ENTITY) =>
        (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();

            setPageAction(PAGE_ACTION.AUDIT_TRAIL);
            setSelectedEntityType(entityType);
            setSelectedEntitySalesforceId(salesforceId);
        };

    const closeAuditTrailModal = () => {
        setPageAction('');
        setSelectedEntityType(null);
        setSelectedEntitySalesforceId(null);
    };

    if (!meterEnrollments?.length && !meters?.length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    return (
        <>
            <Card size="small" style={{ padding: '0 16px 16px 16px' }}>
                {!!meters?.length && (
                    <>
                        <Divider plain>Meters list</Divider>
                        <MeterInfo meterList={meters} showAuditTrailModal={showAuditTrailModal} />
                    </>
                )}
                {!!meterEnrollments?.length && (
                    <>
                        <Divider plain>Meter Enrollments list</Divider>
                        <MeterEnrollmentInfo
                            enrollmentList={meterEnrollments}
                            showAuditTrailModal={showAuditTrailModal}
                        />
                    </>
                )}
            </Card>
            {selectedEntitySalesforceId && selectedEntityType && pageAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal
                    entityId={selectedEntitySalesforceId}
                    entityType={selectedEntityType}
                    onClose={closeAuditTrailModal}
                />
            )}
        </>
    );
};
