import './metricData.css';

interface IMetricData {
    value: number | string;
    uom?: string;
    valueClassName?: string[];
}
export const MetricData = ({ value, uom, valueClassName }: IMetricData) => (
    <div className="metric-data-container">
        <span className={`metric-value ${valueClassName?.join(' ')}`}>{value}</span>
        {uom && <span className="metric-uom">{uom}</span>}
    </div>
);
