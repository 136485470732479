import format from 'date-fns-tz/format';
import { formatInTimeZone } from 'date-fns-tz';

export const DEFAULT_DATE_FORMAT = 'hh:mm a MMM dd, yyyy';

export function getComputerFormattedTime(date: Date, dateFormat = DEFAULT_DATE_FORMAT): string {
    return format(date, dateFormat);
}

export function getUtcFormattedTime(date: Date, dateFormat = DEFAULT_DATE_FORMAT): string {
    return formatInTimeZone(date, 'UTC', dateFormat);
}

export function getTimezoneFormattedTime(date: Date, timeZone?: string, dateFormat = DEFAULT_DATE_FORMAT): string {
    if (!timeZone) return '';

    return formatInTimeZone(date, timeZone, dateFormat);
}
