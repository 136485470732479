import { useCallback, useEffect, useState } from 'react';
import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import Modal from 'antd/lib/modal';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import { InfoCircleOutlined } from '@ant-design/icons';
import { WithEmptyDataTable } from '../../../../components/table/withEmptyDataTable';
import { SORT_ORDER } from '../../../../domain/commonConst';
import { IEvent } from '../../../../domain/event/interface';
import useAbortableFetch from '../../../../domain/useAbortableFetch';
import { sendScheduledAndActiveEventsToUser, fetchScheduledAndActiveEventsForUser } from '../../../../domain/user';
import { IProgram } from '../../../../domain/program/interface';
import { getEventState } from '../../../../domain/event/getEventState';
import { getColumnSortOrder } from '../../../../components/table/columnFormatHelpers';
import { MemoizedDatetimePopover } from '../../../../components/datetimePopover/datetimePopover';
import { checkIfUserSubscribed } from './checkIfUserSubscribed';
import { IResendEventModalProps } from './interface';
import { checkIfUserSiteLabelMatchesEvent } from './checkIfUserSiteLabelMatchesEvent';
import { formatToMinutesAndHours } from '../../../../domain/common/dateFormatters';
import App from 'antd/lib/app';
import { ProgramTag } from '../../../../components/ProgramTag';

interface IModalState {
    loading: boolean;
    pagination: {
        current: number;
        pageSize: number;
        total: number;
    };
    sorter: {
        field: string;
        order: SORT_ORDER;
    };
}

const defaultState = {
    loading: false,
    pagination: { current: 1, pageSize: 10000, total: 0 },
    sorter: {
        field: 'event_datetime_start',
        order: SORT_ORDER.DESCEND,
    },
};

export const ResendScheduledAndActiveEventsModal = ({ user, onClose }: IResendEventModalProps) => {
    const { notification } = App.useApp();
    const [state, setState] = useState<IModalState>(defaultState);
    const [abortableFetchEvents] = useAbortableFetch(fetchScheduledAndActiveEventsForUser);
    const [events, setEvents] = useState<IEvent[]>([]);

    const fetchEventsAction = useCallback(
        async ({ pagination }: any) => {
            setState(prevState => ({ ...prevState, loading: true }));

            try {
                const query = {
                    userId: user.user_id,
                    pagination,
                };

                const { meta, data: events } = await abortableFetchEvents(query);

                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    pagination: {
                        total: meta.total,
                        current: Math.ceil(meta.offset / meta.limit) + 1,
                        pageSize: meta.limit,
                    },
                }));

                setEvents(events);
            } catch (error: any) {
                if (error.name === 'AbortError') return;
                notification.error({ key: 'event-fetch-info', message: 'Cannot fetch events' });

                setState(prevState => ({ ...prevState, loading: false }));
            }
        },
        [abortableFetchEvents, user.company_id]
    );

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleResend = useCallback(async () => {
        setState(prevState => ({ ...prevState, loading: true }));

        try {
            await sendScheduledAndActiveEventsToUser(user.user_id);
            notification.success({ key: 'event-resend', message: 'Active and scheduled events were sent to the user' });
        } catch (error: any) {
            if (error.name === 'AbortError') return;
            notification.error({ key: 'event-resend', message: error?.message || 'Cannot resend events to user' });
        }

        setState(prevState => ({ ...prevState, loading: false }));
    }, [user.user_id]);

    useEffect(() => {
        (async () => {
            await fetchEventsAction(defaultState);
        })();
    }, [fetchEventsAction]);

    const columns: ColumnsType<IEvent> = [
        {
            title: 'Start date',
            dataIndex: 'event_datetime_start',
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => a.event_datetime_start - b.event_datetime_start,
            ...getColumnSortOrder('event_datetime_start', state.sorter),
            render: (text: string) => <MemoizedDatetimePopover timestamp={new Date(text).valueOf()} />,
        },
        {
            title: 'Site',
            dataIndex: 'site_name',
            sorter: (a: any, b: any) => a?.site.site_name - b?.site.site_name,
            ...getColumnSortOrder('site_name', state.sorter),
            render: (text, record) => <div style={{ maxWidth: '200px' }}>{record?.site?.site_name}</div>,
        },
        {
            title: 'Program',
            dataIndex: 'program_name',
            render: (text, record) => {
                return (
                    <>
                        {record?.site?.programs?.map((p: IProgram, i: number) => (
                            <ProgramTag programName={p.name} key={`program-${i}-${p.program_id}`} />
                        ))}
                    </>
                );
            },
        },
        {
            title: 'Address',
            render: (text, record) => (
                <div>
                    <Typography.Text>{record?.site?.site_city},</Typography.Text>
                    <br />
                    <Typography.Text>
                        {record?.site?.site_state} {record?.site?.site_zip || ''}
                    </Typography.Text>
                </div>
            ),
        },

        {
            title: 'Zone, Network',
            dataIndex: ['site', 'site_slap'],
            render: (text, record) => {
                const zone = text ? text : '-';
                const network = record?.site?.site_network ? ` ${record?.site?.site_network}` : '';
                if (network) return `${zone}, ${network}`;
                if (zone !== '-') return zone;
                return '';
            },
        },

        {
            title: 'Duration',
            dataIndex: 'event_duration',
            render: text => formatToMinutesAndHours(text),
        },
        {
            title: 'Status',
            render: (text, record) => getEventState(record),
        },
        {
            title: 'Info',
            align: 'center',
            render: (text, record) => {
                const notificationSettingsError = checkIfUserSubscribed(record, user);
                const siteMismatchError = checkIfUserSiteLabelMatchesEvent(record, user);
                const hasError = notificationSettingsError || siteMismatchError;
                const Icon = hasError ? <InfoCircleOutlined style={{ fontSize: 20, color: 'red' }} /> : null;

                return (
                    <Tooltip
                        title={
                            hasError && (
                                <Space direction="vertical" size={0}>
                                    {notificationSettingsError && (
                                        <Typography.Text style={{ color: 'white' }}>{notificationSettingsError.message}</Typography.Text>
                                    )}
                                    {siteMismatchError && <Typography.Text style={{ color: 'white' }}>{siteMismatchError.message}</Typography.Text>}
                                </Space>
                            )
                        }
                    >
                        {Icon}
                    </Tooltip>
                );
            },
            width: 70,
        },
    ];

    return (
        <Modal
            open
            centered
            destroyOnClose
            title="Resend Scheduled And Active Events"
            width={1100}
            style={{ top: 20 }}
            onCancel={handleClose}
            onOk={handleResend}
            footer={[
                <Button key="resend-modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button
                    key="resend-modal-submit"
                    type="primary"
                    disabled={state.loading || events.length === 0}
                    loading={state.loading}
                    onClick={handleResend}
                >
                    Resend {state.pagination?.total} event{state.pagination?.total > 1 ? 's' : ''} to email
                </Button>,
            ]}
        >
            <Typography.Paragraph style={{ fontSize: '15px', paddingBottom: '20px' }}>
                Resend email to: <strong>{user.user_email}</strong>
            </Typography.Paragraph>
            <WithEmptyDataTable condition={state.loading} emptyDataDescription="This user doesn't have scheduled or active events">
                <Table
                    sticky
                    size="small"
                    rowKey="event_id"
                    columns={columns}
                    dataSource={events}
                    loading={state.loading}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                    }}
                />
            </WithEmptyDataTable>
        </Modal>
    );
};
