import Icon from '@ant-design/icons';
import { ReactComponent as LeafIcon } from './leaf.svg';
import { ReactComponent as GridIcon } from './grid.svg';
import { ReactComponent as CubeIcon } from './cube.svg';
import { ReactComponent as MaintenanceIcon } from './maintenance.svg';
import { ReactComponent as CoinsIcon } from './coins.svg';
import { ReactComponent as EnergyIcon } from './energy.svg';
import { ReactComponent as IsoIcon } from './iso.svg';
import { ReactComponent as ConnectIcon } from './connect.svg';

const CircleSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
        <circle cx="512" cy="512" r="400" />
    </svg>
);

export const CircleIcon = (props: any) => <Icon component={CircleSvg} {...props} />;
export const LeafSVG = (props: any) => <Icon component={LeafIcon} {...props} />;
export const GridSVG = (props: any) => <Icon component={GridIcon} {...props} />;
export const CubeSVG = (props: any) => <Icon component={CubeIcon} {...props} />;
export const MaintenanceSVG = (props: any) => <Icon component={MaintenanceIcon} {...props} />;
export const CoinsSVG = (props: any) => <Icon component={CoinsIcon} {...props} />;
export const EnergySVG = (props: any) => <Icon component={EnergyIcon} {...props} />;
export const IsoSVG = (props: any) => <Icon component={IsoIcon} {...props} />;
export const ConnectSVG = (props: any) => <Icon component={ConnectIcon} {...props} />;
