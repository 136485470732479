import isEmpty from 'lodash/isEmpty';
import { toAllowedStringLength } from '../../domain/common/formattersToAllowedValueLength';
import { ICompany } from '../../domain/company/interface';
import { isArray } from 'lodash';

export const buildCompanyFilter = (companies: ICompany[]): { text: string; value: number }[] => {
    return companies.map(c => {
        return { text: toAllowedStringLength(c.company_name), value: c.company_id };
    });
};

interface IControlProviderFilterOptions {
    includeWithoutControlProvider: boolean;
}

export const buildControlProviderFilter = (controlProviderCompanies: ICompany[], options?: IControlProviderFilterOptions): { text: string; value: string }[] => {
    const filterOptions = controlProviderCompanies.map(c => {
        return { text: toAllowedStringLength(c.company_name, 30), value: c.company_id.toString() };
    });

    if (options?.includeWithoutControlProvider) {
        filterOptions.unshift({ text: 'No Control Provider', value: "null" });
    }

    return filterOptions;
};

export const getColumnSortOrder = (
    field: string,
    sorter?: {
        field: string;
        order: 'descend' | 'ascend' | undefined;
    }
) => ({
    sortOrder: sorter && sorter.field === field ? sorter.order : undefined,
});

export const getColumnFilteredValue = (field: string, filter: any) => {
    const isFieldExist = filter && !isEmpty(filter) && filter[field] !== undefined;
    const getFieldValue = () => (isArray(filter[field]) ? filter[field] : [filter[field]]);
    return {
        filteredValue: isFieldExist ? getFieldValue() : null,
    };
};
