import React from 'react';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';

interface IShortEventsInfoProps {
    eventsCountByYear: {
        [key: string]: number; // [year]: [events_count]
    }
}

export function YearlyEventsCountInfo({ eventsCountByYear }: IShortEventsInfoProps) {
    if (Object.entries(eventsCountByYear).length === 0) {
        return (
            <Typography.Text>No events</Typography.Text>
        )
    }

    return (
        <List size='small' bordered={false}>
            {Object.entries(eventsCountByYear).map(([year, numberOfEvents]) => (
                <List.Item key={year}>
                    <Typography.Text>{`${year}: ${numberOfEvents} event(s)`}</Typography.Text>
                </List.Item>
            ))}
        </List>
    )
}
