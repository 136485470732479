export interface ITriggerTimeTagProps {
    value: { start_time: string | null; end_time: string | null; };
}

export const TriggerTimeTag = ({ value }: ITriggerTimeTagProps) => {
    if (!value.start_time || !value.end_time) return <></>

    const triggerStart = value.start_time;

    // library at backEnd return 23:59 instead 00:00
    const triggerEnd = value.end_time && (value.end_time === '23:59' ? '24:00' : value.end_time);
    return (
        <>
            {triggerStart} - {triggerEnd}
        </>
    );
};
