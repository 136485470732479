import DollarOutlined from '@ant-design/icons/lib/icons/DollarOutlined';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { ISite } from '../../domain/site/interface';
import { EnergySVG, LeafSVG } from '../icons/icons';
import { ProgramTag } from '../ProgramTag';
import { PRIMARY_COLOR } from '../../theme';

export interface ISiteEnrollmentsProps {
    value?: ISite;
}

export const SiteEnrollments = ({ value }: ISiteEnrollmentsProps) => {
    if (!value) return <></>;

    return (
        <>
            {value.site_price_response_enrolled && (
                <Tooltip title="Enrolled into Price Response">
                    <Typography.Paragraph ellipsis={true} style={{ width: '100%', marginBottom: 0 }}>
                        <Tag
                            style={{ display: 'initial', marginRight: 0, padding: '0 4px' }}
                            icon={<DollarOutlined style={{ color: PRIMARY_COLOR }} />}
                        >
                            Price Response
                        </Tag>
                    </Typography.Paragraph>
                </Tooltip>
            )}
            {value.site_clean_response_enrolled && (
                <Tooltip title="Enrolled into Clean Response">
                    <Typography.Paragraph ellipsis={true} style={{ width: '100%', marginBottom: 0 }}>
                        <Tag
                            style={{ display: 'initial', marginRight: 0, padding: '0 4px' }}
                            icon={<LeafSVG style={{ color: PRIMARY_COLOR, width: '10px' }} />}
                        >
                            Clean Response
                        </Tag>
                    </Typography.Paragraph>
                </Tooltip>
            )}
            {value.site_leap_api_dispatch_enrolled && (
                <Tooltip title="Enrolled into Leap API dispatch">
                    <Typography.Paragraph ellipsis={true} style={{ width: '100%', marginBottom: 0 }}>
                        <Tag
                            style={{ display: 'initial', marginRight: 0, padding: '0 4px' }}
                            icon={<EnergySVG style={{ color: PRIMARY_COLOR, width: '10px' }} />}
                        >
                            Leap API
                        </Tag>
                    </Typography.Paragraph>
                </Tooltip>
            )}
            {value?.programs?.map((program, index) => (
                <ProgramTag programName={program.name} key={`program-${index}`} />
            ))}
        </>
    );
};
