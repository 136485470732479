import { useContext, useMemo } from 'react';
import Tooltip from 'antd/lib/tooltip';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { WARNING_COLOR } from '../../theme';
import { suggestSANFormat } from '../../domain/site/suggestSANFormat';
import { AbilityContext } from '../ability/can';

interface ISuggestedFormatIconProps {
    san: string;
    market: string | null;
    utility: string | null;
}

export const SuggestedFormatIcon = ({ san, utility, market }: ISuggestedFormatIconProps) => {
    const ability = useContext(AbilityContext);

    const suggestedSANFormat = useMemo(
        () => suggestSANFormat(san || '', utility, market),
        [san, market, utility],
    );

    const isSuggestionShown =
              ability.can('read', 'SanValidationSuggestion') &&
              san &&
              suggestedSANFormat &&
              !suggestedSANFormat?.isValid;

    if (!isSuggestionShown) {
        return <></>;
    }

    return (
        <Tooltip title={`This account number should look like a ${suggestedSANFormat.suggestedIdentifier} identifier and has the suggested format: ${suggestedSANFormat.suggestedFormat}`}>
            <ExclamationCircleOutlined style={{ margin: '0 4px', color: WARNING_COLOR, fontSize: '14px' }} />
        </Tooltip>
    );
};
