import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchEnergyDataFileInfo } from './index';
import { DataInboxFileInfoDto } from './interface';

export const useDataInboxFileInfoQuery = (key: string, options = {}) => {
    return useQuery<DataInboxFileInfoDto, Error>({
        queryKey: [QueryKey.INBOX_FILE, key],
        queryFn: ({ signal }) => fetchEnergyDataFileInfo(key, signal),
        ...options,
    });
};
