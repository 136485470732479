export interface IPageState {
    loading: boolean;
    pagination: {
        current: number;
        pageSize: number;
        total: number;
    };
    sorter: {
        field: string;
        order: 'descend' | 'ascend';
    };
    filter: {
        company_vertical?: string;
        type?: number;
        isClosed?: boolean | string;
    };
    search?: string;
}

export interface IDateRange {
    start: Date;
    end: Date;
}

export enum VIEW_MODE {
    CLOUD_VEN = 'cloud-ven',
    CUSTOMER_VEN = 'customer-ven',
    OVERVIEW = 'overview',
}

export enum CAISO_MODE {
    SIBR = 'sibr',
    DRRS = 'drrs',
}

export enum CAISO_DRRS_VIEW {
    LOCATIONS = 'locations',
    REGISTRATIONS = 'registrations',
}
