const local: IConfig = {
    APP_STAGE: 'local',
    HOST: 'https://drms.stg.enersponse.com',
    API_URL: 'http://localhost:5000/api/v1',
    WEATHER_API_KEY: 'dd17276cfbba0ee2f3334d7128dfe4cb',
    GOOGLE_MAP_API: 'AIzaSyBSm5vA7Vy_9yrlYbS_OgWBSYoVJlT9JrQ',
    FULL_CALENDER_LICENSE_KEY: '0874672795-fcs-1634671044',
    RECAPTCHA_SITE_KEY: '6LcgzIQhAAAAABXXVXjKcH3K8VSJA_y7shmBRgVj',
    SUPPORT_EMAIL: 'info@enersponse.com',
    SUPPORT_PHONE: '+1 (949) 734-0043',
};

const dev: IConfig = {
    APP_STAGE: 'dev',
    HOST: 'https://drms.dev.enersponse.com',
    API_URL: 'https://api.dev.enersponse.com/api/v1',
    WEATHER_API_KEY: 'dd17276cfbba0ee2f3334d7128dfe4cb',
    GOOGLE_MAP_API: 'AIzaSyBSm5vA7Vy_9yrlYbS_OgWBSYoVJlT9JrQ',
    FULL_CALENDER_LICENSE_KEY: '0874672795-fcs-1634671044',
    RECAPTCHA_SITE_KEY: '6LdvwYIkAAAAAAWbSQ8vecz0UeM7c5nUogxOBKNg',
    SUPPORT_EMAIL: 'info@enersponse.com',
    SUPPORT_PHONE: '+1 (949) 734-0043',
};

const staging: IConfig = {
    APP_STAGE: 'staging',
    HOST: 'https://drms.stg.enersponse.com',
    API_URL: 'https://api.stg.enersponse.com/api/v1',
    WEATHER_API_KEY: 'dd17276cfbba0ee2f3334d7128dfe4cb',
    GOOGLE_MAP_API: 'AIzaSyBSm5vA7Vy_9yrlYbS_OgWBSYoVJlT9JrQ',
    FULL_CALENDER_LICENSE_KEY: '0874672795-fcs-1634671044',
    RECAPTCHA_SITE_KEY: '6LcgzIQhAAAAABXXVXjKcH3K8VSJA_y7shmBRgVj',
    SUPPORT_EMAIL: 'info@enersponse.com',
    SUPPORT_PHONE: '+1 (949) 734-0043',
};

const demo: IConfig = {
    APP_STAGE: 'demo',
    HOST: 'https://drms.demo.enersponse.com',
    API_URL: 'https://api.demo.enersponse.com/api/v1',
    WEATHER_API_KEY: 'dd17276cfbba0ee2f3334d7128dfe4cb',
    GOOGLE_MAP_API: 'AIzaSyBSm5vA7Vy_9yrlYbS_OgWBSYoVJlT9JrQ',
    FULL_CALENDER_LICENSE_KEY: '0874672795-fcs-1634671044',
    RECAPTCHA_SITE_KEY: '6LeaPyspAAAAAPDm_p-4u3ThJRdXRxCJaen_ziYh',
    SUPPORT_EMAIL: 'info@enersponse.com',
    SUPPORT_PHONE: '+1 (949) 734-0043',
};

const production: IConfig = {
    APP_STAGE: 'production',
    HOST: 'https://drms.enersponse.com',
    API_URL: 'https://api.enersponse.com/api/v1',
    WEATHER_API_KEY: 'dd17276cfbba0ee2f3334d7128dfe4cb',
    GOOGLE_MAP_API: 'AIzaSyACer9QCrgOOcAk7jU4U37SG02RbMdVw-0',
    FULL_CALENDER_LICENSE_KEY: '0874672795-fcs-1634671044',
    RECAPTCHA_SITE_KEY: '6Le-zYQhAAAAAObiHR8xkbi-A6HKp_lzDv6Co9Te',
    SUPPORT_EMAIL: 'info@enersponse.com',
    SUPPORT_PHONE: '+1 (949) 734-0043',
};

interface IConfig {
    APP_STAGE: string;
    HOST: string; // frontend host
    API_URL: string;
    WEATHER_API_KEY: string;
    GOOGLE_MAP_API: string;
    FULL_CALENDER_LICENSE_KEY: string;
    RECAPTCHA_SITE_KEY: string;
    SUPPORT_EMAIL: string;
    SUPPORT_PHONE: string;
}

let config: IConfig = { ...dev };
switch (process.env.REACT_APP_STAGE) {
    case 'local':
        config = { ...local };
        break;
    case 'dev':
        config = { ...dev };
        break;
    case 'staging':
        config = { ...staging };
        break;
    case 'demo':
        config = { ...demo };
        break;
    case 'production':
        config = { ...production };
        break;
    default:
        config = { ...dev };
}

export default config;
