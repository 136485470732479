import { API } from '../api';
import { IPagination } from '../IPagination';
import { IAuditTrail, IFetchAuditTrail } from './interface';

export const fetchAuditTrail = async (
    { entityId, entityType, pagination }: IFetchAuditTrail,
    signal?: AbortSignal | null
): Promise<IPagination<IAuditTrail>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    const path = `/audit-trail?${new URLSearchParams({
        ...(entityId && { entityId: entityId.toString() }),
        ...(entityType && { entityType }),
        offset: offset.toString(),
        limit: limit.toString(),
        sortField: 'datetime',
        sortOrder: 'descend',
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot fetch data!`);
};
