import { useState } from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import Result from 'antd/lib/result';
import UserOutlined from '@ant-design/icons/UserOutlined';
import App from 'antd/lib/app';
import { Link } from 'react-router-dom';

import { resetPassword } from '../../domain/auth/auth';
import logoImg from '../../logo.png';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import './resetPasswordStyle.css';

interface ResetPasswordData {
    email: string;
}

export const ResetPassword = () => {
    const { notification } = App.useApp();
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useDocumentTitle('Reset Password');

    const onFinish = async (values: ResetPasswordData) => {
        setLoading(true);
        try {
            await resetPassword(values);
            setIsEmailSend(true);
        } catch (error: any) {
            notification.error({
                key: 'reset-password-error',
                message: 'Reset Password Error. Email was not sent',
                description: error.message,
            });
        }
        setLoading(false);
        form.resetFields();
    };

    return (
        <>
            <img className="logo" src={logoImg} alt="Enersponse logo" />

            {isEmailSend ? (
                <Result
                    data-cy='reset-password-letter-send'
                    status="success"
                    title="Done!"
                    subTitle="If this email address is registered in our system, you will receive instructions on how to reset your password via email."
                />
            ) : (
                <>
                    <Typography.Title level={2} type="secondary" className="form-tittle">
                        Forgot Password
                    </Typography.Title>
                    <Form form={form} name="reset_password" onFinish={onFinish}>
                        <Form.Item>
                            <Typography.Paragraph>
                                Please enter your email address below. If this email is registered we'll send you instruction how to reset password.
                            </Typography.Paragraph>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid Email!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >
                            <Input data-cy='reset-email-input' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>

                        <Form.Item>
                            <Button data-cy='reset-submit' loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}

            <Link data-cy='navigate-back' to="/">Go back</Link>
        </>
    );
};
