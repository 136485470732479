import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';

export interface IProgramTagProps {
    programName: string;
    tooltip?: string;
}

export const ProgramTag = ({ programName, tooltip }: IProgramTagProps) => {
    if (!programName) return null;

    return (
        <Tooltip title={tooltip ?? programName}>
            <Typography className="ellipsis">
                <Tag color="gold" style={{ display: 'initial' }}>
                    {programName}
                </Tag>
            </Typography>
        </Tooltip>
    );
};
