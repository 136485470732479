import { IUser } from '../user/interface';
import config from "../../config";

export const initializePendo = (user: IUser) => {
    const appStage = config.APP_STAGE;
    (window as any)?.pendo?.initialize({
        visitor: {
            id: `${user.user_id}-${appStage}`,
            email: user.user_email,
            full_name: `${user.user_fname || ''} ${user.user_lname || ''}`,
            role: user.user_type,
        },
        account: {
            id: `${user.company_id}-${appStage}`,
        }
    });
}