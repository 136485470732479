import Col from 'antd/lib/col';
import Alert from 'antd/lib/alert';
import { ISite, PARTNER_ID_ERROR_STATUS } from '../../domain/site/interface';
import { checkWarningPeriod, getLatestError } from './PartnerIdStatus';

export const PartnerIdStatusAlert = ({ partner_id_status }: { partner_id_status: ISite['partner_id_status'] }) => {
    if (!partner_id_status) return null;

    if (partner_id_status.status === PARTNER_ID_ERROR_STATUS.SOLVED || partner_id_status.status === PARTNER_ID_ERROR_STATUS.SUCCESS) {
        return null;
    }
    const errors = partner_id_status?.errors;
    if (!errors?.length) return null;

    const latestError = checkWarningPeriod(getLatestError(errors), partner_id_status.status);
    return (
        latestError && (
            <Col span={24}>
                <Alert message={latestError.error} type={partner_id_status.status} showIcon />
            </Col>
        )
    );
};
