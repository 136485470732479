import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { WARNING_COLOR } from '../../../theme';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import { ICompany } from '../../../domain/company/interface';

interface ICompanyDeleteModal {
    company: ICompany | Partial<ICompany>;
    onClose: Function;
    onSubmit: Function;
}

export const DeleteCompanyModal = ({ company, onClose, onSubmit }: ICompanyDeleteModal) => {
    const handleConfirm = async () => {
        onSubmit(company);
    };

    const handleClose = () => onClose();
    return (
        <Modal
            open
            destroyOnClose
            closeIcon={false}
            styles={{
                footer: {
                    borderTop: 'none',
                    padding: 24,
                    paddingTop: 0,
                },
            }}
            footer={[
                <Button key="modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="delete-confirm" data-cy="delete-confirm" type="primary" onClick={handleConfirm}>
                    Ok
                </Button>,
            ]}
        >
            <Row align="top" wrap={false}>
                <ExclamationCircleOutlined style={{ fontSize: 22, color: WARNING_COLOR, marginRight: 8 }} />
                <Typography.Text>
                    Do you want to delete <strong>{company.company_name}</strong>? <br/>
                    We will unlink all Accounts from it and delete all its Sites.
                    This process cannot be undone.
                </Typography.Text>
            </Row>
        </Modal>
    );
};
