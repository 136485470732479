import format from 'date-fns/format';
import omit from 'lodash/omit';
import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { IEvent } from '../event/interface';
import { DATE_FORMAT_CSV, SORT_ORDER } from '../commonConst';
import { IChangePassword, IUser } from './interface';
import { exportCSVData } from '../common/exportCSVData';

export const fetchUsers = async (
    { pagination, sorter, filter, company_id = null, include, search }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IUser>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    if (filter && (filter.user_type === null || filter.user_type === undefined)) {
        delete filter.user_type;
    }

    const path = `/users?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(company_id && { company_id }),
        ...(search && { search }),
        ...filter,
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of users!`);
};

export const deleteUser = async (user: IUser) => {
    const response = await API.fetch(`/users/${user.user_id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete user!`);
};

export const createUser = async (user: IUser) => {
    const response = await API.fetch(`/users`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(user, ['user_id'])),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save user!`);
};

export const updateUser = async (user: Partial<IUser>) => {
    const update = {
        user_phone: user.user_phone ?? undefined, // allow user to clear phone number
        ...(user.company_id && { company_id: user.company_id }),
        ...(user.user_fname && { user_fname: user.user_fname }),
        ...(user.user_lname && { user_lname: user.user_lname }),
        ...(user.user_type && { user_type: user.user_type }),
        ...(user.user_password && { user_password: user.user_password }),
        ...(user.user_email && { user_email: user.user_email }),
        ...(user.notification_settings && { notification_settings: user.notification_settings }),
        ...(user.user_feature_flag && { user_feature_flag: user.user_feature_flag }),
        ...(user.site_label && { site_label: user.site_label }),
    };
    const response = await API.fetch(`/users/${user.user_id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(update),
    });
    const respBody: any = await response.json();
    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save user!`);
};

export const saveUser = async (user: IUser) => {
    user.user_id ? await updateUser(user) : await createUser(user);
};

export const exportUsers = async ({ filter, company_id = null, search }: IFetchPageQuery) => {
    if (filter?.user_type == null) {
        delete filter?.user_type;
    }

    const query = new URLSearchParams({
        format: 'csv',
        include: 'company',
        ...(company_id && { company_id }),
        ...(search && { search }),
        ...filter,
    });

    const response = await API.fetch(`/users?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `users--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const fetchUser = async (userId: number): Promise<IUser> => {
    const response = await API.fetch(`/users/${userId}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get user!`);
};

export const changePassword = async ({ newPassword, oldPassword, userId }: IChangePassword) => {
    const response = await API.fetch(`/users/${userId}/password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword, oldPassword }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot change password`);
};

export interface IFetchEventsForUserParams extends IFetchPageQuery {
    userId: number;
    pagination?: any;
}

export const fetchScheduledAndActiveEventsForUser = async (
    { userId, pagination = { pageSize: 10000, current: 1 }, sorter }: IFetchEventsForUserParams,
    signal: AbortSignal | null = null
): Promise<IPagination<IEvent>> => {
    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/users/${userId}/events?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        sortField: 'event_datetime_start',
        sortOrder: SORT_ORDER.ASCEND,
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }
    throw new Error(response.statusText);
};

export const sendScheduledAndActiveEventsToUser = async (userId: number) => {
    const path = `/users/${userId}/events/send`;

    const response = await API.fetch(path, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody = await response.json();
    throw new Error(respBody?.error?.message || `Cannot send events!`);
};
