import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../domain/auth/useAuth';
import { ICompany } from '../../../domain/company/interface';
import { UserType } from '../../../domain/user/interface';
import { usePageLocation } from '../../../pages/usePageState';
import { useCompanyListQuery } from '../../../domain/company/queries';

export interface ICompanyValue {
    company: number | null;
    companies: ICompany[];
    setCompany: (companyId: number | null) => void;
    handleCompany: (company: number | null) => void;
}

const CompanyContext = createContext<ICompanyValue | null>(null);

export const CompanyProvider = ({ children }: { children: React.ReactNode }) => {
    const urlLocation = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(urlLocation.search);

    const auth = useAuth()!;
    const isAllowedRoleType = [UserType.ADMIN, UserType.ACCOUNT_MANAGER, UserType.CONTROL_PROVIDER].includes(auth.user!.user_type);

    const [company, setCompany] = useState<ICompanyValue['company']>(pageState?.companyId || null);

    const setActiveCompany = () => {
        const activeCompany = pageState?.companyId || null;
        const currentActiveCompany = isAllowedRoleType ? activeCompany : auth.user?.company_id;
        setCompany(currentActiveCompany);
    };

    const { data: companies } = useCompanyListQuery({});

    useEffect(() => {
        setActiveCompany();
    }, []);

    // to set active company when we logIn after expired session
    useEffect(() => {
        if (pageState?.companyId && !company) {
            setActiveCompany();
        }
    }, [pageState?.companyId]);

    const handleCompany = (selectedCompany: ICompanyValue['company']) => {
        setCompany(selectedCompany);
        const copyState = { ...pageState };

        const preparedParams = {
            ...copyState,
            companyId: selectedCompany,
        };
        setPageQuery(preparedParams);
    };

    return (
        <CompanyContext.Provider
            value={{
                company,
                companies: companies?.data || [],
                setCompany,
                handleCompany,
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export const useCompanies = () => {
    return useContext(CompanyContext);
};
