import { ICompany } from '../../domain/company/interface';
import { IUser, UserType } from '../../domain/user/interface';

export interface IUserForm {
    user: IUser | Partial<IUser>;
    companies: ICompany[];
    onClose: Function;
    isEditMode: boolean;
}

export const userTypeDescription: { [key: string]: string[] } = {
    [UserType.ADMIN]: ['Can manage everything'],
    [UserType.ACCOUNT_MANAGER]: [
        'Can manage VEN, Site, User, Settlement, Company and Price and Clean Response pages',
        'Can view and enroll Program',
        'Can view and update Ven',
        'Can view Event page',
    ],
    [UserType.CUSTOMER]: [
        'Typically the senior manager and Enersponse, LLC. point of contact. Will see settlement financial and reporting data.',
        'Can view Settlement page',
        'Can view Event page (Access to user`s companies is limited)',
        'Can manage User pages',
        'Can create and update at Site page',
    ],
    [UserType.OPERATOR]: [
        'Typically a site or store manager, the person activating or monitoring system changes during an event.',
        'Can view User, Site pages',
        'Can view Event page (Access to user`s companies is limited)',
    ],
    [UserType.CONTROL_PROVIDER]: ['Can manage User page', 'Can view Event, VEN, Site, Reporting pages', 'Can view and update Ven'],
};

export const enersponseUserTypes = [UserType.ADMIN, UserType.ACCOUNT_MANAGER] as string[];

const optionsMap: { [key: string]: string[] } = {
    [UserType.ADMIN]: [...enersponseUserTypes, UserType.CUSTOMER, UserType.OPERATOR, UserType.CONTROL_PROVIDER],
    [UserType.ACCOUNT_MANAGER]: [UserType.CUSTOMER, UserType.OPERATOR, UserType.CONTROL_PROVIDER],
    [UserType.CUSTOMER]: [UserType.OPERATOR],
    [UserType.CONTROL_PROVIDER]: [],
};

const userTypeLabel: { [key: string]: string } = {
    [UserType.ADMIN]: 'Enersponse Admin',
    [UserType.ACCOUNT_MANAGER]: 'Enersponse Account Manager',
    [UserType.CUSTOMER]: 'Customer',
    [UserType.OPERATOR]: 'Operator',
    [UserType.CONTROL_PROVIDER]: 'Control Provider',
};

export const preparedUserTypeOptions = (userType: string) =>
    optionsMap[userType].map(type => ({
        label: userTypeLabel[type],
        value: type,
        description: userTypeDescription[type] ?? [''],
        group: enersponseUserTypes.includes(type) ? 'enersponse' : 'custom',
        hidden: false,
    }));

const filterUserTypeOption: { [key: string]: string[] } = {
    [UserType.ADMIN]: optionsMap[UserType.ADMIN],
    [UserType.ACCOUNT_MANAGER]: [UserType.ACCOUNT_MANAGER, ...optionsMap[UserType.ACCOUNT_MANAGER]],
    [UserType.CUSTOMER]: [UserType.CUSTOMER, ...optionsMap[UserType.CUSTOMER]],
    [UserType.CONTROL_PROVIDER]: [UserType.CONTROL_PROVIDER, ...optionsMap[UserType.CONTROL_PROVIDER]],
};

export const userTypeFilter = (userType: string) => {
    return filterUserTypeOption[userType].map(type => ({
        text: userTypeLabel[type],
        value: type,
    }));
};

export const colormap: any = {
    [UserType.ADMIN]: 'red',
    [UserType.CUSTOMER]: 'blue',
    [UserType.ACCOUNT_MANAGER]: 'volcano',
    [UserType.OPERATOR]: 'geekblue',
};
