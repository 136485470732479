import { API } from '../api';
import { ISettings } from './interface';

export const fetchSettings = async (companyId: number, signal?: AbortSignal | null): Promise<ISettings> => {
    const response = await API.fetch(`/companies/${companyId}/settings`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load settings.`);
};

export const updateSettings = async (companyId: number, settings: ISettings): Promise<ISettings> => {
    const response = await API.fetch(`/companies/${companyId}/settings`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot update settings.`);
};
