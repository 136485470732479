import App from 'antd/lib/app';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ICreateProgram, IProgram, IUniqueProgramOperator, IUniqueProgramType, IUpdateProgram } from './interface';
import {
    createProgram,
    deleteProgram,
    fetchProgramOperators,
    fetchPrograms,
    fetchProgramsByCompanyId,
    fetchProgramTypes,
    updateProgram,
} from './index';

export const useProgramListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<IProgram>, Error>({
        queryKey: [QueryKey.PROGRAMS, query],
        queryFn: ({ signal }) => fetchPrograms(query, signal),
        ...options,
    });
};

export const useProgramListByCompanyQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IProgram[], Error>({
        queryKey: [QueryKey.PROGRAM_BY_COMPANY, query],
        queryFn: ({ signal }) => fetchProgramsByCompanyId(query, signal),
        ...options,
    });
};

export const useProgramOperatorsListQuery = (options = {}) => {
    return useQuery<IUniqueProgramOperator[], Error>({
        queryKey: [QueryKey.PROGRAM_OPERATORS],
        queryFn: ({ signal }) => fetchProgramOperators(signal),
        ...options,
    });
};

export const useProgramTypesListQuery = (options = {}) => {
    return useQuery<IUniqueProgramType[], Error>({
        queryKey: [QueryKey.PROGRAM_TYPES],
        queryFn: ({ signal }) => fetchProgramTypes(signal),
        ...options,
    });
};

export const useProgramCreateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, ICreateProgram>(createProgram, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.PROGRAMS]);
            notification.success({ key: 'program-create-success', message: 'Program created successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'program-create-error', message: error.message || 'Cannot create program' });
        },
    });
};

export const useProgramUpdateMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUpdateProgram>(updateProgram, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.PROGRAMS]);
            notification.success({ key: 'program-update-success', message: 'Program updated successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'program-update-error', message: error.message || 'Cannot update program' });
        },
    });
};

export const useProgramDeleteMutation = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, IProgram>(deleteProgram, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.PROGRAMS]);
            notification.success({ key: 'program-delete-success', message: 'Program deleted successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'program-delete-error', message: error.message || 'Cannot delete program' });
        },
    });
};
