import { IEvent } from '../../../../domain/event/interface';
import { IUser } from '../../../../domain/user/interface';
import { getEventState } from '../../../../domain/event/getEventState';

export function checkIfUserSubscribed(event: IEvent, user: IUser): Error | null {
    const eventState = getEventState(event);

    if (eventState === 'pre-event' && !user.notification_settings.pre_event.email) {
        return new Error(`User not subscribed for ${eventState} event emails`);
    }

    if (eventState === 'scheduled' && !user.notification_settings.event_created.email) {
        return new Error(`User not subscribed for ${eventState} event emails`);
    }

    if (eventState === 'active' && !user.notification_settings.event_started.email) {
        return new Error(`User not subscribed for ${eventState} event emails`);
    }

    return null;
}
