export enum SAN_IDENTIFIER {
    SAN = 'SAN',
    POD_ID = 'POD ID',
    ESI_ID = 'ESI ID',
}

export interface ISuggestSANFormat {
    suggestedIdentifier: SAN_IDENTIFIER;
    suggestedFormat: string;
    isValid: boolean;
}

export function suggestSANFormat(receivedSan = '', utility: string | null, market: string | null): ISuggestSANFormat | null {
    if (!market || !utility) return null;

    const san = receivedSan.replaceAll('-', '').replaceAll(' ', '').toLocaleLowerCase();
    market = market.toUpperCase();
    utility = utility.toLowerCase(); // toLowerCase for case insensitive matching

    // 1. Utility
    // 2. Market
    // 3. Identifier
    // 4. Format
    // 5. Example

    // Southern California Edison
    // CAISO
    // SAN
    // 10-digit account number starting with "8"
    // 8001256559
    if (utility.includes('southern california edison') && market === 'CAISO') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10-digit account number starting with "8"',
            isValid: san.length === 10 && san.startsWith('8'),
        };
    }

    // Pacific Gas & Electric Company
    // CAISO
    // SAN
    // 10 digit account number - no specific identifier
    // 5716859589
    if (utility.includes('pacific gas & electric') && market === 'CAISO') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // San Diego Gas & Electric
    // CAISO
    // SAN
    // 10 digit account number starting with "SDG" (count of 10 includes SDG)
    // SDG1025549
    if (utility.includes('san diego gas & electric') && market === 'CAISO') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 symbols account number, but with prefix "sdg"',
            isValid: san.length === 10 && san.startsWith('sdg'),
        };
    }

    // Eversource Energy
    // ISONE
    // SAN
    // 11 digit account number - no specific identifier
    // 51771007085
    if (utility.includes('eversource') && market === 'ISONE') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '11 digit account number',
            isValid: san.length === 11,
        };
    }

    // National Grid
    // ISONE
    // SAN
    // 10 digit account number - no specific identifier
    // 28608-89025
    if (utility.includes('national grid') && market === 'ISONE') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // United Illuminating
    // ISONE
    // SAN
    // 14 digit account number starting with "010"
    // 010-0001410-8582
    if (utility.includes('united illuminating') && market === 'ISONE') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '14 digit account number starting with "010"',
            isValid: san.length === 14 && san.startsWith('010'),
        };
    }

    // Unitil
    // ISONE
    // SAN
    // 10 digit account number - no specific identifier
    // 1090651002
    if (utility.includes('unitil') && market === 'ISONE') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // American Electric Power
    // PJM
    // SAN
    // 11 digit account number - no specific identifier
    // 5330-2519-01-0
    if (utility.includes('american electric power') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '11 digit account number',
            isValid: san.length === 11,
        };
    }

    // Atlantic City Electric
    // PJM
    // SAN
    // 21 digit account number starting with "55" or "50"
    // 50168486947225132564, 556859425559645200152
    if (utility.includes('atlantic city electric') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '21 digit account number starting with "55" or "50"',
            isValid: san.length === 21 && (san.startsWith('55') || san.startsWith('50')),
        };
    }

    // Baltimore Gas & Electric
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 6582459965
    if (utility.includes('baltimore gas & electric') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Commonwealth Edison
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 1068081060
    if (utility.includes('commonwealth edison') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Dayton Power & Light/AES Ohio
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 3578469449
    if (utility.includes('dayton power & light') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Delmarva Power
    // PJM
    // SAN
    // 21 digit account number starting with "55" or "50"
    // 501566665899954122451, 556599995854441255321
    if (utility.includes('delmarva power') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '21 digit account number starting with "55" or "50"',
            isValid: san.length === 21 && (san.startsWith('55') || san.startsWith('50')),
        };
    }

    // Dominion Energy
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 7330390696
    if (utility.includes('dominion energy') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Duke Energy
    // PJM
    // SAN
    // 11 digit account number - no specific identifier
    // 7370-2062-02-8
    if (utility.includes('duke energy') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '11 digit account number',
            isValid: san.length === 11,
        };
    }

    // Duquesne Light
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 1234567890
    if (utility.includes('duquesne light') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // PECO (fka Philadelphia Electric)
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 9808546621
    if (utility.includes('peco energy') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Potomac Electric Power
    // PJM
    // SAN
    // 21 digit account number starting with "55" or "50"
    // 501550126899554761235, 550002369544589564128
    if (utility.includes('potomac electric power') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '21 digit account number starting with "55" or "50"',
            isValid: san.length === 21 && (san.startsWith('55') || san.startsWith('50')),
        };
    }

    // PPL Corp
    // PJM
    // SAN
    // 10 digit account number - no specific identifier
    // 5568944578
    if (
        (utility.includes('ppl electric utilities') || utility.includes('ppl corp'))
        && market === 'PJM'
    ) {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // Public Service Electric & Gas (PSEG)
    // PJM
    // POD ID
    // 20 digit account number starting with "PE0"
    // PE000009598115556423
    if (
        (utility.includes('public service electric & gas') || utility.includes('public service elec & gas co'))
        && market === 'PJM'
    ) {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.POD_ID,
            suggestedFormat: '20 digit account number, but with prefix "pe0"',
            isValid: san.length === 20 && san.startsWith('pe0'),
        };
    }

    // Rockland Electric
    // PJM
    if (utility.includes('rockland electric') && market === 'PJM') {
        return null;
    }

    // Cleveland Electric Illuminating
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08025145670007845212
    if (utility.includes('cleveland electric illum') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Jersey Central Power & Light
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08035688895421956589
    if (
        (utility.includes('jersey central power & lt') || utility.includes('jersey central power & light'))
        && market === 'PJM'
    ) {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Metropolitan Edison
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08018395140007583944
    if (utility.includes('metropolitan edison') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Ohio Edison
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08012556885496422225
    if (utility.includes('ohio edison') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Penn Power
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08016685944255333256
    if (utility.includes('penn power') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Pennsylvania Electric
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08009551374000655376
    if (utility.includes('pennsylvania electric') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // Toledo Edison
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08018899456122222386
    if (utility.includes('toledo edison') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // West Penn Power
    // PJM
    // SAN
    // 20 digit account number starting with "08"
    // 08056332360007222079
    if (utility.includes('west penn power') && market === 'PJM') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '20 digit account number starting with "08"',
            isValid: san.length === 20 && san.startsWith('08'),
        };
    }

    // AEP Central (AEP Texas Central)
    // ERCOT
    // ESI ID
    // 17 digit account number starting with "1003"
    // 10032789483259860
    if (utility.includes('aep central') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '17 digit account number starting with "1003"',
            isValid: san.length === 17 && san.startsWith('1003'),
        };
    }

    // AEP North (AEP Texas North)
    // ERCOT
    // ESI ID
    // 17 digit account number starting with "1020"
    // 10204049784322960
    if (utility.includes('aep north') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '17 digit account number starting with "1020"',
            isValid: san.length === 17 && san.startsWith('1020'),
        };
    }

    // CenterPoint
    // ERCOT
    // ESI ID
    // 22 digit account number starting with "1008"
    // 1008901001157033779100
    if (utility.includes('centerpoint') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '22 digit account number starting with "1008"',
            isValid: san.length === 22 && san.startsWith('1008'),
        };
    }

    // Oncor
    // ERCOT
    // ESI ID
    // 17-digit account number starting with "1044" or "1017"
    // 10443720004732506, 10176990006156247
    if (utility.includes('oncor') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '17 digit account number starting with "1044" or "1017"',
            isValid: san.length === 17 && (san.startsWith('1044') || san.startsWith('1017')),
        };
    }

    // TNMP
    // ERCOT
    // ESI ID
    // 17-digit account number starting with "1040"
    // 10400513111520001
    if (utility.includes('tnmp') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '17 digit account number starting with "1040"',
            isValid: san.length === 17 && san.startsWith('1040'),
        };
    }

    // Nueces Electric
    // ERCOT
    // ESI ID
    // 15-digit account number starting with "1013"
    // 101383000057067
    if (utility.includes('nueces electric') && market === 'ERCOT') {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.ESI_ID,
            suggestedFormat: '15 digit account number starting with "1013"',
            isValid: san.length === 15 && san.startsWith('1013'),
        };
    }

    // CPS Energy
    // ERCOT
    // ESI ID

    // Austin Energy
    // ERCOT
    // ESI ID

    // National Grid
    // NYISO

    // ConEd
    // NYISO
    // SAN
    // 15 digit account number - no specific identifier
    // 700321288500005
    if (
        (utility.includes('con edison solutions') || utility.includes('coned'))
        && market === 'NYISO'
    ) {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '15 digit account number',
            isValid: san.length === 15,
        };
    }

    // RGE
    // NYISO
    // No specified format - skip

    // NYSEG
    // NYISO
    // POD ID
    // No specified format - skip

    // PSEG-LI
    // NYISO
    // No specified format - skip

    // Central Hudson
    // NYISO
    // No specified format - skip

    // Orange and Rockland Utilities
    // NYISO
    // POD ID
    // No specified format - skip

    // TODO: We don't have a utility for this
    // Ameren MO
    // MISO
    // SAN
    // 10 digit account number - no specific identifier
    // 2962170012
    if (
        (utility.includes('ameren missouri') || utility.includes('ameren mo'))
        && market === 'MISO'
    ) {
        return {
            suggestedIdentifier: SAN_IDENTIFIER.SAN,
            suggestedFormat: '10 digit account number',
            isValid: san.length === 10,
        };
    }

    // TODO: We have only utility - Nevada Power Co
    // TODO: What means market - "WEST-Other"?
    // Nevada Energy
    // WEST-Other
    // SAN
    // 19 digit account number starting with "100" or "300"
    // 3000336853811240005, 1000034263801521666
    // if (utility.includes('Nevada Power Co') && market === 'WEST-Other') {
    //     return {
    //         suggestedIdentifier: SAN_IDENTIFIER.SAN,
    //         suggestedFormat: '19 digit account number starting with "100" or "300"',
    //         isValid: san.length === 19 && (san.startsWith('100') || san.startsWith('300')),
    //     };
    // }

    // Arizona Public Service
    // WEST-Other
    // SAN
    // 10 digit account number - no specific identifier
    // 3371235401
    // if (utility.includes('Arizona Public Service') && market === 'WEST-Other') {
    //     return {
    //         suggestedIdentifier: SAN_IDENTIFIER.SAN,
    //         suggestedFormat: '10 digit account number',
    //         isValid: san.length === 10,
    //     };
    // }

    return null;
}
