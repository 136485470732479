import { INyisoResource } from '../../../domain/nyiso/interface';
import { useNyisoResourceToggleMutation } from '../../../domain/nyiso/queries';
import Switch from 'antd/lib/switch';

interface IResourceEnableStateSwitchProps {
    resource: INyisoResource;
}

export const ResourceEnableStateSwitch = ({ resource }: IResourceEnableStateSwitchProps) => {
    const { mutateAsync: onResourceToggle, isLoading } = useNyisoResourceToggleMutation();
    const onToggle = async (state: boolean) => {
        try {
            resource.is_enabled = state;
            await onResourceToggle({ enable: state, ids: [resource.id] });
        } catch (err) {
            console.log('NYISO resource toggle failed with error: ', err);
        }
    };
    return <Switch disabled={isLoading} checked={resource.is_enabled} onChange={onToggle} />;
};
