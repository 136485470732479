import { IFetchPageQuery } from '../IFetchQueryOptions';
import { ISite } from '../site/interface';
import { UserType } from '../user/interface';
import { VenStatus } from '../ven/interface';

export enum SignalType {
    NORMAL = 0,
    MODERATE = 1,
    HIGH = 2,
    SPECIAL = 3,
}

export enum OptType {
    UNSET = -1,
    OPT_IN = 0,
    OPT_OUT = 1,
}

export enum SliceType {
    NONE = 'none',
    EVEN = 'even',
    TIME = 'time',
    COUNT = 'count',
    KW = 'kw',
}

export const SliceTypeLabel: { [key: string]: string } = {
    [SliceType.NONE]: 'None',
    [SliceType.EVEN]: 'Evenly',
    [SliceType.TIME]: 'Minutes',
    [SliceType.COUNT]: 'Count',
    [SliceType.KW]: 'KW',
};

export const signalTypes = [SignalType.NORMAL, SignalType.MODERATE, SignalType.HIGH, SignalType.SPECIAL];

export const SIGNAL_LEVEL_OPTIONS = [
    {
        label: 'Normal (0)',
        value: SignalType.NORMAL,
    },
    {
        label: 'Moderate (1)',
        value: SignalType.MODERATE,
    },
    {
        label: 'High (2)',
        value: SignalType.HIGH,
    },
    {
        label: 'Special (3)',
        value: SignalType.SPECIAL,
    },
];

export interface ISlice {
    type: SliceType;
    amount: number;
}

export interface IEventContext {
    programs?: string[];
    customer_ven?: {
        ven_id: string;
        status: VenStatus;
        date_online: string;
    };
}

export interface IEvent {
    event_id: number;
    site_id: number;
    site?: ISite;
    event_batch?: string;
    event_cancelled: boolean;
    event_datetime_start: Date;
    event_duration: number; // minutes
    event_emergency_generator_allowed: boolean;
    event_voluntary: boolean;
    event_external_event_id?: number;
    event_market_context?: string;
    event_modification_number?: number;
    event_opt_type?: OptType;
    event_post_duration: number;
    event_pre_duration: number;
    event_secondary_duration: number;
    event_signal: SignalType;
    event_test: boolean;
    event_datetime_created: Date;
    event_slice?: ISlice;
    event_source: string;
    event_context?: IEventContext;
    event_completed?: boolean;
}

export interface IFetchEventsRequestParams extends IFetchPageQuery {
    companyId?: null | number;
    programId?: any;
    site_utility?: string;
    event_completed?: boolean;
    siteId?: any;
    eventProgramName?: string | string[];
    event_cancelled?: boolean;
    event_opt_type?: boolean;
    start?: Date;
    end?: Date;
    startInRange?: boolean;
    load_zone?: string;
    market?: string | null;
}

export interface IExportEventsRequestParams extends IFetchPageQuery {
    companyId?: null | number;
    event_cancelled?: boolean;
    event_completed?: boolean;
    event_opt_type?: boolean;
    start?: Date;
    end?: Date;
    startInRange?: boolean;
    programId?: any;
    eventProgramName?: string | string[];
    load_zone?: string;
    market?: string | null;
    site_utility?: string;
}

export interface ITrackingAlert {
    company: string;
    user: {
        type: UserType;
        lname: string;
        fname: string;
        email: string;
        phone: string;
    } | null;
    event_datetime_start?: string;
    event_status?: string;
    sentAt: string;
    openedAt: string;
    confirmedAt: string | null;
    rejectedAt: string | null;
}

export interface ITrackingUser {
    value: string; // user_id
    text: string; // full name
}

export interface IGetEventsDataService {
    ids: number[];
    date: { start: Date; end: Date };
    company: number;
}

export interface IEventMetrics {
    locations: number;
    days: number;
    eventsCount: number;
    estimatedKWH: number;
    date_modified: string;
}

export interface IFetchResourcesRequestParams {
    companyId?: null | number;
    programId?: any;
    load_zone?: string;
    search?: string;
    market?: string;
}

export interface IEventsCountBySiteAndYears {
    [siteId: string]: {
        [year: string]: number;
    };
}
