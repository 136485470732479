import { ISite, IntervalDataStatus } from './interface';

export function getActiveSiteSan(site: ISite) {
    const activeSiteWithConnectionProblem = site.sans.find(san => {
        const status = san.san_info?.intervalDataState?.status;
        return (
            status &&
            san.is_active &&
            [IntervalDataStatus.CONNECTED_BUT_HAS_PROBLEM, IntervalDataStatus.INVALID_AGREEMENT].includes(status)
        );
    });
    if (activeSiteWithConnectionProblem) return activeSiteWithConnectionProblem;

    const activeConnectedSite = site.sans.find(
        san => san.is_active && san.san_info?.intervalDataState?.status === IntervalDataStatus.CONNECTED
    );
    if (activeConnectedSite) return activeConnectedSite;

    // Find any active san
    return site.sans.find(san => san.is_active);
}
