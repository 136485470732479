export const IMPACT_TYPE = {
    ANY_PROGRAM: 'any_program',
    PRICE_RESPONSE: 'price_response',
    CLEAN_RESPONSE: 'clean_response',
};

export interface IMetrics {
    locations: number;
    eventsHours: number;
    co2Impact: number;
    capacity: number;
}

interface IComparisons {
    acresOfForest: number;
    homeElectricityPerYear: number;
    passengerVehicleMiles: number;
    passengerVehicle: number;
    poundsOfCoalBurned: number;
    seedlingPlanted: number;
}

export interface IMetricsByPeriod {
    [key: string]: IMetrics | null;
}

export interface IRawImpact {
    metrics: { [key: string]: IMetricsByPeriod };
    comparisons: IComparisons;
    year: number;
}

export interface IImpact {
    metrics: IMetricsByPeriod;
    comparisons: IComparisons;
}
