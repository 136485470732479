import Layout from 'antd/lib/layout';
import { Outlet } from 'react-router-dom';
import logoImg from '../../logo.png';

export const PublicLayout = () => (
    <Layout>
        <Layout.Header className="header">
            <img className="app-logo" src={logoImg} alt="Enersponse logo" />
        </Layout.Header>
        <Layout.Content className="layout-content">
            <Outlet />
        </Layout.Content>
    </Layout>
);
