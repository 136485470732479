import { useQuery } from '@tanstack/react-query';
import { IFetchVenLogsQuery, IVenLogsResponse } from './interface';
import { QueryKey } from 'src/query-client';
import { API } from 'src/domain/api';

export const fetchCloudVensLogs = async ({ id, start, end }: IFetchVenLogsQuery): Promise<IVenLogsResponse> => {
    const path = `/cloud-ven/${id}/logs?${new URLSearchParams({
        start: start.toISOString(),
        end: end.toISOString()
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody
    }

    throw new Error(respBody?.error?.message || `Cannot get cloud VEN logs!`);
};

export const useCloudVenLogsQuery = (query: IFetchVenLogsQuery, options = {}) => {
    return useQuery<IVenLogsResponse, Error>({
        queryKey: [QueryKey.CLOUD_VEN_LOGS, query],
        queryFn: () => fetchCloudVensLogs(query),
        ...options,
    });
};