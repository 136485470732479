import React from 'react';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';

export const ContentBlock = ({
    value,
    description,
    descriptionClass = '',
    valueClass = '',
}: {
    value: number | string | React.ReactNode;
    description: string | React.ReactNode;
    descriptionClass?: string;
    valueClass?: string;
}) => (
    <Row justify="center" align="middle">
        <div style={{ textAlign: 'center' }}>
            <p style={{ marginBottom: 0 }} className={valueClass}>
                {value}
            </p>
            <Typography.Text className={descriptionClass}>{description}</Typography.Text>
        </div>
    </Row>
);
