import config from '../../config';

interface IOpenWeatherIcon {
    id: number;
    main: string;
    description: string;
    icon: string;
}

interface IOpenWeatherMinutely {
    dt: number;
    precipitation: number;
}

interface IOpenWeatherItem {
    dt: number;
    sunrise: number;
    sunset: number;
    temp: number;
    feels_like: number;
    pressure: number;
    humidity: number;
    dew_point: number;
    uvi: number;
    clouds: number;
    visibility: number;
    wind_speed: number;
    wind_deg: number;
    wind_gust: number;
    weather: IOpenWeatherIcon[];
    pop?: number;
}

interface IOpenWeatherItemDaily {
    dt: number;
    sunrise: number;
    sunset: number;
    moonrise: number;
    moonset: number;
    moon_phase: number;
    temp: {
        day: number;
        min: number;
        max: number;
        night: number;
        eve: number;
        morn: number;
    };
    feels_like: {
        day: number;
        night: number;
        eve: number;
        morn: number;
    };
    pressure: number;
    humidity: number;
    dew_point: number;
    wind_speed: number;
    wind_deg: number;
    wind_gust: number;
    weather: IOpenWeatherIcon[];
    clouds: number;
    pop: number;
    uvi: number;
    summary: string;
}

interface IOpenWeatherResponse {
    lat: number;
    lon: number;
    timezone: string;
    timezone_offset: number;
    current: IOpenWeatherItem | null;
    minutely: IOpenWeatherMinutely[] | null;
    hourly: IOpenWeatherItem[] | null;
    daily: IOpenWeatherItemDaily[];
    alerts: IOpenWeatherAlert[] | null;
}

interface IOpenWeatherAlert {
    sender_name: string;
    event: string;
    start: number;
    end: number;
    description: string;
    tags: string[];
}

export interface IWeatherForecastDaily {
    dayName: string;
    temperature: number;
    description: string;
    icon?: string;
    drChance: ForecastChanceDREvent;
}

export enum ForecastChanceDREvent {
    NONE = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

const shortDayName = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Today'];

// const weatherConditionsNoaa = [
//     ["skc", "wi-day-sunny"],                // Fair/clear
//     ["few", "wi-day-cloudy"],               // A few clouds
//     ["sct", "wi-day-cloudy"],               // Partly cloudy
//     ["bkn", "wi-day-cloudy"],               // Mostly cloudy
//     ["ovc", "wi-day-sunny-overcast"],       // Overcast
//     ["wind_skc", "wi-day-windy"],           // Fair/clear and windy
//     ["wind_few", "wi-day-cloudy-gusts"],    // A few clouds and windy
//     ["wind_sct", "wi-day-cloudy-gusts"],    // Partly cloudy and windy
//     ["wind_bkn", "wi-day-cloudy-gusts"],    // Mostly cloudy and windy
//     ["wind_ovc", "wi-day-cloudy-gusts"],    // Overcast and windy
//     ["snow", "wi-snow"],                    // Snow
//     ["rain_snow", "wi-rain-mix"],           // Rain/snow
//     ["rain_sleet", "wi-rain"],              // Rain/sleet
//     ["snow_sleet", "wi-sleet"],             // Rain/sleet
//     ["fzra", "wi-snow"],                    // Freezing rain
//     ["rain_fzra", "wi-rain"],               // Rain/freezing rain
//     ["snow_fzra", "wi-snow"],               // Freezing rain/snow
//     ["sleet", "wi-sleet"],                  // Sleet
//     ["rain", "wi-rain"],                    // Rain
//     ["rain_showers", "wi-day-rain"],        // Rain showers (high cloud cover)
//     ["rain_showers_hi", "wi-day-rain"],     // Rain showers (low cloud cover)
//     ["tsra", "wi-thunderstorm"],            // Thunderstorm (high cloud cover)
//     ["tsra_sct", "wi-thunderstorm"],        // Thunderstorm (medium cloud cover)
//     ["tsra_hi", "wi-thunderstorm"],         // Thunderstorm (low cloud cover)
//     ["tornado", "wi-tornado"],              // Tornado
//     ["hurricane", "wi-hurricane"],          // Hurricane conditions
//     ["tropical_storm", "wi-rain"],          // Tropical storm conditions

//     ["dust", "wi-dust"],                    // Dust
//     ["smoke", "wi-smoke"],                  // Smoke
//     ["haze", "wi-day-haze"],                // Haze
//     ["hot", "wi-hot"],                      // Hot
//     ["cold", "wi-snowflake-cold"],          // Cold
//     ["blizzard", "wi-sleet"],               // Blizzard
//     ["fog", "wi-fog"]                       // Fog/mist
// ];

const weatherConditionsOpenWeather = [
    ['01d', 'wi-day-sunny', 'clear sky'],
    ['02d', 'wi-day-cloudy', 'few clouds'],
    ['03d', 'wi-cloud', 'scattered clouds'],
    ['04d', 'wi-cloudy', 'broken clouds'],
    ['09d', 'wi-showers', 'shower rain'],
    ['10d', 'wi-day-rain-mix', 'rain'],
    ['11d', 'wi-thunderstorm', 'thunderstorm'],
    ['13d', 'wi-snowflake-cold', 'snow'],
    ['50d', 'wi-fog', 'mist'],
    ['01n', 'wi-night-clear', 'clear sky'],
    ['02n', 'wi-night-alt-cloudy', 'few clouds'],
    ['03n', 'wi-cloud', 'scattered clouds'],
    ['04n', 'wi-cloudy', 'broken clouds'],
    ['09n', 'wi-showers', 'shower rain'],
    ['10n', 'wi-night-rain-mix', 'rain'],
    ['11n', 'wi-thunderstorm', 'thunderstorm'],
    ['13n', 'wi-snowflake-cold', 'snow'],
    ['50n', 'wi-fog', 'mist'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with light rain'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with rain'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with heavy rain'],
    ['11d', 'wi-thunderstorm', 'light thunderstorm'],
    ['11d', 'wi-thunderstorm', 'thunderstorm'],
    ['11d', 'wi-thunderstorm', 'heavy thunderstorm'],
    ['11d', 'wi-thunderstorm', 'ragged thunderstorm'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with light drizzle'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with drizzle'],
    ['11d', 'wi-thunderstorm', 'thunderstorm with heavy drizzle'],
    ['09d', 'wi-showers', 'light intensity drizzle'],
    ['09d', 'wi-showers', 'drizzle'],
    ['09d', 'wi-showers', 'heavy intensity drizzle'],
    ['09d', 'wi-showers', 'light intensity drizzle rain'],
    ['09d', 'wi-showers', 'drizzle rain'],
    ['09d', 'wi-showers', 'heavy intensity drizzle rain'],
    ['09d', 'wi-showers', 'shower rain and drizzle'],
    ['09d', 'wi-showers', 'heavy shower rain and drizzle'],
    ['09d', 'wi-showers', 'shower drizzle'],
    ['10d', 'wi-day-rain-mix', 'light rain'],
    ['10d', 'wi-day-rain-mix', 'moderate rain'],
    ['10d', 'wi-day-rain-mix', 'heavy intensity rain'],
    ['10d', 'wi-day-rain-mix', 'very heavy rain'],
    ['10d', 'wi-day-rain-mix', 'extreme rain'],
    ['13d', 'wi-snowflake-cold', 'freezing rain'],
    ['09d', 'wi-showers', 'light intensity shower rain'],
    ['09d', 'wi-showers', 'shower rain'],
    ['09d', 'wi-showers', 'heavy intensity shower rain'],
    ['09d', 'wi-showers', 'ragged shower rain'],
    ['13d', 'wi-snowflake-cold', 'light snow'],
    ['13d', 'wi-snowflake-cold', 'Snow'],
    ['13d', 'wi-snowflake-cold', 'Heavy snow'],
    ['13d', 'wi-snowflake-cold', 'Sleet'],
    ['13d', 'wi-snowflake-cold', 'Light shower sleet'],
    ['13d', 'wi-snowflake-cold', 'Shower sleet'],
    ['13d', 'wi-snowflake-cold', 'Light rain and snow'],
    ['13d', 'wi-snowflake-cold', 'Rain and snow'],
    ['13d', 'wi-snowflake-cold', 'Light shower snow'],
    ['13d', 'wi-snowflake-cold', 'Shower snow'],
    ['13d', 'wi-snowflake-cold', 'Heavy shower snow'],
    ['50d', 'wi-fog', 'mist'],
    ['50d', 'wi-fog', 'Smoke'],
    ['50d', 'wi-fog', 'Haze'],
    ['50d', 'wi-fog', 'sand/ dust whirls'],
    ['50d', 'wi-fog', 'fog'],
    ['50d', 'wi-fog', 'sand'],
    ['50d', 'wi-fog', 'dust'],
    ['50d', 'wi-fog', 'volcanic ash'],
    ['50d', 'wi-fog', 'squalls'],
    ['50d', 'wi-fog', 'tornado'],
    ['01d', 'wi-day-sunny', 'clear sky'],
    ['01n', 'wi-night-clear', 'clear sky'],
    ['02d', 'wi-day-cloudy', 'few clouds: 11-25%'],
    ['02n', 'wi-night-alt-cloudy', 'few clouds: 11-25%'],
    ['03d', 'wi-cloud', 'scattered clouds: 25-50%'],
    ['03n', 'wi-cloud', 'scattered clouds: 25-50%'],
    ['04d', 'wi-cloudy', 'broken clouds: 51-84%'],
    ['04n', 'wi-cloudy', 'broken clouds: 51-84%'],
    ['04d', 'wi-cloudy', 'overcast clouds: 85-100%'],
    ['04n', 'wi-cloudy', 'overcast clouds: 85-100%'],
];

export class OpenWeatherProvider {
    async getForecast(lat: number, lng: number): Promise<IWeatherForecastDaily[]> {
        const forecast_url = `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lng}&units=imperial&exclude=alerts,hourly,minutely,current&appid=${config.WEATHER_API_KEY}`;
        const response = await fetch(forecast_url, {});

        if (response.status !== 200) {
            return [];
        }

        const body: IOpenWeatherResponse = await response.json();

        const forecast = body.daily.map(day => this.transform(day));
        return forecast;
    }

    private transform(day: IOpenWeatherItemDaily): IWeatherForecastDaily {
        const date = new Date(day['dt'] * 1000).getDay();
        const dayName = shortDayName[date];
        const temperature = Math.round(day.temp.max);
        const description = day.weather[0]['description'];

        // @ts-ignore
        const weatherIconConversions = new Map<string, string>(weatherConditionsOpenWeather);
        const icon = weatherIconConversions.get(day.weather[0]['icon']);

        const drChance = this.getForecastChanceOfDREvent(dayName, temperature);

        return {
            dayName,
            temperature,
            description,
            icon,
            drChance,
        };
    }

    private getForecastChanceOfDREvent(dayName: string, temperature: number): ForecastChanceDREvent {
        const THRESHOLD_HIGH = 95;
        const THRESHOLD_MED = 90;
        const THRESHOLD_MED_LOW = 32;

        if (['sat', 'sun'].includes(dayName.toLowerCase())) {
            return ForecastChanceDREvent.NONE;
        }

        if (temperature > THRESHOLD_HIGH) {
            return ForecastChanceDREvent.HIGH;
        }

        if (temperature >= THRESHOLD_MED_LOW && temperature <= THRESHOLD_MED) {
            return ForecastChanceDREvent.LOW;
        }

        return ForecastChanceDREvent.MEDIUM;
    }
}
