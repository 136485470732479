import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PageHeader } from 'src/components/pageHeader/pageHeader';
import { useCompanies } from 'src/components/selectors/globalCompanySelector/CompanyContext';
import { useDocumentTitle } from 'src/components/useDocumentTitle';
import { useAuth } from 'src/domain/auth/useAuth';
import { CAISO_MODE } from '../interface';
import { caisoViewOptions, ICaiso } from './interface';
import { CaisoSwitcher } from 'src/components/caisoSwitcher/CaisoSwitcher';
import { AbilityContext } from 'src/components/ability/can';

export function Caiso() {
    const { pathname } = useLocation();
    const currentView = (pathname.split('/')[2] as CAISO_MODE) || CAISO_MODE.SIBR;

    useDocumentTitle('CAISO');

    const auth = useAuth()!;
    const { company, companies } = useCompanies()!;
    const navigate = useNavigate();
    const ability = useContext(AbilityContext);

    const [view, setView] = useState(currentView);
    const companyQuery = company ? `?companyId=${company}` : '';

    useEffect(() => {
        setView(currentView);
    }, [currentView]);

    const handleViewMode = (newView: CAISO_MODE) => {
        navigate(`/caiso/${newView}${companyQuery}`);
    };

    return (
        <>
            <PageHeader
                pageTitle="CAISO"
                extra={[
                    <CaisoSwitcher
                        viewOptions={caisoViewOptions(ability)}
                        key="caiso-view-switcher"
                        view={view}
                        handleViewMode={handleViewMode}
                    />,
                ]}
            />
            <Outlet context={{ view, handleViewMode, auth, company, companies } as ICaiso} />;
        </>
    );
}
