import format from 'date-fns/format';
import omit from 'lodash/omit';
import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ISettlementFile } from './interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

export const fetchSettlements = async (
    { pagination, sorter, filter, company_id = null, include }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ISettlementFile>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/settlement-files?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(company_id && { company_id }),
        ...filter,
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of files!`);
};

export const exportSettlements = async ({ company_id = null }: IFetchPageQuery) => {
    const response = await API.fetch(
        `/settlement-files?${new URLSearchParams({
            format: 'csv',
            include: 'company',
            ...(company_id && { company_id }),
        })}`,
        {
            headers: {
                'Content-Type': 'text/csv',
            },
        }
    );

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `settlement-files--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const deleteSettlement = async (settlement: ISettlementFile) => {
    const response = await API.fetch(`/settlement-files/${settlement.settlement_file_id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete settlement!`);
};

export const createSettlement = async (settlement: ISettlementFile) => {
    const response = await API.fetch(`/settlement-files`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(settlement, ['settlement_file_id'])),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save settlement!`);
};

export const fetchLatestSettlements = async (timestamp: number): Promise<ISettlementFile[]> => {
    const response = await API.fetch(`/settlement-files/latest?timestamp=${timestamp}`, {
        headers: { 'Content-Type': 'application/json' },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    return [];
};
