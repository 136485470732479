import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input/Input';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import Dragger from 'antd/lib/upload/Dragger';
import { PRIMARY_COLOR } from '../../../theme';
import Row from 'antd/lib/row';
import { useState } from 'react';
import Col from 'antd/lib/col';
import { useLocation } from 'react-router-dom';
import { App, Button, Upload, UploadFile } from 'antd/lib';
import { createFeedback } from '../../../domain/feedback';
import { IFeedback } from '../../../domain/feedback/interface';

interface IFeedbackModal {
    onClose: Function;
}

const initialFeedbackValues = {
    title: '',
    description: '',
    otherInfo: '',
};

const ALLOWED_FILE_SIZE = 4;

export const FeedbackModal = ({ onClose }: IFeedbackModal) => {
    const { notification } = App.useApp();
    let { pathname, search } = useLocation();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const closeFeedbackModal = () => onClose();

    const uploadProps = {
        multiple: true,
        maxCount: 5,
        onRemove: (file: UploadFile) => {
            const filesList = (files || []).filter(f => f?.uid !== file.uid);
            setFiles(filesList);
        },
        beforeUpload: (file: UploadFile) => {
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE}MB.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: { file: UploadFile; fileList: UploadFile[] }) => {
            if (info.file.status) {
                return;
            }
            setFiles(info.fileList.map(f => (f.originFileObj ? f.originFileObj : f)));
        },
        fileList: files,
    };

    const onSubmit = async (feedback: IFeedback) => {
        const preparedFeedback = {
            ...feedback,
            files,
            feedbackUrl: `${pathname}${search}`,
        };
        try {
            setLoading(true);
            await createFeedback(preparedFeedback);
            notification.info({ key: 'feedback-create-info', message: 'Feedback created.' });
            onClose();
        } catch (error: any) {
            notification.error({ key: 'feedback-create-error', message: error.message || 'Cannot create feedback!' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            footer={[
                <Button key="feedback-modal-cancel" onClick={closeFeedbackModal}>
                    Cancel
                </Button>,
                <Button key="feedback-modal-submit" type="primary" onClick={form.submit} loading={loading}>
                    Send
                </Button>,
            ]}
            open
            destroyOnClose
            title="Share your feedback"
            onCancel={closeFeedbackModal}
            data-cy="feedback-modal"
        >
            <Form layout="vertical" name="feedback-form" form={form} onFinish={onSubmit} preserve={false} initialValues={initialFeedbackValues}>
                <Form.Item
                    name="title"
                    required
                    hasFeedback
                    label={<Typography.Text strong>Summary</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter summary!' },
                        { max: 235, message: 'Number of characters should be less than 235' },
                    ]}
                >
                    <Input size="large" placeholder="Briefly describe the enhancement or request" />
                </Form.Item>
                <Form.Item
                    required
                    name="description"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter description!' },
                        { max: 31000, message: 'Number of characters should be less than 31000' },
                    ]}
                    label={<Typography.Text strong>Provide a detailed description of the Feature/Enhancement request</Typography.Text>}
                >
                    <TextArea size="large" rows={8} placeholder="Provide more information here" />
                </Form.Item>
                <Form.Item
                    rules={[{ max: 512, message: 'Number of characters should be less than 512' }]}
                    name="otherInfo"
                    label={<Typography.Text strong>Any other information to share?</Typography.Text>}
                >
                    <TextArea size="large" placeholder="Any additional details you'd like to share" />
                </Form.Item>
                <Form.Item label={<Typography.Text strong>Upload any relevant files (optional)</Typography.Text>}>
                    <Dragger {...uploadProps} height={120}>
                        <Row align="middle" justify="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Col xs={24}>
                                <InboxOutlined style={{ fontSize: '20px', marginRight: '4px', color: PRIMARY_COLOR }} />
                                <Typography.Text>Click or drag file to this area to upload</Typography.Text>
                            </Col>
                        </Row>
                    </Dragger>
                    <Typography.Text type="secondary" style={{ display: 'block' }}>*You can upload a maximum of 5 files, each file can be up to 4MB</Typography.Text>
                </Form.Item>
            </Form>
        </Modal>
    );
};
