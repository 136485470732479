import { ReactNode } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { ConfirmationModal } from '../../../components/modals/confirmationModal/confirmationModal';
import { FORM_ACTION } from '../eventInterface';

interface IEventConfirmationModal {
    action: FORM_ACTION;
    handleConfirm: () => void;
    handleClose: () => void;
    modalContent: ReactNode;
}

export const EventConfirmationModal = ({ handleConfirm, handleClose, action, modalContent }: IEventConfirmationModal) => {
    return (
        <ConfirmationModal onCancel={handleClose} onConfirm={handleConfirm} danger={[FORM_ACTION.DELETE, FORM_ACTION.DELETE_BATCH].includes(action)}>
            <Row gutter={16} style={{ marginBottom: '8px' }} align="middle">
                <Col span={2}>
                    <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '20px' }} />
                </Col>

                <Col span={22}>{modalContent}</Col>
            </Row>
        </ConfirmationModal>
    );
};
