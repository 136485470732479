import { API } from '../api';
import { ICarbonIntensity } from './interface';

export const fetchCarbonIntensity = async (signal?: AbortSignal | null): Promise<ICarbonIntensity[]> => {
    const response = await API.fetch('/carbon-intensity', {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot fetch data!`);
};
