import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import { ConfirmationModal } from '../../components/modals/confirmationModal/confirmationModal';
import { changePassword } from '../../domain/user';
import { IUser } from '../../domain/user/interface';
import App from 'antd/lib/app';

interface IChangePasswordModal {
    onCloseModal: Function;
    user: IUser | undefined;
}

export const ChangePasswordModal = ({ onCloseModal, user }: IChangePasswordModal) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();

    const onFinish = async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
        try {
            await changePassword({ oldPassword, newPassword, userId: user?.user_id! });
            notification.info({ key: 'change-password-modal', message: 'Password updated!' });
            onCloseModal();
        } catch (error: any) {
            notification.error({ key: 'change-password-error', message: error.message || 'Cannot change password!' });
        }
    };

    return (
        <ConfirmationModal onCancel={onCloseModal} onConfirm={form.submit} confirmCountdown={0}>
            <Form layout="vertical" form={form} name="update-password-form" preserve={false} onFinish={onFinish}>
                <Form.Item
                    hasFeedback
                    name="oldPassword"
                    label={<Typography.Text strong>Old Password</Typography.Text>}
                    rules={[{ required: true, message: 'Old password is required!' }]}
                >
                    <Input.Password placeholder="old password" size="large" autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label={<Typography.Text strong>New Password</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter your password!' },
                        {
                            pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                            message:
                                'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="input password" autoComplete="new-password" size="large" />
                </Form.Item>
                <Form.Item
                    name="confirmNewPassword"
                    label={<Typography.Text strong>Confirm Password</Typography.Text>}
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="input password" autoComplete="new-password" size="large" />
                </Form.Item>
            </Form>
        </ConfirmationModal>
    );
};
