import { INyisoPeakHour, NYISO_PEAK_HOURS_TIME_FORMAT } from '../../../domain/nyiso/interface';
import groupBy from 'lodash/groupBy';
import Select from 'antd/lib/select';
import { useMemo, useState } from 'react';
import Descriptions, { DescriptionsProps } from 'antd/lib/descriptions';
import { utcToZonedTime } from 'date-fns-tz';
import { getComputerFormattedTime } from '../../../domain/date/date';
import { NyisoLoadZones } from '../../../domain/nyiso/NyisoFormData';

interface IPeakHoursDataPreviewProps {
    peakHours: INyisoPeakHour[];
}
export const PeakHoursDataPreview = ({ peakHours }: IPeakHoursDataPreviewProps) => {
    const [zone, selectZone] = useState<string>(NyisoLoadZones[0].value);
    const preparedData = useMemo(() => {
        return groupBy(peakHours, 'zone');
    }, [peakHours]);

    const renderDescription = (data: INyisoPeakHour[]) => {
        const items: DescriptionsProps['items'] = [];
        data.forEach(item =>
            items.push({
                key: item.top_40_hour.toString(),
                label: item.top_40_hour.toString(),
                children: (
                    <span>
                        {getComputerFormattedTime(
                            utcToZonedTime(item.datetime, 'America/New_York'),
                            NYISO_PEAK_HOURS_TIME_FORMAT
                        )}
                    </span>
                ),
            })
        );

        return (
            <Descriptions
                title="Uploaded Peak Hours"
                bordered
                column={2}
                contentStyle={{ padding: '2px 16px', height: '36px' }}
                labelStyle={{ padding: '2px 16px', textAlign: 'center', height: '36px' }}
                items={items}
            />
        );
    };

    return (
        <>
            <Select
                style={{ width: '100%', marginBottom: '24px' }}
                placeholder="Select Zone"
                options={NyisoLoadZones}
                onChange={value => selectZone(value)}
                defaultValue={zone}
                size="large"
            />

            {renderDescription(preparedData[zone])}
        </>
    );
};
