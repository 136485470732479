import flatten from 'lodash/flatten';
import { getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { SiteUtility } from '../../components/site/SiteUtility';
import { ColumnsType } from 'antd/lib/table';
import { ISite } from './interface';
import { compareWithLocale } from '../compareWithLocale';

export const getUtilityFilterValue = (state: any) => {
    let { filteredValue } = getColumnFilteredValue('site_utility', state?.filter || {});
    if (Array.isArray(filteredValue)) {
        filteredValue = flatten(filteredValue).map(utilityName => utilityName.toString());
    }
    return filteredValue;
};

export function buildSiteUtilityTableFilter(state: any, utilities: string[]) {
    const column: ColumnsType<ISite>[number] = {
        title: 'Utility',
        dataIndex: 'site_utility',
        sorter: (a, b) => compareWithLocale(a.site_utility, b.site_utility),
        ...getColumnSortOrder('site_utility', state.sorter),
        filters: utilities.map(utility => ({ text: utility, value: utility })),
        filteredValue: getUtilityFilterValue(state),
        filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
        render: (text, _record) => <SiteUtility siteUtility={text} />,
    };

    return column;
}
