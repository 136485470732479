import React from 'react';
import flatten from 'lodash/flatten';
import { getColumnFilteredValue } from '../../components/table/columnFormatHelpers';
import { SiteEnrollments } from '../../components/site/SiteEnrollments';
import { buildProgramFilter } from './buildProgramFilter';
import { IProgram } from './interface';

export const getProgramFilterValue = (state: any) => {
    let { filteredValue } = getColumnFilteredValue('program_id', state?.filter || {});
    if (Array.isArray(filteredValue)) {
        filteredValue = flatten(filteredValue).map(programId => programId.toString());
    }
    return filteredValue;
};

export function buildProgramTableFilter(state: any, programs: IProgram[], title = 'Program') {
    return {
        title,
        dataIndex: 'program_id',
        ellipsis: { showTitle: false },
        filters: buildProgramFilter(programs),
        filteredValue: getProgramFilterValue(state),
        filterSearch: (input: string, record: any) => record?.fullText?.toLowerCase().includes(input.toLowerCase()),
        render: (text: string, record: any) => <SiteEnrollments value={record} />,
    };
}
