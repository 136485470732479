import Flex from 'antd/lib/flex';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { ISan } from '../../domain/site/interface';
import { SuggestedFormatIcon } from './SuggestedSanFormatIcon';
import { removeNonUTF8ASCII } from '../../domain/stringCleaner';

interface ISiteSANProps {
    market: string | null;
    utility: string | null;
    sans: ISan[];
}

export const SiteSANs = ({ market, utility, sans }: ISiteSANProps) => {
    const sortedSans = useMemo(() => orderBy(sans, ['is_active', 'service_account_number'], ['desc', 'asc']), [sans]);

    const sanitazedSAN = useMemo(() => {
        const _sans = sortedSans.map(san => {
            return {
                ...san,
                service_account_number: removeNonUTF8ASCII(san.service_account_number),
            };
        });
        return _sans;
    }, [sortedSans]);

    return (
        <Flex vertical>
            {sanitazedSAN.map(san => (
                <Flex key={san.service_account_number}>
                    <Tooltip placement="left" title={san.service_account_number}>
                        <Typography.Text ellipsis type={san.is_active ? undefined : 'secondary'}>
                            {san.service_account_number}
                        </Typography.Text>
                    </Tooltip>

                    <SuggestedFormatIcon utility={utility} market={market} san={san.service_account_number} />
                </Flex>
            ))}
        </Flex>
    );
};
