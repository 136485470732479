export interface ICaisoSync {
    id: string;
    date: string;
    is_lambda_sync: boolean;
    requester?: {
        id: string;
        name: string;
        email: string;
    };
    status: 'Pending' | 'Success' | 'Failed';
    error_message?: string;
}

export enum CaisoSyncOrigin {
    locations = 'locations',
    registrations = 'registrations',
}
