import React from 'react';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import { PRIMARY_COLOR } from '../../../../theme';

export const LocalMWMapLegends = () => {
    return (
        <div className='map-legend'>
            <Typography.Text>Local MW Price&nbsp;&nbsp;</Typography.Text>
            <Tag color='#ad4093'>&lt; -$50</Tag>
            <Tag color='#2771ad'>&lt; -$20</Tag>
            <Tag color='#52affa'>&lt; $0</Tag>
            <Tag color={PRIMARY_COLOR}>normal</Tag>
            <Tag color='#fac42a'>&gt;$60</Tag>
            <Tag color='#fa962a'>&gt;$120</Tag>
            <Tag color='#fa675c'>&gt;$200</Tag>
            <Tag color='#ad4840'>&gt;$500</Tag>
        </div>
    );
};
