import { Navigate, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AntApp from 'antd/lib/app';
import ConfigProvider from 'antd/lib/config-provider';
import { PrivateRoute, ProvideAuth } from './domain/auth/useAuth';
import { Dashboard } from './pages/dashboard/dashboard';
import { Vens as VenDevices } from './pages/ven/vens';
import { Events } from './pages/events/events';
import { Settlements } from './pages/settlements/settlements';
import { Sites } from './pages/sites/Sites';
import { Users } from './pages/users/users';
import { Login } from './pages/login/login';
import { ResetPassword } from './pages/resetPassword/resetPassword';
import { ResetPasswordComplete } from './pages/resetPasswordComplete/resetPasswordComplete';
import { AuthLayout } from './layouts/auth/AuthLayout';
import { PrivateLayout } from './layouts/private/PrivateLayout';
import { Companies } from './pages/companies/Companies';
import { PriceResponse } from './pages/priceResponse/PriceResponse';
import { CleanResponse } from './pages/cleanResponse/CleanResponse';
import { Impact } from './pages/impact/impact';
import { GreenButtonConnect } from './components/greenButtonConnect/greenButtonConnect';
import { PublicLayout } from './layouts/public/PublicLayout';
import { CompleteSignup } from './pages/completeSignup/completeSignup';
import { CloudVenView, CustomerVenView, OverviewView } from './pages/ven/view';
import { ReCaptchaProvider } from './domain/auth/recaptcha';
import { PriceResponseReporting } from './pages/priceResponseReporting/priceResponseReporting';
import { Programs } from './pages/programs/Programs';
import { EnergyUsageDataSummary } from './pages/energyUsageData/view/EnergyUsageDataSummary';
import { EnergyUsageDataConnect } from './pages/energyUsageData/view/EnergyUsageDataConnect';
import { EnergyUsageDataAnalytics } from './pages/energyUsageData/view/EnergyUsageDataAnalytics';
import { queryClient } from './query-client';
import theme from './theme';
import './App.css';
import { Caiso } from './pages/caiso/caiso';
import { CAISO_MODE } from './pages/interface';
import { SibrView, DrrsView } from './pages/caiso/view';
import { NyisoResourcesList } from './pages/nyiso/NyisoResourcesList';

export function App() {
    return (
        <ConfigProvider theme={theme}>
            <AntApp>
                <div className="App">
                    <QueryClientProvider client={queryClient}>
                        <CookiesProvider>
                            <ReCaptchaProvider>
                                <ProvideAuth>
                                    <Routes>
                                        <Route
                                            path="/:url*(/+)"
                                            element={<Navigate to={document.location.pathname.slice(0, -1)} />}
                                        />
                                        <Route element={<AuthLayout />}>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/reset-password" element={<ResetPassword />} />
                                            <Route
                                                path="/reset-password/complete/token/:token"
                                                element={<ResetPasswordComplete />}
                                            />
                                            <Route path="/complete-signup/token/:token" element={<CompleteSignup />} />
                                        </Route>
                                        <Route element={<PublicLayout />}>
                                            <Route path="/greenbutton" element={<GreenButtonConnect />} />
                                        </Route>
                                        <Route element={<PrivateRoute />}>
                                            <Route element={<PrivateLayout />}>
                                                <Route path="/companies" element={<Companies />} />
                                                <Route path="/sites" element={<Sites />} />
                                                <Route path="/ven-devices" element={<VenDevices />}>
                                                    <Route index element={<Navigate to="/" replace />} />
                                                    <Route path="overview" element={<OverviewView />} />
                                                    <Route path="cloud-ven" element={<CloudVenView />} />
                                                    <Route path="customer-ven" element={<CustomerVenView />} />
                                                </Route>
                                                <Route
                                                    path="/energy-usage-data/summary"
                                                    element={<EnergyUsageDataSummary />}
                                                />
                                                <Route
                                                    path="/energy-usage-data/connect"
                                                    element={<EnergyUsageDataConnect />}
                                                />
                                                <Route
                                                    path="/energy-usage-data/analytics"
                                                    element={<EnergyUsageDataAnalytics />}
                                                />
                                                <Route
                                                    path="/reporting/price-response"
                                                    element={<PriceResponseReporting />}
                                                />
                                                {/*<Route path="/reporting/power-bi" element={<PowerBIEmbeddedReport />} />*/}
                                                <Route path="/settlements" element={<Settlements />} />
                                                <Route path="/events" element={<Events />} />
                                                <Route path="/programs" element={<Programs />} />
                                                <Route path="/trigger/price-response" element={<PriceResponse />} />
                                                <Route path="/trigger/clean-response" element={<CleanResponse />} />
                                                <Route path="/users" element={<Users />} />
                                                <Route path="/impact" element={<Impact />} />
                                                <Route path="/caiso" element={<Caiso />}>
                                                    <Route index element={<Navigate to="/" replace />} />
                                                    <Route path={CAISO_MODE.SIBR} element={<SibrView />} />
                                                    <Route path={CAISO_MODE.DRRS} element={<DrrsView />} />
                                                </Route>
                                                <Route path="/nyiso-resources" element={<NyisoResourcesList />} />
                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="*" element={<Navigate to="/" replace />} />
                                            </Route>
                                        </Route>
                                    </Routes>
                                </ProvideAuth>
                            </ReCaptchaProvider>
                        </CookiesProvider>

                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </div>
            </AntApp>
        </ConfigProvider>
    );
}
