import { API } from '../api';
import { dateToUtcLocale } from '../common/timeHelpers';
import { IFetchMarketZonePriceQuery, IMarketZonePrice, IRealTimeNodesPrice, IRealTimeZonesPrice } from './interface';

export const fetchRealTimePrice = async (signal?: AbortSignal | null): Promise<(IRealTimeZonesPrice | IRealTimeNodesPrice)[]> => {
    const path = `/market-prices/real-time`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load market prices!`);
};

export const fetchPrices = async ({
    start,
    end,
    market,
    zone,
}: IFetchMarketZonePriceQuery, signal?: AbortSignal | null): Promise<(IMarketZonePrice)[]> => {
    const path = `/market-prices?${new URLSearchParams({
        ...(market && { market }),
        ...(zone && { loadZone: zone }),
        start: dateToUtcLocale(start).toISOString(),
        end: dateToUtcLocale(end).toISOString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load market prices!`);
};