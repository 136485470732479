import { getColumnFilteredValue } from 'src/components/table/columnFormatHelpers';
import { ICaisoLocation } from './locations/interface';
import Tooltip from 'antd/lib/tooltip';
import { toAllowedStringLength } from 'src/domain/common/formattersToAllowedValueLength';
import { ICaisoPageState } from 'src/pages/caiso/interface';
import { ColumnFilterItem } from 'antd/lib/table/interface';

export function sortingComparator(a?: string | number, b?: string | number) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a?.localeCompare(b ?? '');
    }

    if (a === b) {
        return 0;
    }

    if (a === undefined) {
        return 1;
    }

    if (b === undefined) {
        return -1;
    }

    return a < b ? -1 : 1;
}

export function getFilterValue(state: ICaisoPageState, attribute: keyof ICaisoLocation) {
    let { filteredValue } = getColumnFilteredValue(attribute, state?.filter || {});

    if (Array.isArray(filteredValue)) {
        filteredValue = filteredValue.flatMap(el => el.toString());
    }

    return filteredValue;
}

export function buildFilters(filters: string[]) {
    return filters.map(p => {
        return {
            text: (
                <Tooltip title={p} placement="right">
                    {toAllowedStringLength(p)}
                </Tooltip>
            ),
            fullText: p,
            value: p,
        } as ColumnFilterItem;
    });
}

export function prepareFilters(filter?: Record<string, string | string[]>): Record<string, string> {
    const filters: Record<string, string> = {};

    if (!filter) {
        return filters;
    }

    Object.entries(filter).forEach(([key, value]) => {
        if (!value) return;
        if (Array.isArray(value)) {
            filters[key] = value.join(',');
        } else {
            filters[key] = value;
        }
    });

    console.info('Filters:', filters);
    return filters;
}
