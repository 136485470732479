export const readAsBlob = async (file: File) => {
    const result: string = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = e => {
            const result = e.target?.result! as string;

            return resolve(result);
        };
        reader.readAsDataURL(file);
    });

    /** result => data:application/pdf;base64,... */
    const data = result.split(',')[1];

    const binary = atob(data);
    const array: number[] = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }

    const blobData = new Blob([new Uint8Array(array)], { type: file.type });

    return blobData;
};
