import { INyisoResource, ICreateNyisoResource, IUpdateNyisoResource } from './interface';
import { UploadFile } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';

export interface INyisoFormData {
    id?: number;
    resource_id: string;
    resource_name: string;
    enrollment_date?: Dayjs | null;
    to_service_voltage_id: string;
    aggregation_id: string;
    to_account_number: string;
    meter_authority: string;
    transmission_owner: string;
    street: string;
    street2?: string | null;
    city: string;
    state: string;
    zip: string;
    zone: string;
    cbl_method: string;
    response_type: string;
    provisional_acl?: NyisoProvisionalACLValues;
    enrollment_id: string;
}

export enum NyisoProvisionalACLValues {
    ENABLED = 'Y',
    DISABLED = 'N',
}

export const NyisoProvisionalACLOptions = [
    {
        value: NyisoProvisionalACLValues.ENABLED,
        label: 'Yes',
    },
    {
        value: NyisoProvisionalACLValues.DISABLED,
        label: 'No',
    },
];

export const NyisoMeterAuthorities = [
    {
        value: 'CEC',
        label: 'CEC - Con Edison',
    },
    {
        value: 'CHG',
        label: 'CHG - Central Hudson',
    },
    {
        value: 'LIP',
        label: 'LIP - LIPA',
    },
    {
        value: 'NMP',
        label: 'NMP - Niagara Mohawk',
    },
    {
        value: 'NYP',
        label: 'NYP - NYPA',
    },
    {
        value: 'NYS',
        label: 'NYS - New York State E & G',
    },
    {
        value: 'ORU',
        label: 'ORU - Orange & Rockland',
    },
    {
        value: 'RGE',
        label: 'RGE - Rochester G & E',
    },
];

export const NyisoLoadZones = [
    {
        value: 'A',
        label: 'Zone A',
    },
    {
        value: 'B',
        label: 'Zone B',
    },
    {
        value: 'C',
        label: 'Zone C',
    },
    {
        value: 'D',
        label: 'Zone D',
    },
    {
        value: 'E',
        label: 'Zone E',
    },
    {
        value: 'F',
        label: 'Zone F',
    },
    {
        value: 'G',
        label: 'Zone G',
    },
    {
        value: 'H',
        label: 'Zone H',
    },
    {
        value: 'I',
        label: 'Zone I',
    },
    {
        value: 'J',
        label: 'Zone J',
    },
    {
        value: 'K',
        label: 'Zone K',
    },
];

export const NyisoCBLMethods = [
    {
        value: 'A',
        label: 'Average Day',
    },
    {
        value: 'W',
        label: 'Weather-sensitive',
    },
];

export const NyisoStates = [
    {
        label: 'NY',
        value: 'NY',
    },
];

export const NyisoResponseTypeOptions = [
    {
        value: 'C',
        label: 'Curtailment',
    },
    {
        value: 'G',
        label: 'Generation',
    },
    {
        value: 'B',
        label: 'Both',
    },
];

export class NyisoFormData {
    public static fromEntity(resource: INyisoResource | Partial<INyisoResource>): INyisoFormData {
        return {
            id: resource.id,
            resource_id: resource.resource_id!,
            resource_name: resource.resource_name!,
            enrollment_date: resource.enrollment_date ? dayjs(resource.enrollment_date) : undefined,
            to_service_voltage_id: resource.to_service_voltage_id!,
            aggregation_id: resource.aggregation_id!,
            to_account_number: resource.to_account_number!,
            meter_authority: resource.meter_authority!,
            transmission_owner: resource.transmission_owner!,
            street: resource.street!,
            street2: resource.street2,
            city: resource.city!,
            state: resource.state || NyisoStates[0].value,
            zip: resource.zip!,
            zone: resource.zone!,
            cbl_method: resource.cbl_method!,
            response_type: resource.response_type!,
            provisional_acl: resource.provisional_acl
                ? NyisoProvisionalACLValues.ENABLED
                : NyisoProvisionalACLValues.DISABLED,
            enrollment_id: resource.enrollment_id!,
        };
    }

    public static toEntity(
        formData: INyisoFormData,
        file?: UploadFile | null
    ): IUpdateNyisoResource | ICreateNyisoResource {
        const entity: ICreateNyisoResource | IUpdateNyisoResource = {
            id: formData.id!,
            resource_id: formData.resource_id,
            resource_name: formData.resource_name,
            to_service_voltage_id: formData.to_service_voltage_id,
            aggregation_id: formData.aggregation_id,
            to_account_number: formData.to_account_number,
            meter_authority: formData.meter_authority,
            transmission_owner: formData.transmission_owner,
            street: formData.street,
            street2: formData.street2 || null,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            zone: formData.zone,
            cbl_method: formData.cbl_method,
            response_type: formData.response_type,
            provisional_acl: formData.provisional_acl === NyisoProvisionalACLValues.ENABLED,
            enrollment_id: formData.enrollment_id,
            enrollment_date: formData.enrollment_date ? formData.enrollment_date.toISOString() : null,
        };

        if (file) {
            entity.file = file;
        }

        return entity;
    }
}
