import React, { useMemo } from 'react';
import Popover from 'antd/lib/popover';
import Typography from 'antd/lib/typography';
import { EVENT_COLORS } from '../../../domain/commonConst';

interface ICalendarEventPreviewProps {
    isTest?: boolean;
    isEmergency?: boolean;
    isVoluntary?: boolean;
    isPriceResponse?: boolean;
    isCleanResponse?: boolean;
    preEventDurationHours: number;
    eventDurationHours: number;
    postEventDurationHours: number;
    secondaryPreEventDurationHours: number;
}

export const CalendarEventPreview: React.FC<ICalendarEventPreviewProps> = ({
    isTest = false,
    isEmergency = false,
    isVoluntary = false,
    isPriceResponse = false,
    isCleanResponse = false,
    preEventDurationHours,
    eventDurationHours,
    postEventDurationHours,
    secondaryPreEventDurationHours,
}) => {
    /*
    * if no pre-event use secondary pre-event instead in single line
    * if it has both, move secondary pre-event to the next line
    * */

    const durations = {
        preEvent: secondaryPreEventDurationHours > preEventDurationHours ? secondaryPreEventDurationHours : preEventDurationHours,
        event: eventDurationHours,
        postEvent: postEventDurationHours,
        secondaryPreEvent: preEventDurationHours === 0 ? 0 : secondaryPreEventDurationHours > preEventDurationHours ? preEventDurationHours : secondaryPreEventDurationHours,
    };

    const {
              maxLineDuration,
              preEventWidth,
              eventWidth,
              postEventWidth,
              secondaryPreEventWidth,
          } = useMemo(() => {
        const firstLineDuration = durations.preEvent + durations.event + durations.postEvent;
        const maxDuration = Math.max(firstLineDuration, durations.secondaryPreEvent);

        return {
            maxLineDuration: maxDuration,
            preEventWidth: (durations.preEvent / maxDuration) * 100,
            eventWidth: (durations.event / maxDuration) * 100,
            postEventWidth: (durations.postEvent / maxDuration) * 100,
            secondaryPreEventWidth: (durations.secondaryPreEvent / maxDuration) * 100,
        };
    }, [durations.event, durations.postEvent, durations.preEvent, durations.secondaryPreEvent]);

    const eventTitle = useMemo(() => {
        let titleParts: string[] = [];

        if (isTest) {
            titleParts.push('TEST');
        }

        if (isEmergency) {
            titleParts.push('EMERGENCY');
        }

        if (isVoluntary) {
            titleParts.push('VOLUNTARY');
        }

        if (isPriceResponse) {
            titleParts = ['PRICE RESPONSE'];
        }

        if (isCleanResponse) {
            titleParts = ['CLEAN RESPONSE'];
        }

        // if (isCoincidentPeak) {
        //     titleParts = ['COINCIDENT PEAK'];
        // }

        return titleParts.join(' & ');
    }, [isTest, isEmergency, isVoluntary, isPriceResponse, isCleanResponse]);

    const eventColor = useMemo(() => {
        if (isTest) {
            return EVENT_COLORS.TEST;
        }

        if (isPriceResponse) {
            return EVENT_COLORS.PRICE_RESPONSE;
        }

        if (isCleanResponse) {
            return EVENT_COLORS.CLEAN_RESPONSE;
        }

        // if (isCoincidentPeak) {
        //     return EVENT_COLORS.COINCIDENT_PEAK;
        // }

        return EVENT_COLORS.DEFAULT;
    }, [isCleanResponse, isPriceResponse, isTest]);

    if (!maxLineDuration) {
        return (
            <>
                <Typography.Text strong style={{ paddingBottom: 8, display: 'block' }}>Event Preview</Typography.Text>
                <div style={{
                    width: '100%',
                    height: 24,
                }}>
                    -
                </div>
            </>
        );
    }

    return (
        <>
            <Typography.Text strong style={{ paddingBottom: 8, display: 'block' }}>Event Preview</Typography.Text>

            <div style={{
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    height: 24,
                    width: `${preEventWidth}%`,
                    backgroundColor: EVENT_COLORS.PRE_EVENT,
                }} />

                <div style={{
                    height: 24,
                    width: `${eventWidth}%`,
                    backgroundColor: eventColor,
                    color: '#ffffff',
                    textTransform: 'uppercase',
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                }}>
                    {eventTitle ? (
                        <Popover content={eventTitle}>
                            <Typography.Text ellipsis style={{
                                padding: '0 12px',
                                color: 'white',
                                fontWeight: 'normal',
                                fontSize: '12px',
                                lineHeight: '14px',
                            }}>{eventTitle}</Typography.Text>
                        </Popover>
                    ) : null}
                </div>

                <div style={{
                    height: 24,
                    width: `${postEventWidth}%`,
                    backgroundColor: EVENT_COLORS.POST_EVENT,
                }} />
            </div>

            <div style={{
                height: 24,
                width: `${secondaryPreEventWidth}%`,
                backgroundColor: EVENT_COLORS.PRE_EVENT,
                marginTop: 4,
                marginLeft: 'auto',
                marginRight: `${eventWidth + postEventWidth}%`,
            }} />
        </>
    );
};
