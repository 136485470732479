import { InfoCircleOutlined } from '@ant-design/icons';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Collapse from 'antd/lib/collapse';
import DatePicker from 'antd/lib/date-picker';
import Divider from 'antd/lib/divider';
import Dropdown from 'antd/lib/dropdown';
import Form from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal/Modal';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import format from 'date-fns/format';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../../domain/auth/useAuth';
import { formatToMinutesAndHours } from '../../../domain/common/dateFormatters';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import {
    createEventBatch, deleteEvent, deleteEventBatch, fetchEventsBatch, isCompleted, updateEvent, updateEventBatch,
} from '../../../domain/event';
import { IEvent, SIGNAL_LEVEL_OPTIONS, SliceType, SliceTypeLabel } from '../../../domain/event/interface';
import { UserType } from '../../../domain/user/interface';
import { PRIMARY_COLOR } from '../../../theme';
import { FORM_ACTION, IAffectedSites, IEventFormProps, toBatchRequest, ViewEvent } from '../eventInterface';
import { isPastDate } from '../utils';
import { EventConfirmationModal } from './EventConfirmationModal';
import { CalendarEventPreview } from './CalendarEventPreview';
import { ResourceCollapsedList } from './ResourceCollapsedList';

const preparedBatchEventData = (batchEvents: IEvent[]) =>
    (batchEvents || []).map(event => ({
        company: event?.site?.company?.company_name!,
        company_id: event?.site?.company?.company_id,
        site: event?.site?.site_name!,
        id: `${event?.site?.site_id!}`,
    }));

const CONFIRMATION_TITLE = {
    [FORM_ACTION.DELETE_BATCH]: 'delete',
    [FORM_ACTION.DELETE]: 'delete',
    [FORM_ACTION.UPDATE_BATCH]: 'update',
    [FORM_ACTION.UPDATE]: 'update',
    [FORM_ACTION.UPDATE_BATCH_IN_COMPANY]: 'update',
    [FORM_ACTION.CREATE]: 'save',
    [FORM_ACTION.DELETE_BATCH_IN_COMPANY]: 'delete',
};

export const EventBySiteFormModal = (props: IEventFormProps) => {
    const { notification } = App.useApp();
    const { event, resources, onClose, isEditMode } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [batchEvents, setBatchEvents] = useState<IAffectedSites[]>([]);
    const viewEvent = useMemo(() => new ViewEvent(event), [event]);
    const [affectedSites, setAffectedSites] = useState<IAffectedSites[]>(resources);
    const [formAction, setFormAction] = useState<FORM_ACTION | null>(null);
    const [form] = Form.useForm();
    const auth = useAuth();
    const customerCompanyId = auth?.user?.user_type === UserType.CUSTOMER ? auth.user.company_id : undefined;

    const isTestEvent = Form.useWatch('event_test', form);
    const isEmergencyEvent = Form.useWatch('event_emergency_generator_allowed', form);
    const isVoluntaryEvent = Form.useWatch('event_voluntary', form);

    const preEventDuration = Form.useWatch('event_pre_duration_hours', form);
    const secondaryPreEventDuration = Form.useWatch('event_secondary_duration_hours', form);
    const eventDuration = Form.useWatch('event_duration_hours', form);
    const postEventDuration = Form.useWatch('event_post_duration_hours', form);

    useEffect(() => {
        viewEvent && form.setFieldsValue(viewEvent);

        const batchId = viewEvent.event_batch;
        if (batchId) {
            (async () => {
                const data = await fetchEventsBatch({
                    batchId,
                    include: 'site,company',
                    companyId: customerCompanyId,
                });
                setBatchEvents(preparedBatchEventData(data));
            })();
        }
    }, [viewEvent]);

    useEffect(() => setAffectedSites(resources), [resources]);

    const warning = useMemo(
        () =>
            event.event_cancelled || isCompleted(event as IEvent) ? (
                <Alert
                    showIcon
                    message='Warning'
                    description='Cannot edit an event which has been canceled or has already completed.'
                    type='warning'
                    style={{ display: 'flex', alignItems: 'center' }}
                />
            ) : null,
        [event],
    );

    function resourcesValidator({ getFieldValue }: { getFieldValue: Function }) {
        return {
            validator(_: any, value: any) {
                return resources?.length
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('Please select at least one site from the left section of the calendar!'),
                    );
            },
        };
    }

    //create + update event
    const onEventFormFinish = () => {
        if (isEditMode) {
            updateEventWithConfirmation();
        } else {
            createEventWithConfirmation();
        }
    };

    //create event
    const createEventWithConfirmation = () => setFormAction(FORM_ACTION.CREATE);
    const createBatchAction = async () => {
        setLoading(true);
        try {
            const preparedEvent = await form.validateFields();
            const { event, sites } = toBatchRequest(preparedEvent, resources);
            await createEventBatch(event, sites, customerCompanyId);
            notification.info({ key: 'event-save-info', message: 'Events saved' });
            onClose(preparedEvent);
        } catch (err: any) {
            notification.error({ key: 'event-save-error', message: err.message || 'Cannot save events!' });
        }
        setLoading(false);
    };

    //update event
    const updateEventWithConfirmation = () => {
        setFormAction(FORM_ACTION.UPDATE);
        setAffectedSites(resources);
    };
    const updateEventAction = async () => {
        const eventId = event.event_id!;
        setLoading(true);
        try {
            const preparedEvent = await form.validateFields();
            const { event } = toBatchRequest(preparedEvent) as any;
            await updateEvent(eventId, event);
            notification.info({ key: 'event-update-info', message: 'Event updated' });
            onClose(event);
        } catch (err: any) {
            notification.error({ key: 'event-update-error', message: err.message || 'Cannot update event!' });
        }
        setLoading(false);
    };

    // delete one event
    const deleteEventWithConfirmation = () => {
        setAffectedSites(resources);
        setFormAction(FORM_ACTION.DELETE);
    };
    const deleteEventAction = async () => {
        const eventId = event.event_id;

        if (!eventId) return;

        try {
            await deleteEvent(eventId);
            notification.info({ key: 'event-delete-info', message: 'Event deleted' });
        } catch (error: any) {
            notification.error({ key: 'event-delete-error', message: error.message || 'Cannot delete event!' });
        }
        onClose(event);
    };

    // update batch event
    const updateBatchWithConfirmation = () => {
        form.validateFields().then(() => {
            setFormAction(FORM_ACTION.UPDATE_BATCH);
            setAffectedSites(batchEvents);
        });
    };
    const updateBatchAction = async (companyId?: number) => {
        try {
            const formValues = form.getFieldsValue();
            const { event } = toBatchRequest(formValues);
            await updateEventBatch(viewEvent.event_batch!, event, companyId);
            notification.info({ key: 'batch-update-info', message: 'Batch updated' });
            onClose(event);
        } catch (error: any) {
            notification.error({ key: 'batch-update-error', message: error.message || 'Cannot update batch!' });
        }
    };

    //delete batch events
    const deleteBatchWithConfirmation = () => {
        setAffectedSites(batchEvents);
        setFormAction(FORM_ACTION.DELETE_BATCH);
    };
    const deleteBatchAction = async (companyId?: number) => {
        try {
            await deleteEventBatch(viewEvent.event_batch!, companyId);
            notification.info({ key: 'batch-delete-info', message: 'Batch deleted' });
            onClose(viewEvent);
        } catch (error: any) {
            notification.error({ key: 'batch-delete-error', message: error.message || 'Cannot delete batch!' });
        }
    };

    const deleteBatchInCompanyWithConfirmation = () => {
        setFormAction(FORM_ACTION.DELETE_BATCH_IN_COMPANY);
        const affectedSitesInCompany = batchEvents.filter(site => site.company_id === event.site?.company_id);
        setAffectedSites(affectedSitesInCompany);
    };

    const updateBatchInCompanyWithConfirmation = () => {
        form.validateFields().then(() => {
            setFormAction(FORM_ACTION.UPDATE_BATCH_IN_COMPANY);
            const affectedSitesInCompany = batchEvents.filter(site => site.company_id === event.site?.company_id);
            setAffectedSites(affectedSitesInCompany);
        });
    };

    const eventConfirmationModalAction = {
        [FORM_ACTION.DELETE_BATCH]: () => {
            deleteBatchAction(customerCompanyId);
            closeEventConfirmationModal();
        },
        [FORM_ACTION.DELETE]: () => {
            deleteEventAction();
            closeEventConfirmationModal();
        },
        [FORM_ACTION.DELETE_BATCH_IN_COMPANY]: () => {
            const companyId = auth?.user?.isAdmin() ? event.site?.company_id : customerCompanyId;
            deleteBatchAction(companyId);
            closeEventConfirmationModal();
        },
        [FORM_ACTION.UPDATE_BATCH]: () => {
            updateBatchAction(customerCompanyId);
            closeEventConfirmationModal();
        },
        [FORM_ACTION.UPDATE_BATCH_IN_COMPANY]: () => {
            const companyId = auth?.user?.isAdmin() ? event.site?.company_id : customerCompanyId;
            updateBatchAction(companyId);
            closeEventConfirmationModal();
        },

        [FORM_ACTION.UPDATE]: () => {
            updateEventAction();
            closeEventConfirmationModal();
        },
        [FORM_ACTION.CREATE]: () => {
            createBatchAction();
            closeEventConfirmationModal();
        },
    };

    const closeEventConfirmationModal = () => setFormAction(null);

    const closeDialog = () => onClose();

    const deleteActionsMenu = () => {
        const companyName = toAllowedStringLength(event.site?.company?.company_name!, 20);
        const companySitesCount = batchEvents.filter(site => site.company_id === event.site?.company_id).length;

        return (
            <Menu>
                <Menu.Item key='delete-batch-events' onClick={deleteBatchWithConfirmation}>
                    Delete batch ({batchEvents.length})
                </Menu.Item>
                {auth?.user?.isAdmin() && companySitesCount > 1 && (
                    <Menu.Item key='delete-batch-in-company-events' onClick={deleteBatchInCompanyWithConfirmation}>
                        Delete in <strong>{companyName}</strong>({companySitesCount})
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const updateActionsMenu = () => {
        const companyName = toAllowedStringLength(event.site?.company?.company_name!, 20);
        const companySitesCount = batchEvents.filter(site => site.company_id === event.site?.company_id).length;

        return (
            <Menu>
                <Menu.Item key='update-batch-events' onClick={updateBatchWithConfirmation}>
                    Update batch ({batchEvents.length})
                </Menu.Item>
                {auth?.user?.isAdmin() && companySitesCount > 1 && (
                    <Menu.Item key='update-batch-in-company-events' onClick={updateBatchInCompanyWithConfirmation}>
                        Update in <strong>{companyName}</strong>({companySitesCount})
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Update event' : 'New event'}
            onCancel={closeDialog}
            footer={[
                <Space wrap style={{ justifyContent: 'flex-end' }}>
                    <Button key='event-form-cancel' data-cy='close-dialog' onClick={closeDialog}>
                        Close
                    </Button>

                    {!warning && (
                        <>
                            {isEditMode ? (
                                <>
                                    {batchEvents.length ? (
                                        <>
                                            <Dropdown.Button
                                                onClick={deleteEventWithConfirmation}
                                                overlay={deleteActionsMenu}
                                                loading={loading}
                                                key='delete-event-actions'
                                            >
                                                Delete
                                            </Dropdown.Button>
                                            <Dropdown.Button
                                                onClick={form.submit}
                                                overlay={updateActionsMenu}
                                                loading={loading}
                                                key='update-event-actions'
                                            >
                                                Update
                                            </Dropdown.Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                onClick={deleteEventWithConfirmation}
                                                loading={loading}
                                                key='delete-event-action'
                                                data-cy='delete-event'
                                            >
                                                Delete
                                            </Button>
                                            <Button onClick={form.submit} loading={loading} key='update-event-action'
                                                    data-cy='update-event'>
                                                Update
                                            </Button>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Button data-cy='save-event' key='event-form-submit' type='primary' loading={loading}
                                        onClick={form.submit}>
                                    Save ({resources.length})
                                </Button>
                            )}
                        </>
                    )}
                </Space>,
            ]}
            className='event-modal'
            data-cy='event-by-site'
        >
            <div className='event-form-container content-background' data-cy='warning-msg'>
                {warning && (
                    <>
                        {warning}
                        <br />
                    </>
                )}

                <Form form={form} name='event-form' preserve={false} layout='vertical' onFinish={onEventFormFinish}
                      initialValues={viewEvent}>
                    <Row>
                        <Col xs={24}>
                            {!isEditMode && (
                                <Form.Item
                                    name='resources'
                                    label={<Typography.Text strong>Resources</Typography.Text>}
                                    hasFeedback
                                    wrapperCol={{ span: 24 }}
                                    rules={[resourcesValidator]}
                                >
                                    <ResourceCollapsedList resources={resources} />
                                </Form.Item>
                            )}

                            <Form.Item name='event_id' hidden>
                                <Input data-cy='event-id' />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item label={<Typography.Text strong>Pre Event duration</Typography.Text>}>
                                <Space>
                                    <Form.Item name='event_pre_duration_hours' noStyle>
                                        <InputNumber data-cy='event-pre-duration' min={0} max={4} step={0.25}
                                                     size='large' />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }}>
                                <Space>
                                    <Form.Item
                                        name='event_datetime_start_local'
                                        required
                                        rules={[{ required: true, message: 'Please select date!' }]}
                                        label={
                                            <>
                                                <Typography.Text strong>Event start&nbsp;</Typography.Text>
                                                <Tooltip
                                                    title="The event will be dispatched to start at the site's local timezone">
                                                    <InfoCircleOutlined style={{ color: PRIMARY_COLOR }} />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <DatePicker data-cy='date-picker' format='YYYY-MM-DD HH:mm'
                                                    showTime={{ format: 'HH:mm' }} size='large' />
                                    </Form.Item>
                                    <Form.Item dependencies={['event_datetime_start_local']}
                                               style={{ marginBottom: 0 }}>
                                        {({ getFieldValue }) => {
                                            const selectedDate = getFieldValue('event_datetime_start_local');

                                            return (
                                                selectedDate &&
                                                isPastDate(getFieldValue('event_datetime_start_local')) && (
                                                    <Typography.Text type='secondary'>
                                                        <WarningOutlined style={{ color: '#faad14' }} /> Event started
                                                        in the past.
                                                    </Typography.Text>
                                                )
                                            );
                                        }}
                                    </Form.Item>
                                </Space>
                            </Form.Item>

                            <Form.Item label={<Typography.Text strong>Event duration</Typography.Text>}>
                                <Space>
                                    <Form.Item name='event_duration_hours' noStyle>
                                        <InputNumber data-cy='event-duration' min={0} max={8} step={0.25}
                                                     size='large' />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    {!isEditMode && (
                        <Collapse
                            accordion
                            size='small'
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                            <Collapse.Panel
                                key='1'
                                header='Slice'
                            >
                                <FormItem
                                    wrapperCol={{ span: 24 }}
                                    label={<Typography.Text strong>Slice Type</Typography.Text>}
                                    name={['event_slice', 'type']}
                                >
                                    <Radio.Group>
                                        {Object.entries(SliceTypeLabel).map(([value, label]) => (
                                            <Radio key={value} value={value} disabled={value === SliceType.KW}>
                                                {label}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={<Typography.Text strong>Slice Amount</Typography.Text>}
                                          name={['event_slice', 'amount']}>
                                    <InputNumber min={0} size='large' />
                                </FormItem>
                            </Collapse.Panel>
                        </Collapse>
                    )}

                    <Divider plain>Advanced</Divider>

                    <Row>
                        <Col xs={24}>
                            <Form.Item label={<Typography.Text strong>OADR Signal Level</Typography.Text>}
                                       name='event_signal'>
                                <Select
                                    placeholder='Please select signal level'
                                    options={SIGNAL_LEVEL_OPTIONS}
                                    style={{ width: '175px' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item label={<Typography.Text strong>Post Event</Typography.Text>}>
                                <Space>
                                    <Form.Item noStyle name='event_post_duration_hours'>
                                        <InputNumber min={0} max={4} step={0.25} size='large' />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item label={<Typography.Text strong>Secondary Pre Event</Typography.Text>}>
                                <Space>
                                    <Form.Item name='event_secondary_duration_hours' noStyle>
                                        <InputNumber min={0} max={4} step={0.25} size='large' />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Form.Item name='event_test' label={<Typography.Text strong>Test Event</Typography.Text>}
                                       valuePropName='checked'>
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item
                                name='event_emergency_generator_allowed'
                                label={<Typography.Text strong>Emergency Generators Allowed</Typography.Text>}
                                valuePropName='checked'
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name='event_voluntary'
                                label={<Typography.Text strong>Voluntary Event</Typography.Text>}
                                valuePropName='checked'
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <CalendarEventPreview
                        isTest={isTestEvent}
                        isEmergency={isEmergencyEvent}
                        isVoluntary={isVoluntaryEvent}
                        preEventDurationHours={preEventDuration}
                        eventDurationHours={eventDuration}
                        postEventDurationHours={postEventDuration}
                        secondaryPreEventDurationHours={secondaryPreEventDuration}
                    />
                </Form>

                {formAction && (
                    <EventConfirmationModal
                        action={formAction}
                        handleConfirm={eventConfirmationModalAction[formAction]}
                        handleClose={closeEventConfirmationModal}
                        modalContent={
                            <ConfirmationModalContent
                                event={{
                                    ...form.getFieldsValue(),
                                    ...([FORM_ACTION.DELETE, FORM_ACTION.DELETE_BATCH,
                                        FORM_ACTION.DELETE_BATCH_IN_COMPANY].includes(formAction) && {
                                        event_datetime_start_local: event.event_datetime_start,
                                    }),
                                }}
                                affectedSites={affectedSites}
                                actionName={CONFIRMATION_TITLE[formAction]}
                            />
                        }
                    />
                )}
            </div>
        </Modal>
    );
};

interface IConfirmationModalContent {
    event: ViewEvent;
    affectedSites: IAffectedSites[];
    actionName: string;
}

export const ConfirmationModalContent = ({ event, affectedSites, actionName }: IConfirmationModalContent) => {
    const numberOfEvents = affectedSites?.length || 1;
    const eventSpelling = `event${numberOfEvents > 1 ? 's' : ''}`;
    const event_duration_in_minutes = event.event_duration_hours * 60;
    const event_pre_duration_in_minutes = event.event_pre_duration_hours * 60;

    return (
        <>
            <Typography>
                You are going to {actionName} <strong>{numberOfEvents}</strong> {eventSpelling}
            </Typography>
            <Typography>
                {eventSpelling} starts at: <strong>{format(new Date(event.event_datetime_start_local!),
                'dd-LLL-yyyy hh:mmaaa')}</strong>
            </Typography>
            {Boolean(event_pre_duration_in_minutes) && (
                <Typography>
                    {eventSpelling} pre duration: <strong>{formatToMinutesAndHours(
                    event_pre_duration_in_minutes)}</strong>.
                </Typography>
            )}
            <Typography>
                {eventSpelling} duration: <strong>{formatToMinutesAndHours(event_duration_in_minutes)}</strong>.
            </Typography>
        </>
    );
};
