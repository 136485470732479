import Typography from 'antd/lib/typography';

export interface ISiteAddressProps {
    address?: string;
    city?: string;
    state?: string;
}

export interface ISiteFullAddressProps {
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
}

export const SiteAddress = ({ address, city, state }: ISiteAddressProps) => {
    if (!address || !city || !state) return <></>;

    return (
        <div>
            <Typography.Text>{address}</Typography.Text>
            <br />
            <Typography.Text>
                {city}, {state}
            </Typography.Text>
        </div>
    );
};

export const SiteFullAddress = ({ address, city, state, country, zip }: ISiteFullAddressProps) => {
    if (!address || !city || !state) return <>-</>;

    return (
        <div>
            <Typography.Text>
                {address} {city}, {state} {zip} {country}
            </Typography.Text>
        </div>
    );
};
