import Typography from 'antd/lib/typography';
import './informationCard.css';

interface IInformationCardTitle {
    icon?: React.ReactNode;
    classNames?: string[];
    title: string;
}

export const InformationCardTitle = ({ icon, title, classNames = [] }: IInformationCardTitle) => (
    <Typography.Text className={`information-card-title ${classNames?.join(' ')}`}>
        {icon && icon}&nbsp;{title}
    </Typography.Text>
);
