import { useContext } from 'react';
import App from 'antd/lib/app';
import { AbilityContext } from '../../../../components/ability/can';
import { ExportButton, IExportButtonProps } from '../../../../components/ExportButton/ExportButton';
import { useExportConnectCustomersQuery, useExportEnergyCustomersQuery } from '../../../../domain/company/queries';

export const ExportIntervalDataBtn = () => {
    const { notification } = App.useApp();
    const ability = useContext(AbilityContext);

    const exportEnergyCustomersQuery = useExportEnergyCustomersQuery();
    if (exportEnergyCustomersQuery.isError) {
        notification.error({
            key: 'energy-customers-export-error',
            message: exportEnergyCustomersQuery.error.message || 'Cannot export customers!',
        });
    }

    const exportConnectCustomersQuery = useExportConnectCustomersQuery();
    if (exportEnergyCustomersQuery.isError) {
        notification.error({
            key: 'connect-customers-export-error',
            message: exportEnergyCustomersQuery.error.message || 'Cannot export Connect customers!',
        });
    }

    const exportEnergyCustomersAction = async () => {
        await exportEnergyCustomersQuery.refetch();
    };

    const exportConnectCustomersAction = async () => {
        await exportConnectCustomersQuery.refetch();
    };

    function getActions() {
        const actions: IExportButtonProps['actions'] = [];

        if (ability.can('read', 'EnergyCustomer')) {
            actions.push({
                key: 'e-b-1',
                title: 'Download Interval Data Customer List',
                fn: exportEnergyCustomersAction,
            });
        }

        if (ability.can('read', 'ConnectCustomer')) {
            actions.push({
                key: 'e-b-2',
                title: 'Download Connect Customer List',
                fn: exportConnectCustomersAction,
            });
        }

        return actions;
    }

    const actions = getActions();

    return <ExportButton actions={actions} />;
};
