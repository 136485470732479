import orderBy from 'lodash/orderBy';
import React from 'react';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Collapse from 'antd/lib/collapse';
import Empty from 'antd/lib/empty';
import Flex from 'antd/lib/flex';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import isEmpty from 'lodash/isEmpty';
import { IAccount } from '../../domain/account/interface';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { numberFormatter } from '../../domain/numberFormatter';

interface IAccountListProps {
    accounts: IAccount[];
    onUnlinkAccount: (salesforceId: string) => void;
}

export const AccountList: React.FC<IAccountListProps> = ({
    accounts,
    onUnlinkAccount,
}) => {
    const { modal } = App.useApp();
    const sortedAccounts = orderBy(accounts, ['name'], ['asc']);

    const handleUnlinkAccount = (account: IAccount) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `You are going to unlink account "${account.name}". Are you sure?`,
            onOk() {
                onUnlinkAccount(account.salesforce_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    if (isEmpty(sortedAccounts)) {
        return (
            <Flex style={{ height: 90 }} justify='center' align='center'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Link Salesforce Accounts to see them in list' />
            </Flex>
        );
    }

    return (
        <Collapse>
            {sortedAccounts.map((account: IAccount, index) => (
                <Collapse.Panel
                    key={index}
                    header={
                        <Flex justify='space-between' align='center'>
                            <Typography.Text ellipsis={{ tooltip: { title: account.name } }} style={{ maxWidth: '330px' }}>
                                {account.name}
                            </Typography.Text>
                            <Button size='small' onClick={handleUnlinkAccount(account)}>
                                Unlink
                            </Button>
                        </Flex>
                    }
                >
                    <Row gutter={[16, 8]}>
                        <ListItem label='Name' value={account.name} />
                        <ListItem label='Total Active Enrollments' value={numberFormatter(account.total_active_enrollments)} />
                        <ListItem label='Account Status' value={account.account_status} />
                        <ListItem label='Industry' value={account.industry} />
                        <ListItem label='Marketing Segmentation' value={account.marketing_segmentation} />
                        <ListItem label='Salesforce ID' value={account.salesforce_id} />
                        <ListItem label='Parent' value={!account.parent_name ? '-' : `${account.parent_name} (${account.parent_salesforce_id})`} />
                        <ListItem label='Last Modified' value={`${defaultDateFormat(account.salesforce_updated_date, '-')} / ${account.salesforce_updated_by || '-'}`} />
                    </Row>
                </Collapse.Panel>
            ))}
        </Collapse>
    )
}

interface IListItemProps {
    label: string;
    value?: string | React.JSX.Element | null;
    labelSpan?: number;
    valueSpan?: number;
}

function ListItem({ label, value, labelSpan = 10, valueSpan = 14 }: IListItemProps) {
    return (
        <>
            <Col span={labelSpan}>
                <Typography.Text strong>{label}</Typography.Text>
            </Col>
            <Col span={valueSpan}>
                <Typography.Text>{value || '-'}</Typography.Text>
            </Col>
        </>
    );
}
