import { API } from '../api';
import { IMfaSetting, MfaRequiredError } from './interface';

export const enableMfa = async (userId: number, confirmationCode?: any, accessToken?: string): Promise<IMfaSetting | null> => {
    const isConfirmationCode = confirmationCode?.code;
    const response = await API.fetch(`/users/${userId}/mfa`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...(isConfirmationCode && { 'ener-mfa': isConfirmationCode }),
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
    });

    if (response.ok) {
        return null;
    }

    if (response.status === 400) {
        const respBody: any = await response.json();
        if ((respBody.require || []).includes('mfa')) {
            throw new MfaRequiredError(respBody);
        }
    }
    throw new Error('Unknown server error, please try again later');
};

export const disableMfa = async (userId: number, confirmationCode?: any): Promise<IMfaSetting | null> => {
    const isConfirmationCode = confirmationCode?.code;
    const response = await API.fetch(`/users/${userId}/mfa`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            ...(isConfirmationCode && { 'ener-mfa': isConfirmationCode }),
        },
    });

    if (response.ok) {
        return null;
    }

    if (response.status === 400) {
        const respBody: any = await response.json();
        if ((respBody.require || []).includes('mfa')) {
            throw new MfaRequiredError(respBody);
        }
    }

    throw new Error('Unknown server error, please try again later');
};

export const resendMfa = async (email: string, uuid: string) => {
    const response = await API.fetch('/auth/mfa/resend', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, uuid }),
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot resend confirmation code!`);
};
