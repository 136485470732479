import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'antd/lib/button';
import Table, { ColumnsType } from 'antd/lib/table';
import App from 'antd/lib/app';

import { AbilityContext } from 'src/components/ability/can';
import { CaisoDrrsSwitcher } from 'src/components/caisoDrrsSwitcher/CaisoDrrsSwitcher';
import { PageHeader } from 'src/components/pageHeader/pageHeader';
import { WithEmptyDataTable } from 'src/components/table/withEmptyDataTable';
import { ICaisoRegistration } from 'src/domain/caiso/drrs/registrations/interface';
import {
    useCaisoRegistrationResourceIdsListQuery,
    useCaisoRegistrationsListQuery,
    useCaisoRegistrationStatusListQuery,
    useCaisoRegistrationSubLapListQuery,
    useCaisoRegistrationUdcListQuery,
} from 'src/domain/caiso/drrs/registrations/queries';
import { defaultDateFormat } from 'src/domain/common/dateFormatters';
import { DEFAULT_PAGINATION } from 'src/domain/commonConst';
import { IPaginationMeta } from 'src/domain/IPagination';
import { caisoDrrsViewOptions, ICaisoPageState } from 'src/pages/caiso/interface';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { usePageLocation } from 'src/pages/usePageState';
import { IUseCaisoDrrsSwitcher } from 'src/components/caisoDrrsSwitcher/caisoDrrsInterface';
import { DrrsRegistrationModal } from '../components/DrrsRegistrationModal/DrrsRegistrationModal';
import { DrrsCaisoSyncModal } from '../components/DrrsCaisoSyncModal/DrrsCaisoSyncModal';
import { CaisoSyncOrigin } from 'src/domain/caiso/drrs/caiso-sync/interface';
import { SearchInput } from 'src/components/searchInput/searchInput';
import { buildFilters, sortingComparator, getFilterValue } from 'src/domain/caiso/drrs/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { exportRegistrations } from 'src/domain/caiso/drrs/registrations';

export const DrrsRegistrationsView = ({ view, handleView }: IUseCaisoDrrsSwitcher) => {
    const { notification } = App.useApp();
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation<ICaisoPageState>();
    const pageState = queryToState(location.search);

    const [drrsRegistration, setDrrsRegistration] = useState<ICaisoRegistration>();
    const [showCaisoSync, setShowCaisoSync] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.search && { search: pageState?.search }),
        ...(pageState?.filter && { filter: pageState.filter }),
    };

    const { data: statuses } = useCaisoRegistrationStatusListQuery();
    const { data: udcs } = useCaisoRegistrationUdcListQuery();
    const { data: subLaps } = useCaisoRegistrationSubLapListQuery();
    const { data: resourceIds } = useCaisoRegistrationResourceIdsListQuery();
    const { data, isLoading, error } = useCaisoRegistrationsListQuery(listQuery);

    const registrations = data?.data || [];
    const meta = (data?.meta ?? { total: 0 }) as IPaginationMeta;

    useEffect(() => {
        if (!isLoading && error) {
            notification.error({
                key: 'caiso-registrations-list-error',
                message: (error as Error)?.message || 'Cannot load registrations!',
            });
        }
    }, [isLoading, error]);

    const handleExportRegistrations = async () => {
        try {
            setExportLoading(true);
            await exportRegistrations(pageState);
        } catch (error) {
            console.error('Export failed:', error);
            alert('Export failed. Please try again.');
        } finally {
            setExportLoading(false);
        }
    };

    function handleResetFilters() {
        setPageQuery({
            pagination: DEFAULT_PAGINATION,
            sorter: { field: '', order: 'ascend' },
            search: '',
            filter: {},
        });
    }

    function handleSync() {
        setShowCaisoSync(true);
    }

    function handleRowClick(record: ICaisoRegistration) {
        setDrrsRegistration(record);
    }

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    async function onDrrsRegistrationModalClose() {
        setDrrsRegistration(undefined);
    }

    function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    const columns: ColumnsType<ICaisoRegistration> = [
        {
            title: 'Reg ID',
            dataIndex: 'id',
            width: 60,
            fixed: 'left',
            sorter: (a, b) => sortingComparator(a.id, b.id),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 140,
            fixed: 'left',
            sorter: (a, b) => sortingComparator(a.name, b.name),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 60,
            ellipsis: { showTitle: false },
            filters: buildFilters(statuses || []),
            filteredValue: getFilterValue(pageState, 'status'),
            filterSearch: (input, record) => !!record?.fullText?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => sortingComparator(a.status, b.status),
        },
        {
            title: 'UDC',
            dataIndex: 'udc',
            width: 60,
            ellipsis: { showTitle: false },
            filters: buildFilters(udcs || []),
            filteredValue: getFilterValue(pageState, 'udc'),
            filterSearch: (input, record) => !!record?.fullText?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => sortingComparator(a.udc, b.udc),
        },
        {
            title: 'SUBLAP',
            dataIndex: 'sub_lap',
            width: 60,
            ellipsis: { showTitle: false },
            filters: buildFilters(subLaps || []),
            filteredValue: getFilterValue(pageState, 'sub_lap'),
            filterSearch: (input, record) => !!record?.fullText?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => sortingComparator(a.sub_lap, b.sub_lap),
        },
        {
            title: 'Resource ID',
            dataIndex: 'resource_id',
            width: 60,
            ellipsis: { showTitle: false },
            filters: buildFilters(resourceIds || []),
            filteredValue: getFilterValue(pageState, 'resource_id'),
            filterSearch: (input, record) => !!record?.fullText?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => sortingComparator(a.resource_id, b.resource_id),
        },
        {
            title: 'Start',
            dataIndex: 'start_date',
            fixed: 'left',
            width: 60,
            render: text => {
                return <>{defaultDateFormat(text, '', 'dd/MMM/yyyy')}</>;
            },
            sorter: (a, b) => sortingComparator(a.start_date, b.start_date),
        },
        {
            title: 'End',
            dataIndex: 'end_date',
            fixed: 'left',
            width: 60,
            render: text => {
                return <>{defaultDateFormat(text, '', 'dd/MMM/yyyy')}</>;
            },
            sorter: (a, b) => sortingComparator(a.end_date, b.end_date),
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle=""
                extra={[
                    <CaisoDrrsSwitcher
                        viewOptions={caisoDrrsViewOptions(ability)}
                        key="caiso-view-switcher"
                        view={view}
                        handleViewMode={handleView}
                    />,
                ]}
                actions={[
                    <SearchInput
                        key="drrs-registration-search"
                        onSearch={handleSearch}
                        defaultValue={pageState?.search}
                    />,
                    showCaisoSync && (
                        <Button key="caiso-drrs-sync-button" onClick={handleSync} size="large">
                            CAISO Sync
                        </Button>
                    ),
                    <Button key="caiso-drrs-reset-button" onClick={handleResetFilters} size="large">
                        Clear Filters
                    </Button>,
                    <Button
                        data-cy="download-data"
                        onClick={handleExportRegistrations}
                        loading={exportLoading}
                        size="large"
                    >
                        {!exportLoading && <DownloadOutlined />} Download CSV
                    </Button>,
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={registrations}
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                    onRow={(record, _rowIndex) => {
                        return {
                            onClick: () => handleRowClick(record),
                        };
                    }}
                />
            </WithEmptyDataTable>
            {drrsRegistration && (
                <DrrsRegistrationModal onClose={onDrrsRegistrationModalClose} registration={drrsRegistration} />
            )}
            {showCaisoSync && (
                <DrrsCaisoSyncModal origin={CaisoSyncOrigin.registrations} onClose={() => setShowCaisoSync(false)} />
            )}
        </>
    );
};
