import { useContext, useState } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import App from 'antd/lib/app';
import Alert from 'antd/lib/alert';
import Space from 'antd/lib/space';
import { IEnrollSitesBatchToPrograms, ISite } from '../../domain/site/interface';
import { attachProgramsToSitesBatch } from '../../domain/site';
import { ResourceCollapsedList } from '../../pages/events/components/ResourceCollapsedList';
import { IAffectedSites } from '../../pages/events/eventInterface';
import { AbilityContext } from '../ability/can';

interface IBatchUnenrollProgramsModalProps {
    sites: ISite[];
    onCancel: Function;
}

export const BatchUnenrollProgramsModal = ({ sites, onCancel }: IBatchUnenrollProgramsModalProps) => {
    const ability = useContext(AbilityContext);
    const { notification } = App.useApp();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => onCancel();

    const prepareUnenrollProgramObject = () => {
        const unenrollObj: Partial<IEnrollSitesBatchToPrograms> = {};

        if (ability.can('enroll', 'Program')) {
            unenrollObj['programs'] = [];
        }

        if (ability.can('enroll', 'PriceResponse')) {
            unenrollObj['price_response'] = false;
        }

        if (ability.can('enroll', 'CleanResponse')) {
            unenrollObj['clean_response'] = false;
        }

        if (ability.can('enroll', 'LeapApi')) {
            unenrollObj['leap_api_dispatch'] = false;
        }

        return unenrollObj;
    };

    const handleUnenrollPrograms = async () => {
        const siteIds = sites.map(site => site.site_id);

        setLoading(true);
        try {
            await attachProgramsToSitesBatch({
                sitesId: siteIds,
                ...prepareUnenrollProgramObject(),
            });
            onCancel(true);
        } catch (error: any) {
            notification.error({
                key: 'unenroll-sites-programs-error',
                message: error.message || 'Cannot unenroll programs!',
            });
        }
        setLoading(false);
    };

    const prepareSelectedItems = (sites: ISite[]) => {
        return sites.map(site => ({
            id: site.site_id,
            company: site?.company?.company_name,
            site: site.site_name,
        })) as unknown as IAffectedSites[];
    };

    return (
        <Modal
            title={`Unenroll programs from sites`}
            open
            destroyOnClose
            onCancel={handleCancel}
            footer={[
                <Button key="unenroll-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="unenroll-modal-submit"
                    type="primary"
                    loading={loading}
                    disabled={!sites.length}
                    onClick={handleUnenrollPrograms}
                >
                    Confirm
                </Button>,
            ]}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                    showIcon
                    type="warning"
                    message={`The following sites (${sites.length}) - will be unenrolled from all programs`}
                    style={{ marginBottom: '8px' }}
                />
                <ResourceCollapsedList resources={prepareSelectedItems(sites)} />
            </Space>
        </Modal>
    );
};
