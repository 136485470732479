import { API } from '../api';
import { IUtility } from './interface';

export const fetchUtilities = async (signal?: AbortSignal | null): Promise<IUtility[]> => {
    const response = await API.fetch('/utilities', {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load utilities list data.`);
};
