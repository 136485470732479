import React from 'react';
import { supportedEnergyMarkets } from '../domain/market-prices/interface';
import { IsoSVG } from './icons/icons';

export function UtilityOrIsoTag({ utilityOrIso }: { utilityOrIso?: string | null }) {
    if (!utilityOrIso) return null;

    const isIso = supportedEnergyMarkets.includes(utilityOrIso);

    return (
        <>
            {isIso && <IsoSVG style={{ marginRight: '5px' }} />}
            {utilityOrIso.toUpperCase()}
        </>
    );
}
