import { lowerCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Popover from 'antd/lib/popover';
import List from 'antd/lib/list';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Checkbox from 'antd/lib/checkbox';
import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import ToolOutlined from '@ant-design/icons/lib/icons/ToolOutlined';
import groupBy from 'lodash/groupBy';
import { IUser, UserType } from '../../../../domain/user/interface';
import { useAuth } from '../../../../domain/auth/useAuth';
import { COMPANY_TYPE, ICompany } from '../../../../domain/company/interface';
import { filterSelectorOption } from '../../../../components/selectors/selectorHelpers';
import { SiteLabelsSelector } from '../../../../components/selectors/SiteLabelsSelector/SiteLabelsSelector';
import { NotificationsSettings } from '../../../../components/notificationsSettings/notificationsSettings';
import { twoColumnFormLayout } from '../../../../components/form';
import { enersponseUserTypes, IUserForm, preparedUserTypeOptions } from '../../interface';
import { useUserCreate, useUserUpdate } from '../../../../domain/user/queries';
import '../../users.css';

export const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

const popoverContent = (description: string[]) => (
    <div style={{ maxWidth: '200px' }}>
        <strong style={{ fontSize: '12px' }}>Permission</strong>
        <div>
            {description.map(text => (
                <div key={text} style={{ fontSize: '10px', padding: '5px 0' }}>
                    {text}
                </div>
            ))}
        </div>
    </div>
);

const ADMIN_ROLES = [UserType.ADMIN, UserType.ACCOUNT_MANAGER];

export function UserFormModal({ user, companies, onClose, isEditMode }: IUserForm) {
    const [form] = Form.useForm();
    const [isSelectedAdminRoleType, setIsSelectedAdminRoleType] = useState(false);
    const companyId = Form.useWatch('company_id', form);
    const userType = Form.useWatch('user_type', form);

    const auth = useAuth()!;
    const isAdminRoleType = auth.user?.isAdminRoleType();
    const isControlProviderRoleType = auth.user?.user_type === UserType.CONTROL_PROVIDER;
    const sortedCompaniesByType = useMemo(() => [...companies].sort((a, b) => b.type - a.type), [companies]);
    const groupedUserTypeOptions = useMemo(
        () => Object.entries(groupBy(preparedUserTypeOptions(auth.user!.user_type), 'group')),
        [auth.user]
    );
    const [labels, setLabels] = useState<string[]>(user?.site_label || []);

    const useUserSaveMutation = user.user_id ? useUserUpdate : useUserCreate;
    const { mutate: saveUser, isLoading } = useUserSaveMutation();

    async function onUserModalFormFinish(updatedUser: IUser) {
        const isPasswordChanged = (isEditMode && updatedUser.user_password) || !isEditMode;
        const preparedUser: IUser = {
            ...updatedUser,
            site_label: labels,
            user_password: isPasswordChanged ? updatedUser.user_password : undefined,
            company_id: isAdminRoleType ? updatedUser.company_id : auth.user!.company_id,
        };
        saveUser(preparedUser, {
            onSuccess: () => onClose(),
        });
    }

    function closeUserDialog() {
        onClose();
    }

    const isTypeOptionDisabled = (userType: string, selectedCompany: ICompany | undefined) => {
        if (!selectedCompany) return true;

        const isSelectedEnersponseCompany = selectedCompany?.company_name === 'Enersponse';
        const isEnersponseUserTypes = enersponseUserTypes.includes(userType);

        if (userType === UserType.CONTROL_PROVIDER && selectedCompany.type !== COMPANY_TYPE.CONTROL_PROVIDER)
            return true;

        if (userType !== UserType.CONTROL_PROVIDER && selectedCompany.type === COMPANY_TYPE.CONTROL_PROVIDER)
            return true;

        if (!isSelectedEnersponseCompany && isEnersponseUserTypes) return true;

        if (auth.user?.user_super_admin) return false;

        return userType === UserType.ADMIN;
    };

    const handleValueChange = (changedValues: any, allValues: IUser) => {
        const changedCompanyId = changedValues.company_id;
        if (changedCompanyId) {
            form.setFieldsValue({ user_type: undefined });
        }
    };

    const handleUserTypeChange = async (e: RadioChangeEvent) => {
        const selectedType = e.target.value;
        setIsSelectedAdminRoleType(ADMIN_ROLES.includes(selectedType));
    };

    useEffect(() => {
        form.validateFields(['user_phone'])
            .then(data => { })
            .catch(err => { });
    }, [form, isSelectedAdminRoleType]);

    useEffect(() => {
        setIsSelectedAdminRoleType(ADMIN_ROLES.includes(user.user_type as UserType));
    }, [user.user_type]);

    return (
        <Modal
            open
            title={user?.user_id ? 'Edit user' : 'New user'}
            destroyOnClose
            onCancel={closeUserDialog}
            footer={[
                <Button key="cancel" onClick={closeUserDialog}>
                    Cancel
                </Button>,
                <Button data-cy="save-user" key="submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            className="user-modal"
            data-cy="user-modal"
        >
            <Form
                form={form}
                name="user-form"
                preserve={false}
                {...formItemLayout}
                onFinish={onUserModalFormFinish}
                onValuesChange={handleValueChange}
                initialValues={{
                    ...user,
                    ...(isControlProviderRoleType && { user_type: UserType.CONTROL_PROVIDER }),
                }}
                layout="vertical"
            >
                <Form.Item name="user_id" hidden>
                    <Input data-cy="user-id" />
                </Form.Item>

                {isAdminRoleType && (
                    <Form.Item
                        name="company_id"
                        label={<Typography.Text strong>Company</Typography.Text>}
                        hasFeedback
                        rules={[{ required: true, message: 'Please select company!' }]}
                        {...twoColumnFormLayout}
                    >
                        <Select
                            data-cy="user-company-selector"
                            placeholder="Please select company"
                            size="large"
                            showSearch
                            filterOption={filterSelectorOption}
                            options={sortedCompaniesByType.map(c => ({
                                value: c.company_id,
                                label: c.company_name,
                                companyType: c.type,
                            }))}
                            optionRender={option => {
                                return (
                                    <div style={{ maxWidth: '460px' }}>
                                        {option.data?.companyType === COMPANY_TYPE.CONTROL_PROVIDER && (
                                            <ToolOutlined style={{ marginRight: 8 }} />
                                        )}
                                        <Typography.Text ellipsis>{option.label}</Typography.Text>
                                    </div>
                                );
                            }}
                        />
                    </Form.Item>
                )}

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            {...twoColumnFormLayout}
                            name="user_fname"
                            label={<Typography.Text strong>First name</Typography.Text>}
                            rules={[
                                { required: true, message: 'First name is required!' },
                                { max: 20, message: 'Number of characters should be less than 20' },
                            ]}
                        >
                            <Input placeholder="John" size="large" data-cy="user-fname" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            {...twoColumnFormLayout}
                            name="user_lname"
                            label={<Typography.Text strong>Last name</Typography.Text>}
                            rules={[
                                { required: true, message: 'Last name is required!' },
                                { max: 20, message: 'Number of characters should be less than 20' },
                            ]}
                        >
                            <Input placeholder="Dow" size="large" data-cy="user-lname" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            {...twoColumnFormLayout}
                            name="user_email"
                            label={<Typography.Text strong>Email</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter your email!' },
                                { type: 'email', message: 'Please enter valid email!' },
                                { max: 128, message: 'Number of characters should be less than 128' },
                            ]}
                        >
                            <Input placeholder="hello@example.com" size="large" data-cy="user-email" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            {...twoColumnFormLayout}
                            name="user_phone"
                            rules={[
                                {
                                    required: isSelectedAdminRoleType,
                                    message: 'Please enter your phone!',
                                },
                                {
                                    pattern: /^\+[1-9]\d{1,14}$/g,
                                    message: 'Must be a number up to fifteen digits in length starting with a "+"',
                                },
                            ]}
                            label={<Typography.Text strong>Phone number</Typography.Text>}
                        >
                            <Input placeholder="+19000000000" size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item noStyle dependencies={['company_id']}>
                    {({ getFieldValue }) => {
                        const selectedCompanyId = getFieldValue('company_id');
                        const selectedCompany = companies.find(c => c.company_id === selectedCompanyId);
                        return (
                            <Form.Item
                                name="user_type"
                                label={<Typography.Text strong>Type</Typography.Text>}
                                hasFeedback
                                rules={[{ required: true, message: 'Please select user type!' }]}
                            >
                                <Radio.Group onChange={handleUserTypeChange}>
                                    {groupedUserTypeOptions.map(group => (
                                        <List
                                            size="small"
                                            key={group[0]}
                                            className="user-type-list"
                                            data-cy="user-type-list"
                                        >
                                            {group[1].map(groupItem => (
                                                <List.Item key={groupItem.label}>
                                                    <Radio
                                                        data-cy={`user-type-${lowerCase(groupItem.label).replace(' ', '-')}`}
                                                        value={groupItem.value}
                                                        disabled={isTypeOptionDisabled(
                                                            groupItem.value,
                                                            selectedCompany
                                                        )}
                                                    >
                                                        <Space>
                                                            {groupItem.label}
                                                            <Popover content={popoverContent(groupItem.description)}>
                                                                <QuestionCircleOutlined />
                                                            </Popover>
                                                        </Space>
                                                    </Radio>
                                                </List.Item>
                                            ))}
                                        </List>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        );
                    }}
                </Form.Item>
                <Form.Item
                    name="user_password"
                    rules={[
                        {
                            pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                            message:
                                'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                        },
                        ...(!isEditMode ? [{ required: true, message: 'Password is required!' }] : []),
                    ]}
                    label={<Typography.Text strong>Password</Typography.Text>}
                >
                    <Input.Password
                        placeholder="input password"
                        autoComplete="new-password"
                        size="large"
                        data-cy="user-password"
                    />
                </Form.Item>

                {auth.user?.isAdminRoleType() && (
                    <>
                        <NotificationsSettings />

                        <Form.Item
                            label={<Typography.Text strong>Site Labels</Typography.Text>}
                            tooltip="Used for additional grouping and filtering email event notifications"
                            style={{ marginTop: 24, marginBottom: 12 }}
                        >
                            <SiteLabelsSelector companyId={companyId} siteLabels={labels} setSiteLabels={setLabels} />
                        </Form.Item>

                        {[UserType.CUSTOMER, UserType.CONTROL_PROVIDER].includes(userType as UserType) &&
                            auth.user?.isAdmin() && (
                                <>
                                    <Divider plain>Feature flags</Divider>
                                    <Form.Item name="user_feature_flag">
                                        <Checkbox.Group>
                                            <Checkbox value="event:manage" data-cy="event-manage-checkbox">
                                                Event manage{' '}
                                                <Tooltip title="By default only Enersponse admin can create or edit events. Enabling this flag allows a user to perform these actions for the sites they control.">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </>
                            )}
                    </>
                )}
            </Form>
        </Modal>
    );
}
