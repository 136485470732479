import EditOutlined from '@ant-design/icons/EditOutlined';
import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import { AccountList } from '../../../components/company/AccountList';
import { CompanyFormData, ICompanyFormData } from '../../../components/company/CompanyFormData';
import { ManageAccountModal } from '../../../components/company/ManageAccountModal';
import { filterSelectorOption } from '../../../components/selectors/selectorHelpers';
import { WithControlledTooltip } from '../../../components/withControlledTooltip';
import { IAccount } from '../../../domain/account/interface';
import { useAuth } from '../../../domain/auth/useAuth';
import { COMPANY_TYPE, COMPANY_VERTICAL, ICompany } from '../../../domain/company/interface';
import {
    useCompanyCreateMutation,
    useCompanyUpdateMutation,
    useSyncEmersonSitesMutation,
} from '../../../domain/company/queries';
import { AlternativeNamesInput } from './AlternativeNamesInput/AlternativeNamesInput';

interface ICompanyFormModal {
    company: ICompany | Partial<ICompany>;
    onClose: Function;
    isEditMode: boolean;
}

const companyVerticalOption = COMPANY_VERTICAL.sort().map(vertical => ({
    label: vertical,
    value: vertical,
}));

export function CompanyFormModal({ company, onClose, isEditMode }: ICompanyFormModal) {
    const { notification } = App.useApp();
    const auth = useAuth()!;

    const [form] = Form.useForm();

    const companyType = Form.useWatch('type', form);

    const [utilityNames, setUtilityNames] = useState<string[]>(company.utility_names || []);
    const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>(company.accounts || []);
    const [isManageAccountsModalOpen, setIsManageAccountsModalOpen] = useState<boolean>(false);

    const companyCreateMutation = useCompanyCreateMutation();
    const companyUpdateMutation = useCompanyUpdateMutation();
    const { mutateAsync: syncEmersonSites, isLoading: isSyncEmersonLoading } = useSyncEmersonSitesMutation();

    const isLoading = companyCreateMutation.isLoading || companyUpdateMutation.isLoading;

    async function onCompanyModalFormFinish(companyFormData: ICompanyFormData) {
        const company = CompanyFormData.toEntity(companyFormData);
        company.utility_names = utilityNames;

        try {
            if ('company_id' in company && !!company.company_id) {
                await companyUpdateMutation.mutateAsync(company);
            } else {
                await companyCreateMutation.mutateAsync(company);
            }

            notification.success({ key: 'company-save-info', message: 'Company saved successfully!' });
            onClose(company);
        } catch (err: any) {
            notification.error({ key: 'company-save-info', message: err?.message || 'Cannot save company!' });
        }
    }

    function closeCompanyDialog() {
        onClose();
    }

    const onSyncEmersonAPI = async () => {
        try {
            await syncEmersonSites(company.company_id!);
            notification.info({ key: 'emerson-sync-info', message: 'Synchronization completed' });
        } catch (error: any) {
            notification.error({
                key: 'emerson-sync-error',
                message: error.message || 'Synchronization failed, please try again later',
            });
        }
    };

    const openManageProductsModal = () => {
        setIsManageAccountsModalOpen(true);
    };

    const closeManageProductsModal = () => {
        setIsManageAccountsModalOpen(false);
    };

    const handleManageAccountsFinish = (updatedAccounts: IAccount[]) => {
        const updatedAccountIds = updatedAccounts.map(account => account.salesforce_id);

        setSelectedAccounts(updatedAccounts);
        form.setFieldValue('account_ids', updatedAccountIds);
        form.validateFields(['account_ids']);

        setIsManageAccountsModalOpen(false);
    };

    const handleUnlinkAccount = (salesforceId: string) => {
        const updatedAccounts = selectedAccounts.filter(account => account.salesforce_id !== salesforceId);
        const updatedAccountIds = updatedAccounts.map(account => account.salesforce_id);

        setSelectedAccounts(updatedAccounts);
        form.setFieldValue('account_ids', updatedAccountIds);
        form.validateFields(['account_ids']);
    };

    useEffect(() => {
        setSelectedAccounts(orderBy(company.accounts, ['account_name'], ['asc']));
    }, [company.accounts]);

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Edit company' : 'New company'}
            onCancel={closeCompanyDialog}
            footer={[
                <Button key="company-modal-cancel" onClick={closeCompanyDialog}>
                    Cancel
                </Button>,
                <Button
                    key="company-modal-submit"
                    data-cy="save-company"
                    type="primary"
                    loading={isLoading}
                    onClick={form.submit}
                >
                    Save
                </Button>,
            ]}
            data-cy="company-modal"
        >
            <Form
                form={form}
                name="company-form"
                preserve={false}
                layout="vertical"
                onFinish={onCompanyModalFormFinish}
                initialValues={CompanyFormData.fromEntity(company)}
                className="company-form"
            >
                <Form.Item name="company_id" hidden>
                    <Input data-cy="company-id" />
                </Form.Item>

                <Form.Item
                    name="company_name"
                    label={<Typography.Text strong>Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter name!' },
                        { max: 100, message: 'Number of characters should be less than 100' },
                    ]}
                >
                    <Input placeholder="Company name" size="large" data-cy="company-name" />
                </Form.Item>

                <Form.Item
                    name="company_vertical"
                    hasFeedback
                    rules={[{ required: true, message: 'Please select vertical!' }]}
                    label={<Typography.Text strong>Vertical</Typography.Text>}
                >
                    <Select
                        size="large"
                        placeholder="Please select Vertical"
                        options={[...companyVerticalOption, { label: 'Other', value: 'Other' }]}
                        showSearch
                        filterOption={filterSelectorOption}
                        data-cy="company_vertical"
                    />
                </Form.Item>

                {!isEditMode && (
                    <Form.Item name="type" label={<Typography.Text strong>Type</Typography.Text>}>
                        <Radio.Group>
                            <Radio data-cy="customer-company" value={COMPANY_TYPE.CUSTOMER_COMPANY}>
                                Customer Company
                            </Radio>
                            <Radio data-cy="cp-company" value={COMPANY_TYPE.CONTROL_PROVIDER}>
                                Control Provider
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && (
                    <Form.Item style={{ marginBottom: 0 }}>
                        <AlternativeNamesInput utilities={utilityNames} setUtilityNames={setUtilityNames} />
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && (
                    <>
                        <Divider plain>Salesforce</Divider>

                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="account_ids"
                                    label={
                                        <>
                                            <Typography.Text strong>Linked Salesforce Accounts</Typography.Text>
                                            <Button size="small" type="link" onClick={openManageProductsModal}>
                                                Manage <EditOutlined />
                                            </Button>
                                        </>
                                    }
                                    rules={(() => {
                                        return !isEditMode && companyType === COMPANY_TYPE.CUSTOMER_COMPANY
                                            ? [{ required: true, message: 'Please select Salesforce Account!' }]
                                            : [];
                                    })()}
                                >
                                    <AccountList accounts={selectedAccounts} onUnlinkAccount={handleUnlinkAccount} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                {auth.user?.isAdmin() && company.type === COMPANY_TYPE.CUSTOMER_COMPANY && (
                    <>
                        <Divider plain>Integrations</Divider>

                        <Form.Item label={<Typography.Text strong>Emerson API key</Typography.Text>}>
                            <Row gutter={[8, 16]} justify="space-between">
                                <Col span={isEditMode ? 20 : 24}>
                                    <Form.Item noStyle name={['api_credentials', 'emerson_key']}>
                                        <Input.Password placeholder="Emerson API key" size="large" />
                                    </Form.Item>
                                </Col>

                                {isEditMode && (
                                    <>
                                        <Col span={4}>
                                            <WithControlledTooltip
                                                title="Please set API key"
                                                extraVisibleCondition={!company.api_credentials?.emerson_key}
                                            >
                                                <Button
                                                    style={
                                                        !company.api_credentials?.emerson_key
                                                            ? { pointerEvents: 'none' }
                                                            : {}
                                                    }
                                                    type="primary"
                                                    onClick={onSyncEmersonAPI}
                                                    loading={isSyncEmersonLoading}
                                                    size="large"
                                                    disabled={!company.api_credentials?.emerson_key}
                                                >
                                                    Sync
                                                </Button>
                                            </WithControlledTooltip>
                                        </Col>

                                        {company.api_credentials?.emerson_key_error && (
                                            <Col span={24}>
                                                <Alert
                                                    showIcon
                                                    message={company.api_credentials?.emerson_key_error}
                                                    type="warning"
                                                />{' '}
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form>

            {isManageAccountsModalOpen && (
                <ManageAccountModal
                    company={company.company_id ? (company as ICompany) : null}
                    accounts={selectedAccounts}
                    onOk={handleManageAccountsFinish}
                    onClose={closeManageProductsModal}
                />
            )}
        </Modal>
    );
}
