import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchUtilities } from './index';
import { IUtility } from './interface';

export const useUtilityListQuery = (query: {}, options = {}) => {
    return useQuery<IUtility[], Error>({
        queryKey: [QueryKey.UTILITIES, query],
        queryFn: ({ signal }) => fetchUtilities(signal),
        ...options,
    });
};
