import React from 'react';
import Input from 'antd/lib/input';
import Tooltip from 'antd/lib/tooltip';

interface ISearchInputProps {
    defaultValue?: string;
    tooltip?: string;
    onSearch: (value: string) => void;
}

export const SearchInput: React.FC<ISearchInputProps> = ({ defaultValue = '', tooltip, onSearch }) => {
    const handleSearch = (value: string, event: any) => {
        onSearch(value.trim());

        event.target.blur();
    };

    return (
        <Tooltip title={tooltip}>
            <Input.Search
                allowClear
                defaultValue={defaultValue}
                size="large"
                style={{ width: '200px' }}
                onSearch={handleSearch}
                data-cy="search-input"
                placeholder="Search"
            />
        </Tooltip>
    );
};
