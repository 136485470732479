import subMinutes from 'date-fns/subMinutes';

export const minutesToHours = (minutes?: number): number => {
    return minutes ? minutes / 60 : 0;
};

export const timeToMinutes = (hours: number, minutes = 0): number => {
    return hours * 60 + minutes;
};

export const getTimeAsMinutes = (time: string) => {
    const [hour, minutes] = time.split(':');
    return timeToMinutes(parseInt(hour), parseInt(minutes));
};

export const dateToUtcLocale = (date: Date) => {
    const offsetInMinutes = date.getTimezoneOffset();

    return subMinutes(date, offsetInMinutes);
}
