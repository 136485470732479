import Card from 'antd/lib/card';
import './informationCard.css';

interface IInformationCard {
    children: React.ReactNode;
    title: React.ReactNode | string;
    classNames?: string[];
}

export const InformationCard = ({ children, title, classNames = [] }: IInformationCard) => (
    <Card size="small" className={`information-card ${classNames?.join(' ')}`}>
        <div className="information-card-title-container">{title}</div>
        <div className="information-card-content">{children}</div>
    </Card>
);
