import { useState } from 'react';
import { useParams } from 'react-router-dom';
import App from 'antd/lib/app';
import { resetPasswordComplete } from '../../domain/auth/auth';
import { NewPasswordForm } from './newPasswordForm';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import logoImg from '../../logo.png';

export const ResetPasswordComplete = () => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const { token } = useParams<{ token: string }>();

    useDocumentTitle('Reset Password');

    const onFinish = async (values: { password: string }) => {
        setLoading(true);
        try {
            await resetPasswordComplete({ password: values.password, token });
            setIsFinished(true);
        } catch (error: any) {
            notification.error({
                key: 'reset-password-complete-error',
                message: 'Reset Password Error',
                description: error.message,
            });
        }
        setLoading(false);
    };

    return (
        <>
            <img className="logo" src={logoImg} alt="Enersponse logo" />
            <NewPasswordForm title="Reset password" onFinish={onFinish} isFinished={isFinished} loading={loading} />
        </>
    );
};
