import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Collapse from 'antd/lib/collapse';
import Empty from 'antd/lib/empty';
import Flex from 'antd/lib/flex';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import React from 'react';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { IProduct } from '../../domain/product/interface';
import { ActivityTag } from '../ActivityTag';
import { UtilityOrIsoTag } from '../UtilityOrIsoTag';

interface IProductListProps {
    products: IProduct[];
    onUnlinkProduct: (salesforceId: string) => void;
}

export const ProductList = ({ products, onUnlinkProduct }: IProductListProps) => {
    const { modal } = App.useApp();

    const handleUnlinkProduct = (product: IProduct) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `You are going to unlink product "${product.name}". Are you sure?`,
            onOk() {
                onUnlinkProduct(product.salesforce_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    if (!products || products.length === 0) {
        return (
            <Flex style={{ height: 90 }} justify='center' align='center'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Link Salesforce Products to see them in list' />
            </Flex>
        );
    }

    return (
        <Collapse>
            {products.map((product, index) => (
                <Collapse.Panel
                    key={index}
                    header={
                        <Flex justify='space-between' align='center'>
                            <Typography.Text ellipsis={{ tooltip: { title: product.name } }} style={{ maxWidth: '330px' }}>
                                {product.name}
                            </Typography.Text>
                            <Button size='small' onClick={handleUnlinkProduct(product)}>
                                Unlink
                            </Button>
                        </Flex>
                    }
                >
                    <Row gutter={[16, 8]}>
                        <ListItem label='Name' value={product.name} />
                        <ListItem label='Status' value={<ActivityTag isActive={product.is_active!} />} />
                        <ListItem label='Utility/ISO' value={<UtilityOrIsoTag utilityOrIso={product.utility_or_iso} />} />
                        <ListItem label='Opportunity' value={product.access_partner} />
                        <ListItem label='Program Type' value={product.program_type} />
                        <ListItem label='Start date' value={defaultDateFormat(product.start_date, '-')} />
                        <ListItem label='End date' value={defaultDateFormat(product.end_date, '-')} />
                        <ListItem label='Enrollment Deadline' value={product.enrollment_deadline} />
                        <ListItem label='Salesforce ID' value={product.salesforce_id} />
                        <ListItem label='Last Modified' value={`${defaultDateFormat(product.salesforce_updated_date, '-')} / ${product.salesforce_updated_by || '-'}`} />
                        <ListItem label='Description' value={product.description} />
                        <ListItem label='Other program rules' value={product.other_program_rules} />
                    </Row>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

interface IListItemProps {
    label: string;
    value?: string | React.JSX.Element;
    labelSpan?: number;
    valueSpan?: number;
}

function ListItem({ label, value, labelSpan = 10, valueSpan = 14 }: IListItemProps) {
    return (
        <>
            <Col span={labelSpan}>
                <Typography.Text strong>{label}</Typography.Text>
            </Col>
            <Col span={valueSpan}>
                <Typography.Text>{value || '-'}</Typography.Text>
            </Col>
        </>
    );
}
