import isNil from 'lodash/isNil';

export function compareWithLocale(a: string | number | null | undefined, b: string | number | null | undefined): number {
    // If both values are null or undefined or 0, consider them equal
    if (!a && !b) return 0;

    // If a is null or undefined, consider it smaller
    if (isNil(a)) return -1;

    // If b is null or undefined, consider it smaller
    if (isNil(b)) return 1;

    // If both are numbers, compare them directly
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }

    // Convert both to strings if necessary and compare using localeCompare
    return String(a).localeCompare(String(b));
}
