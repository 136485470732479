import { useState } from 'react';
import Button from 'antd/lib/button';
import { CreateAccessPartnerFormModal } from './CreateAccessPartnerFormModal';

export const CreateAccessPartnerAction = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button size='small' type="default" onClick={handleOpenModal} style={{ marginLeft: 'auto' }}>Create New</Button>

            {isModalOpen &&
                <CreateAccessPartnerFormModal onClose={handleCloseModal} onFormFinish={handleCloseModal} />
            }
        </>
    );
};
