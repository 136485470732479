export const GET_ROW_HEIGHT_TYPE = {
    DEFAULT: 'default',
    INTERVAL_DATA: 'interval_data'
};

export const getRowHeightDefault = (numberOfRows: number) => {
    if (numberOfRows < 7) {
        return 60;
    }
    if (numberOfRows < 14) {
        return 50;
    }
    if (numberOfRows < 21) {
        return 40;
    }
    if (numberOfRows < 30) {
        return 30;
    }

    return 20;
};

export const getRowHeightIntervalData = (numberOfRows: number) => {
    return numberOfRows < 95 ? 45 : 20;
};

export const getRowHeight:{[key:string]:Function} = ({
    [ GET_ROW_HEIGHT_TYPE.INTERVAL_DATA ]:getRowHeightIntervalData,
    [GET_ROW_HEIGHT_TYPE.DEFAULT]:getRowHeightDefault 
});



export const calculateChartHeight = (numberOfRows:number, type=GET_ROW_HEIGHT_TYPE.DEFAULT) => {
    const rowHeight = getRowHeight[type](numberOfRows);
    return numberOfRows * rowHeight;
};
