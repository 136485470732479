import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import App from 'antd/lib/app';
import Skeleton from 'antd/lib/skeleton';
import { AbilityContext } from '../../../../components/ability/can';
import config from '../../../../config';
import { useCarbonIntensityListQuery } from '../../../../domain/carbon-intensity/queries';
import { ISiteLocation } from '../../../../domain/site/interface';
import { BASE_COLOR } from '../../../../theme';
import { CarbonIntensityMapLegends } from './CarbonIntensityMapLegends';
import { calculateClusterStyle, clusterMarkerStyle } from './formatMapStyle';
import { getSiteMarker } from './formatSiteMarker';
import { LocalMWMapLegends } from './LocalMWMapLegends';
import { SelectedSiteInfo } from './SelectedSiteInfo';
import { useUpdateGoogleMapStyles } from './useUpdateGoogleMapStyles';

const mapState = {
    zoom: 2,
    // initial center of the map
    center: {
        lat: 0,
        lng: -180,
    },
    markerClusterOptions: {
        gridSize: 90,
        styles: clusterMarkerStyle,
        maxZoom: 15,
        zoomOnClick: true,
        minimumClusterSize: 5,
        averageCenter: true,
    },
    styles: [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: BASE_COLOR,
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#575656',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9b9b9b',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#e9e9e9',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#e7f3e4',
                },
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#c4e5f3',
                },
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    saturation: '0',
                },
                {
                    color: '#d1e5e9',
                },
            ],
        },
    ],
};

//google return longitude not in range [-180,+180]
const normalizeLongitude = (longitude: number) => {
    let preparedLongitude = longitude;

    while (Math.abs(preparedLongitude) > 180) {
        if (preparedLongitude > 180) {
            preparedLongitude -= 360;
        } else if (preparedLongitude < 0) {
            preparedLongitude += 360;
        }
    }

    return preparedLongitude;
};

interface ISiteMapProps {
    siteLocations: ISiteLocation[];
    onMarkerClick: Function;
    onMapLocationChange: Function;
}

export function SitesMap(props: ISiteMapProps) {
    const { notification } = App.useApp();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.GOOGLE_MAP_API,
    });
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [activeSite, setActiveSite] = useState<ISiteLocation | any>(null);
    const sitePriceMap = useRef(new Map<string, number>());
    const ability = useContext(AbilityContext);

    const { data: carbonIntensity = [], isLoading, isError, error } = useCarbonIntensityListQuery({
        enabled: ability.can('read', 'carbon-intensity'),
    });

    if (isError) {
        notification.error(
            { key: 'carbon-intensity-fetch-error', message: error?.message || 'Cannot fetch Carbon Intensity!' });
    }

    const onMarkerClickHandler = (siteLocation: ISiteLocation) => {
        setActiveSite(siteLocation);
        props.onMarkerClick(siteLocation);
    };

    const fitMapBounds = useCallback((siteLocations: ISiteLocation[]) => {
        if (!map) return;

        const bounds = new google.maps.LatLngBounds();

        siteLocations.forEach(site => {
            bounds.extend(new google.maps.LatLng(site.site_lat!, site.site_long!));
        });

        map.fitBounds(bounds);
    }, [map]);

    const onMapLoad = useCallback((map: google.maps.Map) => {
        if (!map) return;

        setMap(map);

        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
    }, []);

    const onUnmount = React.useCallback(() => {
        setMap(null);
    }, []);

    const onMapDragEnd = () => {
        if (!map) return;

        const mapCenter = map.getCenter()?.toJSON() as google.maps.LatLngLiteral;
        const preparedMapCenter = {
            lat: mapCenter.lat,
            lng: normalizeLongitude(mapCenter.lng),
        };
        props.onMapLocationChange(preparedMapCenter);
    };

    function formatSiteMarkerPriceMap(siteLocations: ISiteLocation[]) {
        sitePriceMap.current.clear();
        siteLocations.forEach(
            siteLocation => sitePriceMap.current.set(`${siteLocation.site_name}`, siteLocation.lmp_price_rt || 0));
    }

    const calculateClusterMarkerStyle = useCallback(
        (markers: any[]) => {
            if (!markers.length) {
                return {
                    index: 1,
                    text: '0',
                    title: '',
                };
            }

            const totalPrice = markers.reduce((prevValue: number, curItem: any) => {
                const price = sitePriceMap.current.get(curItem.title) || 0;
                return prevValue + price;
            }, 0);

            const avg = totalPrice / markers.length;
            const clusterStyle = calculateClusterStyle(avg);

            return {
                index: clusterStyle,
                text: `${markers.length}`,
                title: '',
            };
        },
        [],
    );

    useUpdateGoogleMapStyles(isLoaded, map, carbonIntensity);

    useEffect(() => {
        fitMapBounds(props.siteLocations);
        formatSiteMarkerPriceMap(props.siteLocations);
    }, [fitMapBounds, props.siteLocations]);

    if (!isLoaded) {
        return <Skeleton loading paragraph={{ rows: 5 }} active />;
    }

    return (
        <>
            <GoogleMap
                id='dashboard'
                mapContainerStyle={{ height: '65vh', width: '100%' }}
                options={{
                    styles: mapState.styles,
                    maxZoom: 19,
                }}
                zoom={mapState.zoom}
                center={mapState.center}
                onDragEnd={onMapDragEnd}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
            >
                <MarkerClusterer
                    key='marker-cluster'
                    options={{
                        ...mapState.markerClusterOptions,
                        calculator: calculateClusterMarkerStyle,
                    }}
                >
                    {clusterer =>
                        // @ts-ignore
                        props.siteLocations.map((s: any) => {
                            const marker = getSiteMarker(s);
                            return <Marker {...marker} onClick={() => onMarkerClickHandler(s)} noClustererRedraw={true} clusterer={clusterer} />;
                        })
                    }
                </MarkerClusterer>

                {activeSite && (
                    <SelectedSiteInfo activeSiteLocation={activeSite} setActiveSiteLocation={setActiveSite} />
                )}
            </GoogleMap>

            <LocalMWMapLegends />

            {ability.can('read', 'carbon-intensity') && (
                <CarbonIntensityMapLegends />
            )}
        </>
    );
}

function propsAreEqual(prev: ISiteMapProps, next: ISiteMapProps) {
    return prev.siteLocations === next.siteLocations;
}

export const MemoizedSitesMap = memo(SitesMap, propsAreEqual);
