import { IEvent } from '../../../domain/event/interface';
import { ProgramTag } from '../../../components/ProgramTag';

export interface IEventProgramsContextProps {
    value?: IEvent;
}

export const EventProgramsContext = ({ value }: IEventProgramsContextProps) => {
    if (!value) return <></>;

    const programs: string[] = value?.event_context?.programs || [];

    return (
        <>
            {programs.map((name: string, i: number) => (
                <ProgramTag programName={name} key={i} />
            ))}
        </>
    );
};
